/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 */

import React, {useEffect, useRef, useState} from 'react';
import {
    Row,
    Col,
    Button,
    TextInput, Checkbox, Image, Dropdown, Typography, PasswordInput
}                                           from 'diginet-core-ui/components';
import * as mainActions                     from "../../redux/main/main_actions";
import Config                               from "../../config";
import {useDispatch}                        from "react-redux";
import {Button as ButtonSM}                 from 'devextreme-react';
import LocalizedStrings                     from "react-localization";
import {localize}                      from "../../localize/localize";
// import W00F1015                             from "../W0X/W00/W00F1015/W00F1015";
import moment                               from 'moment';
import localeCore                           from 'diginet-core-ui/locale';

const logoImg      = require('../../assets/images/general/logo.svg');
const dataLanguage = [
    {
        ID:   "vi",
        Name: "Tiếng Việt"
    },
    {
        ID:   "en",
        Name: "English"
    }
];
const Login        = (props) => {
    const dispatch                                  = useDispatch();
    const [userID, setUserID]                       = useState("");
    const [passW, setPassW]                         = useState("");
    const [message, setMessage]                     = useState("");
    const [isSubmit, setIsSubmit]                   = useState(false);
    const [lang, setLang]                           = useState("vi");
    const [saveAccount, setSaveAccount]             = useState(false);
    // const [openPopupW00F1015, setOpenPopupW00F1015] = useState(false);

    const refUserID    = useRef(null);
    const refDataForm    = useRef({
        userID: "",
        passW: ""
    });
    const refInputTimer1    = useRef(null);
    const refInputTimer2    = useRef(null);

    useEffect(() => {
        let lang               = Config.getLocalStorage("langBEM");
        let accountSaved       = Config.getLocalStorage('LOGINBEM');
        const hasRememberLogin = String(Config.getSetting("FORCE_USER_LOGOUT")).toLowerCase() === "false";
        if (hasRememberLogin) {
            accountSaved = accountSaved && Config.isJson(accountSaved) ? JSON.parse(accountSaved) : null;
            if (accountSaved) {
                setSaveAccount(true);
                refDataForm.current = {userID: accountSaved.user, passW: accountSaved.pass};
                setUserID(accountSaved.user);
                setPassW(accountSaved.pass);
            }
        }
        if (lang !== null && typeof lang !== 'undefined') {
            setLang(lang);
        }
        if (refUserID.current) refUserID.current.focus();
    }, []);

    const onKeyDown = (e) => {
        if (e?.key === "Enter") {
            const value = e?.target?.value || "";
            const {userID, passW} = refDataForm.current || {};
            switch (e.target.name) {
                case 'UserID':
                    if (value && passW) onFormSubmit();
                    break;
                case 'PassW':
                    if (value && userID) onFormSubmit();
                    break;
                default:
                    break;
            }
        }
    }

    const onEventSave = () => {
        const submit = document.getElementById("btnSubmit");
        submit.click();
    }

    const handleChange = (e) => {
        const value = e?.target?.value || "";
        switch (e.target.name) {
            case "UserID":
                refDataForm.current = {...refDataForm.current, userID: value};
                if (refInputTimer1.current) clearTimeout(refInputTimer1.current);
                refInputTimer1.current = setTimeout(() => {
                    setUserID(value);
                }, 500);
                break;
            case "PassW":
                refDataForm.current = {...refDataForm.current, passW: value}
                if (refInputTimer2.current) clearTimeout(refInputTimer2.current);
                refInputTimer2.current = setTimeout(() => {
                    setPassW(value);
                }, 500);
                break;
            default:
                break;
        }
    };

    const handleLanguageChange = (e) => {
        const {value = "vi"} = e || {};
        Config.setLocalStorage('langBEM', value);
        localeCore.set(value);
        loadLocalize();
        setLang(value);
    };

    const handleSaveAccount = (e) => {
        setSaveAccount(!!e?.value);
    };

    const loadLocalize = () => {
        let cfLocalize = null;
        let lang       = Config.getLocalStorage("langBEM");

        if (!lang || lang.length !== 2) {
            lang = "vi";
            Config.setLocalStorage('langBEM', 'vi');
        }

        try {
            const lc   = Config.getLocalStorage('LOCALIZE');
            cfLocalize = lc ? JSON.parse(lc) : null;

            if (!cfLocalize || cfLocalize.timestamps !== localize.timestamps) {
                cfLocalize = localize;
                Config.setLocalStorage('LOCALIZE', JSON.stringify(cfLocalize));
            }

        } catch (e) {

        }

        Config.localization = new LocalizedStrings(cfLocalize);
        Config.localization.setLanguage(lang);

        if (lang === "vi") {
            Config.language = "84";
        } else {
            Config.language = "01";
        }
    };

    //get user lockout time..
    const getUserLockoutTime = (defaultValue = 10) => {
        let lockoutTime = Config.getSetting("USER_LOCKOUT_DURATION");
        if (!lockoutTime && lockoutTime !== 0) return defaultValue;
        return parseInt(lockoutTime) || defaultValue;
    };

    const onFormSubmit = () => {
        const {userID, passW} = refDataForm.current || {};
        setMessage("");
        if (!userID || !passW) {
            setMessage(Config.lang("Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung"));
            return null;
        }

        // check User is login failed 3 times
        let blockListLogin = {};
        if (userID) {
            blockListLogin = Config.getLocalStorage('BLOCK_LIST_LOGIN', true) || {};
            const lockoutTime = getUserLockoutTime()*60*1000;
            const time     = blockListLogin[userID] ? moment().valueOf() - blockListLogin[userID] : null;
            if (time && time < lockoutTime) { //compare time dynamic from settings
                setMessage(Config.lang(`Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%${moment(lockoutTime - time).format(`m [${Config.lang('phut')}] ss ${Config.lang('Giay').toLowerCase()}`)}%_de_dang_nhap_lai`));
                return null;
            } else if (blockListLogin[userID]) {
                delete blockListLogin[userID];
                Config.setLocalStorage('BLOCK_LIST_LOGIN', JSON.stringify(blockListLogin));
            }
        }

        setIsSubmit(true);
        dispatch(mainActions.login({
            username: userID,
            password: passW,
            language: Config.language || "84"
        }, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message   = "";

                switch (errorCode) {
                    case "US034":
                        message = Config.lang("Tai_khoan_chua_duoc_thiet_lap_nhan_vien_Ban_khong_the_dang_nhap");
                        break;
                    case "US004":
                        message = Config.lang("Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    case "US005":
                    case "US029":
                        message = Config.lang("Tai_khoan_khong_co_quyen");
                        break;
                    case "US038":
                        const lockoutTime = getUserLockoutTime();
                        message = Config.lang(`Ban_da_dang_nhap_sai_qua_so_lan_cho_phep_vui_long_cho_%${lockoutTime} ${Config.lang('phut')}%_de_dang_nhap_lai`);

                        // save User to list user is login failed 3 times
                        const blockListLogin   = Config.getLocalStorage('BLOCK_LIST_LOGIN', true) || {};
                        blockListLogin[userID] = moment().valueOf();
                        Config.setLocalStorage('BLOCK_LIST_LOGIN', JSON.stringify(blockListLogin));

                        break;
                    default:
                        message = error.message || Config.lang("Loi_chua_xac_dinh");
                        break;
                }

                setIsSubmit(false);
                setMessage(message);
                return false;
            } else if (data) {
                let user    = data.user || {};
                let lang    = Config.getLocalStorage("langBEM");

                if (!lang || lang.length !== 2) {
                    lang = "vi";
                    Config.setLocalStorage('langBEM', lang);
                }

                if (saveAccount) {
                    const login = {
                        user: userID,
                        pass: passW
                    };
                    Config.setLocalStorage("LOGINBEM", JSON.stringify(login));
                } else {
                    Config.removeLocalStorage("LOGINBEM");
                }

                const expire = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;

                Config.token = {
                    id:     data.token,
                    expire: expire
                };
                user.type    = 2;

                //if page go to from linkMail then get menuType from linkMail ( BA An)
                const menuType = 1;
                const divisionID = user?.DivisionID || "";

                Config.setLocalStorage('DIVISIONBEM', divisionID);
                Config.setLocalStorage('MENUTYPEBEM', menuType);
                Config.setLocalStorage('PROFILEBEM', Config.encryptData(JSON.stringify(user)));
                Config.setLocalStorage('TOKENBEM', JSON.stringify(Config.token));
                Config.setLocalStorage('REPORTS_VIEW', "grid");
                if (process && process.env && process.env.REACT_APP_DEV_ENV === 'YES') {
                    Config.setLocalStorage('TOKENTM_LOGIN', JSON.stringify(Config.token));
                }

                Config.getMenuInfo(true, () => {
                    setIsSubmit(false);
                    setMessage("");
                });

                // Config.getModule();
            }
        }));
    };

    // const toggleW00F1015 = (flag) => {
    //     setOpenPopupW00F1015(flag);
    // }

    const logoImgCustomer  = Config.getSetting('LOGO_URL') ? Config.getSetting('LOGO_URL') : logoImg;
    const allowMultiLang   = process && process.env && process.env.REACT_APP_MULTILANG ? process.env.REACT_APP_MULTILANG : 'YES';
    const hasRememberLogin = !(String(Config.getSetting("FORCE_USER_LOGOUT")).toLowerCase() === "true");
    return (
        <div className="login-container page-login">
            <div className="login-bound">
                <div className="display_row align-between" style={{width: "100%", gap: 136}}>
                    <div className={"display_col align-between"} style={{width: "100%"}}>
                        <div className="header mgb10x">
                            <Image className={"logo mgr4x"} src={logoImg} width={"auto"} height={64} />
                            <Typography type={"t4"} color={"primary"}>{Config.lang("Dang_nhap").toUpperCase()}</Typography>
                        </div>
                        <div className="login-form">
                            <Row className={"mgb10x"}>
                                <Col xs={12}>
                                    <TextInput
                                        inputRef={refUserID}
                                        label={Config.lang("Ten_dang_nhap")}
                                        viewType={"underlined"}
                                        value={userID}
                                        onKeyDown={onKeyDown}
                                        name={"UserID"}
                                        readOnly={isSubmit}
                                        onInput={handleChange}
                                    />
                                </Col>
                                <Col xs={12}>
                                    {!hasRememberLogin
                                        ? <PasswordInput
                                            label={Config.lang("Mat_khau")}
                                            viewType={"underlined"}
                                            value={passW}
                                            onKeyDown={onKeyDown}
                                            name={"PassW"}
                                            autoComplete={"off"}
                                            secureText
                                            readOnly={isSubmit}
                                            delayOnChange={0}
                                            visibilityToggle={true}
                                            onInput={handleChange}
                                        />
                                        : <PasswordInput
                                            label={Config.lang("Mat_khau")}
                                            viewType={"underlined"}
                                            value={passW}
                                            onKeyDown={onKeyDown}
                                            name={"PassW"}
                                            delayOnChange={0}
                                            readOnly={isSubmit}
                                            visibilityToggle={true}
                                            onInput={handleChange}
                                        />
                                    }
                                </Col>
                                {message && <Col xs={12}>
                                    <Typography color={"danger"}>{`*${message}`}</Typography>
                                </Col>}
                                <Col xs={12}>
                                    {hasRememberLogin && <Checkbox
                                        checked={saveAccount}
                                        label={Config.lang("Nho_mat_khau1")}
                                        onChange={handleSaveAccount}
                                    />}
                                </Col>
                            </Row>
                            <Button
                                viewType={"filled"}
                                color={"primary"}
                                size={"large"}
                                style={{
                                    width: "100%"
                                }}
                                className={"mgb4x"}
                                label={Config.lang("Dang_nhap")}
                                loading={isSubmit}
                                onClick={onEventSave}
                            />
                            {/*<div className="display_row align-center valign-middle" style={{ width:  "100%" }}>*/}
                            {/*    <Button*/}
                            {/*        viewType={"link"}*/}
                            {/*        color={"primary"}*/}
                            {/*        disabled={isSubmit}*/}
                            {/*        labelProps={{*/}
                            {/*            format: "none"*/}
                            {/*        }}*/}
                            {/*        label={`${Config.lang("Quen_mat_khau")}?`}*/}
                            {/*        onClick={() => toggleW00F1015(true)}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <ButtonSM id="btnSubmit" useSubmitBehavior={true} onClick={onFormSubmit} className="hide"/>
                            <div className="display_row align-between mgt10x" style={{width: "100%"}}>
                                <div className={"span-language"}>
                                    <Image src={require("../../assets/images/internet.svg")} width={20} height={20}
                                           className={"mgr5"}/>
                                    <Dropdown
                                        dataSource={dataLanguage}
                                        displayExpr={"Name"}
                                        valueExpr={"ID"}
                                        style={{margin: 0}}
                                        allowSearch={false}
                                        disabled={allowMultiLang === "NO"}
                                        value={lang}
                                        placeholder={Config.lang("Ngon_ngu")}
                                        onChange={handleLanguageChange}
                                    />
                                </div>
                                <div
                                    className={"span-power"}>{Config.lang("Phien_ban")}: {Config.getSetting('APP_VERSION')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="display_row align-center valign-middle">
                        <div className="display_row align-center valign-middle panel-logo">
                            {Config.getSetting("LOGO_URL") &&
                                <Image src={logoImgCustomer} width={"100%"} height={"auto"}/>}
                            {!Config.getSetting("LOGO_URL") && (
                                <React.Fragment>
                                    <Image src={logoImgCustomer} style={{width: "35%"}}/>
                                    <span style={{
                                        marginLeft: 5,
                                        fontSize:   "2.125rem",
                                        fontWeight: 500
                                    }}>LemonHR</span>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"login-wrapper-footer"}>
                <Row className={"login-footer"}>
                    <Col sm={6} md={6} xs={12}>
                        <span>DIGINET CORPORATION</span>
                        <label>© Copyright 2020 DigiNet Corporation.</label>
                    </Col>
                    <Col sm={6} md={6} xs={12}>
                        <Image src={require("../../assets/images/logo.png")} width={"auto"} height={"auto"}/>
                    </Col>
                </Row>
            </div>
            {/*<W00F1015 open={openPopupW00F1015} onClose={() => toggleW00F1015(false)}/>*/}
        </div>
    );
}

export default Login;
