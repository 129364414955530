/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/16/2019
 * @Example
 */
import React from "react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import {DropDownBox} from "devextreme-react";
import FormControl from "@material-ui/core/FormControl";
import {withStyles} from "@material-ui/styles";
import Config from "../../config";
import { DropdownBox as DropDownBoxCore } from 'diginet-core-ui/components';

const styles = theme => ({
    root: {
        // zIndex: 1502
    },
    rootMobile: {
        margin: '4px 0',
        // zIndex: 1502,
        '& .dx-texteditor.dx-editor-filled': {
            borderRadius: 5,
            '& input': {
                padding: '3px !important'
            }
        }
    },
    clearBtn: {
        width: 22,
        minWidth: 22,
        height: 22,
        "&.dx-state-hover": {
            backgroundColor: theme.palette.primary.main + " !important"
        },
        "& .dx-icon-clear": {
            color: "#FFF",
            width: 22,
            height: 22,
            backgroundPosition: '0 0',
            backgroundSize: '22px 22px',
            padding: 0,
            fontSize: 12,
            textAlign: 'center',
            lineHeight: '12px',
            "&:before": {
                position: 'absolute',
                display: 'block',
                width: 12,
                top: '50%',
                marginTop: -6,
                left: '50%',
                marginLeft: -6
            }
        }
    },
    dropdownCore: {
        '& > .DGN-UI-InputBase.outlined::after': {
            width: '100%',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: 'unset',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        }
    }
});
class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shrink: (props.value && props.value.length > 0) || props.shrink,
            value: props.value,
            opened: false
        };
        this.isLoaded = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {showHeader} = this.props;
        if (showHeader || typeof showHeader === "undefined") {
            Config.filters.setFilter(() => {
                return this.render();
            });
        }
    }

    onTextChanged = (e, isDDCore = false) => {
        const {onTextChanged} = this.props;
        if (isDDCore === false) this.setState({ value: e.event.target.value });
        if (onTextChanged) onTextChanged(isDDCore ? e : e.event);
    };

    onFocusIn = () => {
        this.setState({
            shrink: true,
        });
    };

    onFocusOut = () => {
        this.setState({
            shrink: !!this.state.value,
        });
    };

    _onOpened = (e) => {
        const {onOpened, onOpenLoaded} = this.props;
        if (onOpened) onOpened(e);
        if (onOpenLoaded && !this.isLoaded) {
            onOpenLoaded();
            this.isLoaded = true;
        }
        this.setState({opened: true});
    };

    _onClosed = (e) => {
        const {onClosed} = this.props;
        if (onClosed) onClosed(e);
        this.setState({opened: false});
    };

    clearSearch = () => {
        console.log("clear");
        this.onTextChanged({
            event: {
                target: {
                    value: ""
                }
            }
        });
    };

    render() {
        const {value, shrink, opened} = this.state;
        const {
            label, style, className, margin, classes,
            height, placeholder, dataSource, stylingMode, showClearButton, acceptCustomValue,
            openOnFieldClick, showDropDownButton, disabled,
            InputLabelProps, dropdownProps,
            renderFilter, onTextChanged, isUseDDCore
        } = this.props;

        const _showDropDownButton = typeof showDropDownButton !== "undefined" ? showDropDownButton : !!renderFilter;
        const variant = stylingMode === 'underlined' ? "standard" : (stylingMode === 'outlined' ? 'outlined' : 'filled');

        let buttons = [{
                name: "search",
                location: "before",
                options: {
                    icon: "search",
                    stylingMode: "text"
                }
            },
        ];
        if (value) {
            buttons.push({
                name: "clearcustom",
                location: "after",
                options: {
                    stylingMode: "contained",
                    type: "default",
                    icon: "clear",
                    elementAttr: {
                        class: classes.clearBtn,
                    },
                    onClick: () => this.clearSearch()
                },
            });
        }
        buttons.push("dropDown");

        return (
            <React.Fragment>
                <FormControl
                    ref={ref => this.filter = ref}
                    className={className + ' filter-input'}
                    classes={{root: !label ? classes.rootMobile : classes.root}}
                    variant={variant}
                    margin={margin ? margin : "dense"}
                    fullWidth={true} style={style}>
                    <InputLabel
                        shrink={shrink}
                        style={{
                            marginLeft: 33,
                            padding: '0 14px'
                        }}
                        {...InputLabelProps}
                    >{label}</InputLabel>
                    {!isUseDDCore && <DropDownBox
                        value={value}
                        acceptCustomValue={acceptCustomValue || !!onTextChanged}
                        openOnFieldClick={openOnFieldClick}
                        showDropDownButton={_showDropDownButton}
                        showClearButton={showClearButton}
                        stylingMode={stylingMode ? stylingMode : "filled"}
                        height={height}
                        placeholder={placeholder}
                        dataSource={dataSource}
                        activeStateEnabled={true}
                        deferRendering
                        disabled={disabled}
                        focusStateEnabled={false}
                        onValueChanged={this.syncTreeViewSelection}
                        onFocusIn={this.onFocusIn}
                        onFocusOut={this.onFocusOut}
                        onOpened={this._onOpened}
                        onClosed={this._onClosed}
                        onKeyUp={this.onTextChanged}
                        buttons={buttons}
                        {...dropdownProps}
                        contentRender={() => {
                            return (
                                <div className={"pd15"} style={{width: '100%', minHeight: 100}}>
                                    {opened && renderFilter()}
                                </div>
                            );
                        }}
                    />}
                    {isUseDDCore && <DropDownBoxCore
                         bgColor
                         openOnClickAt={openOnFieldClick ? "full" : "icon"}
                         placeholder={placeholder}
                         viewType={'outlined'}
                         className={classes.dropdownCore}
                         inputStyle={{ border: 'none' }}
                         onOpened={this._onOpened}
                         onClosed={this._onClosed}
                         onChangeInput={e => this.onTextChanged(e, true)}
                     >
                         <div style={{ width: '100%', minHeight: 100 }}>
                             {opened && renderFilter()}
                         </div>
                     </DropDownBoxCore>}
                </FormControl>
            </React.Fragment>
        )
    }
}

Filter.propTypes = {
    label: PropTypes.string,
    shrink: PropTypes.bool,
    style: PropTypes.any,
    className: PropTypes.string,
    height: PropTypes.any,
    margin: PropTypes.string,
    disabled: PropTypes.bool,

    value: PropTypes.any,
    acceptCustomValue: PropTypes.bool,
    openOnFieldClick: PropTypes.bool,
    showDropDownButton: PropTypes.bool,
    dataSource: PropTypes.any,
    placeholder: PropTypes.string,
    stylingMode: PropTypes.string,
    showClearButton: PropTypes.bool,
    showHeader: PropTypes.bool,

    renderFilter: PropTypes.any,

    InputLabelProps: PropTypes.any,
    dropdownProps: PropTypes.any,

    onOpenLoaded: PropTypes.func,
    onFocusIn: PropTypes.func,
    onFocusOut: PropTypes.func,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,

    onTextChanged: PropTypes.func,
    isUseDDCore: PropTypes.bool,
};

Filter.defaultProps = {
    isUseDDCore: false
}

export default withStyles(styles)(Filter);
