/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 24/11/2021
 * @Example
 */
import { LoadPanel } from 'devextreme-react/load-panel';
import {
    Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Button, DatePicker, Dropdown, Modal, ModalBody,
    ModalFooter, ModalHeader, MoneyInput, NumberInput, TextInput, Col, Row, ButtonIcon
} from 'diginet-core-ui/components';
import _, { isEmpty } from "lodash";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2021Actions from "../../../../redux/modules/WA3/WA3F2021/WA3F2021_actions";
import MForm from "../../../common/forms/form-material/form";
import History from "../../../common/libs/history/history";
import BookingModal from "./BookingModal";
import ExportReportModal from "../../../custom/ExportReportModal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import 'moment-lunar';
import { Column, Lookup } from "devextreme-react/data-grid";
import GridContainer from "../../../common/grid-container/grid-container";
import { makeStyles } from "diginet-core-ui/theme";
import GridActionBar from "../../../common/grid-container/grid-actionbar";
import { useTheme } from "diginet-core-ui/theme";

const useStyles = makeStyles({
    moneyInputColumn: {
        width: '100%',
        margin: 0,
        '& input': {
            textAlign: 'right'
        }
    },
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }
});

const WA3F2021 = (props) => {
    const { colors } = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { openModal, onOpenModalAction, dataInfo: dataInfoProp = {} } = props;
    const dataCboCurrency = useSelector(state => state?.general?.dataCboCurrency ?? []);
    const dataCboBank = useSelector(state => state?.general?.dataCboBank ?? []);
    const dataCboPaymentMethod = useSelector(state => state?.general?.dataCboPaymentMethodForm ?? []);
    const dataCboVoucherTypeStage = useSelector(state => state?.general?.dataCboVoucherType ?? []);
    const dataCboReport = useSelector(state => state?.general?.dataCboReport ?? []);

    const Language = Config.language || "84";
    const isModeAdd = _.get(dataInfoProp, "Mode", "add") === "add";
    const DivisionID = Config.getLocalStorage("DIVISIONBEM") || _.get(Config.profile, "DivisionID", "");
    //==========STATE==========
    const [Mode, setMode] = useState(_.get(dataInfoProp, "Mode", "add"));
    const [loading, _setLoading] = useState({
        formLoading: false,
        cboCustomerLoading: false,
        cboCreateUserLoading: false,
        cboVoucherTypeLoading: false,
        cboCurrencyLoading: false,
        cboBankLoading: false,
        cboPaymentMethodLoading: false,
        gridPaymentMethodLoading: false
    });
    const { formLoading, cboVoucherTypeLoading, cboCustomerLoading, cboCreateUserLoading, cboCurrencyLoading, cboBankLoading, cboPaymentMethodLoading, gridPaymentMethodLoading } = loading;
    const [openBookingModal, setOpenBookingModal] = useState(false);
    const [voucherNoStatus, setVoucherNoStatus] = useState(true);
    const [error, setError] = useState({});
    const [dataCboCreateUser, setDataCboCreateUser] = useState({
        rows: [],
        total: 0
    });
    const [dataCboCustomer, setDataCboCustomer] = useState({
        rows: [],
        total: 0
    });
    const dataCboStatus = [{
        StatusID: 1,
        StatusName: Config.lang("Chuyen_YCTC")
    },
    {
        StatusID: 100,
        StatusName: Config.lang("Huy")
    }];
    const initialState = {
        StatusID: dataCboStatus[0]?.StatusID, // default
        StatusName: dataCboStatus[0]?.StatusName,
        OAmount: null,
        CAmount: null,
        VoucherDate: moment().format("YYYY-MM-DD"),
        ExchangeRate: null,
        ReadCAmount: null,
        ObjectTypeID: "KH", // default
        CurrencyID: "VND", // default
        VoucherNo: "",
        VoucherTypeID: "",
        ObjectID: _.get(dataInfoProp, "ObjectID", ""),
        ObjectName: _.get(dataInfoProp, "ObjectName", ""),
        EmployeeID: "",
        EmployeeName: "",
        Description: "",
        Notes: "",
        BookingID: _.get(dataInfoProp, "BookingID", ""),
        BookingItemID: _.get(dataInfoProp, "BookingItemID", ""),
    };
    const [dataMaster, _setDataMaster] = useState(initialState);
    const [dataGridPaymentMethod, setDataGridPaymentMethod] = useState([]);


    //=======REF==========
    const tmpDataMaster = useRef(initialState);
    const btnSaveStatus = useRef(false);
    const readCAmountRef = useRef(null);
    const voucherNoRef = useRef(null);
    const refLoading = useRef({});
    const oldDataMaster = useRef({});
    const exportReportModalRef = useRef(null);
    const dataInfo = useRef({
        iPermission: _.get(dataInfoProp, "Permission", 0),
        VoucherNo: _.get(dataInfoProp, "VoucherNo", ""),
        VoucherID: _.get(dataInfoProp, "VoucherID", ""),
        ObjectID: props?.ObjectID ? props.ObjectID : _.get(dataInfoProp, "ObjectID", ""),
        DataType: _.get(dataInfoProp, "DataType", "ViewDetail") || "ViewDetail",
        BookingID: _.get(dataInfoProp, "BookingID", ""),
        BookingItemID: _.get(dataInfoProp, "BookingItemID", ""),
    });
    const filterCboCreateUser = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    const filterCboCustomer = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    const paymentMethodRef = useRef(null);
    const countOrderNo = useRef(1);
    //=======Variable==========
    const FormID = "WA3F2021";
    const disabledLoading = useMemo(() => {
        return !!Object.keys(loading).find(l => loading[l])
    }, [loading]);
    const disabled = useMemo(() => {
        return formLoading || Mode === "view";
    }, [formLoading, Mode]);
    const { BookingID, ObjectID, ObjectTypeID } = dataMaster;
    const UserID = _.get(Config, "profile.UserID", "");
    const placeholderInput = disabled ? "" : Config.lang("Nhap");
    const placeholderDropdown = disabled ? "" : Config.lang("Chon");
    const placeholderVoucherNo = useMemo(() => {
        return dataMaster.Auto === 0 && disabled === false ? Config.lang("Nhap") : "";
    }, [dataMaster.Auto, disabled]);
    const StoreParams = [
        { id: "DivisionID", type: "VarChar", value: DivisionID },
        { id: "Language", type: "VarChar", value: Language },
        { id: "DataType", type: "VarChar", value: "ViewReport" },
        { id: "FormID", type: "VarChar", value: FormID },
        { id: "UserID", type: "VarChar", value: UserID },
        { id: "VoucherNo", type: "VarChar", value: _.get(dataInfo.current, "VoucherNo", "") },
        { id: "VoucherID", type: "VarChar", value: _.get(dataInfo.current, "VoucherID", "") },
    ];

    const dataCboVoucherType = useMemo(() => {
        return dataCboVoucherTypeStage.map(item => {
            const ContractTypeID = _.get(item, "ContractTypeID", "");
            const ContractTypeName = _.get(item, "ContractTypeName", "");
            const line = _.isEmpty(ContractTypeID) || _.isEmpty(ContractTypeName) ? "" : "-";
            return {
                ...item,
                VoucherTypeID: ContractTypeID,
                VoucherTypeName: ContractTypeName,
                ContractTypeCustomName: `${ContractTypeID}${line}${ContractTypeName}`
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboVoucherTypeStage]);

    const setDataMaster = (obj) => {
        Object.assign(tmpDataMaster.current, obj);
        _setDataMaster({ ...tmpDataMaster.current });
    };

    useEffect(() => {
        const { VoucherNo, VoucherID, BookingID, BookingItemID } = dataInfo.current;
        if (_.isEmpty(dataCboCurrency)) getDataCboGeneral("getCboCurrency");
        if (_.isEmpty(dataCboVoucherTypeStage)) getDataCboGeneral("getCboVoucherType");
        loadCboCreateUser();
        loadCboCustomer();
        if (BookingID && BookingItemID) loadDataBookingNo();
        if (Mode !== "add" || VoucherNo || VoucherID) {
            loadForm();
            loadGridPaymentMethod();
        }
        getDataCboGeneralWithParams("getCboBank", { FormID });
        getDataCboGeneralWithParams("getCboPaymentMethodForm", { FormID });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dataMaster.ExchangeRate = _.get(dataCboCurrency.find(item => item.CurrencyID === "VND"), "ExchangeRate", null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboCurrency]);

    useEffect(() => {
        dataInfo.current.ObjectID = dataMaster.ObjectID;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.ObjectID]);

    useEffect(() => {
        if (dataMaster.Auto === 1) loadVoucherNo(dataMaster.VoucherTypeID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.VoucherTypeID]);

    useEffect(() => {
        if (error?.['VoucherNo'] && dataMaster.VoucherNo) setError(_.omit(error, ["VoucherNo"]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.VoucherNo]);

    useEffect(() => {
        if (Mode === "view" && exportReportModalRef.current && exportReportModalRef.current.hasOwnProperty("loadCboReports")) {
            exportReportModalRef.current.loadCboReports(); // Load
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Mode]);

    const setLoading = (obj) => {
        refLoading.current = { ...refLoading.current, ...obj };
        _setLoading(refLoading.current);
    };

    const loadGridPaymentMethod = () => {
        const { VoucherNo, VoucherID } = dataInfo.current;
        const params = {
            FormID,
            VoucherNo,
            VoucherID,
        };
        setLoading({ gridPaymentMethodLoading: true });
        dispatch(WA3F2021Actions.loadGridPaymentMethod(params, (error, data) => {
            setLoading({ gridPaymentMethodLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const dataOrder = data.map(item => ({ ...item, OrderNo: countOrderNo.current++ }));
                setDataGridPaymentMethod(dataOrder);
            }
        }));
    };

    const loadForm = () => {
        const { VoucherNo, VoucherID, DataType } = dataInfo.current;
        const params = {
            FormID,
            DivisionID,
            DataType,
            VoucherNo,
            VoucherID,
        };
        setLoading({ formLoading: true });
        dispatch(WA3F2021Actions.loadForm(params, (error, data) => {
            setLoading({ formLoading: false });
            if (error) {
                setDataMaster(initialState);
                Config.popup.show("ERROR", error);
                return false;
            } else if (!_.isEmpty(data)) {
                oldDataMaster.current = { ...data };
                setDataMaster({ ...dataMaster, ...data, EventLunaDate: formatValidTime(data?.EventDate, true) });
            }
        }));
    };

    const formatValidTime = (date, isLuna = false) => {
        let result = date;
        if (!_.isNull(date) && Config.isValidDateTime(date)) {
            result = isLuna === true ? moment(date).lunar().format("YYYY-MM-DD") : moment(date).format("YYYY-MM-DD");
        }
        return result;
    };

    const loadDataBookingNo = () => {
        const { BookingID = "", BookingItemID = "" } = dataInfo.current;
        const param = {
            FormID,
            BookingID,
            BookingItemID
        };
        dispatch(WA3F2021Actions.loadBooking(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const dataBooking = {
                    ...data,
                    EventDate: formatValidTime(data?.EventDate),
                    EventLunaDate: formatValidTime(data?.EventDate, true)
                }
                setDataMaster({
                    ...dataMaster,
                    ...dataBooking
                });
            }
        }));
    };

    const getDataCboGeneral = (cboName = "") => {
        if (_.isEmpty(cboName)) return;
        setCboLoading(cboName, true);
        dispatch(generalActions[cboName]((error) => {
            setCboLoading(cboName, false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const getDataCboGeneralWithParams = (cboName = "", params = {}) => {
        if (_.isEmpty(cboName) || _.isEmpty(params)) return;
        setCboLoading(cboName, true);
        dispatch(generalActions[cboName](params, (error) => {
            setCboLoading(cboName, false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const onPrint = useCallback(() => {
        if (_.isArray(dataCboReport)) {
            if (dataCboReport.length === 0) {
                Config.popup.show("INFO", Config.lang("Ban_phai_thiet_lap_mau_bao_cao_truoc_khi_xuat_du_lieu"));
            } else if (dataCboReport.length === 1) { // 1 Mẫu
                if (exportReportModalRef.current.hasOwnProperty("onExport")) exportReportModalRef.current.onExport();
            } else { // Nhiều mẫu
                if (exportReportModalRef.current && exportReportModalRef.current.hasOwnProperty("onClickShow")) {
                    exportReportModalRef.current.onClickShow();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboReport, exportReportModalRef.current]);

    const loadVoucherNo = (VoucherTypeID = "") => {
        if (_.isEmpty(VoucherTypeID)) return;
        const params = {
            DivisionID,
            VoucherTypeID
        };
        setLoading({ cboVoucherTypeLoading: true });
        dispatch(WA3F2021Actions.loadVoucherNo(params, (error, data) => {
            setLoading({ cboVoucherTypeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const VoucherNo = _.get(data, "VoucherNo", "");
                setDataMaster({ ...dataMaster, VoucherNo });
            }
        }));
    };

    const setCboLoading = (cboName = "", status) => {
        if (_.isEmpty(cboName) || !_.isBoolean(status)) return;
        switch (cboName) {
            case "getCboVoucherType":
                setLoading({ cboVoucherTypeLoading: status });
                break;
            case "getCboCurrency":
                setLoading({ cboCurrencyLoading: status });
                break;
            case "getCboBank":
                setLoading({ cboBankLoading: status });
                break;
            case "getCboPaymentMethodForm":
                setLoading({ cboPaymentMethodLoading: status });
                break;
            default:
                break;
        }
    };
    const loadCboCreateUser = (isReset) => {
        const { skip, limit, search } = filterCboCreateUser.current;
        const params = {
            FormID,
            ObjectID,
            ObjectTypeID,
            BookingID,
            skip,
            limit,
            search
        };
        setLoading({ cboCreateUserLoading: true });
        dispatch(generalActions.getCboCreateUser(params, (error, data) => {
            setLoading({ cboCreateUserLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (isModeAdd) { // Default theo UserID
                    const dataEmployee = rows.find(item => item.UserID === _.get(Config.profile, "UserID", ""));
                    if (!_.isEmpty(dataEmployee)) setDataMaster({ ...dataMaster, EmployeeID: dataEmployee.EmployeeID });
                }
                setDataCboCreateUser({
                    rows: isReset ? rows : dataCboCreateUser.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const loadCboCustomer = (isReset) => {
        const { skip, limit, search } = filterCboCustomer.current;
        const params = {
            FormID,
            ObjectID,
            BookingID,
            skip,
            limit,
            search
        };
        setLoading({ cboCustomerLoading: true });
        dispatch(generalActions.getCboCustomer(params, (error, data) => {
            setLoading({ cboCustomerLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = _.isEmpty(item.ObjectID) || _.isEmpty(item.ObjectName) ? "" : "-";
                        return ({ ...item, ObjectCustomName: `${item.ObjectID} ${line} ${item.ObjectName}` })
                    });
                }
                setDataCboCustomer({
                    rows: isReset ? rows : dataCboCustomer.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const saveAndGetDataSource = async () => {
        await paymentMethodRef.current.instance.saveEditData();
        return paymentMethodRef.current.instance.option("dataSource");
    };

    const calculateGridPaymentMethod = async (ExchangeRate = null) => {
        if (Config.isEmpty(ExchangeRate, true) || Config.isEmpty(dataGridPaymentMethod, true)) return;
        const dataPaymentMethodGrid = await saveAndGetDataSource();
        for (let i = 0; i < dataPaymentMethodGrid.length; i++) {
            dataPaymentMethodGrid[i].CAmount = dataPaymentMethodGrid[i].OAmount * ExchangeRate;
        }
    };

    const onChange = (fieldName, e) => {
        if (!e || _.isEmpty(fieldName)) return;
        let value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        let objectValuePlus = {};
        switch (fieldName) {
            //DropDown
            case "EmployeeID":
            case "ObjectID":
            case "VoucherTypeID":
            case "CurrencyID":
                const positionID = fieldName.indexOf("ID");
                const standardDDName = positionID > 0 ? fieldName.slice(0, positionID) : fieldName;
                let DDName = standardDDName + "Name";
                let valueDDName = _.get(e.data, [DDName], _.get(e.data, "ObjectName", ""));
                objectValuePlus = { [DDName]: valueDDName };
                if (fieldName === "CurrencyID") {
                    const { ExchangeRate = "" } = _.get(e, "data", {});
                    objectValuePlus = {
                        ...objectValuePlus,
                        ExchangeRate,
                    };
                    calculateGridPaymentMethod(ExchangeRate);
                }
                if (fieldName === "VoucherTypeID") {
                    const { Auto = null } = _.get(e, "data", {});
                    if (Auto === 0 || isEmpty(value)) { // Tự Nhập hoặc clear value
                        setVoucherNoStatus(isEmpty(value));
                        objectValuePlus = { ...objectValuePlus, Auto, VoucherNo: "" };
                        if (voucherNoRef.current) voucherNoRef.current.value = "";
                    } else if (Auto === 1) { // Gọi Api Get
                        setVoucherNoStatus(true);
                        objectValuePlus = { ...objectValuePlus, Auto };
                    }
                }
                break;
            case "OAmount":
                const ExchangeRate = _.get(dataMaster, "ExchangeRate", 0) || 0;
                const CAmountCal = _.toNumber(value) * ExchangeRate;
                objectValuePlus = {
                    CAmount: CAmountCal || null,
                    ReadCAmount: CAmountCal || null
                };
                break;
            default:
                break;
        }
        objectValuePlus = { ...objectValuePlus, [fieldName]: value };
        if (dataMaster[fieldName] !== value) {
            setDataMaster({
                ...dataMaster,
                ...objectValuePlus,
            });
        }
        if (error.hasOwnProperty(fieldName) && !Config.isEmpty(value, true)) {
            if (fieldName === "VoucherTypeID" && _.get(e, "data.Auto", null) === 1) {
                setError(_.omit(error, [fieldName, "VoucherNo"]));
            } else {
                setError(_.omit(error, fieldName));
            }
        }
    };

    const setStateErrorText = (objValue) => {
        setError({ ...error, ...objValue });
        return Object.keys(objValue).length !== 0;
    };

    const checkValidateMaster = (arrName) => {
        if (_.isEmpty(arrName)) return [];
        return arrName.map(item => ({
            name: item,
            rules: ["isRequired"],
            value: dataMaster[item]
        }));
    };

    const onSaveHistory = async (action = 0, VoucherID = "") => {
        const captions = {
            VoucherTypeID: "Loai_phieu",
            VoucherNo: "So_phieu",
            EmployeeName: "Nguoi_lap",
            ObjectID: "Ma_khach_hang",
            ObjectName: "Ten_khach_hang",
            BookingNo: "Ma_booking",
            EventDate: "Ngay_tiec_DL",
            EventName: "Ten_su_kien",
            BanquetName: "Sanh",
        };
        const newData = dataMaster;
        const oldData = oldDataMaster.current;
        const options = {
            data: newData,
            dataCompare: oldData,
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID: VoucherID,
            TransactionName: Config.lang("Lap_bien_nhan_coc_cho"),
        };
        const history = new History(options);
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const getFieldName = useCallback((keyName) => {
        let fieldName = "";
        switch (keyName) {
            case "VoucherTypeID":
                fieldName = "Loai_phieu";
                break;
            case "VoucherNo":
                fieldName = "So_phieu";
                break;
            case "EmployeeID":
                fieldName = "Nguoi_lap";
                break;
            case "VoucherDate":
                fieldName = "Ngay_phieu";
                break;
            case "ObjectID":
                fieldName = "Khach_hang";
                break;
            case "CurrencyID":
                fieldName = "Loai_tien";
                break;
            case "OAmount":
                fieldName = "So_tien_coc";
                break;
            case "BookingNo":
                fieldName = "Ma_booking";
                break;
            default:
                break;
        }
        return fieldName;
    }, []);

    const onSave = async () => {
        const requiredField = ["VoucherTypeID", "VoucherNo", "EmployeeID", "VoucherDate", "ObjectID", "CurrencyID", "BookingNo"];
        const resultValidate = checkValidateMaster(requiredField);
        const validateForm = MForm.formValidation(resultValidate);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            const fieldName = getFieldName(_.keys(validateForm)[0]);
            Config.popup.show("INFO", `${Config.lang("Ban_chua_nhap_gia_tri")}: ${Config.lang(fieldName)}`);
            return false;
        } else {
            let messageRequired = "";
            const dataPaymentMethodGrid = await saveAndGetDataSource();
            const emptyColStatus = dataPaymentMethodGrid.some(item => Config.isEmpty(item.PaymentMethodID, true) || Config.isEmpty(item.OAmount, true));
            if (_.isEmpty(dataPaymentMethodGrid)) {
                messageRequired = Config.lang("Luoi_thong_tin_thanh_toan_chua_nhap_lieu,_ban_khong_duoc_luu");
            } else if (emptyColStatus) {
                messageRequired = `${Config.lang("Vui_long_nhap_day_du_thong_tin_tren_luoi")} ${Config.lang("Thong_tin_thanh_toan").toLowerCase()}`;
            }
            if (messageRequired) {
                Config.popup.show("INFO", messageRequired);
                return;
            }
            const params = {
                FormID,
                DivisionID,
                ReadCAmount: _.get(readCAmountRef.current, "value", ""),
                ..._.omit(dataMaster, "ReadCAmount"),
                paymentmethods: JSON.stringify(dataPaymentMethodGrid.map(item => _.omit(item, "OrderNo")))
            };
            setLoading({ formLoading: true });
            dispatch(WA3F2021Actions.saveAdd(params, async (error, data) => {
                setLoading({ formLoading: false });
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    if (data.Status === 0) {
                        const VoucherID = _.get(data, "VoucherID", "") || _.get(dataInfo.current, "VoucherID", "");
                        const VoucherNo = _.get(data, "VoucherNo", "") || _.get(dataInfo.current, "VoucherNo", "");
                        dataInfo.current.VoucherID = VoucherID;
                        dataInfo.current.VoucherNo = VoucherNo;
                        setDataMaster({ ...dataMaster, VoucherID, VoucherNo });
                        if (VoucherID) await onSaveHistory(0, VoucherID);
                        btnSaveStatus.current = true;
                        setMode("view"); // Chuyển về View
                        Config.notify.show("success", _.get(data, "Message", "") || Config.lang("Luu_thanh_cong"), 2000);
                    } else {
                        Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                        return false;
                    }
                }
            }));
        }
    };

    const onOpenModal = (modalName, status = false, data = {}) => {
        if (!_.isBoolean(status) || _.isEmpty(modalName)) return;
        switch (modalName) {
            case "Booking":
                setOpenBookingModal(status);
                break;
            default:
                break;
        }
        if (!_.isEmpty(data)) {
            if (modalName === "Booking") {
                let dataBooking = data;
                const { BookingNo = "" } = data;
                if (isModeAdd === false) {
                    dataBooking = {
                        EventDate: _.get(data, "EventDate", null),
                        EventLunaDate: _.get(data, "EventLunaDate", null),
                    };
                }
                setDataMaster({
                    ...dataMaster,
                    ...dataBooking,
                    Description: BookingNo
                });
                if (error.hasOwnProperty("BookingNo") && !_.isEmpty(BookingNo)) setError(_.omit(error, "BookingNo"));
            }
        }
    };

    const setFormMasterLoading = (status) => {
        if (_.isBoolean(status)) setLoading({ formLoading: status });
    };

    const numberInputCol = (props) => <NumberInput
        nonStyle
        disabledNegative
        decimalDigit={8}
        thousandSeparator={","}
        className={classes.moneyInputColumn}
        {...props}
    />;

    const renderRequiredCol = (e) => {
        if (!e) return false;
        const { column = {}, row = {}, displayValue = "", column: { dataField = "" }, row: { data = {} } } = e || {};
        const value = _.isNumber(data[dataField]) ? data[dataField] : displayValue;
        if (_.isUndefined(value)) return;
        const cellElement = e.component.getCellElement(row.rowIndex, column.dataField);
        if (cellElement && !(value > 0)) {
            if (!(row?.isNewRow === true)) {
                if (_.isEmpty(_.get(dataGridPaymentMethod[e.rowIndex], dataField, ""))) {
                    if (["OAmount", "PaymentMethodID"].includes(dataField)) {
                        cellElement.style.backgroundColor = colors.danger2;
                    }
                }
            }
        }
        const domValue = _.isNumber(value) ? numberInputCol({ readOnly: true, value }) : <div className={`${classes.ellipsis} pull-left textCellHidden`} title={value}>{value}</div>;
        return domValue;
    };

    const handleUpdateOAmount = (dataSource = dataGridPaymentMethod) => { // Tính lại text số tiền cọc
        const OAmount = dataSource && dataSource.reduce((acc, o) => acc + parseInt(o.OAmount), 0);
        const ReadCAmount = _.toNumber(OAmount) * dataMaster.ExchangeRate;
        if (!_.isNaN(OAmount)) setDataMaster({ OAmount, CAmount: ReadCAmount, ReadCAmount });
    };

    const onDelete = (e) => {
        if (!e) return;
        paymentMethodRef.current.instance.saveEditData();
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            e.component.deleteRow(e.rowIndex);
            paymentMethodRef.current.instance.refresh();// Refesh lại grid để lấy đúng data
            dataGridPaymentMethod.forEach((item, idx) => item.OrderNo = idx + 1);
            countOrderNo.current = dataGridPaymentMethod.length + 1;// Sắp xếp lại STT
            handleUpdateOAmount();
        });
    };

    const renderActionGrid = (e) => {
        return (
            <GridActionBar>
                <ButtonIcon
                    circular
                    disabled={e?.row?.isNewRow || disabled}
                    name={"Delete"}
                    size={"medium"}
                    viewType={"text"}
                    onClick={() => onDelete(e)} />
            </GridActionBar>
        );
    };

    const renderGrid = useMemo(() => {
        return (
            <GridContainer
                reference={ref => paymentMethodRef.current = ref}
                loading={gridPaymentMethodLoading}
                height={300}
                typePaging={"normal"}
                pagerFullScreen={false}
                keyExpr={"OrderNo"}
                editing={{
                    mode: "cell",
                    refreshMode: "reshape",
                    allowUpdating: !disabled,
                    texts: {
                        confirmDeleteMessage: "",
                    },
                    startEditAction: "click",
                }}
                onRowUpdating={(e) => {
                    const { newData, oldData } = e;
                    const columnName = (Object.keys(newData))[0];
                    if (columnName === "OAmount") {
                        oldData.CAmount = newData[columnName] * dataMaster.ExchangeRate;
                    }
                }}
                onRowUpdated={(e) => {
                    handleUpdateOAmount(e.component.option("dataSource"));
                }}
                onInitNewRow={(eMethod) => {
                    eMethod.data.PaymentMethodID = "";
                    eMethod.data.OAmount = null;
                    eMethod.data.CAmount = null;
                    eMethod.data.BankID = "";
                }}
                onRowInserted={(e) => {
                    const { OAmount = null } = _.get(e, "data", {});
                    e.data["OrderNo"] = countOrderNo.current++;
                    e.data["CAmount"] = OAmount * dataMaster.ExchangeRate;
                    if (!Config.isEmpty(OAmount, true)) handleUpdateOAmount(e.component.option("dataSource"));
                    paymentMethodRef.current.instance.refresh();
                }}
                dataSource={dataGridPaymentMethod}
            >
                <Column
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile && !disabled}
                    cellRender={renderActionGrid}
                />
                <Column
                    width={60}
                    allowEditing={false}
                    alignment={"center"}
                    dataField={"OrderNo"}
                    caption={Config.lang("STT")}
                />
                <Column
                    width={220}
                    dataField={"PaymentMethodID"}
                    headerCellRender={() =>
                    (<> {Config.lang("Phuong_thuc_thanh_toan")}
                        <span style={{ color: colors.danger }}>&nbsp;*</span>
                    </>)}
                    cellRender={renderRequiredCol}>
                    <Lookup dataSource={dataCboPaymentMethod}
                        valueExpr={"PaymentMethodID"}
                        displayExpr={"PaymentMethodName"} />
                </Column>
                <Column
                    width={150}
                    alignment={"right"}
                    dataField={"OAmount"}
                    cellRender={renderRequiredCol}
                    headerCellRender={() =>
                    (<> {Config.lang("So_tien_coc")}
                        <span style={{ color: colors.danger }}>&nbsp;*</span>
                    </>)}
                    editCellRender={(e) => numberInputCol({ value: e.value, onChange: (c) => e.setValue(c.value) })} />
                <Column
                    minWidth={320}
                    dataField={"BankID"}
                    caption={Config.lang("Ngan_hang")}>
                    <Lookup dataSource={dataCboBank}
                        valueExpr={"BankID"}
                        displayExpr={"BankName"}
                    />
                </Column>
                <Column
                    width={40}
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile && !disabled}
                    cellRender={renderActionGrid}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGridPaymentMethod, disabled, dataMaster.ExchangeRate, gridPaymentMethodLoading, dataCboBank, dataCboPaymentMethod]);

    const onAddRow = async () => {
        setTimeout(async () => {
            paymentMethodRef.current.instance.addRow();
            await paymentMethodRef.current.instance.saveEditData();
        }, 0);
    };

    if (!_.get(dataInfo.current, "iPermission", null)) return null;
    return (
        <Modal
            id={"Popup-WA3F2021"}
            zIndex={1020}
            width={"1440"}
            open={openModal}
            onClose={() => {
                if (onOpenModalAction) {
                    let reLoad = false;
                    if (btnSaveStatus.current === true) reLoad = true;
                    onOpenModalAction(false, reLoad);
                }
            }}>
            <ModalHeader>{Config.lang("Lap_phieu_bien_nhan_coc_cho")}</ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <LoadPanel
                        position={{ my: 'center', of: '#Popup-WA3F2021' }}
                        visible={formLoading}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                    />
                    {openBookingModal &&
                        <BookingModal
                            FormID={FormID}
                            currentSelect={dataMaster.BookingNo}
                            openModal={openBookingModal}
                            dataInfo={dataInfo.current}
                            onOpenModalAction={(status, data) => onOpenModal("Booking", status, data)}
                        />
                    }
                    <ExportReportModal
                        ref={exportReportModalRef}
                        IsDefault={0}
                        ModuleID={"A3"}
                        FormID={"WA3F2021"}
                        label={Config.lang("Xuat_du_lieu")}
                        disabled={formLoading}
                        dataInfo={dataInfo.current}
                        ReportTypeID={"WA3F2020"}
                        StoreParams={StoreParams}
                        setFormMasterLoading={(status) => setFormMasterLoading(status)}
                    />
                    <AccordionGroup>
                        <Accordion expand={true}>
                            <AccordionSummary>
                                {Config.lang("Thong_tin_bien_nhan")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            required={true}
                                            readOnly={disabled}
                                            valueExpr={"ContractTypeID"}
                                            keyExpr={"ContractTypeID"}
                                            displayExpr={"ContractTypeCustomName"}
                                            placeholder={placeholderDropdown}
                                            label={Config.lang("Loai_phieu")}
                                            error={error && error["VoucherTypeID"]}
                                            onChange={e => onChange("VoucherTypeID", e)}
                                            dataSource={dataCboVoucherType}
                                            loading={cboVoucherTypeLoading}
                                            value={dataMaster.VoucherTypeID}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            required={true}
                                            inputRef={voucherNoRef}
                                            readOnly={disabled || voucherNoStatus || cboVoucherTypeLoading}
                                            label={Config.lang("So_phieu")}
                                            placeholder={placeholderVoucherNo}
                                            error={error && error["VoucherNo"]}
                                            onChange={e => onChange("VoucherNo", e)}
                                            value={dataMaster.VoucherNo}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            required={true}
                                            readOnly={disabled && !cboCreateUserLoading}
                                            allowSearch
                                            dataSource={_.get(dataCboCreateUser, "rows", [])}
                                            total={_.get(dataCboCreateUser, "total", 0)}
                                            skip={filterCboCreateUser.current.skip}
                                            limit={filterCboCreateUser.current.limit}
                                            value={dataMaster.EmployeeID}
                                            loading={cboCreateUserLoading}
                                            valueExpr={"EmployeeID"}
                                            keyExpr={"EmployeeName"}
                                            displayExpr={"EmployeeName"}
                                            {...(dataMaster.EmployeeName ? {
                                                valueObjectDefault: {
                                                    EmployeeID: dataMaster.EmployeeID,
                                                    EmployeeName: dataMaster.EmployeeName
                                                }
                                            } : {})}
                                            label={Config.lang("Nguoi_lap")}
                                            placeholder={placeholderDropdown}
                                            error={error && error["EmployeeID"]}
                                            onChange={(e) => onChange("EmployeeID", e)}
                                            onLoadMore={(e) => {
                                                filterCboCreateUser.current.skip = e.skip;
                                                filterCboCreateUser.current.limit = e.limit;
                                                loadCboCreateUser();
                                            }}
                                            searchDelayTime={700}
                                            onInput={(e) => {
                                                filterCboCreateUser.current.search = e?.target?.value || "";
                                                filterCboCreateUser.current.skip = 0;
                                                loadCboCreateUser(true);
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <DatePicker
                                            clearAble
                                            required={true}
                                            readOnly={disabled}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            label={Config.lang("Ngay_phieu")}
                                            value={dataMaster.VoucherDate}
                                            error={error && error["VoucherDate"]}
                                            onChange={e => onChange("VoucherDate", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            required={true}
                                            readOnly={disabled && !cboCustomerLoading}
                                            allowSearch
                                            dataSource={_.get(dataCboCustomer, "rows", [])}
                                            total={_.get(dataCboCustomer, "total", 0)}
                                            skip={filterCboCustomer.current.skip}
                                            limit={filterCboCustomer.current.limit}
                                            value={dataMaster.ObjectID}
                                            loading={cboCustomerLoading}
                                            valueExpr={"ObjectID"}
                                            keyExpr={"ObjectID"}
                                            displayExpr={"ObjectCustomName"}
                                            {...(dataMaster.ObjectID ? {
                                                valueObjectDefault: {
                                                    ObjectID: dataMaster.ObjectID,
                                                    ObjectName: dataMaster.ObjectName,
                                                    ObjectCustomName: `${dataMaster.ObjectID} 
                                                     ${_.isEmpty(dataMaster.ObjectID) || _.isEmpty(dataMaster.ObjectName) ? "" : "-"} 
                                                     ${dataMaster.ObjectName}`
                                                }
                                            } : {})}
                                            label={Config.lang("Ma_khach_hang")}
                                            placeholder={placeholderDropdown}
                                            onChange={(e) => onChange("ObjectID", e)}
                                            error={error && error["ObjectID"]}
                                            onLoadMore={(e) => {
                                                filterCboCustomer.current.skip = e.skip;
                                                filterCboCustomer.current.limit = e.limit;
                                                loadCboCustomer();
                                            }}
                                            searchDelayTime={700}
                                            onInput={(e) => {
                                                filterCboCustomer.current.search = e?.target?.value || "";
                                                filterCboCustomer.current.skip = 0;
                                                loadCboCustomer(true);
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Ten_khach_hang")}
                                            placeholder={placeholderInput}
                                            value={dataMaster.ObjectName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <Dropdown
                                                    clearAble
                                                    readOnly={disabled}
                                                    valueExpr={"CurrencyID"}
                                                    keyExpr={"CurrencyID"}
                                                    displayExpr={"CurrencyID"}
                                                    style={{ minWidth: 'auto' }}
                                                    label={Config.lang("Loai_tien")}
                                                    placeholder={placeholderDropdown}
                                                    onChange={e => onChange("CurrencyID", e)}
                                                    dataSource={dataCboCurrency}
                                                    loading={cboCurrencyLoading}
                                                    value={dataMaster.CurrencyID}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <NumberInput
                                                    readOnly={true}
                                                    style={{ minWidth: 'auto' }}
                                                    label={Config.lang("Ty_gia")}
                                                    value={dataMaster.ExchangeRate}
                                                    defaultValue={_.get(dataCboCurrency.find(item => item.CurrencyID === "VND"), "ExchangeRate", null)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            readOnly={true}
                                            valueExpr={"StatusName"}
                                            keyExpr={"StatusName"}
                                            displayExpr={"StatusName"}
                                            label={Config.lang("Trang_thai")}
                                            dataSource={dataCboStatus}
                                            loading={cboCurrencyLoading}
                                            value={dataMaster.StatusName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={9}>
                                        <Button
                                            disabled={disabled || cboBankLoading || cboPaymentMethodLoading}
                                            size={"medium"}
                                            color={"primary"}
                                            startIcon={"AddCircle"}
                                            viewType={"filled"}
                                            label={Config.lang("Them")}
                                            className={"mgb4x"}
                                            onClick={onAddRow}
                                        />
                                        {renderGrid}
                                    </Col>
                                    <Col xs={0} sm={0} md={0} lg={3} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }} />
                                    <Col xs={12} sm={12} md={4} lg={3}>
                                        <MoneyInput
                                            readOnly
                                            disabledNegative
                                            thousandSeparator={","}
                                            label={Config.lang("So_tien_coc")}
                                            error={error && error["OAmount"]}
                                            value={dataMaster.OAmount}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={9}>
                                        <MoneyInput
                                            readOnly={true}
                                            convertToWords={true}
                                            inputRef={readCAmountRef}
                                            label={Config.lang("So_tien_bang_chu")}
                                            value={dataMaster.ReadCAmount}
                                            suffix={Config.lang("DongC").toLowerCase()} //currency
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Dien_giai")}
                                            placeholder={placeholderInput}
                                            value={dataMaster.Description}
                                            onChange={(e) => onChange("Description", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Ghi_chu")}
                                            placeholder={placeholderInput}
                                            value={dataMaster.Notes}
                                            onChange={(e) => onChange("Notes", e)}
                                        />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                    <AccordionGroup>
                        <Accordion expand={true}>
                            <AccordionSummary>
                                {Config.lang("Thong_tin_booking")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <div style={{ cursor: 'pointer !important' }}>
                                            <TextInput
                                                readOnly={true}
                                                required={true}
                                                endIcon={"ArrowDown"}
                                                label={Config.lang("Ma_booking")}
                                                placeholder={placeholderDropdown}
                                                value={dataMaster.BookingNo}
                                                error={error && error["BookingNo"]}
                                                onClick={() => { if (disabled === false) onOpenModal("Booking", true) }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <DatePicker
                                            readOnly={true}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            label={Config.lang("Ngay_tiec_DL")}
                                            value={dataMaster.EventDate}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <DatePicker
                                            readOnly={true}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            label={Config.lang("Ngay_tiec_AL")}
                                            value={dataMaster.EventLunaDate}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                <TextInput
                                                    readOnly={true}
                                                    endIcon={"Clock"}
                                                    placeholder={"hh:mm"}
                                                    label={Config.lang("Gio_tiec")}
                                                    value={dataMaster.HourFrom}
                                                />
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                <TextInput
                                                    readOnly={true}
                                                    label={" "}
                                                    endIcon={"Clock"}
                                                    placeholder={"hh:mm"}
                                                    value={dataMaster.HourTo}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Hinh_thuc_tiec")}
                                            value={dataMaster.EventTypeName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Loai_ban_tiec")}
                                            value={dataMaster.SetupName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Ten_su_kien")}
                                            value={dataMaster.EventName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Sanh")}
                                            value={dataMaster.BanquetName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Vi_tri")}
                                            value={dataMaster.Location}
                                        />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <div className={"wrap"}>
                    <Button
                        disabled={Mode !== "view" || disabledLoading}
                        className={"mgr2x"}
                        size={"medium"}
                        color={"primary"}
                        startIcon={"Printer"}
                        viewType={"outlined"}
                        label={Config.lang("In1")}
                        onClick={onPrint}
                    />
                    <Button
                        disabled={disabled}
                        size={"medium"}
                        color={"info"}
                        startIcon={"Save"}
                        viewType={"filled"}
                        label={Config.lang("Luu")}
                        onClick={onSave}
                    />
                </div>
            </ModalFooter>
        </Modal>
    );
};

WA3F2021.propTypes = {
    openModal: PropTypes.bool,
    dataInfo: PropTypes.object,
    onOpenModalAction: PropTypes.func,
};

export default WA3F2021;
