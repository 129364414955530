/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/2/2020
 * @Example
 */

const mouse = {
    /**
     * on
     */
    dragToScroll: (element) => {
        element.style.cursor = 'grab';

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        const mouseDownHandler = (e) => {
            element.style.cursor = 'grabbing';
            element.style.userSelect = 'none';

            pos = {
                left: element.scrollLeft,
                top: element.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = (e) => {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            // Scroll the element
            element.scrollTop = pos.top - dy;
            element.scrollLeft = pos.left - dx;
        };

        const mouseUpHandler = () => {
            element.style.cursor = 'grab';
            element.style.removeProperty('user-select');

            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        element.addEventListener('mousedown', mouseDownHandler);
    }


};

export default mouse;