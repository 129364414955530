/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/17/2021
 * @Example
 */
import React, {useMemo, useRef, useState} from "react";
import Config                             from "../../../../config";
import {Row, Col, FormGroup}              from "react-bootstrap";
import ActionToolbar                      from "../../../common/layouts/toolbar/action-toolbar";
import {Column}                           from "devextreme-react/data-grid";
import GridContainer                      from "../../../common/grid-container/grid-container";
import WA3F0100Nav                        from "./WA3F0100Nav";
import _                                  from "lodash";
import WA3F0100Detail                     from "./WA3F0100Detail";
import moment                             from "moment";
import WA3F0100Config                   from "./Api";
import {makeStyles, useTheme}             from "diginet-core-ui/theme";
import {Status}                           from 'diginet-core-ui/components';
import Icons, {ArrowDoubleRight}          from "diginet-core-ui/icons";

const useStyles = makeStyles(theme => ({
    root:              {
        display: "flex",
    },
    hide:              {
        display: "none",
    },
    viewDetail:        {
        color:     "#7F828E",
        cursor:    "pointer",
        "&:hover": {
            color: "#111D5E"
        },
        "&:focus": {
            color: "#111D5E"
        }
    },
    panel:             {
        transition: "all .4s",
    },
    panelForm:         {
        transition: "all .5s",
        opacity:    1,
    },
    hiddenOpacity:     {
        opacity: 0,
    },
    leftPanelMinimum:  {
        width: "105px",
    },
    rightPanelMinimum: {
        width: "calc(100% - 105px)",
    },
    titleColorFilter:  {
        backgroundColor: theme.colors.primary + " !important",
        "& svg":         {
            "& path": {
                fill: theme.colors.text.main,
            },
        },
    },
    textError:         {
        "& .MuiFormHelperText-root.Mui-error": {
            whiteSpace:   "nowrap",
            overflow:     "hidden",
            textOverflow: "ellipsis",
            width:        "100px",
        },
    },
    toolTipTitle:      {
        "& .MuiTooltip-popper": {
            display: "none",
        },
    },
}));
const coreTheme = useTheme();
const WA3F0100  = (props) => {

    const classes                               = useStyles();
    const [loading, _setLoading]                = useState({});
    const [dataSource, setDataSource]           = useState({
        rows:  [],
        total: 0
    });
    const [dataDetail, setDataDetail]           = useState(null);
    const [dataCboModules, setDataCboModules]           = useState(null);
    const [showDrawer, setShowDrawer]           = useState(true);
    const [showPopupDetail, setShowPopupDetail] = useState(false);

    const filter      = useRef({
        limit: 15,
        skip:  0
    });
    const params      = useRef({});
    const refLoading  = useRef({});
    const activeIndex = useRef(null);
    const dataGrid = useRef(null);

    const setLoading = (obj) => {
        refLoading.current = {...refLoading.current, ...obj};
        _setLoading(refLoading.current);
    };

    const loadHistorySearch = () => {
        if (params.current) {
            params.current.skip  = filter.current.skip;
            params.current.limit = filter.current.limit;
            setLoading({main: true});
            WA3F0100Config.search(params.current, (error, data) => {
                setLoading({main: false});
                if (error) {
                    let message = error.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (data) {
                    setDataSource(data);
                }
            });
        }
    };

    const onChangePage = (page) => {
        filter.current.skip = page * filter.current.limit;
        loadHistorySearch();
    };

    const onChangePerPage = (per) => {
        filter.current.skip  = 0;
        filter.current.limit = per;
        loadHistorySearch();
    };

    const handleDrawer      = (flag) => {
        setShowDrawer(!!flag);
        setTimeout(() => {
            dataGrid.current.instance.updateDimensions();
        }, 500);
    };
    const _renderAction     = (e) => {
        const {data} = e.row;
        return <div>{_renderStatus(data)}</div>;
    };
    const _renderStatus     = (data) => {
        let actions = {
            0: {
                color:  coreTheme.colors.success,
                action: Config.lang("Them_moi1"),
                icons:  "add",
            },
            1: {
                color:  coreTheme.colors.info,
                action: Config.lang("Sua"),
                icons:  "edit",
            },
            2: {
                color:  "",
                action: Config.lang("Khac"),
                icons:  "loading",
            },
            3: {
                color:  coreTheme.colors.warning,
                action: Config.lang("Xoa"),
                icons:  "delete",
            },
        };
        if (!_.isEmpty(data) && actions[data.action]) {
            return <Status
                text={actions[data.action].action}
                icon={<Icons
                    name={actions[data.action].icons}
                    color={actions[data.action].color}
                />}
                color={actions[data.action].color}
            />;
        }
    };
    const _renderContent    = (e) => {
        const {data} = e.row;
        if (Config.isEmpty(data.content) || _.isArray(data.content)) return false;
        let titles = Object.keys(data.content).map((item) => {
            if (!(item.substring(0, 4) === "data")) {
                if (
                    _.isObject(data.content[item]) &&
                    !_.isUndefined(data.content[item]["old"])
                ) {
                    return {
                        title: Config.lang(item),
                        value: `${data.content[item]["old"]} -> ${data.content[item]["new"]}`,
                    };
                } else {
                    return {
                        title: Config.lang(item),
                        value: data.content[item],
                    };
                }
            } else {
                return null;
            }
        });
        return (
            <div>
                {titles.map((i, idx) => {
                    if (!_.isNull(i) && !_.isObject(i.value)) {
                        let parseDate = moment(i.value, moment.ISO_8601, true).isValid() ? Date.parse(i.value) : false;
                        return (
                            <div className="display_row align-center" key={idx}>
                                {`${i.title}: ${parseDate ? Config.convertDate(parseDate, null, "DD/MM/YYYY") : i.value}`}
                            </div>
                        );
                    } else if (!_.isNull(i)) {
                        return (
                            <div
                                key={idx}
                                className={classes.viewDetail + " display_row align-center"}
                                onClick={() => onOpenPopupDetail(e, i)}
                                id={`rowIndex${e.rowIndex}`}
                                style={e.rowIndex === activeIndex.current ? {color: "#111D5E"} : {}}
                            >
                                <div className="display_row align-center">
                                    <span>{`${i.title}: ${Config.lang("Chi_tiet")}`}</span>
                                    <ArrowDoubleRight color={"currentColor"}/>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    };
    const _renderModule = (e) => {
        const { data } = e.row;
        const findData = !Config.isEmpty(dataCboModules) && data.moduleID ? dataCboModules.find(item => item.ModuleID === data.moduleID) : "";
        return <div>{findData?.ModuleName || ""}</div>
    };

    const onOpenPopupDetail = (e, item) => {
        setShowDrawer(false);
        setShowPopupDetail(true);
        setDataDetail(item);
        activeIndex.current = e.rowIndex;
    };

    const disabled = useMemo(() => !!Object.keys(loading).find(l => loading[l]), [loading]);
    return (
        <React.Fragment>
            <WA3F0100Detail
                showPopupDetail={showPopupDetail}
                dataDetail={dataDetail}
                handleClose={() => {
                    activeIndex.current = null;
                    setShowPopupDetail(false);
                }}
            />
            <ActionToolbar
                alignment={"flex-end"}
                title={Config.lang("Lich_su_tac_dong")}
                // showBorder={false}
                upperCase={false}
                className={"text-uppercase"}
                style={{
                    marginLeft:  -15,
                    paddingLeft: 15,
                    width:       "calc(100% + 30px)"
                }}
            />
            <Row>
                <Col xs={12} sm={4} md={4} lg={4}
                     className={`${classes.panel} ${window.innerWidth > 768 && !showDrawer ? classes.leftPanelMinimum : ""}`}
                     style={{padding: 0}}>
                    <div className={classes.panelForm}>
                        <WA3F0100Nav
                            showDrawer={showDrawer}
                            FormID={"WA3F0100"}
                            loading={loading}
                            handleToggle={handleDrawer}
                            onFilter={(data) => {
                                params.current = data;
                                loadHistorySearch();
                            }}
                            getDataCboModules={(data) => setDataCboModules(data)}
                        />
                    </div>
                </Col>
                <Col xs={12} sm={8} md={8} lg={8}
                     className={`${classes.panel} bdl ${window.innerWidth > 768 && !showDrawer ? classes.rightPanelMinimum : ""}`}
                >
                    <FormGroup style={{marginTop: 15}}>
                        <GridContainer
                            reference={ref => dataGrid.current = ref}
                            totalItems={dataSource && dataSource.total}
                            itemPerPage={filter.current.limit}
                            skipPerPage={filter.current.skip}
                            listPerPage={[15, 30, 45, 60]}
                            dataSource={dataSource && dataSource.rows}
                            keyExpr={"id"}
                            showBorders={false}
                            height={Config.getHeightGrid() - 20}
                            onChangePage={onChangePage}
                            onChangePerPage={onChangePerPage}
                            loading={disabled}
                            rowAlternationEnabled={false}
                            typePaging={"remote"}
                            hoverStateEnabled={true}
                            wordWrapEnabled={true}
                            // showRowLines={false}
                        >
                            <Column
                                caption={Config.lang("Hanh_dong")}
                                dataField={"action"}
                                alignment={"left"}
                                width={150}
                                cellRender={_renderAction}
                            />
                            <Column
                                caption={Config.lang("Nhan_vien")}
                                dataField={"userID"}
                                alignment={"left"}
                                width={200}
                                cellRender={(e) => {
                                    const {data} = e.row || {};
                                    return <div>
                                        <span>{`${data.userID} - ${data.userName}`}</span>
                                    </div>
                                }}
                            />
                            <Column
                                caption={Config.lang("Nghiep_vu")}
                                dataField={"transactionName"}
                                alignment={"left"}
                                width={200}
                            />
                            <Column
                                caption={Config.lang("Ngay")}
                                dataField={"createdAt"}
                                dataType={"date"}
                                format={"dd/MM/yyyy"}
                                alignment={"center"}
                                width={150}
                            />
                            <Column
                                caption={Config.lang("Mo_ta")}
                                dataField={"content"}
                                alignment={"left"}
                                minWidth={300}
                                cellRender={_renderContent}
                            />
                            {WA3F0100Config.showCboModule && <Column
                                caption={"Module"}
                                alignment={"left"}
                                minWidth={150}
                                cellRender={_renderModule}
                            />}
                        </GridContainer>
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default WA3F0100;
