/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 25/04/2022
 * @Example
 */
import { LoadPanel } from 'devextreme-react/load-panel';
import { Button, Dropdown, Modal, ModalBody, ModalHeader, TextInput } from 'diginet-core-ui/components';
import { useTheme } from "diginet-core-ui/theme";
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../config";
import * as generalActions from "../../redux/general/general_actions";

const ButtonExportReport = React.forwardRef((props, ref) => {
    const { spacing } = useTheme();
    const dispatch = useDispatch();
    const dataCboReport = useSelector(state => state?.general?.dataCboReport ?? []);
    const { ReportTypeID, ModuleID, onClick, IsDefault, StoreParams, setFormMasterLoading,
        disabled, viewType, color, label, iconName, size, className, style, titlePopup, comboProps, noteType, showBtnExxport, FormID } = props;
    const DivisionID = _.get(Config.profile, "DivisionID", Config.getDivisionID()) || Config.getLocalStorage("DIVISIONBEM");
    const inititalInfor = {
        btnExportStatus: true,
        downloadURL: "",
        downloadExt: "",
        downloadName: "",
    };

    const [openPopup, setOpenPopup] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [fileInFo, setFileInFo] = useState(inititalInfor);
    const [dataReports, setDataReports] = useState({
        ReportID: "",
        ReportName: ""
    });

    const { downloadURL, downloadName, btnExportStatus } = fileInFo;
    //=======REF==========
    const report = useRef(null);

    useImperativeHandle(ref, () => ({
        onClickShow, loadCboReports, onExport
    }));

    useEffect(() => {
        if (dataCboReport.length === 1) report.current = dataCboReport[0];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboReport]);

    useEffect(() => {
        loadCboReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPopup]);

    useEffect(() => {
        if (!_.isEmpty(downloadURL)) {
            const el = document.getElementById(`linkExportReport${FormID}`);
            if (el) el.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadURL]);

    const onExport = (isBtnExport = false) => {
        if (_.isEmpty(report.current)) return;
        const { ReportID = "", ReportName = "", StrSQL = "", URL = "", FileExt = "", ExportType = FileExt } = report.current;
        const params = {
            ReportID,
            ReportName,
            FileExt,
            ExportType,
            DivisionID,
            StrSQL,
            URL,
            Params: JSON.stringify(StoreParams)
        };
        isBtnExport === true ? setFormLoading(true) : setFormMasterLoading && setFormMasterLoading(true);
        dispatch(generalActions.exportTemplate(params, (error, data) => {
            isBtnExport === true ? setFormLoading(false) : setFormMasterLoading && setFormMasterLoading(false);
            if (error) {
                setFileInFo(inititalInfor);
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setFileInFo({
                    downloadURL: _.get(data, "URL", ""),
                    downloadExt: _.get(data, "fileExt", ""),
                    downloadName: _.get(data, "fileName", ""),
                });
            }
        }));
    };

    const loadCboReports = () => {
        const params = {
            FormID,
            ModuleID,
            ReportTypeID,
            IsDefault
        };
        dispatch(generalActions.getReportList(params, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const changeCboReports = (e) => {
        if (!e) return;
        const data = _.get(e, "data", {});
        report.current = data;
        setDataReports({ ...dataReports, ...data });
        if (btnExportStatus === true) setFileInFo({ ...fileInFo, btnExportStatus: false });
    };

    const onClickShow = (e) => {
        if (e && onClick) onClick(e);
        setOpenPopup(true);
        report.current = dataReports;
        if (btnExportStatus && dataReports.ReportID) setFileInFo({ ...fileInFo, btnExportStatus: false });
    };

    const onCloseModal = () => { //Close Modal and clear state and global
        report.current = null;
        setFileInFo(inititalInfor);
        setOpenPopup(false);
    };

    return (
        <React.Fragment>
            {showBtnExxport &&
                <Button
                    size={size}
                    label={label}
                    color={color}
                    startIcon={iconName}
                    viewType={viewType}
                    className={className}
                    disabled={disabled}
                    style={{ ...style }}
                    onClick={onClickShow} />
            }
            <Modal
                id={"PopupExportReport-WA3F2021"}
                open={openPopup}
                zIndex={1020}
                width={"696"}
                onClose={onCloseModal}>
                <ModalHeader>{titlePopup || Config.lang("Xuat_du_lieu_bao_cao")}</ModalHeader>
                <ModalBody>
                    <LoadPanel
                        position={{ my: 'center', of: '#PopupExportReport-WA3F2021' }}
                        visible={formLoading}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                    />
                    <Row className={"wrap"}>
                        <Col xs={12} sm={12} md={9} lg={9}>
                            <Dropdown
                                valueExpr={"ReportID"}
                                keyExpr={"ReportID"}
                                displayExpr={"ReportCustomName"}
                                placeholder={Config.lang("Chon")}
                                label={Config.lang("Mau_bao_cao")}
                                onChange={changeCboReports}
                                dataSource={dataCboReport}
                                value={dataReports?.ReportID}
                                {...comboProps}
                            />
                        </Col>
                        <Col xs={0} sm={0} md={3} lg={3} className={"jus-end"}>
                            <span style={{ marginBottom: spacing(5) }}>{noteType || ""}</span>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                label={Config.lang("Ten_bao_cao")}
                                placeholder={"ReportName"}
                                value={dataReports?.ReportName}
                            />
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            size={"medium"}
                            color={"primary"}
                            viewType={"filled"}
                            startIcon={"Printer"}
                            label={Config.lang("In1")}
                            disabled={btnExportStatus}
                            onClick={() => onExport(true)}
                        />
                    </div>
                </ModalBody>
            </Modal>
            <a id={`linkExportReport${FormID}`} className={"hide"} download={downloadName} href={downloadURL}>File</a>
        </React.Fragment>
    );
});

ButtonExportReport.defaultProps = {
    showBtnExxport: false,
    disabled: false,
    DataType: "",
    ModuleID: "",
    ReportTypeID: "",
    dataInfo: {},
    color: "primary",
    iconName: "Export",
    viewType: "outlined",
    noteType: ".html/.doc/.xlsx",
    dataTabHeaderTitle: [],
};

ButtonExportReport.propTypes = {
    disabled: PropTypes.bool,
    showBtnExxport: PropTypes.bool,
    IsDefault: PropTypes.number,
    label: PropTypes.string,
    color: PropTypes.string,
    FormID: PropTypes.string,
    DataType: PropTypes.string,
    iconName: PropTypes.string,
    noteType: PropTypes.string,
    className: PropTypes.string,
    titlePopup: PropTypes.string,

    dataInfo: PropTypes.object,
    style: PropTypes.any,
    size: PropTypes.any,
    icon: PropTypes.any,
    comboProps: PropTypes.any,
    onClick: PropTypes.func,
    setFormMasterLoading: PropTypes.func,

    ReportTypeID: PropTypes.string.isRequired,
    ModuleID: PropTypes.string.isRequired,
    StoreParams: PropTypes.any.isRequired,
};

export default ButtonExportReport;

