/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 16/06/2021
 * @Example
 */
import { Column } from "devextreme-react/data-grid";
import { LoadPanel } from 'devextreme-react/load-panel';
import {
    Button, ButtonIcon, DatePicker, Dropdown, Label, Modal, ModalBody, ModalHeader, TabContainer,
    TabHeader, TabItem, TextInput, Col, Row
} from 'diginet-core-ui/components';
import { makeStyles, useTheme } from "diginet-core-ui/theme";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2000Actions from "../../../../redux/modules/WA3/WA3F2000/WA3F2000_actions";
import MForm from "../../../common/forms/form-material/form";
import GridActionBar from "../../../common/grid-container/grid-actionbar";
import GridContainer from "../../../common/grid-container/grid-container";
import PopoverAction from "../../../common/grid-container/popover-action";
import ActionToolbar from "../../../common/layouts/toolbar/action-toolbar";
import History from "../../../common/libs/history/history";
import Filter from "../../../filter/filter";
import WA3F2001 from "../WA3F2001/WA3F2001";

const useStyles = makeStyles((theme) => ({
    btnActionItem: {
        display: 'block',
        width: '100%',
        textAlign: 'left',
        '&:hover': {
            backgroundColor: `${_.get(theme, "colors.hover", "")} !important`
        }
    },
    gridTitle: {
        display: 'inline-block',
        textTransform: 'uppercase',
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: 700,
        marginRight: 16
    }
}));

const FormID = "WA3F2000";
const initialDataGrid = {
    total: 0,
    rows: [],
};
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const WA3F2000 = (props) => {
    const { spacing, colors } = useTheme();
    const classes = useStyles();
    const { dataCboEStatus, dataContractGrid: dataContractGridOriginal = [], location } = props;
    const paramEvent = _.get(location, "state.paramEvent", {});
    const contractGrid = _.get(location, "state.contractGrid", {});
    const DivisionID = _.get(Config.profile, "DivisionID", Config.getDivisionID()) || Config.getLocalStorage("DIVISIONBEM");
    const dataDayByCombo = useMemo(() => {
        return [
            {
                id: 0,
                fieldName: Config.lang("Hom_nay"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
            {
                id: 1,
                fieldName: Config.lang("Hom_qua"),
                dateFrom: moment().subtract(1, "day").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "day").format("YYYY-MM-DD")
            },
            {
                id: 2,
                fieldName: Config.lang("Tuan_nay"),
                dateFrom: moment().startOf("week").format("YYYY-MM-DD"),
                dateTo: moment().endOf("week").format("YYYY-MM-DD")
            },
            {
                id: 3,
                fieldName: Config.lang("Tuan_truoc"),
                dateFrom: moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD")
            },
            {
                id: 4,
                fieldName: Config.lang("Thang_nay"),
                dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 5,
                fieldName: Config.lang("Thang_truoc"),
                dateFrom: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 6,
                fieldName: Config.lang("Nam_nay"),
                dateFrom: moment().startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 7,
                fieldName: Config.lang("Nam_truoc"),
                dateFrom: moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 8,
                fieldName: Config.lang("Chon_thoi_gian"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showChoseDate, setShowChoseDate] = useState(false);
    const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
    const [openModalWA3F2001, setOpenModalWA3F2001] = useState(false);
    const [btnSaveUpdataStatus, setBtnSaveUpdataStatus] = useState(true);
    const [modalUpdateStatusLoading, setModalUpdateStatusLoading] = useState(false);
    const [loading, _setLoading] = useState({
        grid01Loading: false,
        grid02Loading: false,
        cboCreateUserLoading: false,
        cboObjectLoading: false,
        cboEStatusLoading: false,
    });
    const { grid01Loading, grid02Loading, cboCreateUserLoading, cboObjectLoading, cboEStatusLoading } = loading;

    const [tabIndex, setTabIndex] = useState(0);
    const [permission, setPermission] = useState(_.get(location, "state.permission", null));
    const [errorUStatus, setErrorUStatus] = useState({});
    const [updateStatusState, setUpdateStatusState] = useState({
        ContractID: "",
        ContractNo: "",
        EStatus: "",
    });
    const [dataContractGrid, setDataContractGrid] = useState(initialDataGrid);
    const [dataEventGrid, setDataEventGrid] = useState(initialDataGrid);
    const [dataTabHeaderTitle, setDataTabHeaderTitle] = useState([]);

    const isClearParamEvent = useRef(false);
    const setFocusEventGrid = useRef(false);
    const popoverGrid01 = useRef(null);
    const popoverGrid02 = useRef(null);
    const inititalTabIndex = useRef(null);
    const timerCbo = useRef(null);
    const refLoading = useRef({});
    const paramEventGrid = useRef({});
    const currentClickActionRow01 = useRef({});
    const currentClickActionRow02 = useRef({});
    const [dataCboCreateUser, setDataCboCreateUser] = useState({
        rows: [],
        total: 0
    });
    const [dataCboCustomer, setDataCboCustomer] = useState({
        rows: [],
        total: 0
    });
    const defaultDate = 4; // default Tháng này
    const [filterSearch, setFilterSearch] = useState({
        BookingID: "",
        Employee: "",
        EmployeeID: "", // Người lập
        ObjectID: "", // Khách hàng
        ObjectName: "",
        ContractNo: "",
        ContractID: "",
        EStatus: "1", // Defaul
        selectedDate: defaultDate,
        DateFrom: _.get(dataDayByCombo[defaultDate], "dateFrom", null),
        DateTo: _.get(dataDayByCombo[defaultDate], "dateTo", null),
    });
    const [currentRowActionData01, setCurrentRowActionData01] = useState({});
    const [currentRowActionData02, setCurrentRowActionData02] = useState({});
    const EStatusOld = usePrevious(filterSearch.EStatus) || "";
    const inititalContractGridPages = {
        strSearch: "",
        skip: 0,
        limit: 30,
    }
    const inititalEventGridPages = {
        skip: 0,
        limit: 10,
    }
    const dataContractGridPages = useRef(inititalContractGridPages);
    const dataEventGridPages = useRef(inititalEventGridPages);
    const filterCboCreateUsers = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    const filterCboCustomer = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    const dataInfo = useRef({
        Permission: permission,
        Mode: "add",
        ContractID: "",
        ContractNo: "",
        EventID: "",
    });
    let dataEventRow = _.get(dataEventGrid, "rows", []);

    const setLoading = (obj) => {
        refLoading.current = { ...refLoading.current, ...obj };
        _setLoading(refLoading.current);
    };

    useEffect(() => { // Chạy lần đầu tiên
        if (_.isNull(permission)) {
            loadPermission();
            return;
        }
        if (_.isNull(_.get(location, "state.permission", null)) && _.isEmpty(_.get(contractGrid, "dataTabHeaderTitle", []))) {
            loadGridTabs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission]);

    useEffect(() => {
        if (_.isEmpty(paramEvent)) {
            if ((EStatusOld !== filterSearch.EStatus || dataTabHeaderTitle) && isClearParamEvent.current === false) {
                if (!_.isEmpty(dataTabHeaderTitle)) {
                    loadContractGrid();
                } else {
                    setDataContractGrid(initialDataGrid);
                }
            }
        } else {
            if (!_.isEmpty(contractGrid)) {
                const dataContractGridOld = _.get(contractGrid, "dataContractGrid", initialDataGrid);
                const dataContractGridPagesOld = _.get(contractGrid, "dataContractGridPages", inititalContractGridPages);
                const currentClickActionRow01Old = _.get(contractGrid, "currentClickActionRow01", {});
                const dataTabHeaderTitleOld = _.get(contractGrid, "dataTabHeaderTitle", []);
                if (dataContractGridPagesOld) dataContractGridPages.current = dataContractGridPagesOld;
                if (currentClickActionRow01Old) currentClickActionRow01.current = currentClickActionRow01Old;
                if (dataContractGridOld) setDataContractGrid(dataContractGridOld);
                if (dataTabHeaderTitleOld) setDataTabHeaderTitle(dataTabHeaderTitleOld);
            } else { // Không có data lưới 1 thì load lại tab là ==> lưới 1 ==> lưới 2
                loadGridTabs();
            }
            loadEventGrid(paramEvent);
            isClearParamEvent.current = true;
            browserHistory.push({ // Clear State trong location khi F5 lại
                pathname: Config.getRootPath() + "WA3F2000",
                state: {}
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSearch.EStatus, dataTabHeaderTitle]);

    const loadPermission = useCallback(() => {
        props.generalActions.getPermission("WA3F2000", (isPer) => {
            setPermission(isPer);
            dataInfo.current = {
                ...dataInfo.current,
                Permission: isPer
            }
        });
    }, [props.generalActions]);

    const redirectPage = (mode, screen, currentRowClick = {}) => {
        if (!mode || _.isEmpty(screen)) return;
        let dataEdit = {
            Mode: mode,
            Permission: permission,
        };
        let paramEvent = {};
        let contractGrid = {};
        if (mode === "add") { // NÚt ADD lấy data từ lưới 1
            dataEdit = {
                ...dataEdit,
                ContractID: _.get(currentClickActionRow01.current, "ContractID", ""),
                ContractNo: _.get(currentClickActionRow01.current, "ContractNo", ""),
                ObjectID: _.get(currentClickActionRow01.current, "ObjectID", ""),
                ObjectName: _.get(currentClickActionRow01.current, "ObjectName", ""),
                CurrencyID: _.get(currentClickActionRow01.current, "CurrencyID", ""),
                ExchangeRate: _.get(currentClickActionRow01.current, "ExchangeRate", ""),
            };
        }
        if (mode === "edit" || mode === "view") { // CLick lưới 2
            const currentRowClickData = _.isEmpty(currentRowClick) ? currentClickActionRow02.current : currentRowClick;
            dataEdit = {
                ...dataEdit,
                ContractID: _.get(currentRowClickData, "ContractID", ""),
                BatchID: _.get(currentRowClickData, "BatchID", ""),
                EventID: _.get(currentRowClickData, "EventID", ""),
                Version: _.get(currentRowClickData, "Version", ""),
            };
        }
        if (screen === "WA3F2010" && paramEventGrid.current) {
            paramEvent = paramEventGrid.current;
            contractGrid = {
                dataContractGrid,
                dataTabHeaderTitle,
                dataContractGridPages: dataContractGridPages.current,
                currentClickActionRow01: currentClickActionRow01.current
            }
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: {
                ...dataEdit,
                screenMaster: "WA3F2000",
                paramEvent,
                contractGrid,
                screen,
                mode,
                permission
            }
        });
    };

    const loadGridTabs = () => {
        const { EventID } = dataInfo.current;
        const { EmployeeID, ContractID, ContractNo, ObjectID, DateFrom, DateTo } = filterSearch;
        const { skip, limit, strSearch } = dataContractGridPages.current;
        const params = {
            DivisionID,
            skip,
            limit,
            strSearch,
            EmployeeID,
            ContractID,
            ContractNo,
            ObjectID,
            DateFrom,
            DateTo,
            EventID,
        };
        props.wA3F2000Actions.loadTabContract(params, (error, data) => {
            if (error) {
                setDataTabHeaderTitle([]);
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (!_.isEmpty(data)) {
                    const mindTab = Math.min.apply(Math, data.map(item => item.EStatus));
                    inititalTabIndex.current = mindTab;
                    filterSearch.EStatus = _.toString(mindTab);
                    setTabIndex(mindTab);
                }
                setDataTabHeaderTitle(data);
            }
        });
    };

    const loadContractGrid = (isLoadEventGrid = true) => { //EStatusTab Tab hiện tại đang đứng để filter data
        const { EventID } = dataInfo.current;
        const { EmployeeID, ContractID, ContractNo, ObjectID, DateFrom, DateTo, EStatus } = filterSearch;
        const { skip, limit, strSearch } = dataContractGridPages.current;
        const params = {
            DivisionID,
            skip,
            limit,
            strSearch,
            EmployeeID,
            ContractID,
            ContractNo,
            ObjectID,
            EStatus,
            DateFrom,
            DateTo,
            EventID,
        };
        setLoading({ grid01Loading: true });
        if (!_.isEmpty(dataContractGrid?.rows)) setDataContractGrid(initialDataGrid); // fix bug cột thay đổi
        props.wA3F2000Actions.loadContractGrid(params, (error, data) => {
            setLoading({ grid01Loading: false });
            if (error) {
                setDataContractGrid(initialDataGrid);
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const total = _.get(data, "total", 0);
                const rows = _.get(data, "rows", []);
                if (!_.isEmpty(rows)) {
                    currentClickActionRow01.current = rows[0];
                    if (isLoadEventGrid === true && _.isEmpty(paramEvent)) loadEventGrid(rows[0]); // Load lưới Tiệc
                } else {
                    currentClickActionRow01.current = {};
                }
                setDataContractGrid({
                    total,
                    rows
                });
            }
        });
    };

    const loadEventGrid = (rowData = {}) => {
        if (_.isEmpty(rowData)) return;
        const { EventID } = dataInfo.current;
        const { DateFrom, DateTo } = filterSearch;
        const { EmployeeID = "", ContractID = "", ContractNo = "", ObjectID = "" } = rowData;
        const { skip, limit } = dataEventGridPages.current;
        const params = {
            DivisionID,
            skip,
            limit,
            EmployeeID,
            ContractID,
            ContractNo,
            ObjectID,
            DateFrom,
            DateTo,
            EventID,
        };
        paramEventGrid.current = params;
        setLoading({ grid02Loading: true });
        props.wA3F2000Actions.loadEventGrid(params, (error, data) => {
            setLoading({ grid02Loading: false });
            if (error) {
                setDataEventGrid(initialDataGrid);
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataEventGrid({
                    total: _.get(data, "total", 0),
                    rows: _.get(data, "rows", [])
                });
            }
        });
    };

    const loadCboCreateUser = (isReset) => {
        const { skip, limit, search } = filterCboCreateUsers.current;
        const { ObjectID, ObjectTypeID, BookingID } = filterSearch;
        const param = {
            FormID,
            ObjectID,
            ObjectTypeID,
            BookingID,
            skip,
            limit,
            search
        };
        setLoading({ cboCreateUserLoading: true });
        props.generalActions.getCboCreateUser(param, (error, data) => {
            setLoading({ cboCreateUserLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = _.isEmpty(item.EmployeeID) || _.isEmpty(item.EmployeeName) ? "" : "-";
                        return ({ ...item, EmployeeCustomName: `${item.EmployeeID} ${line} ${item.EmployeeName}` })
                    });
                }
                setDataCboCreateUser({
                    rows: isReset ? rows : dataCboCreateUser.rows.concat(rows),
                    total
                })
            }
        });
    };

    const loadCboCustomer = (isReset) => {
        const { skip, limit, search } = filterCboCustomer.current;
        const { ObjectID, BookingID } = filterSearch;
        const param = {
            FormID,
            ObjectID,
            BookingID,
            skip,
            limit,
            search
        };
        setLoading({ cboCustomerLoading: true });
        props.generalActions.getCboCustomer(param, (error, data) => {
            setLoading({ cboCustomerLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = _.isEmpty(item.ObjectID) || _.isEmpty(item.ObjectName) ? "" : "-";
                        return ({ ...item, ObjectCustomName: `${item.ObjectID} ${line} ${item.ObjectName}` })
                    });
                }
                setDataCboCustomer({
                    rows: isReset ? rows : dataCboCustomer.rows.concat(rows),
                    total
                });
            }
        });
    };

    const openModalPage = (Mode, grid, screen, currentRowClick = {}) => {  // Modal nằm Màn hình khác
        if (!Mode || _.isEmpty(screen)) return;
        let ContractID = "";
        let ContractNo = "";
        let DataType = "DetailContract";
        let currentRowSelected = currentRowClick;
        if (_.isEmpty(currentRowClick) && grid === 1)
            currentRowSelected = currentClickActionRow01.current;
        else if (_.isEmpty(currentRowClick) && grid === 2) {
            currentRowSelected = currentClickActionRow02.current;
        }
        if (Mode !== "add" || Mode === "addendum") {
            ContractID = _.get(currentRowSelected, "ContractID", "");
            ContractNo = _.get(currentRowSelected, "ContractNo", "");
        }
        if (Mode === "copy") {
            DataType = "LoadCopyContract";
        }
        if (Mode === "reSign") {
            Mode = "add";
            DataType = "LoadReSignContract";
        }
        if (Mode === "addendum") {
            Mode = "add";
            DataType = "LoadAddendumContract"; 
        }
        dataInfo.current = {
            ...dataInfo.current,
            Mode,
            DataType,
            ContractID,
            ContractNo,
        };
        switch (screen) {
            case "WA3F2001":
                setOpenModalWA3F2001(true);
                break;
            default:
                break;
        }
        if (popoverGrid01.current) popoverGrid01.current.instance.hide(); // Đóng Action
        if (popoverGrid02.current) popoverGrid02.current.instance.hide(); // Đóng Action
    };

    const setGridLoading = (gridIndex, status) => {
        if (_.isBoolean(status)) {
            if (gridIndex === 1) {
                setLoading({ grid01Loading: status });
            } else if (gridIndex === 1) {
                setLoading({ grid02Loading: status });
            }

        }
    };

    const onReLoadGrid = (gridIndex) => { // Handle reload grid
        if (!_.isNumber(gridIndex)) return;
        if (gridIndex === 1) {
            loadGridTabs();
        } else if (gridIndex === 2) {
            if (currentClickActionRow01.current) loadEventGrid(currentClickActionRow01.current);
        }
    };

    const onDelete = async (gridIndex) => {
        if (!_.isNumber(gridIndex)) return;
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), async () => {
            let apiDeleteLink = "deleteContract";
            let currentRow = currentClickActionRow01.current;
            const { ContractID = "", ContractNo = "" } = currentRow;
            const { EventID = "", EventName = "" } = currentClickActionRow02.current;
            const params = {
                ContractID,
                ContractNo,
                EventID: gridIndex === 2 ? EventID : "",
                DivisionID: "",
                EmployeeID: "",
                ObjectID: "",
                DateFrom: null,
                DateTo: null,
                skip: 0,
                limit: 0,
            };
            if (gridIndex === 2) {
                currentRow = currentClickActionRow02.current;
                apiDeleteLink = "deleteEvent";
            }
            setGridLoading(gridIndex, true);
            props.wA3F2000Actions[apiDeleteLink](params, async (error, data) => {
                setGridLoading(gridIndex, false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    const status = _.get(data, "Status", null);
                    const paramHis = gridIndex === 1 ? { ContractNo } : { EventID, EventName };
                    await onSaveHistory(paramHis, gridIndex, {}, 3);
                    if (status === 0) {
                        Config.notify.show("success", _.get(data, "Message", "") || Config.lang("Xoa_thanh_cong"), 2000);
                        onReLoadGrid(gridIndex);
                    } else {
                        Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                        return false;
                    }
                }
            });
        });
    };

    const onCancel = async (gridIndex) => {
        if (!_.isNumber(gridIndex)) return;
        let apiDeleteLink = "cancelContract";
        let currentRow = currentClickActionRow01.current;
        const { ContractID = "", ContractNo = "" } = currentRow;
        const params = {
            ContractID,
            ContractNo
        };
        if (gridIndex === 2) {
            const { EventID = "" } = currentClickActionRow02.current;
            currentRow = currentClickActionRow02.current;
            apiDeleteLink = "cancelEvent";
            params.EventID = EventID;
        }
        setGridLoading(gridIndex, true);
        props.wA3F2000Actions[apiDeleteLink](params, async (error, data) => {
            setGridLoading(gridIndex, false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const status = _.get(data, "Status", null);
                if (status === 0) {
                    await onSaveHistory(params, gridIndex, {}, 2);
                    Config.notify.show("success", _.get(data, "Message", "") || Config.lang("Huy_thanh_cong"), 2000);
                    onReLoadGrid(gridIndex);
                } else {
                    Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                    return false;
                }
            }
        });
    };

    const checkEditEvent = () => {
        const { ContractID, ContractNo, EventID, BatchID } = currentClickActionRow02.current;
        const param = {
            DivisionID,
            ContractID,
            ContractNo,
            EventID,
            BatchID,
        };
        props.wA3F2000Actions.checkEditEvent(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                if (data?.Status === 0) {
                    redirectPage("edit", "WA3F2010");
                } else {
                    Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                    return false;
                }
            }
        });
    };

    const onSaveHistory = async (data = {}, gridIndex, dataCompare = {}, mode = 0) => {
        if (_.isEmpty(data)) return;
        let TransactionName = Config.lang("Hop_dong_dich_vu");
        let action = mode;
        let TransID = _.get(data, "ContractNo", "");
        let captions = {
            EStatusName: "Trang_thai_hop_dong",
        };
        if (!_.isEmpty(dataCompare)) action = 1; // Lịch sử Cập nhập trạng Thái
        if (gridIndex === 1 && action !== 1) {
            captions = {
                ContractNo: "So_hop_dong",
            };
            if (action === 2) TransactionName = `${Config.lang("Huy")} ${Config.lang("Hop_dong_dich_vu")}`;
        } else if (gridIndex === 2) { // Lưới 2
            captions = {
                EventID: "Ma_su_kien",
                EventName: "Ten_su_kien",
            };
            TransID = _.get(data, "EventID", "");
            TransactionName = `${Config.lang("Tiec")} - ${Config.lang("Su_kien")}`;
            if (action === 2) TransactionName = `${Config.lang("Huy")} ${TransactionName}`;
        }
        const options = {
            data,
            dataCompare, // Old Data
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID,
            TransactionName
        };
        const history = new History(options);
        // console.log("===========HIS=========>", history.get());
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const onLoadDataFilter = () => {
        loadCboCreateUser();
        loadCboCustomer();
    };

    const onSearch = (e) => {
        if (dataContractGridPages.current) {
            dataContractGridPages.current.strSearch = _.get(e, "target.value", "");
            loadGridTabs();
        }
    };

    const onChangeContractPage = (page) => {
        dataContractGridPages.current.skip = page * dataContractGridPages.current.limit;
        loadContractGrid();
    };

    const onChangeContractPerPage = (perPage) => {
        dataContractGridPages.current.skip = 0;
        dataContractGridPages.current.limit = perPage;
        loadContractGrid();
    };

    const onChangeEventPage = (page) => {
        dataEventGridPages.current.skip = page * dataEventGridPages.current.limit;
        if (currentClickActionRow01.current) loadEventGrid(currentClickActionRow01.current);
    };

    const onChangeEventPerPage = (perPage) => {
        dataEventGridPages.current.skip = 0;
        dataEventGridPages.current.limit = perPage;
        if (currentClickActionRow01.current) loadEventGrid(currentClickActionRow01.current);
    };

    const onOpenMenu = (e, currentRowSelected, gridIndex) => {
        const target = _.get(e, "currentTarget", {});
        if (gridIndex === 1 && popoverGrid01.current) {
            currentClickActionRow01.current = currentRowSelected;
            setCurrentRowActionData01(currentClickActionRow01.current);
            setTimeout(() => popoverGrid01.current.instance.show(target), 0);
        } else if (gridIndex === 2 && popoverGrid02.current) {
            currentClickActionRow02.current = currentRowSelected;
            setCurrentRowActionData02(currentClickActionRow02.current);
            setTimeout(() => popoverGrid02.current.instance.show(target), 0);
        }
    };

    const handleChangeTab = (index, EStatus = "") => {
        if (!_.isNumber(index) || _.isEmpty(EStatus)) return;
        if (index !== tabIndex) {
            dataContractGridPages.current = inititalContractGridPages;
            dataEventGridPages.current = inititalEventGridPages;
            setTabIndex(index);
            setFilterSearch({ ...filterSearch, EStatus });
        }
    };

    const renderTab = useMemo(() => {
        if (!(dataTabHeaderTitle?.length > 0)) return;
        return (
            <TabContainer tabIndex={tabIndex}>
                <TabHeader>
                    {dataTabHeaderTitle.map((item, idx) => {
                        const { EStatusName = "", Total = null, EStatus = "" } = item;
                        const currenIndex = inititalTabIndex.current + idx;
                        return (
                            <TabItem
                                key={idx}
                                index={currenIndex}
                                label={`${EStatusName} ${!Config.isEmpty(Total, true) ? `(${Total})` : ""}`}
                                onClick={() => handleChangeTab(currenIndex, EStatus)}
                            />
                        )
                    })}
                </TabHeader>
            </TabContainer>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTabHeaderTitle, tabIndex]);

    const onOpenModalState = (modalName, status) => { // Modal nằm Màn hình này
        if (!_.isEmpty(modalName) && _.isBoolean(status)) {
            switch (modalName) {
                case "openUpdateStatusModal":
                    setOpenUpdateStatusModal(status);
                    if (status) { // Mở
                        setUpdateStatusState({
                            ContractID: _.get(currentClickActionRow01.current, "ContractID", ""),
                            ContractNo: _.get(currentClickActionRow01.current, "ContractNo", ""),
                            EStatus: _.get(currentClickActionRow01.current, "EStatus", "")
                        });
                        if (_.isEmpty(dataCboEStatus)) loadCboEStatus();
                    } else { // Đóng
                        setBtnSaveUpdataStatus(true); // Ẩn nút Save
                        setUpdateStatusState({
                            ContractNo: "",
                            EStatus: ""
                        });
                    }
                    break;
                default:
                    break;
            }
            if (popoverGrid01.current) popoverGrid01.current.instance.hide(); // Đóng Action
            if (popoverGrid02.current) popoverGrid02.current.instance.hide(); // Đóng Action
        }
    };

    const loadCboEStatus = () => {
        setLoading({ cboEStatusLoading: true });
        props.generalActions.getCboEStatus((error) => {
            setLoading({ cboEStatusLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const checkValidateMaster = (arrName, stateName = {}) => {
        if (_.isEmpty(arrName) || _.isEmpty(stateName)) return;
        return arrName.map(item => ({
            name: item,
            rules: ["isRequired"],
            value: stateName[item]
        }));
    };

    const onSaveUpdateStatus = async () => {
        const requiredField = ["EStatus"];
        const resultValidate = checkValidateMaster(requiredField, updateStatusState);
        const validateForm = MForm.formValidation(resultValidate);
        if (Object.keys(validateForm).length > 0) {
            setErrorUStatus(validateForm);
            return false;
        } else { //Lưu
            const { EStatus, ContractID, ContractNo } = updateStatusState;
            const param = {
                EStatus,
                ContractID,
                ContractNo,
            };
            setModalUpdateStatusLoading(true);
            props.wA3F2000Actions.saveUpdateStatus(param, async (error, data) => {
                setModalUpdateStatusLoading(false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    if (data.Status === 0) {
                        await onSaveHistory(updateStatusState, 1, currentClickActionRow01.current);
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                        setBtnSaveUpdataStatus(true); // Ẩn nút Save
                        onOpenModalState("openUpdateStatusModal", false); // Đóng Pop
                        loadGridTabs(); // Load lại Lưới 1
                    } else {
                        Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                        return false;
                    }
                }
            });
        }
    };

    const onOpenModalAction = (status, reloadPage = false) => {
        if (_.isBoolean(status)) {
            setOpenModalWA3F2001(status);
            if (reloadPage) loadContractGrid();
        }
    };

    const onChangeUpdateStatus = (fieldName, e) => {
        const value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        const allDDName = ["EStatus"];
        let keyValueObj = { [fieldName]: value };
        if (allDDName.includes(fieldName)) {
            const positionID = fieldName.indexOf("ID");
            const standardDDName = positionID > 0 ? fieldName.slice(0, positionID) : fieldName;
            const DDName = standardDDName + "Name";
            let valueDDName = _.get(e.data, [DDName], _.get(e.data, "ObjectName", ""));
            keyValueObj = {
                ...keyValueObj,
                [DDName]: valueDDName
            }
        }
        setUpdateStatusState({
            ...updateStatusState,
            ...keyValueObj
        });
        if (btnSaveUpdataStatus) setBtnSaveUpdataStatus(false);
    };

    const renderActionGrid = (e, gridIndex) => {
        const currentRowSelected = _.get(e, "data", {});
        return (
            <GridActionBar >
                <ButtonIcon
                    circular
                    name={"More"}
                    size={"medium"}
                    viewType={"text"}
                    onClick={(e) => onOpenMenu(e, currentRowSelected, gridIndex)} />
            </GridActionBar>
        );
    };

    const popupActionBar01 = () => {
        return (
            <>
                <PopoverAction
                    reference={ref => popoverGrid01.current = ref}
                    position={"right"}
                    maxWidth={300}
                    deferRendering={false}
                >
                    <Button
                        className={classes.btnActionItem}
                        disabled={!(permission > 2)}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("Dieu_chinh_hop_dong")}
                        onClick={() => openModalPage("edit", 1, "WA3F2001")}
                    />
                    <Button
                        className={classes.btnActionItem}
                        disabled={!(permission > 2)}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("Cap_nhat_trang_thai")}
                        onClick={() => onOpenModalState("openUpdateStatusModal", true)}
                    />
                    <Button
                        className={classes.btnActionItem}
                        disabled={!(permission > 2) ||
                            ![3, 4].includes(_.toNumber(_.get(currentRowActionData01, "EStatus", 0)))}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("Tai_ky_hop_dong")}
                        onClick={() => openModalPage("reSign", 1, "WA3F2001")}
                    />
                    <Button
                        className={classes.btnActionItem}
                        disabled={!(permission > 2)}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("Sao_chep")}
                        onClick={() => openModalPage("copy", 1, "WA3F2001")}
                    />
                    <Button
                        className={classes.btnActionItem}
                        disabled={!(permission >= 2)}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("Phu_luc_hop_dong")}
                        onClick={() => openModalPage("addendum", 1, "WA3F2001")}
                    />
                    <Button
                        className={classes.btnActionItem}
                        disabled={true}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("In1")}
                    // onClick={onPrint}
                    />
                    <Button
                        className={classes.btnActionItem}
                        disabled={!(permission >= 4)}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("Xoa")}
                        onClick={() => onDelete(1)}
                    />
                    <Button
                        className={classes.btnActionItem}
                        disabled={!(permission >= 2) || _.toNumber(currentRowActionData01?.EStatus) === 6}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("Huy")}
                        onClick={() => onCancel(1)}
                    />
                </PopoverAction>
            </>
        );
    };

    const popupActionBar02 = () => {
        return (
            <>
                <PopoverAction
                    reference={ref => popoverGrid02.current = ref}
                    position={"right"}
                    maxWidth={300}
                    deferRendering={false}
                >
                    <div>
                        <Button
                            className={classes.btnActionItem}
                            disabled={!(permission > 2)}
                            size={"medium"}
                            color={"primary"}
                            viewType={"text"}
                            label={Config.lang("Dieu_chinh_su_kien")}
                            onClick={checkEditEvent}

                        />
                        <Button
                            className={classes.btnActionItem}
                            disabled={!(permission >= 4)}
                            size={"medium"}
                            color={"primary"}
                            viewType={"text"}
                            label={Config.lang("Xoa")}
                            onClick={() => onDelete(2)}
                        />
                        <Button
                            className={classes.btnActionItem}
                            disabled={!(permission >= 2) || _.toNumber(currentRowActionData02?.EStatus) === 4}
                            size={"medium"}
                            color={"primary"}
                            viewType={"text"}
                            label={Config.lang("Huy")}
                            onClick={() => onCancel(2)}
                        />
                    </div>
                </PopoverAction>
            </>
        );
    };

    const filterChange = (filterName = "", e) => {
        if (!e || _.isEmpty(filterName)) return;
        const value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        switch (filterName) {
            case "ChoseDate":
                setFilterSearch({
                    ...filterSearch,
                    selectedDate: value,
                    DateFrom: (dataDayByCombo[value]).dateFrom,
                    DateTo: (dataDayByCombo[value]).dateTo,
                });
                if (value === 8) {
                    setShowChoseDate(true);
                } else {
                    if (showChoseDate) setShowChoseDate(false);
                }
                break;
            case "DateFrom":
            case "DateTo":
            case "ObjectID":
            case "EmployeeID":
                setFilterSearch({ ...filterSearch, [filterName]: value });
                break;
            case "ContractNo": // Change Text ko cần setState liền
                filterSearch[filterName] = value;
                break;
            default:
                break;
        }
    };

    const btnSearchAction = () => {
        dataContractGridPages.current.skip = inititalContractGridPages.skip;
        dataContractGridPages.current.limit = inititalContractGridPages.limit;
        dataEventGridPages.current = inititalEventGridPages;
        loadGridTabs();
    };

    const renderFilterData = () => {
        return (
            <Filter
                isUseDDCore={true}
                placeholder={Config.lang("Noi_dung_can_tim")}
                onTextChanged={onSearch}
                onOpenLoaded={onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    viewType={"outlined"}
                                    valueExpr={"id"}
                                    keyExpr={"fieldName"}
                                    displayExpr={"fieldName"}
                                    placeholder={Config.lang("Chon")}
                                    label={Config.lang("Chon_thoi_gian")}
                                    onChange={e => filterChange("ChoseDate", e)}
                                    dataSource={dataDayByCombo}
                                    value={filterSearch.selectedDate}
                                />
                            </Col>
                            {showChoseDate &&
                                <Col xs={12} sm={6} md={6} lg={6} >
                                    <div className={"display_row align-center "}>
                                        <Label style={{ minWidth: 22 }} className={"mgr5"}>{Config.lang("Tu")}</Label>
                                        <DatePicker
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            max={filterSearch.DateTo}
                                            value={filterSearch.DateFrom}
                                            onChange={e => filterChange("DateFrom", e)}
                                        />
                                    </div>
                                </Col>
                            }
                            {showChoseDate && <Col xs={12} sm={6} md={6} lg={6}>
                                <div className={"display_row align-center "}>
                                    <Label style={{ minWidth: 22 }} className={"mgr5"}>{Config.lang("Den")}</Label>
                                    <DatePicker
                                        viewType={"outlined"}
                                        placeholder={"dd/mm/yyyy"}
                                        displayFormat={"DD/MM/YYYY"}
                                        min={filterSearch.DateFrom}
                                        value={filterSearch.DateTo}
                                        onChange={e => filterChange("DateTo", e)}
                                    />
                                </div>
                            </Col>}
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    viewType={"outlined"}
                                    label={Config.lang("So_hop_Dong")}
                                    placeholder={Config.lang("Nhap")}
                                    onChange={(e) => filterChange("ContractNo", e)}
                                    value={filterSearch.ContractNo}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    clearAble
                                    allowSearch
                                    dataSource={_.get(dataCboCustomer, "rows", [])}
                                    total={_.get(dataCboCustomer, "total", 0)}
                                    skip={filterCboCustomer.current.skip}
                                    limit={filterCboCustomer.current.limit}
                                    value={filterSearch.ObjectID}
                                    loading={cboObjectLoading}
                                    viewType={"outlined"}
                                    valueExpr={"ObjectID"}
                                    keyExpr={"ObjectName"}
                                    label={Config.lang("Khach_hang")}
                                    placeholder={Config.lang("Chon")}
                                    displayExpr={"ObjectCustomName"}
                                    onChange={(e) => filterChange("ObjectID", e)}
                                    onLoadMore={(e) => {
                                        filterCboCustomer.current.skip = e.skip;
                                        filterCboCustomer.current.limit = e.limit;
                                        loadCboCustomer();
                                    }}
                                    onInput={(e) => {
                                        const value = e?.target?.value;
                                        if (timerCbo.current) clearTimeout(timerCbo.current);
                                        timerCbo.current = setTimeout(() => {
                                            filterCboCustomer.current.search = value;
                                            filterCboCustomer.current.skip = 0;
                                            loadCboCustomer(true);
                                        }, 700);
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    clearAble
                                    allowSearch
                                    dataSource={_.get(dataCboCreateUser, "rows", [])}
                                    total={_.get(dataCboCreateUser, "total", 0)}
                                    skip={filterCboCreateUsers.current.skip}
                                    limit={filterCboCreateUsers.current.limit}
                                    value={filterSearch.EmployeeID}
                                    loading={cboCreateUserLoading}
                                    viewType={"outlined"}
                                    valueExpr={"EmployeeID"}
                                    keyExpr={"EmployeeName"}
                                    label={Config.lang("Nguoi_lap")}
                                    placeholder={Config.lang("Chon")}
                                    displayExpr={"EmployeeCustomName"}
                                    onChange={(e) => filterChange("EmployeeID", e)}
                                    onLoadMore={(e) => {
                                        filterCboCreateUsers.current.skip = e.skip;
                                        filterCboCreateUsers.current.limit = e.limit;
                                        loadCboCreateUser();
                                    }}
                                    onInput={(e) => {
                                        const value = e?.target?.value;
                                        if (timerCbo.current) clearTimeout(timerCbo.current);
                                        timerCbo.current = setTimeout(() => {
                                            filterCboCreateUsers.current.search = value;
                                            filterCboCreateUsers.current.skip = 0;
                                            loadCboCreateUser(true);
                                        }, 700);
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", }}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={btnSearchAction}
                                />
                            </Col>
                        </Row>
                    );
                }}
            />
        );
    };

    const renderGrid01 = useMemo(() => {
        const dataRow = _.get(dataContractGrid, "rows", []);
        return (
            <GridContainer
                itemPerPage={dataContractGridPages.current.limit}
                skipPerPage={dataContractGridPages.current.skip}
                height={320}
                keyExpr={"ContractNo"}
                showBorders={false}
                pagerFullScreen={false}
                showColumnLines={false}
                typePaging={"remote"}
                listPerPage={[30, 60, 90, 120]}
                selection={{ mode: "single" }}
                style={{ border: 'none' }}
                filterRow={{
                    visible: true,
                    showOperationChooser: false,
                }}
                loading={grid01Loading}
                totalItems={_.get(dataContractGridOriginal, "total", 0)}
                dataSource={dataRow}
                onContentReady={(e) => {
                    const grid = e.component;
                    let defaultRow = 0;
                    if (_.isEmpty(paramEvent)) {
                        if (_.get(currentClickActionRow01.current, "ContractNo", "") === _.get(dataRow[0], "ContractNo", "")) {
                            grid.selectRowsByIndexes(0); // Default focus dòng đầu tiên
                        }
                    } else {
                        if (!_.isEmpty(dataRow) && setFocusEventGrid.current === false) {
                            defaultRow = dataRow.findIndex(item => item.ContractNo === _.get(paramEvent, "ContractNo", ""));
                            setFocusEventGrid.current = true; // 1 lần duy nhất
                            grid.selectRowsByIndexes(defaultRow > -1 ? defaultRow : 0);
                        }
                    }
                }}
                onCellClick={(e) => {
                    if (!e || _.isUndefined(e?.column?.dataField)) return;
                    const data = _.get(e, "data", {});
                    if (data) {
                        currentClickActionRow01.current = data;
                        setTimeout(() => {
                            loadEventGrid(data);
                        }, 300);
                    }
                }}
                onRowDblClick={(e) => {
                    if (e.data) openModalPage("view", 1, "WA3F2001", e.data);
                }}
                onChangePage={onChangeContractPage}
                onChangePerPage={onChangeContractPerPage}
            >
                <Column
                    width={40}
                    fixed={true}
                    alignment={"right"}
                    fixedPosition={"right"}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile}
                    cellRender={(e) => renderActionGrid(e, 1)}
                />
                <Column
                    allowFiltering={false}
                    caption={Config.lang("STT")}
                    alignment={"center"}
                    dataField={"OrderNo"}
                    width={60}
                />
                <Column
                    caption={Config.lang("So_hop_dong")}
                    dataField={"ContractNo"}
                    width={220}
                />
                <Column
                    caption={Config.lang("Ma_khach_hang")}
                    dataField={"ObjectID"}
                    width={170}
                />
                <Column
                    caption={Config.lang("Ten_khach_hang")}
                    dataField={"ObjectName"}
                    width={290}
                />
                <Column
                    caption={Config.lang("Nguoi_lap")}
                    dataField={"EmployeeID"}
                    width={210}
                />
                <Column
                    width={120}
                    caption={Config.lang("Ngay_hop_dong")}
                    dataField={"ConstractDate"}
                    alignment={"center"}
                    dataType={"date"}
                    format={"dd/MM/yyyy"}
                />
                <Column
                    width={120}
                    caption={Config.lang("Da_ky_ngay")}
                    dataField={"SignDate"}
                    alignment={"center"}
                    dataType={"date"}
                    format={"dd/MM/yyyy"}
                />
                <Column
                    width={130}
                    caption={Config.lang("Trang_thai_HD")}
                    dataField={"EStatusName"}
                />
                <Column
                    width={40}
                    fixed={true}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile}
                    cellRender={(e) => renderActionGrid(e, 1)}
                />
            </GridContainer>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid01Loading, dataContractGrid, dataContractGridPages.current]);

    const renderGrid02 = useMemo(() => {
        return (
            <GridContainer
                itemPerPage={dataEventGridPages.current.limit}
                skipPerPage={dataEventGridPages.current.skip}
                height={320}
                showRowLines={true}
                showBorders={false}
                pagerFullScreen={false}
                showColumnLines={false}
                typePaging={"remote"}
                keyExpr={"EventID"}
                style={{ border: 'none' }}
                loading={grid02Loading}
                onRowDblClick={(e) => {
                    if (e.data) redirectPage("view", "WA3F2010", e.data);
                }}
                filterRow={{
                    visible: true,
                    showOperationChooser: false,
                }}
                totalItems={_.get(dataEventGrid, "total", 0)}
                dataSource={dataEventRow}
                editing={{ texts: { confirmDeleteMessage: "" } }}
                onChangePage={onChangeEventPage}
                onChangePerPage={onChangeEventPerPage}
            >
                <Column
                    width={40}
                    fixed={true}
                    alignment={"right"}
                    fixedPosition={"right"}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile}
                    cellRender={(e) => renderActionGrid(e, 2)}
                />
                <Column
                    allowFiltering={false}
                    caption={Config.lang("STT")}
                    alignment={"center"}
                    dataField={"OrderNo"}
                    width={60}
                />
                <Column
                    caption={Config.lang("Ma_su_kien")}
                    dataField={"EventID"}
                    width={220}
                />
                <Column
                    caption={Config.lang("Ten_su_kien")}
                    dataField={"EventName"}
                    width={250}
                />
                <Column
                    width={120}
                    caption={Config.lang("Ngay_to_chuc")}
                    dataField={"EventDate"}
                    alignment={"center"}
                    dataType={"date"}
                    format={"dd/MM/yyyy"}
                />
                <Column
                    caption={Config.lang("Sanh")}
                    dataField={"BanquetCode"}
                    width={110}
                />
                <Column
                    width={150}
                    caption={Config.lang("So_ban_chinh_thuc")}
                    dataField={"OQTYTable"}
                    alignment={"right"}
                    dataType={"number"}
                />
                <Column
                    width={90}
                    caption={Config.lang("Phien_ban")}
                    dataField={"Version"}
                    alignment={"right"}
                    dataType={"number"}
                />
                <Column
                    width={120}
                    caption={Config.lang("Dat_coc")}
                    dataField={"IsDepositEvent"}
                    alignment={"center"}
                    dataType={"boolean"}
                    trueText={Config.lang("Co")}
                    falseText={Config.lang("Khong")}
                    calculateCellValue={rowData => !!rowData.IsDepositEvent}
                />
                <Column
                    width={220}
                    caption={Config.lang("Ma_phieu_BEO")}
                    dataField={"BEOCode"}
                />
                <Column
                    width={180}
                    caption={Config.lang("Trang_thai_beo")}
                    dataField={"StatusBEO"}
                />
                <Column
                    width={70}
                    caption={Config.lang("Huy")}
                    dataField={"IsCancel"}
                    alignment={"center"}
                    dataType={"boolean"}
                    trueText={Config.lang("Co")}
                    falseText={Config.lang("Khong")}
                    calculateCellValue={rowData => !!rowData.IsCancel}
                />
                <Column
                    width={40}
                    fixed={true}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile}
                    cellRender={(e) => renderActionGrid(e, 2)}
                />
            </GridContainer>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid02Loading, dataEventRow, dataEventGridPages.current]);

    if (!permission) return null;
    return (
        <React.Fragment>
            {openModalWA3F2001 &&
                <WA3F2001
                    openModal={openModalWA3F2001}
                    dataInfo={dataInfo.current}
                    onOpenModalAction={(status, reloadPage) => {
                        onOpenModalAction(status, reloadPage)
                    }}
                />
            }
            <ActionToolbar title={Config.lang("Hop_dong_dich_vu")}>
                <Button
                    disabled={!(permission >= 2)}
                    size={"medium"}
                    color={"primary"}
                    viewType={"filled"}
                    label={Config.lang("Them")}
                    style={{ textTransform: 'uppercase' }}
                    startIcon={"AddCircle"}
                    onClick={() => openModalPage("add", 1, "WA3F2001")}
                />
            </ActionToolbar>
            {renderTab}
            <Modal
                alignment={"center"}
                id={"PopupUpdateStatus-WA3F2000"}
                zIndex={1020}
                width={"696"}
                open={openUpdateStatusModal}
                onClose={() => onOpenModalState("openUpdateStatusModal", false)}>
                <LoadPanel
                    position={{ my: 'center', of: '#PopupUpdateStatus-WA3F2000' }}
                    visible={modalUpdateStatusLoading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                <ModalHeader>{Config.lang("Cap_nhat_trang_thai")}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                disabled={true}
                                label={Config.lang("So_hop_Dong")}
                                onChange={(e) => onChangeUpdateStatus("ContractNo", e)}
                                value={_.get(updateStatusState, "ContractNo", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Dropdown
                                clearAble
                                required={true}
                                valueExpr={"EStatus"}
                                keyExpr={"EStatusName"}
                                displayExpr={"EStatusName"}
                                placeholder={Config.lang("Chon")}
                                label={Config.lang("Trang_thai")}
                                dataSource={dataCboEStatus}
                                loading={cboEStatusLoading}
                                error={errorUStatus && errorUStatus["EStatus"]}
                                onChange={e => onChangeUpdateStatus("EStatus", e)}
                                value={_.get(updateStatusState, "EStatus", "")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button
                                disabled={btnSaveUpdataStatus || modalUpdateStatusLoading}
                                size={"medium"}
                                color={"info"}
                                startIcon={"Save"}
                                viewType={"filled"}
                                label={Config.lang("Luu")}
                                onClick={onSaveUpdateStatus}
                            />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <div className={"hidden"}>{renderFilterData()}</div>
            {popupActionBar01()}
            {renderGrid01}
            <div className={"wrap align-center"} style={{ padding: spacing([3, 0]) }}>
                <span className={classes.gridTitle}>{Config.lang("Tiec")} - {Config.lang("Su_kien")}</span>
                {_.get(currentClickActionRow01.current, "ContractNo", "") &&
                    <span style={{ color: _.get(colors, "info", ""), textDecoration: 'underline' }}
                        className={classes.gridTitle}>{currentClickActionRow01.current.ContractNo}</span>}
                <Button
                    disabled={!(permission >= 2) || (tabIndex !== 0 && tabIndex !== 1)}
                    size={"medium"}
                    color={"primary"}
                    viewType={"filled"}
                    label={Config.lang("Them")}
                    style={{ textTransform: 'uppercase' }}
                    startIcon={"AddCircle"}
                    onClick={() => redirectPage("add", "WA3F2010")}
                />
            </div>
            {popupActionBar02()}
            {renderGrid02}
        </React.Fragment>
    );
};

export default compose(
    connect((state) => ({
        dataContractGrid: state.WA3F2000.dataContractGrid,
        dataCboEStatus: state.general.dataCboEStatus,
    }), (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        wA3F2000Actions: bindActionCreators(WA3F2000Actions, dispatch),
    })))(WA3F2000);
