import { Column } from "devextreme-react/data-grid";
import { Modal, ModalBody, ModalHeader } from 'diginet-core-ui/components';
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import GridContainer from "../../../common/grid-container/grid-container";

const BookingModal = (props) => {
    const dispatch = useDispatch();
    const dataCboContractNo = useSelector(state => state?.general?.dataCboContractNo ?? {});
    const { openModal, onOpenModalAction, FormID, dataInfo, currentSelect } = props;
    const [dataGridLoading, setDataGridLoading] = useState(false);

    const dataGridPages = useRef({
        skip: 0,
        limit: 10,
    });
    useEffect(() => {
        loadDataBookingNo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadDataBookingNo = () => {
        const { skip, limit } = dataGridPages.current;
        const { ObjectID = "", BookingID = "" } = dataInfo;
        const param = {
            FormID,
            ObjectID, //"000109BT"
            BookingID,
            skip,
            limit,
        };
        setDataGridLoading(true);
        dispatch(generalActions.getDataBookingNo(param, (error) => {
            setDataGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const onChangeContractPage = (page) => {
        dataGridPages.current.skip = page * dataGridPages.current.limit;
        loadDataBookingNo();
    };

    const onChangeContractPerPage = (perPage) => {
        dataGridPages.current.skip = 0;
        dataGridPages.current.limit = perPage;
        loadDataBookingNo();
    };

    const formatValidTime = (date) => {
        let result = date;
        if (!_.isNull(date) && Config.isValidDateTime(date)) result = moment(date).format("YYYY-MM-DD");
        return result;
    };

    return (
        <Modal
            width={"960"}
            open={openModal}
            onClose={() => { if (onOpenModalAction) onOpenModalAction(false) }}>
            <ModalHeader>{Config.lang("Chon_ma_booking")}</ModalHeader>
            <ModalBody>
                <GridContainer
                    itemPerPage={dataGridPages.current.limit}
                    skipPerPage={dataGridPages.current.skip}
                    keyExpr={"BookingNo"}
                    height={420}
                    pagerFullScreen={false}
                    typePaging={"normal"}
                    selection={{ mode: "single" }}
                    style={{ border: 'none' }}
                    loading={dataGridLoading}
                    filterRow={{
                        visible: true,
                        showOperationChooser: false,
                    }}
                    totalItems={_.get(dataCboContractNo, "total", 0)}
                    dataSource={_.get(dataCboContractNo, "rows", [])}
                    onContentReady={(e) => {
                        if (currentSelect && !_.isEmpty(dataCboContractNo?.rows)) {
                            const grid = e.component;
                            const index = dataCboContractNo.rows.findIndex(item => item.BookingNo === currentSelect);
                            grid.selectRowsByIndexes([_.toNumber(index)]); // Default focus dòng đầu tiên
                        }
                    }}
                    onRowDblClick={(e) => {
                        if (onOpenModalAction) {
                            let data = _.get(e, "data", {});
                            data = {
                                ..._.get(e, "data", {}),
                                EventDate: formatValidTime(data?.EventDate)
                            };
                            onOpenModalAction(false, data) //Chọn và đóng modal
                        }
                    }}
                    onChangePage={onChangeContractPage}
                    onChangePerPage={onChangeContractPerPage}
                >
                    <Column
                        width={220}
                        dataField={"BookingNo"}
                        caption={Config.lang("Ma_booking")}
                    />
                    <Column
                        width={120}
                        caption={Config.lang("Ngay_su_kien")}
                        dataField={"EventDate"}
                        alignment={"right"}
                        dataType={"date"}
                        format={"dd/MM/yyyy"}
                    />
                    <Column
                        width={80}
                        alignment={"center"}
                        dataField={"HourFrom"}
                        caption={Config.lang("Tu_gio")}
                    />
                    <Column
                        width={80}
                        alignment={"center"}
                        dataField={"HourTo"}
                        caption={Config.lang("Den_gio")}
                    />
                    <Column
                        minWidth={160}
                        caption={Config.lang("Hinh_thuc_tiec")}
                        dataField={"EventTypeName"}
                    />
                    <Column
                        minWidth={160}
                        caption={Config.lang("Loai_ban_tiec")}
                        dataField={"SetupName"}
                    />
                    <Column
                        minWidth={160}
                        caption={Config.lang("Ten_su_kien")}
                        dataField={"EventName"}
                    />
                </GridContainer>
            </ModalBody>
        </Modal>
    );
};

BookingModal.propTypes = {
    openModal: PropTypes.bool,
    FormID: PropTypes.string,
    currentSelect: PropTypes.string,
    dataInfo: PropTypes.object,
    onOpenModalAction: PropTypes.func,
};
export default BookingModal;

