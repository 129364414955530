/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 24/11/2021
 * @Example
 */
import { Column, Lookup } from "devextreme-react/data-grid";
import { Button, ButtonIcon, Checkbox, DatePicker, Dropdown, Label, Modal, ModalBody, ModalHeader, MoneyInput, Radio, Status, TabContainer, TabHeader, TabItem, TextInput, Col, Row } from 'diginet-core-ui/components';
import { makeStyles, useTheme } from "diginet-core-ui/theme";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2020Actions from "../../../../redux/modules/WA3/WA3F2020/WA3F2020_actions";
import GridActionBar from "../../../common/grid-container/grid-actionbar";
import GridContainer from "../../../common/grid-container/grid-container";
import ActionToolbar from "../../../common/layouts/toolbar/action-toolbar";
import History from "../../../common/libs/history/history";
import Filter from "../../../filter/filter";
import WA3F2021 from "../WA3F2021/WA3F2021";
import WA3F2022 from "../WA3F2022/WA3F2022";

const useStyles = makeStyles((theme) => ({
    btnActionItem: {
        textTransform: 'initial',
        display: 'block',
        width: '100%',
        textAlign: 'left',
        '&:hover': {
            backgroundColor: `${_.get(theme, "colors.hover", "")} !important`
        }
    },
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    moneyInput: {
        margin: 0
    }
}));

const FormID = "WA3F2020";
const initDataInfo = {
    Permission: 0,
    screen: FormID,
    Mode: "view",
    ObjectID: "",
    VoucherNo: "",
    VoucherID: "",
}

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
const initialDataGrid = {
    total: 0,
    rows: [],
};

const WA3F2020 = () => {
    const DivisionID = Config.getLocalStorage("DIVISIONBEM") || _.get(Config.profile, "DivisionID", "");
    const dispatch = useDispatch();
    const classes = useStyles();
    const { spacing } = useTheme();
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [openModalWA3F2021, setOpenModalWA3F2021] = useState(false);
    const [openModalWA3F2022, setOpenModalWA3F2022] = useState(false);
    const [showChoseDate, setShowChoseDate] = useState(false);
    const [loading, _setLoading] = useState({
        gridLoading: false,
        cboObjectLoading: false,
        cboCreateUserLoading: false,
    });
    const [addMode, setAddMode] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const { gridLoading, cboObjectLoading, cboCreateUserLoading } = loading;
    const refLoading = useRef({});
    const [permission, setPermission] = useState(null);
    const [dataTabHeaderTitle, setDataTabHeaderTitle] = useState([]);
    const [dataGrid, setDataGrid] = useState(initialDataGrid);

    //==========REF=============
    const dataDayByCombo = useMemo(() => {
        return [
            {
                id: 0,
                fieldName: Config.lang("Hom_nay"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
            {
                id: 1,
                fieldName: Config.lang("Hom_qua"),
                dateFrom: moment().subtract(1, "day").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "day").format("YYYY-MM-DD")
            },
            {
                id: 2,
                fieldName: Config.lang("Tuan_nay"),
                dateFrom: moment().startOf("week").format("YYYY-MM-DD"),
                dateTo: moment().endOf("week").format("YYYY-MM-DD")
            },
            {
                id: 3,
                fieldName: Config.lang("Tuan_truoc"),
                dateFrom: moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD")
            },
            {
                id: 4,
                fieldName: Config.lang("Thang_nay"),
                dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 5,
                fieldName: Config.lang("Thang_truoc"),
                dateFrom: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD")
            },
            {
                id: 6,
                fieldName: Config.lang("Nam_nay"),
                dateFrom: moment().startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 7,
                fieldName: Config.lang("Nam_truoc"),
                dateFrom: moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD"),
                dateTo: moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD")
            },
            {
                id: 8,
                fieldName: Config.lang("Chon_thoi_gian"),
                dateFrom: moment().format("YYYY-MM-DD"),
                dateTo: moment().format("YYYY-MM-DD")
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [dataCboCustomer, setDataCboCustomer] = useState({
        rows: [],
        total: 0
    });
    const [dataCboCreateUser, setDataCboCreateUser] = useState({
        rows: [],
        total: 0
    });
    const defaultDate = 4; // default Tháng này
    const [filterSearch, setFilterSearch] = useState({
        IsStatusID: 0, // default unCheck
        VoucherNo: "",
        VoucherID: "",
        ObjectID: "",
        EmployeeID: "",
        TransTypeID: "",
        selectedDate: defaultDate,
        DateFrom: _.get(dataDayByCombo[defaultDate], "dateFrom", null),
        DateTo: _.get(dataDayByCombo[defaultDate], "dateTo", null),
    });

    const transTypeIDOld = usePrevious(filterSearch.TransTypeID) || "";
    const inititalPages = {
        strSearch: "",
        skip: 0,
        limit: 10,
    };
    const dataGridPages = useRef(inititalPages);
    const filterCboCustomer = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    const filterCboCreateUsers = useRef({
        ObjectID: "",
        BookingID: "",
        ObjectTypeID: "",
        skip: 0,
        limit: 20,
        search: ""
    });
    const dataInfo = useRef(initDataInfo);

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (permission) {
            loadTab(); // Load Tab
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission]);

    useEffect(() => {
        if (transTypeIDOld !== filterSearch.TransTypeID || dataTabHeaderTitle) { // Load Tab xong load Grid
            if (_.isEmpty(dataTabHeaderTitle)) {
                setDataGrid(initialDataGrid);
            } else {
                loadGrid();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSearch.TransTypeID, dataTabHeaderTitle]);

    const loadPermission = () => {
        dispatch(generalActions.getPermission(FormID, (isPer) => {
            if (dataInfo.current) dataInfo.current.Permission = isPer;
            setPermission(isPer);
        }));
    };

    const setLoading = (obj) => {
        refLoading.current = { ...refLoading.current, ...obj };
        _setLoading(refLoading.current);
    };

    const loadTab = () => {
        const { skip, limit, strSearch } = dataGridPages.current;
        const params = {
            DataType: "LoadTab",
            FormID,
            ..._.omit(filterSearch, ["TransTypeID", "selectedDate"]),
            skip,
            limit,
            strSearch,
        };
        setLoading({ gridLoading: true });
        dispatch(WA3F2020Actions.loadTab(params, (error, data) => {
            setLoading({ gridLoading: false });
            if (error) {
                setDataTabHeaderTitle([]);
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (data) setFilterSearch({ ...filterSearch, TransTypeID: data[0].TransTypeID });
                if (tabIndex !== 0) setTabIndex(0);
                setDataTabHeaderTitle(data);
            }
        }));
    };

    const loadGrid = () => {
        const { skip, limit, strSearch } = dataGridPages.current;
        const params = {
            DataType: "LoadMaster",
            FormID,
            ..._.omit(filterSearch, ["selectedDate"]),
            skip,
            limit,
            strSearch,
        };
        setLoading({ gridLoading: true });
        if (!_.isEmpty(dataGrid?.rows)) setDataGrid(initialDataGrid); // fix bug cột thay đổi
        dispatch(WA3F2020Actions.loadGrid(params, (error, data) => {
            setLoading({ gridLoading: false });
            if (error) {
                setDataGrid(initialDataGrid);
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const total = _.get(data, "total", []);
                const rows = _.get(data, "rows", []);
                setDataGrid({
                    total,
                    rows
                });
            }
        }));
    };

    const loadCboCustomer = (isReset) => {  // CBO KHÁCH HÀNG
        const { skip, limit, search } = filterCboCustomer.current;
        const { ObjectID, BookingID } = filterSearch;
        const params = {
            FormID,
            ObjectID,
            BookingID,
            skip,
            limit,
            search
        };
        setLoading({ cboObjectLoading: true });
        dispatch(generalActions.getCboCustomer(params, (error, data) => {
            setLoading({ cboObjectLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = !_.isEmpty(item.ObjectName) ? "-" : "";
                        return ({ ...item, ObjectCustomName: `${item.ObjectID} ${line} ${item.ObjectName}` })
                    });
                }
                setDataCboCustomer({
                    rows: isReset ? rows : dataCboCustomer.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const loadCboCreateUser = (isReset) => { // CBO người lập
        const { ObjectID, ObjectTypeID, BookingID, skip, limit, search } = filterCboCreateUsers.current;
        const params = {
            FormID,
            ObjectID,
            BookingID,
            ObjectTypeID,
            skip,
            limit,
            search
        };
        setLoading({ cboCreateUserLoading: true });
        dispatch(generalActions.getCboCreateUser(params, (error, data) => {
            setLoading({ cboCreateUserLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = !_.isEmpty(item.EmployeeName) ? "-" : "";
                        return ({ ...item, EmployeeCustomName: `${item.EmployeeID} ${line} ${item.EmployeeName}` })
                    });
                }
                setDataCboCreateUser({
                    rows: isReset ? rows : _.get(dataCboCreateUser, "rows", []).concat(rows),
                    total
                });
            }
        }));
    };

    const openModalPage = (Mode, currentRow = {}) => {  // Modal nằm Màn hình khác
        if (!Mode || !currentRow) return;
        const { TransTypeID = "", VoucherNo = "", VoucherID = "", ObjectID = "", EmployeeID = "", DateFrom = null, DateTo = null } = currentRow;
        let transTypeID = TransTypeID;
        let objInfo = {};
        if (Mode !== "add") {
            objInfo = {
                VoucherNo,
                VoucherID,
                ObjectID,
                EmployeeID,
                DateFrom,
                DateTo,
            };
        } else {
            transTypeID = addMode === 0 ? "Booking" : "Event";
        }
        dataInfo.current = {
            ...dataInfo.current,
            ...objInfo,
            Mode
        };
        switch (transTypeID) {
            case "Booking": // WA3F2021
                setOpenModalWA3F2021(true);
                break;
            case "Event": // WA3F2022
                setOpenModalWA3F2022(true);
                break;
            default:
                break;
        }
    };

    const checkAndReturnLine = (id, name) => {
        if (_.isNull(id)) id = "";
        if (_.isNull(name)) name = "";
        const line = Config.isEmpty(id, true) || Config.isEmpty(name, true) ? "" : "-";
        return `${id} ${line} ${name}`.trim();
    };

    const onSaveHistory = async (action = 1, data = {}, dataCompare = {}) => {
        if (_.isEmpty(data) || Config.isEmpty(action, true)) return;
        const TransactionName = Config.lang("Danh_sach_bien_nhan_coc");
        const TransID = _.get(data, "VoucherID", "");
        let captions = {
            VoucherNo: "So_phieu",
            StatusName: "Trang_thai",
            ObjectID: "Khach_hang",
        };
        if (action === 3) {
            captions.BookingNo = "Ma_booking";
            captions.EventDate = "Ngay_su_kien";
            captions.EventName = "Ten_su_kien";
        }
        const options = {
            data,
            dataCompare, // Old Data
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID,
            TransactionName,
            itemRender: (e, type) => {
                const { data, key } = e;
                if (type === "Content") {
                    if (key === "ObjectID") {
                        return checkAndReturnLine(_.get(data, "ObjectID", ""), _.get(data, "ObjectName", ""));
                    }
                }
            }
        };
        const history = new History(options);
        // console.log("===========HIS=========>", history.get());
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const onOpenFilter = () => {
        loadCboCustomer();
        loadCboCreateUser();
    };

    const onSearch = (e) => {
        if (dataGridPages.current) {
            dataGridPages.current.strSearch = _.get(e, "target.value", "");
            loadTab();
        }
    };

    const onChangeBEOPage = (page) => {
        dataGridPages.current.skip = page * dataGridPages.current.limit;
        loadGrid();
    };

    const onChangeBEOPerPage = (perPage) => {
        dataGridPages.current.skip = 0;
        dataGridPages.current.limit = perPage;
        loadGrid();
    };

    const renderStatusColumn = (e = {}) => {
        const data = _.get(e, "row.data", {});
        if (!e || _.isEmpty(data)) return;
        let color = "";
        let icon = "";
        switch (Number(data["StatusID"])) {
            case 1:
                color = "success";
                icon = "Approval";
                break;
            case 100:
                color = "warning";
                icon = "Delete";
                break;
            default:
                break;
        }
        return <Status icon={icon} color={color} size={"large"} text={data?.StatusName} />
    };

    const renderMaxWidthColum = (e, maxWidth = 280) => {
        const value = _.get(e, "value", "");
        return <div title={value} className={classes.ellipsis}
            {...(_.isNumber(maxWidth) ? { style: { maxWidth } } : {})} >{value}</div>
    };

    const onOpenModalAction = (screen = "", status, reloadPage = false) => {
        if (_.isBoolean(status)) {
            if (screen === "WA3F2021") setOpenModalWA3F2021(status);
            if (screen === "WA3F2022") setOpenModalWA3F2022(status);
            if (reloadPage) loadTab();
        }
    };

    const onAdd = () => {
        dataInfo.current = {
            ...initDataInfo,
            Permission: permission
        };
        setOpenModalAdd(true);
    };

    const renderActionGrid = (e) => {
        const currentRow = _.get(e, "data", {});
        const { StatusID = null, IsCollected = null } = currentRow;
        return (
            <GridActionBar>
                <ButtonIcon
                    circular
                    disabled={!(permission >= 2) || !(IsCollected === 0 && StatusID !== 100)}
                    name={"CancelFilled"}
                    viewType={"text"}
                    onClick={() => onCancel(currentRow)} />
            </GridActionBar>
        );
    };

    const onCancel = async (currentRow = {}) => {
        if (!currentRow) return;
        Config.popup.show('YES_NO', Config.lang("Ban_co_muon_huy_phieu_nay_khong"),
            () => {
                const { VoucherID = "", VoucherNo = "" } = currentRow;
                const params = {
                    DivisionID,
                    FormID,
                    VoucherNo,
                    VoucherID
                };
                setLoading({ gridLoading: true });
                dispatch(WA3F2020Actions.onCancel(params, async (error, data) => {
                    setLoading({ gridLoading: false });
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    } else if (data) {
                        if (_.get(data, "Status", null) === 0) {

                            const dataNewRow = { ...currentRow, StatusName: _.get(data, "StatusName", "Hủy") };
                            await onSaveHistory(1, dataNewRow, currentRow);
                            const Message = data.Message || Config.lang("Cap_nhat_thanh_cong");
                            Config.notify.show("success", Message, 2000);
                            loadTab(); // Load lại lưới
                        } else {
                            Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                            return false;
                        }
                    }
                }));
            }
        );

    };

    const filterChange = (filterName = "", e) => {
        if (!e || _.isEmpty(filterName)) return;
        const value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        switch (filterName) {
            // CheckBox
            case "IsStatusID":
                setFilterSearch({ ...filterSearch, [filterName]: value === true ? 1 : 0 });
                break;
            // Input Text không cần set stage ngay
            case "VoucherNo":
                filterSearch[filterName] = value;
                break;
            // Chọn ngày
            case "ChoseDate":
                setFilterSearch({
                    ...filterSearch,
                    selectedDate: value,
                    DateFrom: _.get(dataDayByCombo[value], "dateFrom", null),
                    DateTo: _.get(dataDayByCombo[value], "dateTo", null),
                });
                if (value === 8) {
                    if (showChoseDate === false) setShowChoseDate(true);
                } else {
                    if (showChoseDate === true) setShowChoseDate(false);
                }
                break;
            default:
                //DropDown ~ DateTime
                setFilterSearch({ ...filterSearch, [filterName]: value });
                break;
        }
    };

    const renderCheckColumn = (e) => {
        if (!e) return;
        return (
            <span style={{ pointerEvents: 'none' }}>
                <Checkbox style={{ margin: 'auto' }} checked={e?.value === 1} />
            </span>
        );
    };

    const renderColumnMoney = (e) => {
        const value = _.get(e, "value", "");
        return <MoneyInput
            nonStyle
            readOnly={true}
            thousandSeparator={","}
            className={classes.moneyInput}
            value={value}
        />
    };

    const btnSearchAction = () => {
        dataGridPages.current.skip = inititalPages.skip;
        dataGridPages.current.limit = inititalPages.limit;
        loadTab();
    };

    const renderFilterData = () => {
        return (
            <Filter
                isUseDDCore={true}
                placeholder={Config.lang("Noi_dung_can_tim")}
                onTextChanged={onSearch}
                onOpenLoaded={onOpenFilter}
                renderFilter={() => {
                    return (
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    viewType={"outlined"}
                                    valueExpr={"id"}
                                    keyExpr={"fieldName"}
                                    displayExpr={"fieldName"}
                                    placeholder={Config.lang("Chon")}
                                    label={Config.lang("Thoi_gian")}
                                    onChange={e => filterChange("ChoseDate", e)}
                                    dataSource={dataDayByCombo}
                                    value={filterSearch.selectedDate}
                                />
                            </Col>
                            {showChoseDate &&
                                <Col xs={12} sm={6} md={6} lg={6} >
                                    <div className={"display_row align-center "}>
                                        <Label style={{ minWidth: 22 }} className={"mgr5"}>{Config.lang("Tu")}</Label>
                                        <DatePicker
                                            viewType={"outlined"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            max={filterSearch.DateTo}
                                            value={filterSearch.DateFrom}
                                            onChange={e => filterChange("DateFrom", e)}
                                        />
                                    </div>
                                </Col>
                            }
                            {showChoseDate && <Col xs={12} sm={6} md={6} lg={6}>
                                <div className={"display_row align-center "}>
                                    <Label style={{ minWidth: 22 }} className={"mgr5"}>{Config.lang("Den")}</Label>
                                    <DatePicker
                                        viewType={"outlined"}
                                        placeholder={"dd/mm/yyyy"}
                                        displayFormat={"DD/MM/YYYY"}
                                        min={filterSearch.DateFrom}
                                        value={filterSearch.DateTo}
                                        onChange={e => filterChange("DateTo", e)}
                                    />
                                </div>
                            </Col>}
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    viewType={"outlined"}
                                    label={Config.lang("So_phieu")}
                                    placeholder={Config.lang("Nhap")}
                                    onChange={(e) => filterChange("VoucherNo", e)}
                                    value={filterSearch.VoucherNo}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    clearAble
                                    allowSearch
                                    dataSource={_.get(dataCboCustomer, "rows", [])}
                                    total={_.get(dataCboCustomer, "total", 0)}
                                    skip={filterCboCustomer.current.skip}
                                    limit={filterCboCustomer.current.limit}
                                    value={filterSearch.ObjectID}
                                    loading={cboObjectLoading}
                                    viewType={"outlined"}
                                    valueExpr={"ObjectID"}
                                    keyExpr={"ObjectName"}
                                    label={Config.lang("Khach_hang")}
                                    placeholder={Config.lang("Chon")}
                                    displayExpr={"ObjectCustomName"}
                                    onChange={(e) => filterChange("ObjectID", e)}
                                    onLoadMore={(e) => {
                                        filterCboCustomer.current.skip = e.skip;
                                        filterCboCustomer.current.limit = e.limit;
                                        loadCboCustomer();
                                    }}
                                    searchDelayTime={700}
                                    onInput={(e) => {
                                        filterCboCustomer.current.search = e?.target?.value || "";
                                        filterCboCustomer.current.skip = 0;
                                        loadCboCustomer(true);
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    clearAble
                                    allowSearch
                                    dataSource={_.get(dataCboCreateUser, "rows", [])}
                                    total={_.get(dataCboCreateUser, "total", 0)}
                                    skip={filterCboCreateUsers.current.skip}
                                    limit={filterCboCreateUsers.current.limit}
                                    value={filterSearch.EmployeeID}
                                    loading={cboCreateUserLoading}
                                    viewType={"outlined"}
                                    valueExpr={"EmployeeID"}
                                    keyExpr={"EmployeeName"}
                                    label={Config.lang("Nguoi_lap")}
                                    placeholder={Config.lang("Chon")}
                                    displayExpr={"EmployeeCustomName"}
                                    onChange={(e) => filterChange("EmployeeID", e)}
                                    onLoadMore={(e) => {
                                        filterCboCreateUsers.current.skip = e.skip;
                                        filterCboCreateUsers.current.limit = e.limit;
                                        loadCboCreateUser();
                                    }}
                                    searchDelayTime={700}
                                    onInput={(e) => {
                                        filterCboCreateUsers.current.search = e?.target?.value || "";
                                        filterCboCreateUsers.current.skip = 0;
                                        loadCboCreateUser(true);
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Checkbox
                                    label={Config.lang("Hien_thi_phieu_da_huy")}
                                    onChange={(e) => filterChange("IsStatusID", e)}
                                    checked={filterSearch.IsStatusID === 1} />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", }}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={btnSearchAction}
                                />
                            </Col>
                        </Row>
                    );
                }}
            />
        );
    };

    const onResetModalAdd = (onClose = false) => {
        if (onClose) setOpenModalAdd(false);
        setAddMode(null);
    };

    const renderModalAdd = useMemo(() => {
        return (
            <Modal
                zIndex={1020}
                width={"696"}
                open={openModalAdd}
                alignment={"top"}
                onClose={() => onResetModalAdd(true)}>
                <ModalHeader>{Config.lang("Chon_nghiep_vu")}</ModalHeader>
                <ModalBody>
                    <div style={{ padding: spacing([6, 0, 4, 0]) }}>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <Radio
                                    value={"0"}
                                    name={"AddModalMode"}
                                    checked={addMode === 0}
                                    label={Config.lang("Lap_phieu_bien_nhan_coc_cho")}
                                    onChange={() => setAddMode(0)}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <Radio
                                    value={"1"}
                                    name={"AddModalMode"}
                                    checked={addMode === 1}
                                    label={Config.lang("Lap_phieu_bien_nhan_coc_su_kien")}
                                    onChange={() => setAddMode(1)}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className={"jus-end"} style={{ padding: spacing([4, 2]) }}>
                        <Button
                            size={"medium"}
                            color={"primary"}
                            viewType={"outlined"}
                            label={Config.lang("Tiep_tuc")}
                            onClick={() => {
                                openModalPage("add");
                                onResetModalAdd(true);
                            }}
                        />
                    </div>
                </ModalBody>
            </Modal>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModalAdd, addMode]);

    const handleChangeTab = (index, TransTypeID = "") => {
        if (!_.isNumber(index)) return;
        if (index !== tabIndex) {
            dataGridPages.current = inititalPages;
            setTabIndex(index);
            setFilterSearch({ ...filterSearch, TransTypeID });
        }
    };

    const renderTab = useMemo(() => {
        if (!(dataTabHeaderTitle?.length > 0)) return;
        return (
            <TabContainer tabIndex={tabIndex}>
                <TabHeader>
                    {dataTabHeaderTitle.map((item, idx) => {
                        const { TransTypeName = "", Total = null, TransTypeID = "" } = item;
                        const currenIndex = idx;
                        return (
                            <TabItem
                                key={idx}
                                index={currenIndex}
                                label={`${TransTypeName} ${!Config.isEmpty(Total, true) ? `(${Total})` : ""}`}
                                onClick={() => handleChangeTab(currenIndex, TransTypeID)}
                            />
                        )
                    })}
                </TabHeader>
            </TabContainer>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTabHeaderTitle, tabIndex]);

    const renderGrid = useMemo(() => {
        return (
            <GridContainer
                keyExpr={"VoucherNo"}
                style={{ border: 'none' }}
                typePaging={"remote"}
                filterRow={{
                    visible: true,
                    showOperationChooser: false,
                }}
                loading={gridLoading}
                height={Config.getHeightGrid()}
                totalItems={_.get(dataGrid, "total", 0)}
                dataSource={_.get(dataGrid, "rows", [])}
                itemPerPage={dataGridPages.current.limit}
                skipPerPage={dataGridPages.current.skip}
                onRowDblClick={(e) => {
                    if (e?.data) openModalPage("view", e?.data);
                }}
                onChangePage={onChangeBEOPage}
                onChangePerPage={onChangeBEOPerPage}
            >
                <Column
                    width={40}
                    fixed={true}
                    alignment={"center"}
                    fixedPosition={"right"}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile}
                    cellRender={renderActionGrid}
                />
                <Column
                    width={70}
                    allowFiltering={false}
                    caption={Config.lang("STT")}
                    alignment={"center"}
                    dataField={"OrderNum"}
                />
                <Column
                    width={140}
                    dataField={"TransTypeName"}
                    caption={Config.lang("Nghiep_vu")}
                />
                <Column
                    dataField={"VoucherNo"}
                    caption={Config.lang("So_phieu")}
                    cellRender={(e) => renderMaxWidthColum(e, 280)}
                />
                <Column
                    width={140}
                    dataType={"date"}
                    alignment={"center"}
                    format={"dd/MM/yyyy"}
                    dataField={"VoucherDate"}
                    caption={Config.lang("Ngay_phieu")}
                />
                <Column
                    minWidth={100}
                    dataField={"Description"}
                    caption={Config.lang("Dien_giai")}
                    cellRender={(e) => renderMaxWidthColum(e, 240)}
                />
                <Column
                    width={180}
                    dataField={"StatusName"}
                    caption={Config.lang("Trang_thai")}
                    cellRender={renderStatusColumn}
                />
                <Column
                    width={180}
                    dataField={"ObjectID"}
                    caption={Config.lang("Ma_KH")}
                />
                <Column
                    width={220}
                    dataField={"ObjectName"}
                    caption={Config.lang("Ten_KH")}
                />
                <Column
                    width={150}
                    alignment={"right"}
                    dataType={"number"}
                    dataField={"CAmount"}
                    cellRender={renderColumnMoney}
                    caption={Config.lang("So_tien_coc")}
                />
                <Column
                    width={180}
                    dataField={"EventID"}
                    caption={Config.lang("Ma_su_kien")}
                />
                <Column
                    width={180}
                    dataField={"ContractNo"}
                    caption={Config.lang("So_hop_dong")}
                />
                <Column
                    width={220}
                    dataField={"BookingNo"}
                    caption={Config.lang("Ma_booking")}
                />
                <Column
                    width={140}
                    dataType={"date"}
                    alignment={"center"}
                    format={"dd/MM/yyyy"}
                    dataField={"EventDate"}
                    caption={Config.lang("Ngay_su_kien")}
                />
                <Column
                    width={200}
                    dataField={"EventName"}
                    caption={Config.lang("Ten_su_kien")}
                />
                <Column
                    width={220}
                    dataField={"EmployeeName"}
                    caption={Config.lang("Nguoi_lap")}
                />
                <Column
                    width={120}
                    dataType={"boolean"}
                    dataField={"IsCollected"}
                    caption={Config.lang("Da_thu")}
                    cellRender={renderCheckColumn}>
                    <Lookup dataSource={[
                        {
                            ID: 1,
                            Name: Config.lang("Co")
                        },
                        {
                            ID: 0,
                            Name: Config.lang("Khong")
                        }
                    ]}
                        valueExpr={"ID"}
                        displayExpr={"Name"}
                    />
                </Column>
                <Column
                    width={40}
                    fixed={true}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile}
                    cellRender={renderActionGrid}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, gridLoading]);

    if (!permission) return null;
    return (
        <React.Fragment>
            {renderModalAdd}
            {openModalWA3F2021 &&
                <WA3F2021
                    openModal={openModalWA3F2021}
                    dataInfo={dataInfo.current}
                    onOpenModalAction={(status, reloadPage) => {
                        onOpenModalAction("WA3F2021", status, reloadPage)
                    }}
                />
            }
            {openModalWA3F2022 &&
                <WA3F2022
                    openModal={openModalWA3F2022}
                    dataInfo={dataInfo.current}
                    onOpenModalAction={(status, reloadPage) => {
                        onOpenModalAction("WA3F2022", status, reloadPage)
                    }}
                />
            }
            <ActionToolbar title={Config.lang("Danh_sach_bien_nhan_coc")}>
                <Button
                    disabled={!(permission >= 2)}
                    size={"medium"}
                    color={"primary"}
                    viewType={"filled"}
                    label={Config.lang("Them")}
                    startIcon={"AddCircle"}
                    onClick={onAdd}
                />
            </ActionToolbar>
            <div className={"hidden"}>{renderFilterData()}</div>
            {renderTab}
            {renderGrid}
        </React.Fragment >
    );
};

export default WA3F2020;
