import { Column } from "devextreme-react/data-grid";
import { Button, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader, MoneyInput, Col, Row } from 'diginet-core-ui/components';
import { makeStyles } from "diginet-core-ui/theme";
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import Config from '../../../../config/index';
import { useDispatch } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2010Actions from "../../../../redux/modules/WA3/WA3F2010/WA3F2010_actions";
import GridContainer from "../../../common/grid-container/grid-container";
import { useMemo } from "react";

const useStyles = makeStyles(() => ({
    moneyInput: {
        width: '100%',
        '& input': {
            textAlign: 'right'
        }
    },
}));

const MenuModal = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { openModal, onOpenModalAction, FormID, dataInfo, currentMenuData, gridName, mode } = props;
    const DivisionID = _.get(Config.profile, "DivisionID", Config.getDivisionID()) || Config.getLocalStorage("DIVISIONBEM");
    const [dataGridLoading, setDataGridLoading] = useState(false);
    const [cboMenuTypeLoading, setCboMenuTypeLoading] = useState(false);
    const [cboMenuNumLoading, setCboMenuNumLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [dataGrid, setDataGrid] = useState([]);
    const [dataMaster, setDataMaster] = useState({
        MenuID: "",
        MenuName: "",
        MenuType: "",
        PriceMenu: null
    });
    const [dataCboMenuType, setDataCboMenuType] = useState([]);
    const [dataCboMenuNumOriginal, setDataCboMenuNumOriginal] = useState([]);
    const [dataCboMenuNum, setDataCboMenuNum] = useState(dataCboMenuNumOriginal);

    const changePage = useRef(false);
    const refBtn = useRef(null);
    const onChangeStatus = useRef(false);
    const tmpSelectedRowData = useRef([]);
    const tmpSelectedRowKeys = useRef([]);

    useEffect(() => {
        loadCboMenuType();
        loadCboMenuNum();
        loadDataGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadDataGrid = (isFilter = false) => {
        const { MenuID, MenuType } = dataMaster;
        const param = {
            FormID,
            DivisionID,
            MenuID: currentMenuData.MenuID && !onChangeStatus.current ? currentMenuData.MenuID : MenuID,
            MenuType: currentMenuData.MenuType && !onChangeStatus.current ? currentMenuData.MenuType : MenuType,
        };
        setDataGridLoading(true);
        dispatch(WA3F2010Actions.loadGridMenu(param, (error, data) => {
            setDataGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (currentMenuData && !isFilter) {
                    setDataMaster(_.omit(currentMenuData, "detail"));
                    if (currentMenuData.detail) {
                        const sameInventoryID = currentMenuData.detail.filter((obj) => data.some((obj2) => obj.InventoryID === obj2.InventoryID));
                        tmpSelectedRowKeys.current = sameInventoryID.map(item => item.InventoryID);
                        tmpSelectedRowData.current = sameInventoryID;
                    }
                }
                setDataGrid(data);
            }
        }));
    };

    const loadCboMenuType = () => {
        const { ObjectID = "", BookingID = "" } = dataInfo;
        const param = {
            FormID,
            ObjectID,
            BookingID,
        };
        setCboMenuTypeLoading(true);
        dispatch(generalActions.getCboMenuType(param, (error, data) => {
            setCboMenuTypeLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataCboMenuType(data);
            }
        }));
    };

    const loadCboMenuNum = () => {
        const { ObjectID = "", BookingID = "" } = dataInfo;
        const param = {
            FormID,
            ObjectID,
            BookingID
        };
        setCboMenuNumLoading(true);
        dispatch(generalActions.getCboMenuID(param, (error, data) => {
            setCboMenuNumLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                let dataFilter = data;
                setDataCboMenuNumOriginal(data);
                if (currentMenuData.MenuType) {
                    dataFilter = data.filter(item => item.MenuType === currentMenuData.MenuType);
                }
                setDataCboMenuNum(dataFilter);
            }
        }));
    };

    const renderNumberFormat = (e) => {
        if (!e) return false;
        const { column: { dataField = "" }, row: { data = {} } } = e;
        const decimalValue = data[dataField];
        if (_.isUndefined(decimalValue)) return;
        return <MoneyInput
            nonStyle
            readOnly={true}
            thousandSeparator={","}
            value={decimalValue}
            className={classes.moneyInput}
        />
    };

    const onChange = (fieldName = "", e) => {
        if (!e || _.isEmpty(fieldName)) return;
        const value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        let objectValuePlus = { [fieldName]: value };
        if (fieldName === "MenuID") {
            const positionID = fieldName.indexOf("ID");
            const standardDDName = positionID > 0 ? fieldName.slice(0, positionID) : fieldName;
            const DDName = standardDDName + "Name";
            const valueDDName = _.get(e.data, [DDName], _.get(e.data, "ObjectName", ""));
            objectValuePlus = { ...objectValuePlus, [DDName]: valueDDName };
        } else if (fieldName === "MenuType") {
            const dataFilter = dataCboMenuNumOriginal.filter(item => item.MenuType === value);
            const dataMenuIndex = dataFilter.findIndex(item => item.MenuID === dataMaster?.MenuID);
            if (!(dataMenuIndex > -1)) objectValuePlus = { ...objectValuePlus, MenuID: "" };
            setDataCboMenuNum(dataFilter);
        }
        if (!_.isEmpty(_.get(dataGrid, "rows", dataGrid))) {
            tmpSelectedRowKeys.current = [];
            tmpSelectedRowData.current = [];
            setDataGrid([]);
        }
        onChangeStatus.current = true;
        setDataMaster({
            ...dataMaster,
            ...objectValuePlus
        });
    };

    const handleChecked = (currentSelectedRowKeys, keyExpr) => {
        if (_.isEmpty(tmpSelectedRowData.current) && !_.isEmpty(tmpSelectedRowKeys.current)) tmpSelectedRowKeys.current = [];
        currentSelectedRowKeys.forEach((val) => {
            if (tmpSelectedRowData.current.indexOf(val) < 0 && !tmpSelectedRowKeys.current.includes(val[keyExpr])) {
                tmpSelectedRowData.current.push(val);
                tmpSelectedRowKeys.current.push(val[keyExpr]);
            }
        });
    };

    const handleUnChecked = (currentDeselectedRowKeys, keyExpr) => {
        tmpSelectedRowData.current = tmpSelectedRowData.current.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
        });
        tmpSelectedRowKeys.current = tmpSelectedRowKeys.current.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e) < 0;
        });
    };

    const setSelectedRow = (e) => {
        const keyExpr = "InventoryID";
        const currentSelectedRowKeys = e.currentSelectedRowKeys;
        const currentSelectedRowsData = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        const currentQuantityChooseObj = currentSelectedRowsData.find(item => item[keyExpr] === currentSelectedRowKeys[0]);
        const tongGroupMenuName = tmpSelectedRowData.current.filter(item => item.GroupMenuName === _.get(currentQuantityChooseObj, "GroupMenuName", ""));
        const isIncludeInOldDeselect = tmpSelectedRowKeys.current.includes(currentDeselectedRowKeys[0]);
        if (tongGroupMenuName.length + 1 > _.get(currentQuantityChooseObj, "QuantityChoose", null) && !isIncludeInOldDeselect && mode !== "single") return;
        if (changePage.current) {
            if (currentSelectedRowsData.length > 0) {
                handleChecked(currentSelectedRowsData, keyExpr, mode);
            }
            changePage.current = false;
        } else {
            if (currentDeselectedRowKeys.length > 0) {// UnSelect
                handleUnChecked(currentDeselectedRowKeys, keyExpr);
            }
            else if (currentSelectedRowsData.length > 0) {
                if (mode === "multiple") { //Multiple Select
                    handleChecked(currentSelectedRowsData, keyExpr, mode);
                }
                if (mode === "single") { //Select one only
                    currentSelectedRowsData.forEach((val) => {
                        tmpSelectedRowData.current = [val]
                        tmpSelectedRowKeys.current = [val[keyExpr]];
                    });
                }
            }
        }
        const sumPriceMenu = tmpSelectedRowData.current?.[0]?.PriceSales ?? null;
        setDataMaster({ ...dataMaster, PriceMenu: sumPriceMenu });
        setSelectedRowKeys(e.selectedRowKeys);
    };

    const renderGrid = useMemo(() => {
        return (
            <GridContainer
                keyExpr={"InventoryID"}
                columnResizingMode={"widget"}
                gridProps={{ style: { maxHeight: 360 } }}
                typeShort={window.innerWidth < 768}
                elementAttr={{ style: 'min-height: calc(100vh - 490px)' }}
                filterRow={{
                    visible: true,
                    showOperationChooser: false,
                }}
                selection={{
                    allowSelectAll: false,
                    mode: "multiple",
                    selectAllMode: "",
                    showCheckBoxesMode: "always"
                }}
                selectedRowKey={tmpSelectedRowKeys.current}
                loading={dataGridLoading}
                dataSource={_.get(dataGrid, "rows", dataGrid)}
                onSelectionChanged={(e) => setSelectedRow(e)}
            >
                <Column
                    width={140}
                    caption={Config.lang("Ma")}
                    dataField={"InventoryID"}
                />
                <Column
                    width={270}
                    caption={Config.lang("Ten_mon")}
                    dataField={"InventoryName"}
                />
                <Column
                    width={90}
                    caption={Config.lang("DVT")}
                    dataField={"UnitID"}
                />
                <Column
                    width={150}
                    caption={Config.lang("Nhom_mon_an")}
                    dataField={"GroupMenuName"}
                />
                <Column
                    width={120}
                    alignment={"right"}
                    dataField={"UnitPrice"}
                    caption={Config.lang("Don_gia")}
                    cellRender={renderNumberFormat}
                />
                <Column
                    width={90}
                    alignment={"right"}
                    dataType={"number"}
                    caption={Config.lang("SL_tuy_chon")}
                    dataField={"QuantityChoose"}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGridLoading, dataGrid, tmpSelectedRowKeys.current]);

    return (
        <Modal
            width={"960"}
            open={openModal}
            onClose={() => { if (onOpenModalAction) onOpenModalAction(false) }}>
            <ModalHeader>{Config.lang("Chon_goi_menu")}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} sm={5} md={3} lg={3}>
                        <Dropdown
                            clearAble
                            dataSource={dataCboMenuType}
                            value={dataMaster?.MenuType}
                            loading={cboMenuTypeLoading}
                            valueExpr={"MenuType"}
                            displayExpr={"MenuTypeName"}
                            label={Config.lang("Loai_thuc_don")}
                            onChange={(e) => onChange("MenuType", e)}
                        />
                    </Col>
                    <Col xs={12} sm={5} md={3} lg={3}>
                        <Dropdown
                            clearAble
                            dataSource={dataCboMenuNum}
                            value={dataMaster?.MenuID}
                            loading={cboMenuNumLoading}
                            valueExpr={"MenuID"}
                            displayExpr={"MenuName"}
                            treeViewID={"MenuID"}
                            treeViewParentID={"MenuName"}
                            label={Config.lang("So_thuc_don")}
                            onChange={(e) => onChange("MenuID", e)}
                        />
                    </Col>
                    <Col xs={12} sm={5} md={3} lg={3}>
                        <MoneyInput
                            readOnly={true}
                            thousandSeparator={","}
                            label={Config.lang("Gia_menu")}
                            onChange={(e) => onChange("PriceMenu", e)}
                            value={dataMaster?.PriceMenu}
                        />
                    </Col>
                    <Col xs={12} sm={5} md={3} lg={3} style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            disabled={!_.isArray(selectedRowKeys)}
                            style={{ marginLeft: 'auto', textTransform: 'uppercase' }}
                            size={"medium"}
                            color={"primary"}
                            startIcon={"Filter"}
                            viewType={"outlined"}
                            label={Config.lang("Loc")}
                            onClick={() => loadDataGrid(true)}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        {renderGrid}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <div style={{ margin: 'auto' }}>
                    <Button
                        ref={refBtn}
                        disabled={_.isEmpty(tmpSelectedRowKeys.current)}
                        size={"medium"}
                        color={"info"}
                        viewType={"filled"}
                        label={Config.lang("Chon")}
                        onClick={() => {
                            const sameInventoryID = tmpSelectedRowData.current.filter((obj) => currentMenuData.detail.some((obj2) => obj.InventoryID === obj2.InventoryID));
                            if (sameInventoryID.length === tmpSelectedRowData.current.length) {
                                Config.popup.show("ERROR", Config.lang("Menu_nay_da_duoc_chon"));
                            } else {
                                if (onOpenModalAction) {
                                    if (_.isEmpty(currentMenuData.detail)) {
                                        onOpenModalAction(false, {
                                            ...dataMaster,
                                            detail: tmpSelectedRowData.current
                                        });
                                    } else {
                                        Config.popup.show("YES_NO", Config.lang(`Menu_tren_luoi_%${gridName.toLowerCase()}%_se_bi_xoa,_ban_co_muon_tiep_tuc?`), () => {
                                            onOpenModalAction(false, {
                                                ...dataMaster,
                                                detail: tmpSelectedRowData.current
                                            });
                                        });
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </ModalFooter>
        </Modal >
    );
};

MenuModal.defaultProps = {
    mode: "multiple"
};

MenuModal.propTypes = {
    openModal: PropTypes.bool,
    mode: PropTypes.string,
    FormID: PropTypes.string,
    gridName: PropTypes.string,
    dataInfo: PropTypes.object,
    currentMenuData: PropTypes.object,
    onOpenModalAction: PropTypes.func,
};

export default MenuModal;

