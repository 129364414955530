import { Column } from "devextreme-react/data-grid";
import { Col, Modal, ModalBody, ModalHeader, Row, TextInput } from 'diginet-core-ui/components';
import { makeStyles } from "diginet-core-ui/theme";
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Config from '../../config/index';
import * as generalActions from "../../redux/general/general_actions";
import GridContainer from "../common/grid-container/grid-container";

const useStyles = makeStyles({
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    }
});

const CustomerModal = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dataCboCustomer = useSelector(state => state?.general?.dataCboCustomer ?? {});
    const { openModal, onOpenModalAction, FormID, currentSelected, headerTitle } = props;
    const [dataGridLoading, setDataGridLoading] = useState(false);

    const timerSearch = useRef(null);
    const dataGridPages = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });

    useEffect(() => {
        loadDataCustomer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadDataCustomer = () => {
        const { skip, limit, search } = dataGridPages.current;
        const param = {
            FormID,
            search,
            skip,
            limit,
        };
        setDataGridLoading(true);
        dispatch(generalActions.getCboCustomer(param, (error) => {
            setDataGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        }));
    };

    const onChangeCustomerPage = (page) => {
        dataGridPages.current.skip = page * dataGridPages.current.limit;
        loadDataCustomer();
    };

    const onChangeCustomerPerPage = (perPage) => {
        dataGridPages.current.skip = 0;
        dataGridPages.current.limit = perPage;
        loadDataCustomer();
    };

    const renderMaxWidthColum = (e, maxWidth = 280) => {
        const value = _.get(e, "value", "");
        return <div title={value} className={classes.ellipsis}
            {...(_.isNumber(maxWidth) ? { style: { maxWidth } } : {})} >{value}</div>
    };

    return (
        <Modal
            width={"960"}
            open={openModal}
            onClose={() => { if (onOpenModalAction) onOpenModalAction(false) }}>
            <ModalHeader>{headerTitle || Config.lang("Chon_ma_khach_hang")}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            viewType={"outlined"}
                            endIcon={"Search"}
                            placeholder={Config.lang("Tim_kiem")}
                            onChange={(e) => {
                                const value = e?.target?.value || "";
                                if (timerSearch.current) clearTimeout(timerSearch.current);
                                timerSearch.current = setTimeout(() => {
                                    dataGridPages.current.search = value;
                                    dataGridPages.current.skip = 0;
                                    loadDataCustomer();
                                }, 700);
                            }}
                            value={dataGridPages.current.search}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <GridContainer
                            itemPerPage={dataGridPages.current.limit}
                            skipPerPage={dataGridPages.current.skip}
                            // keyExpr={"ObjectID"}
                            height={420}
                            pagerFullScreen={false}
                            typePaging={"remote"}
                            selection={{ mode: "single" }}
                            loading={dataGridLoading}
                            totalItems={_.get(dataCboCustomer, "total", 0)}
                            dataSource={_.get(dataCboCustomer, "rows", [])}
                            onContentReady={(e) => {
                                if (currentSelected && !_.isEmpty(dataCboCustomer?.rows)) {
                                    const grid = e.component;
                                    const index = dataCboCustomer.rows.findIndex(item => item?.ObjectID === currentSelected?.ObjectID && item?.TypeID === currentSelected?.TypeID);
                                    grid.selectRowsByIndexes([_.toNumber(index)]); // Default focus dòng đầu tiên
                                }
                            }}
                            onRowDblClick={(e) => {
                                if (onOpenModalAction) onOpenModalAction(false, _.get(e, "data", {})) //Chọn và đóng modal
                            }}
                            onChangePage={onChangeCustomerPage}
                            onChangePerPage={onChangeCustomerPerPage}
                        >
                            <Column
                                width={200}
                                dataField={"ObjectID"}
                                caption={Config.lang("Ma_khach_hang")}
                            />
                            <Column
                                width={240}
                                dataField={"ObjectName"}
                                caption={Config.lang("Ten_khach_hang")}
                            />
                            <Column
                                width={240}
                                dataField={"ContactPerson"}
                                caption={Config.lang("Nguoi_lien_he")}
                            />
                            <Column
                                width={140}
                                dataField={"ContactTelNo"}
                                caption={Config.lang("SDT")}
                            />
                            <Column
                                width={360}
                                dataField={"ObjectAddress"}
                                caption={Config.lang("Dia_chi")}
                            />
                            <Column
                                minWidth={120}
                                dataField={"Notes"}
                                caption={Config.lang("Ghi_chu")}
                                cellRender={(e) => renderMaxWidthColum(e, 360)}
                            />
                        </GridContainer>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

CustomerModal.defaultProps = {
    openModal: false,
    FormID: "",
    headerTitle: "",
    currentSelected: {}
};

CustomerModal.propTypes = {
    openModal: PropTypes.bool,
    FormID: PropTypes.string,
    headerTitle: PropTypes.string,
    currentSelected: PropTypes.object,
    onOpenModalAction: PropTypes.func,
};

export default CustomerModal;

