import { Column } from "devextreme-react/data-grid";
import { Modal, ModalBody, ModalHeader, TextInput, Row, Col } from 'diginet-core-ui/components';
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import Config from '../../config/index';
import * as generalActions from "../../redux/general/general_actions";
import GridContainer from "../common/grid-container/grid-container";
import { useDispatch } from "react-redux";

const ChoseCBModal = (props) => {
    const dispatch = useDispatch();
    const { openModal, onOpenModalAction, FormID, dataInfo, dataKeyChose, keyExpr, statusInven, keyExprName } = props;
    const { ObjectID = "", BookingID = "" } = dataInfo;
    const [dataGridLoading, setDataGridLoading] = useState(false);
    const [dataGrid, setDataGrid] = useState({
        rows: [],
        total: 0
    });
    const filterCbo = useRef({
        skip: 0,
        limit: 10,
        search: ""
    });
    const timerSearch = useRef(null);
    const keyExprRef = useRef(keyExpr);

    useEffect(() => {
        loadCboName(keyExpr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadCboName = () => {
        let apiName = "";
        let ObjectTypeID = "";
        switch (keyExpr) {
            case "DepartmentID":
                apiName = "getCboDepartment";
                keyExprRef.current = "ObjectID";
                break;
            case "ObjectID":
                apiName = "getCboReceptionist";
                ObjectTypeID = "NV";
                break;
            case "InventoryID":
                if (statusInven === 0) apiName = "getCboInventoryMH";
                if (statusInven === 1) apiName = "getCboInventoryDV";
                break;
            case "UnitID":
                apiName = "getCboUnit";
                break;
            default:
                break;
        }
        if (_.isEmpty(apiName)) return;
        const { skip, limit, search } = filterCbo.current;
        const param = {
            FormID,
            ObjectTypeID,
            ObjectID,
            BookingID,
            skip,
            limit,
            search
        };
        setDataGridLoading(true);
        dispatch(generalActions[apiName](param, (error, data) => {
            setDataGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataGrid({
                    rows: _.get(data, "rows", []),
                    total: _.get(data, "total", 0)
                });
            }
        }));
    };

    const onChangeContractPage = (page) => {
        filterCbo.current.skip = page * filterCbo.current.limit;
        loadCboName();
    };

    const onChangeContractPerPage = (perPage) => {
        filterCbo.current.skip = 0;
        filterCbo.current.limit = perPage;
        loadCboName();
    };

    const getCaptionName = (varStr) => {
        let result = "";
        if (varStr === "ID") result = Config.lang("Ma");
        if (varStr === "Name") result = Config.lang("Ten");
        if (Config.isEmpty(statusInven, true)) {
            switch (keyExpr) {
                case "DepartmentID":
                    if (varStr === "Title") result = Config.lang("Bo_phan_tiep_nhan");
                    if (varStr === "Name") result = Config.lang("Ten_bo_phan");
                    break;
                case "ObjectID":
                    if (varStr === "Title") result = Config.lang("Nguoi_tiep_nhan");
                    if (varStr === "Name") result = Config.lang("Ten_nguoi_tiep_nhan");
                    break;
                case "UnitID":
                    if (varStr === "Title") result = Config.lang("Don_vi_tinh");
                    if (varStr === "Name") result = Config.lang("Ten_nguoi_tiep_nhan");
                    break;
                default:
                    break;
            }
        } else {
            if (varStr === "Title") {
                if (statusInven === 0) {
                    if (keyExpr === "UnitID") {
                        result = Config.lang("Don_vi_tinh");
                    } else if (keyExpr === "InventoryID") {
                        result = Config.lang("Mon_an");
                    }
                }
                if (statusInven === 1) {
                    result = Config.lang("Dich_vu");
                }

            }
        }
        return result;
    };

    return (
        <Modal
            zIndex={1020}
            width={"720"}
            open={openModal}
            onClose={() => { if (onOpenModalAction) onOpenModalAction(false) }}>
            <ModalHeader>{getCaptionName("Title")}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <TextInput
                            viewType={"outlined"}
                            endIcon={"Search"}
                            placeholder={Config.lang("Tim_kiem")}
                            onChange={(e) => {
                                const value = e?.target?.value || "";
                                if (timerSearch.current) clearTimeout(timerSearch.current);
                                timerSearch.current = setTimeout(() => {
                                    filterCbo.current.search = value;
                                    filterCbo.current.skip = 0;
                                    loadCboName();
                                }, 700);
                            }}
                            value={filterCbo.current.search}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <GridContainer
                            itemPerPage={filterCbo.current.limit}
                            skipPerPage={filterCbo.current.skip}
                            keyExpr={keyExprRef.current}
                            gridProps={{
                                style: { maxHeight: 410 }
                            }}
                            pagerFullScreen={false}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            loading={dataGridLoading}
                            typeShort={window.innerWidth < 768}
                            typePaging={"remote"}
                            height={"calc(100vh - 180px)"}
                            onChangePage={onChangeContractPage}
                            onChangePerPage={onChangeContractPerPage}
                            totalItems={_.get(dataGrid, "total", 0)}
                            dataSource={_.get(dataGrid, "rows", [])}
                            onContentReady={(e) => {
                                if (dataKeyChose && !_.isEmpty(dataGrid?.rows)) {
                                    const grid = e.component;
                                    const index = dataGrid.rows.findIndex(item => item[keyExprRef.current] === dataKeyChose);
                                    grid.selectRowsByIndexes([_.toNumber(index)]); // Default focus dòng đầu tiên
                                }
                            }}
                            onRowDblClick={(e) => {
                                if (onOpenModalAction) {
                                    const data = _.get(e, "data", {});
                                    onOpenModalAction(false, data) //Chọn và đóng modal
                                }
                            }}
                        >
                            <Column
                                caption={getCaptionName("ID")}
                                dataField={keyExprRef.current}
                                width={240}
                            />
                            <Column
                                caption={getCaptionName("Name")}
                                dataField={keyExprName}
                            />
                        </GridContainer>
                    </Col>
                </Row>
            </ModalBody>
        </Modal >
    );
};


ChoseCBModal.defaultProps = {
    statusInven: null,
    openModal: false,
    dataKeyChose: "",
    keyExpr: "ObjectID",
    keyExprName: "ObjectName",
    dataInfo: {},
};

ChoseCBModal.propTypes = {
    openModal: PropTypes.bool,
    statusInven: PropTypes.number,
    FormID: PropTypes.string,
    dataKeyChose: PropTypes.string || PropTypes.number,
    keyExpr: PropTypes.string,
    keyExprName: PropTypes.string,
    dataInfo: PropTypes.object,
    onOpenModalAction: PropTypes.func,
};

export default ChoseCBModal;

