/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/5/2019
 * @Example
 */
import React, {Component}          from 'react';
import {Snackbar, SnackbarContent} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from 'prop-types';
import Config from "../../../../config";
import { useTheme } from "diginet-core-ui/theme";
const { zIndex } = useTheme();

const styles = theme => ({
    success: {
        backgroundColor: theme.palette.success.main,
    },
    error: {
        backgroundColor: theme.palette.danger.main,
    },
    info: {
        backgroundColor: theme.palette.info.main,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
    },
    minWidth: {
        minWidth: '60%'
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

class Notify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            type: "",
            time: 0,
            message: "",
            options: null
        };
    }

    show = (type, message, time, cbClose, options) => {
        this.setState({
            show: true,
            type: type,
            time: time ? time : null,
            message: message,
            options: options,
            cbClose: ()=>{
                this.setState({show: false});
                cbClose && cbClose();
            },
        });
    };

    onClose = () => {
        this.setState({show: false});
    };

    render() {
        const {show, type, time, message, options} = this.state;
        const {classes, minWidth, icon, anchorVertical, anchorHorizontal} = this.props;

        return (
            <Snackbar
                style={{minWidth: '100%', zIndex: zIndex(4)}}
                anchorOrigin={{
                    vertical: anchorVertical ? anchorVertical : 'bottom',
                    horizontal: anchorHorizontal ? anchorHorizontal : 'center',
                }}
                open={show}
                autoHideDuration={time}
                TransitionComponent={Config.popupTransition}
                onClose={this.onClose}
                {...(options ? {...options} : {})}
            >
                <SnackbarContent
                    className={clsx(classes[type], !minWidth ? classes.minWidth : "")}
                    style={{...(minWidth ? {minWidth: minWidth} : {})}}
                    aria-describedby="client-snackbar"
                    role={'alertdialog'}
                    message={
                        <span id="client-snackbar">
                            {icon && typeof icon === "function" ? icon() : icon}
                            {message}
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={this.onClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        );
    }
}

Notify.propTypes = {
    icon: PropTypes.any,
    minWidth: PropTypes.any,
    anchorVertical: PropTypes.string,
    anchorHorizontal: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(Notify);
