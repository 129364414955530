/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/17/2021
 * @Example
 */
import React, {useEffect, useMemo, useRef, useState} from "react";
import PropTypes                                     from "prop-types";
import Config                                        from "../../../../config";
import {Row, Col, FormGroup}                         from "react-bootstrap";
import ActionToolbar
                                                     from "../../../common/layouts/toolbar/action-toolbar";
import WA3F0100Config                              from "./Api";
import {
    TextInput, Dropdown, DatePicker, Label, Button, ButtonIcon, Typography
}                                                    from "diginet-core-ui/components";
import {makeStyles}                                  from "diginet-core-ui/theme";
import _                                             from "lodash";
import moment                                        from "moment";
import {Search}                                      from "diginet-core-ui/icons";

const useStyles   = makeStyles(theme => ({
    root:                 {
        display: "flex",
    },
    hide:                 {
        display: "none",
    },
    panel:                {
        transition: "all .4s",
    },
    panelForm:            {
        transition: "all .5s",
        opacity:    1,
    },
    hiddenOpacity:        {
        opacity: 0,
    },
    leftPanelshowDrawer:  {
        width: "74px",
    },
    rightPanelshowDrawer: {
        width: "calc(100% - 74px)",
    },
    titleColorFilter:     {
        backgroundColor: theme.colors.primary + " !important",
    },
}));
const dataActions = [
    {
        action:     "0",
        actionName: Config.lang("Them_moi1")
    },
    {
        action:     "1",
        actionName: Config.lang("Sua"),
    },
    {
        action:     "2",
        actionName: Config.lang("Khac"),
    },
    {
        action:     "3",
        actionName: Config.lang("Xoa"),
    },
];
const WA3F0100Nav = (props) => {

    const classes                                                         = useStyles();
    const {loading: _loading, showDrawer, FormID, handleToggle, onFilter} = props;
    const [loading, _setLoading]                                          = useState({});
    const [dataCboEmployees, setDataCboEmployees]                         = useState({
        rows:  [],
        total: 0
    });
    const [dataCboTransaction, setDataCboTransaction]                     = useState([]);
    const [dataCboModules, setDataCboModules]                     = useState([]);
    const [dataForm, _setDataForm]                                        = useState({
        Content:      "",
        Actions:      null,
        Transactions: "",
        Modules:    "",
        Datefrom:     null,
        Dateto:       null,
    });

    const refLoading         = useRef({});
    const filterCboEmployees = useRef({
        limit:     15,
        skip:      0,
        strSearch: ""
    });
    const timer              = useRef(null);

    const setLoading  = (obj) => {
        refLoading.current = {...refLoading.current, ...obj};
        _setLoading(refLoading.current);
    };
    const setDataForm = (obj) => {
        _setDataForm({...dataForm, ...obj});
    };

    const handleChange = (key, e) => {
        if (!key) return false;
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        setDataForm({[key]: value});
    };

    const _onFilter = () => {
        loadHistorySearch();
    };

    const loadCboEmployees = (isReset) => {
        const params = {
            HostID:   "",
            Type:     "EmployeeID",
            FormID:   FormID,
            Language: Config.language || "84",
            skip:     filterCboEmployees.current?.skip || 0,
            limit:    filterCboEmployees.current?.limit || 15,
            search:   filterCboEmployees.current?.strSearch || "",
        };
        setLoading({cboEmployees: true});
        WA3F0100Config.loadCboEmployees(params, (error, data) => {
            setLoading({cboEmployees: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const rows  = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                setDataCboEmployees({
                    rows:  isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total,
                });
            }
        });
    };

    const loadCboTransaction = () => {
        const params = {
            Language: Config.language || "84",
        };
        setLoading({cboTransaction: true});
        WA3F0100Config.loadCboTransaction(params, (error, data) => {
            setLoading({cboTransaction: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) setDataCboTransaction(data);
        });
    };

    const loadCboModules = () => {
        const {getDataCboModules} = props;
        setLoading({cboModules: true});
        WA3F0100Config.loadCboModules({}, (error, data) => {
            setLoading({cboModules: false});
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                if (getDataCboModules) getDataCboModules(data);
                setDataCboModules(data);
            }
        });
    };

    const loadHistorySearch = () => {
        const {
                  Content,
                  Employee,
                  Actions,
                  Transactions,
                  Modules,
                  Dateto,
                  Datefrom,
              } = dataForm;
        const begin = Datefrom
            ? moment(Datefrom).startOf("day").format("YYYY-MM-DD HH:mm:ss")
            : "";
        const end   = Dateto
            ? moment(Dateto).endOf("day").format("YYYY-MM-DD HH:mm:ss")
            : "";

        let obj = {
            action:        Actions || "",
            createdAt:     {
                ">": begin,
                "<": end
            },
            text:          Content
        };
        if (WA3F0100Config.showCboEmployees) obj.userID = Employee?.UserID || "";
        if (WA3F0100Config.showCboTransaction) obj.transactionID = Transactions || "";
        if (WA3F0100Config.showCboModule) obj.moduleID = Modules || "";
        /**
         * condition: {
         *     "and": [
         *         {
         *           position: "GD",
         *         },
         *        {
         *           "or" : [
         *             {userID: "a"},
         *             {userID: "b"}
         *           ]
         *         }
         *       ]
         *   }
         */
        Object.keys(obj).forEach((item) => {
            switch (item) {
                case "createdAt":
                    if (obj.createdAt[">"] === "") {
                        delete obj.createdAt[">"];
                    }
                    if (obj.createdAt["<"] === "") {
                        delete obj.createdAt["<"];
                    }
                    break;
                case "text":
                    if (obj[item]) {
                        obj[item] = {"contains": obj[item]};
                    } else {
                        delete obj[item];
                    }
                    break;
                default:
                    if (!obj[item]) {
                        delete obj[item];
                    }
                    break;
            }

        });
        if (_.isEmpty(obj.createdAt)) {
            delete obj["createdAt"];
        }
        let sort     = [{updatedAt: "DESC"}];
        const params = {
            sort:      JSON.stringify(sort),
            condition: JSON.stringify(obj),
        };

        if (onFilter) onFilter(params);
    };

    useEffect(() => {
        if (WA3F0100Config.showCboEmployees) loadCboEmployees();
        if (WA3F0100Config.showCboTransaction) loadCboTransaction();
        if (WA3F0100Config.showCboModule) loadCboModules();
        loadHistorySearch();
        /* eslint-disable */
    }, []);

    const disabled = useMemo(() => {
        const l = {...loading, ..._loading};
        return !!Object.keys(l).find(_l => l[_l]);
    }, [loading, _loading]);
    return (
        <React.Fragment>
            <ActionToolbar
                alignment={showDrawer ? "space-between" : "center"}
                // title={Config.lang("Loc_chon_nhan_vien")}
                showBorder={false}
                upperCase={false}
                className={!showDrawer ? classes.titleColorFilter : ""}
                style={{
                    marginTop:       0,
                    backgroundColor: "#EDEEF3",
                    height:          "57.6px",
                    padding:         "0 15px",
                    marginBottom:    15,
                    fontSize:        "1rem",
                }}
            >
                <div className={"display_row align-center valign-middle"}>
                    <ButtonIcon
                        name={"Filter"}
                        viewType={"text"}
                        width={16}
                        height={16}
                        circular
                        color={!showDrawer ? "white" : ""}
                        onClick={() => handleToggle(!showDrawer)}
                    />
                    {showDrawer && (
                        <Typography>{Config.lang("Loc_chon_hanh_dong")}</Typography>
                    )}
                </div>
                {showDrawer && (
                    <ButtonIcon
                        name={showDrawer ? "ArrowLeft" : "ArrowRight"}
                        viewType={"text"}
                        // size={"tiny"}
                        width={16}
                        height={16}
                        circular
                        onClick={() => handleToggle(!showDrawer)}
                    />
                )}
            </ActionToolbar>
            <div className={`${classes.panelForm} form-field ${!showDrawer ? "hide" : ""}`}>
                <div style={{padding: "0 20px"}}>
                    <FormGroup style={{
                        marginBottom: 5,
                        marginTop:    15
                    }}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    dataSource={dataActions}
                                    viewType={"outlined"}
                                    displayExpr={"actionName"}
                                    valueExpr={"action"}
                                    clearAble
                                    placeholder={Config.lang("Hanh_dong")}
                                    label={Config.lang("Hanh_dong")}
                                    onChange={e => handleChange("Actions", e)}
                                    value={dataForm?.Actions || ""}
                                    style={{marginBottom: 5}}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    {WA3F0100Config.showCboModule && <FormGroup style={{marginBottom: 5}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    dataSource={dataCboModules}
                                    viewType={"outlined"}
                                    displayExpr={"ModuleName"}
                                    valueExpr={"ModuleID"}
                                    clearAble
                                    loading={loading?.cboModules}
                                    placeholder={"Module"}
                                    label={"Module"}
                                    onChange={e => handleChange("Modules", e)}
                                    value={dataForm?.Modules || ""}
                                    style={{marginBottom: 5}}
                                />
                            </Col>
                        </Row>
                    </FormGroup>}
                    {WA3F0100Config.showCboTransaction && <FormGroup style={{marginBottom: 5}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    dataSource={dataCboTransaction}
                                    viewType={"outlined"}
                                    displayExpr={"TransactionName"}
                                    valueExpr={"TransactionID"}
                                    clearAble
                                    loading={loading?.cboTransaction}
                                    placeholder={Config.lang("Nghiep_vu")}
                                    label={Config.lang("Nghiep_vu")}
                                    onChange={e => handleChange("Transactions", e)}
                                    value={dataForm?.Transactions || ""}
                                    style={{marginBottom: 5}}
                                />
                            </Col>
                        </Row>
                    </FormGroup>}
                    {WA3F0100Config.showCboEmployees && <FormGroup style={{marginBottom: 5}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    allowSearch
                                    dataSource={dataCboEmployees?.rows || []}
                                    skip={filterCboEmployees.current?.skip}
                                    limit={filterCboEmployees.current?.limit}
                                    total={dataCboEmployees?.total || 0}
                                    displayExpr={'{EmployeeID} - {EmployeeName}'}
                                    keyExpr={"EmployeeID"}
                                    valueExpr={"EmployeeID"}
                                    value={dataForm?.Employee || ""}
                                    loading={loading.cboEmployees}
                                    viewType={'outlined'}
                                    clearAble
                                    label={Config.lang('Nhan_vien')}
                                    placeholder={Config.lang('Chon')}
                                    onChange={(e) => handleChange("Employee", e)}
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        if (timer.current) clearTimeout(timer.current);
                                        timer.current = setTimeout(() => {
                                            filterCboEmployees.current.strSearch = value;
                                            filterCboEmployees.current.skip      = 0;
                                            loadCboEmployees(true);
                                        }, 500);

                                    }}
                                    onLoadMore={(e) => {
                                        filterCboEmployees.current.skip  = e.skip;
                                        filterCboEmployees.current.limit = e.limit;
                                        loadCboEmployees();
                                    }}
                                    style={{marginBottom: 5}}
                                />
                            </Col>
                        </Row>
                    </FormGroup>}
                    <FormGroup style={{marginBottom: 5}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={Config.lang("Mo_ta")}
                                    placeholder={Config.lang("Nhap")}
                                    viewType={"outlined"}
                                    style={{marginBottom: 5}}
                                    onChange={(e) => handleChange("Content", e)}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{marginBottom: 5}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Label>{Config.lang("Ngay_tac_dong")}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <div className={"display_row align-center"}>
                                    <Label className={"pdr5"} style={{whiteSpace: "nowrap"}}>
                                        {Config.lang("Tu")}
                                    </Label>
                                    <DatePicker
                                        // actionIconAt={'start'}
                                        max={dataForm?.Dateto}
                                        placeholder={'DD/MM/YYYY'}
                                        displayFormat={'DD/MM/YYYY'}
                                        value={dataForm?.Datefrom}
                                        viewType={'outlined'}
                                        onChange={e => handleChange("Datefrom", e)}
                                        style={{margin: 0}}
                                        clearAble
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <div className={"display_row align-center"}>
                                    <Label className={"pdr5"} style={{whiteSpace: "nowrap"}}>
                                        {Config.lang("Den")}
                                    </Label>
                                    <DatePicker
                                        // actionIconAt={'start'}
                                        min={dataForm?.Datefrom}
                                        placeholder={'DD/MM/YYYY'}
                                        displayFormat={'DD/MM/YYYY'}
                                        value={dataForm?.Dateto}
                                        viewType={'outlined'}
                                        onChange={e => handleChange("Dateto", e)}
                                        style={{margin: 0}}
                                        clearAble
                                    />
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup className="display_row align_center valign-bottom mgt25">
                        <Button
                            startIcon={<Search color={"primary"}/>}
                            className={classes.btnActionItem}
                            size={"medium"}
                            color={"primary"}
                            viewType={"outlined"}
                            disabled={disabled}
                            label={Config.lang("Tim_kiem")}
                            onClick={_onFilter}
                        />
                    </FormGroup>
                </div>
            </div>
        </React.Fragment>
    );
};

WA3F0100Nav.propTypes = {
    loading:           PropTypes.object,
    FormID:            PropTypes.string,
    showDrawer:        PropTypes.bool,
    handleToggle:      PropTypes.func,
    getDataCboModules: PropTypes.func,
    onFilter:          PropTypes.func,
};

export default WA3F0100Nav;
