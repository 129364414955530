/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/17/2021
 * @Example
 */
import Api     from '../../../../services/api';

export async function search(params, cb) {
    const res = await Api.put("/history/search", params);
    if (res && res.data) {
        cb && cb(null, res.data);
    } else {
        cb && cb(res, null);
    }
}

export async function loadCboEmployees(params, cb) {
    const res = await Api.get("/standard/load-combo-employee");
    if (res && res.data) {
        cb && cb(null, res.data);
    } else {
        cb && cb(res, null);
    }
}

export async function loadCboModules(params, cb) {
    const res = await Api.put("/w00f0010/get-modules");
    if (res && res.data) {
        cb && cb(null, res.data);
    } else {
        cb && cb(res, null);
    }
}

export async function loadCboTransaction(params, cb) {
    const res = await Api.get("/standard/load-combo-transaction");
    if (res && res.data) {
        cb && cb(null, res.data);
    } else {
        cb && cb(res, null);
    }
}

export default {
    showCboEmployees: true,
    showCboTransaction: true,
    showCboModule: false,
    search: search,
    loadCboEmployees: loadCboEmployees,
    loadCboTransaction: loadCboTransaction,
    loadCboModules: loadCboModules,
};


