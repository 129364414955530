/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 12/07/2021
 * @Example
 */
import { Column } from "devextreme-react/data-grid";
import { LoadPanel } from 'devextreme-react/load-panel';
import {
    Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Attachment, Button, ButtonIcon, DatePicker, Dropdown, Label, Checkbox, Popover, TabContainer, TabHeader, TabItem, TabPanel,
    TextInput, Col, Row, FormGroup, NumberInput
} from 'diginet-core-ui/components';
import { makeStyles } from "diginet-core-ui/theme";
import _ from "lodash";
import PropTypes from 'prop-types';
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { browserHistory } from "react-router";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2010Actions from "../../../../redux/modules/WA3/WA3F2010/WA3F2010_actions";
import MForm from "../../../common/forms/form-material/form";
import GridActionBar from "../../../common/grid-container/grid-actionbar";
import GridContainer from "../../../common/grid-container/grid-container";
import ActionToolbar from "../../../common/layouts/toolbar/action-toolbar";
import History from "../../../common/libs/history/history";
import CDN from "../../../common/utils/CDN";
import BookingModal from "./BookingModal";
import ChoseCBModal from "../../../custom/ChoseCBModal";
import ExportExcelModal from "../../../custom/ExportReportModal";
import MenuModal from "./MenuModal";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "diginet-core-ui/theme";
import WA3F2031 from "../WA3F2031/WA3F2031";
import WA3F2022 from "../WA3F2022/WA3F2022";

const useStyles = makeStyles((theme) => ({
    moneyInput: {
        width: '100%',
        '& input': {
            textAlign: 'right'
        }
    },
    moneyInputColumn: {
        width: '100%',
        margin: 0,
        '& input': {
            textAlign: 'right'
        }
    },
    tabDetailPanel: {
        padding: '0px 24px',
        margin: theme.spacing([4, 6]),
        boxShadow: '0px 3px 4px rgb(0 0 0 / 20%)',
        borderRadius: 8,
        backgroundColor: 'white'
    },
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    btnAction: {
        marginRight: 6,
        textTransform: 'uppercase',
    },
    btnMore: {
        marginRight: 8,
        padding: '7px !important',
        '& span': {
            margin: '0px !important'
        }
    },
    btnActionItem: {
        display: 'block',
        width: '100%',
        textAlign: 'left',
        '&:hover': {
            backgroundColor: `${_.get(theme, "colors.hover", "")} !important`
        }
    }
}));

const FormID = "WA3F2010";
const attachmentType = ["txt", "jpg", "png", "doc", "docx", "xls", "xlsx", "jpeg", "pdf", "gif", "ppt", "pptx"];

const WA3F2010 = (props) => {
    const { spacing, colors } = useTheme();
    const dispatch = useDispatch();
    const dataCboReport = useSelector(state => state?.general?.dataCboReport ?? []);
    const Language = Config.language || "84";
    const UserID = _.get(Config, "profile.UserID", "");
    const DivisionID = Config.getLocalStorage("DIVISIONBEM") || _.get(Config.profile, "DivisionID", "");
    const classes = useStyles();
    const { location } = props;
    //==========STATE==========
    const [Mode, setMode] = useState(_.get(location, "state.Mode", "add"));
    const isModeAdd = Mode === "add";
    const [formLoading, setFormLoading] = useState(false);
    const [openMenuModal, setOpenMenuModal] = useState(false);
    const [openBookingModal, setOpenBookingModal] = useState(false);
    const [cboCreateUserLoading, setCboCreateUserLoading] = useState(false);
    const [btnSaveStatus, setBtnSaveStatus] = useState(false);
    const [openBtnMore, setOpenBtnMore] = useState(false);
    const [openModalWA3F2031, setOpenModalWA3F2031] = useState(false);
    const [openModalWA3F2022, setOpenModalWA3F2022] = useState(false);
    const [cboServiceLoading, setCboServiceLoading] = useState(false);
    const [contractNoStatus, setcontractNoStatus] = useState(Mode === "view" || Mode === "edit");
    const [tabIndex, setTabIndex] = useState(1);
    const [tabDetailIndex, setTabDetailIndex] = useState(1);
    const [error, setError] = useState({});
    const [dataCboCTKM, setDataCboCTKM] = useState([]);
    const [dataCboCreateUser, setDataCboCreateUser] = useState({
        rows: [],
        total: 0
    });
    const initialState = {
        ContractNo: isModeAdd ? _.get(location, "state.ContractNo", "") : "",
        ContractID: isModeAdd ? _.get(location, "state.ContractID", "") : "",
        ObjectID: isModeAdd ? _.get(location, "state.ObjectID", "") : "",
        ObjectName: isModeAdd ? _.get(location, "state.ObjectName", "") : "",
        EmployeeID: "",
        EmployeeName: "",
        Version: isModeAdd ? 1 : null,
        EventDate: null,
        OQTYTable: 0,
        VegesTableQTY: 0,
        GiftTableQTY: 0,
        EQTYTable: 0,
        OPreAmount: null,
        TotalAmount01: null,
        TotalAmount02: null,
        TotalAmount03: null,
        TotalAmount05: null,
        OTotalAmount: null,
        DiscountRate: null,
        DiscountAmount: null,
        OAmount: null,
        DepositAmount01: null,
        DepositAmount02: null,
        TotalDepositAmount: null,
        BEO: "",
        Status: "",
        EventID: "",
        Description: "",
        BookingID: "",
        HourFrom: "",
        HourTo: "",
        EventTypeID: "",
        EventTypeName: "",
        SetupName: "",
        BanquetName: "",
        EventName: "",
        Location: "",
        notes: "",
        BookingNo: "",
        BookingItemID: "",
        CurrencyID: isModeAdd ? _.get(location, "state.CurrencyID", "") : "",
        ExchangeRate: isModeAdd ? _.get(location, "state.ExchangeRate", "") : "",
        PromotionsID: "",
        PromotionsName: "",
        UnitPriceTable01: null,
        UnitPriceTable02: null
    };

    const initialMasterInDetail = {
        MenuID: "",
        MenuName: "",
        MenuType: "",
        PriceMenu: null,
    };
    const initialDetailState = {
        tab01: { // Tab01
            ...initialMasterInDetail,
            detail: []
        },
        tab02: { // Tab02
            ...initialMasterInDetail,
            detail: []
        },
        tab03: { // Tab03
            detail: []
        },
        tab04: { // Tab04
            detail: []
        },
        tab05: { // Tab05
            detail: []
        }
    };
    const [dataMaster, _setDataMaster] = useState(initialState);
    const [dataTabDetail, setDataTabDetail] = useState(initialDetailState);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);

    //=======REF==========
    const dataMasterRef = useRef(initialState);
    const OQTYTableRef = useRef(null);
    const VegesTableQTYRef = useRef(null);
    const GiftTableQTYRef = useRef(null);
    const EQTYTableRef = useRef(null);
    const isSetForm = useRef(false);
    const TableTimer = useRef(null);
    const timerCboCreateUser = useRef(null)
    const attRef = useRef(null);
    const saveSuccess = useRef(false);
    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const dataGrid01 = useRef(null);
    const dataGrid02 = useRef(null);
    const dataGrid03 = useRef(null);
    const dataGrid04 = useRef(null);
    const dataGrid05 = useRef(null);
    const selectedRowIndx = useRef(null);
    const btnMoreRef = useRef(null);
    const btnMoreAnchor = useRef(null);
    const exportExcelModalRef = useRef(null);
    const cbMenuModalRef = useRef(null);
    const uniqueKey = useRef(0);
    const countOrderNo01 = useRef(1);
    const countOrderNo02 = useRef(1);
    const countOrderNo03 = useRef(1);
    const countOrderNo04 = useRef(1);
    const countOrderNo05 = useRef(1);
    const objValueDefault = useRef({});
    const oldDataMaster = useRef({});
    const oldDataDetail = useRef([]);
    const oldDataAttachments = useRef([]);
    const dataInfo = useRef({
        iPermission: _.get(location, "state.Permission", null),
        EventID: _.get(location, "state.EventID", dataMaster.EventID),
        ContractID: _.get(location, "state.ContractID", ""),
        ContractNo: _.get(location, "state.ContractNo", ""),
        ObjectID: _.get(location, "state.ObjectID", ""),
        ObjectName: _.get(location, "state.ObjectName", ""),
        DataTypeID: _.get(location, "state.DataTypeID", ""),
        BookingID: _.get(location, "state.BookingID", dataMaster.BookingID),
        BatchID: _.get(location, "state.BatchID", ""),
        BEOCode: _.get(location, "state.BEOCode", ""),
        Version: _.get(location, "state.Version", dataMaster.Version),
    });
    const dataInfoWA3F2031 = useRef({});
    const filterCboCreateUser = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    //=======Variable==========
    const disabled = useMemo(() => {
        return formLoading || Mode === "view";
    }, [formLoading, Mode]);
    const menuTab1Status = useMemo(() => {
        return Mode === "edit" ? _.isEmpty(dataTabDetail.tab01?.MenuID) : Mode !== "view";
    }, [dataTabDetail.tab01, Mode]);
    const menuTab2Status = useMemo(() => {
        return Mode === "edit" ? _.isEmpty(dataTabDetail.tab02?.MenuID) : Mode !== "view";
    }, [dataTabDetail.tab02, Mode]);
    const btnActionStatus = useMemo(() => !(dataInfo.current.iPermission >= 2), []);
    const StoreParams = [
        { id: "DivisionID", type: "VarChar", value: DivisionID },
        { id: "DataType", type: "VarChar", value: "ExportReport" },
        { id: "Language", type: "VarChar", value: Language },
        { id: "FormID", type: "VarChar", value: FormID },
        { id: "UserID", type: "VarChar", value: UserID },
        { id: "ContractID", type: "VarChar", value: _.get(dataInfo.current, "ContractID", "") },
        { id: "BatchID", type: "VarChar", value: _.get(dataInfo.current, "BatchID", "") },
        { id: "EventID", type: "VarChar", value: _.get(dataInfo.current, "EventID", "") },
        { id: "Version", type: "TinyInt", value: _.get(dataInfo.current, "Version", null) },
        { id: "BEOCode", type: "VarChar", value: _.get(dataInfo.current, "BEOCode", "") },
        { id: "BEODate", type: "VarChar", value: "" }
    ];

    const setDataMaster = (obj = {}) => {
        dataMasterRef.current = { ...dataMasterRef.current, ...obj };
        _setDataMaster({ ...dataMasterRef.current });
    };

    const getTabIndexName = () => {
        let typeID = "";
        let tabName = "";
        let gridName = "";
        let totalAmountNum = "";
        let statusInven = 0;
        switch (tabDetailIndex) {
            case 1:
                tabName = "tab01";
                typeID = "MainMenu";
                totalAmountNum = "TotalAmount01";
                gridName = Config.lang("Thuc_don_chinh");
                break;
            case 2:
                tabName = "tab02";
                typeID = "ExtraMenu";
                totalAmountNum = "TotalAmount02";
                gridName = Config.lang("Thuc_don_phu");
                break;
            case 3:
                tabName = "tab03";
                typeID = "ExtraService";
                totalAmountNum = "TotalAmount03";
                gridName = Config.lang("Dich_vu_dat_them");
                statusInven = 1;
                break;
            case 5:
                tabName = "tab05";
                typeID = "PromotionService";
                totalAmountNum = "TotalAmount05";
                gridName = Config.lang("Dich_vu_khuyen_mai");
                statusInven = 1;
                break;
            default:
                break;
        }
        return { tabName, totalAmountNum, typeID, gridName, statusInven };
    };

    const { tabName, totalAmountNum, typeID, gridName, statusInven } = getTabIndexName();

    const getInfo = useCallback(() => {
        let status = true;
        const iPermission = _.get(location, "state.Permission", 0);
        if (iPermission > 0) {
            status = true;
        } else {
            browserHistory.push(Config.getRootPath() + "WA3F2000");
            return null;
        }
        return status;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getInfo()) {
            if (Mode !== "view") {
                loadCboCreateUser(); // Người lập
            }
            if (Mode === "add") {
                getBookingNo();
            } else {
                loadForm();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataMaster.BookingID && isModeAdd) loadDepositAmount01();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.BookingID, isModeAdd]);

    useEffect(() => {
        const setFormStatus = Mode === "edit" || Mode === "view" ? isSetForm.current : true;
        if ((dataMaster.BookingID || dataMaster.OQTYTable) && setFormStatus) loadCboCTKM();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.BookingID, dataMaster.OQTYTable]);

    useEffect(() => {
        const setFormStatus = Mode === "edit" || Mode === "view" ? isSetForm.current : true;
        if (dataMaster.PromotionsID && setFormStatus) loadPromotions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster?.PromotionsID]);

    useEffect(() => {
        dataInfo.current.EventID = dataMaster.EventID;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.EventID]);

    useEffect(() => {
        if (Mode === "view" && exportExcelModalRef.current && exportExcelModalRef.current.hasOwnProperty("loadCboReports")) {
            exportExcelModalRef.current.loadCboReports(); // Load
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Mode]);

    const setFormMasterLoading = (status) => {
        if (_.isBoolean(status)) setFormLoading(status);
    };

    const setDefaultValueGeneral = () => { // Dùng để set default value
        if (!_.isEmpty(objValueDefault.current)) {
            setDataMaster({ ...dataMasterRef.current, ...objValueDefault.current });
        }
    };

    const loadForm = async () => {
        const { ContractID, BatchID, Version, EventID } = dataInfo.current;
        const param = {
            DivisionID,
            FormID,
            ContractID,
            BatchID,
            EventID,
            Version
        };
        setFormLoading(true);
        dispatch(WA3F2010Actions.loadForm(param, (error, data) => {
            setFormLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const dataMasterForm = _.omit(data, ["detail", "attachments"]);
                if (dataMasterForm) {
                    const allMasterKeys = Object.keys(dataInfo.current);
                    const allDataFormKeys = Object.keys(dataMasterForm);
                    const allIncludeKey = allMasterKeys.filter((key1) => allDataFormKeys.some((key2) => key1 === key2));
                    if (allIncludeKey) allIncludeKey.forEach(name => dataInfo.current[name] = dataMasterForm[name]); // Set lại FormInfo
                }
                setDataMaster(dataMasterForm);
                oldDataMaster.current = { ...dataMasterForm };
                isSetForm.current = true;
                if (!_.isEmpty(data.detail)) {
                    let detailTab01 = data.detail.filter(item => item.TypeID === "MainMenu");
                    let detailTab02 = data.detail.filter(item => item.TypeID === "ExtraMenu");
                    let detailTab03 = data.detail.filter(item => item.TypeID === "ExtraService");
                    let detailTab04 = data.detail.filter(item => item.TypeID === "Decorate");
                    let detailTab05 = data.detail.filter(item => item.TypeID === "PromotionService");
                    if (!_.isEmpty(detailTab01)) {
                        detailTab01 = detailTab01.map(item => ({ ...item, "uniqueKey": uniqueKey.current++ }));
                        countOrderNo01.current = _.toNumber(_.maxBy(detailTab01, "OrderNo")["OrderNo"]) + 1 || 1;
                    }
                    if (!_.isEmpty(detailTab02)) {
                        detailTab02 = detailTab02.map(item => ({ ...item, "uniqueKey": uniqueKey.current++ }));
                        countOrderNo02.current = _.toNumber(_.maxBy(detailTab02, "OrderNo")["OrderNo"]) + 1 || 1;
                    }
                    if (!_.isEmpty(detailTab03)) {
                        detailTab03 = detailTab03.map(item => ({ ...item, "uniqueKey": uniqueKey.current++ }));
                        countOrderNo03.current = _.toNumber(_.maxBy(detailTab03, "OrderNo")["OrderNo"]) + 1 || 1;
                    }
                    if (!_.isEmpty(detailTab04)) {
                        detailTab04 = detailTab04.map(item => ({ ...item, "uniqueKey": uniqueKey.current++ }));
                        countOrderNo04.current = _.toNumber(_.maxBy(detailTab04, "OrderNo")["OrderNo"]) + 1 || 1;
                    }
                    if (!_.isEmpty(detailTab05)) {
                        detailTab05 = detailTab05.map(item => ({ ...item, "uniqueKey": uniqueKey.current++ }));
                        countOrderNo05.current = _.toNumber(_.maxBy(detailTab05, "OrderNo")["OrderNo"]) + 1 || 1;
                    }
                    oldDataDetail.current = [...detailTab01, ...detailTab02, ...detailTab03, ...detailTab04, ...detailTab05].map(item => ({ ...item }));
                    setDataTabDetail({
                        tab01: {
                            MenuID: _.get(detailTab01 ? detailTab01[0] : detailTab01, "MenuID", ""),
                            MenuName: _.get(detailTab01 ? detailTab01[0] : detailTab01, "MenuName", ""),
                            PriceMenu: _.get(detailTab01 ? detailTab01[0] : detailTab01, "PriceMenu", null),
                            detail: detailTab01
                        },
                        tab02: {
                            MenuID: _.get(detailTab02 ? detailTab02[0] : detailTab02, "MenuID", ""),
                            MenuName: _.get(detailTab02 ? detailTab02[0] : detailTab02, "MenuName", ""),
                            PriceMenu: _.get(detailTab02 ? detailTab02[0] : detailTab02, "PriceMenu", null),
                            detail: detailTab02
                        },
                        tab03: {
                            detail: detailTab03
                        },
                        tab04: {
                            detail: detailTab04
                        },
                        tab05: {
                            detail: detailTab05
                        },
                    });
                }
                if (!_.isEmpty(data.attachments)) {
                    oldDataAttachments.current = [...data.attachments];
                    setDataOldAttachments(data.attachments);
                }
            }
        })
        );
    };

    const getBookingNo = () => {
        const param = {
            ContractNo: _.get(dataMaster, "ContractNo", "")
        };
        dispatch(WA3F2010Actions.getBookingNo(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (dataInfo.current) dataInfo.current.EventID = _.get(data, "ID", "");
                objValueDefault.current = { ...objValueDefault.current, EventID: _.get(data, "ID", "") };
                setDefaultValueGeneral();
            }
        }));
    };

    const loadDepositAmount01 = () => {
        const { BookingID = "", BookingItemID = "" } = dataMasterRef.current;
        const param = {
            DivisionID,
            BookingID,
            BookingItemID
        };
        dispatch(WA3F2010Actions.loadDepositAmount01(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                const DepositAmount01 = data?.DepositAmount01 || null;
                setDataMaster({
                    ...dataMasterRef.current,
                    DepositAmount01,
                    TotalDepositAmount: DepositAmount01 + dataMaster.DepositAmount02
                });
            }
        }));
    };

    const loadPromotions = () => { // Load data lƯới 5
        const { PromotionsID = "" } = dataMasterRef.current;
        const param = {
            PromotionsID
        };
        dispatch(WA3F2010Actions.loadGridPromotionService(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                setDataTabDetail({
                    ...dataTabDetail,
                    tab05: { // master
                        detail: []
                    }
                });
                return false;
            } else if (data) {
                const totalOAmountData05 = data.reduce((currentValue, sum) => currentValue + sum.OAmount, 0);
                setDataMaster({ ...dataMasterRef.current, TotalAmount05: totalOAmountData05 });// Tính Tổng tiền tab 5
                setDataTabDetail({
                    ...dataTabDetail,
                    tab05: { // master
                        detail: data
                    }
                });
            }
        }));
    };

    const loadCboCreateUser = (isReset) => {
        const { skip, limit, search } = filterCboCreateUser.current;
        const { BookingID, ObjectID } = dataInfo.current;
        const param = {
            FormID,
            ObjectID,
            ObjectTypeID: "",
            BookingID,
            skip,
            limit,
            search
        };
        setCboCreateUserLoading(true);
        dispatch(generalActions.getCboCreateUser(param, (error, data) => {
            setCboCreateUserLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (isModeAdd) { // Default theo UserID
                    const dataEmployee = rows.find(item => item.UserID === _.get(Config.profile, "UserID", ""));
                    objValueDefault.current = { ...objValueDefault.current, EmployeeID: _.get(dataEmployee, "EmployeeID", "") };
                    setDefaultValueGeneral();
                }
                setDataCboCreateUser({
                    rows: isReset ? rows : dataCboCreateUser.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const loadCboCTKM = () => {
        const { EventTypeID, OQTYTable } = dataMaster;
        const param = {
            EventTypeID,
            OQTYTable
        };
        setCboServiceLoading(true);
        dispatch(WA3F2010Actions.loadCboCTKM(param, (error, data) => {
            setCboServiceLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataCboCTKM(data);
                if (data.length === 1) { // data Có 1 dòng thì default chọn luôn
                    setDataMaster({
                        ...dataMasterRef.current,
                        ...data[0]
                    });
                }
            }
        }));
    };

    const onCheckReceipt = (status) => {
        const { ContractID = "", ContractNo = "", EventID = "" } = dataMaster;
        const { BatchID = "" } = dataInfo.current;
        const param = {
            ContractID,
            ContractNo,
            EventID,
            BatchID
        };
        setFormLoading(true);
        dispatch(WA3F2010Actions.checkReceipt(param, (error, data) => {
            setFormLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (_.get(data, "Status", 1) === 0) { // OK
                    onCollectDeposit(status);
                    return;
                } else {
                    if (_.get(data, "MsgAsk", "") === 0) {
                        Config.popup.show("INFO", _.get(data, "Message", ""));
                        return false;
                    }
                    else if (_.get(data, "MsgAsk", "") === 1) {
                        Config.popup.show('YES_NO', _.get(data, "Message", ""), () => { // OK
                            onCollectDeposit(status);
                            return;
                        });
                    }
                }
            }
        }));
    };

    const onCheckBeo = (status) => {
        const { ContractID = "", ContractNo = "", EventID = "" } = dataMaster;
        const { BatchID = "" } = dataInfo.current;
        const param = {
            ContractID,
            ContractNo,
            EventID,
            BatchID
        };
        setFormLoading(true);
        dispatch(WA3F2010Actions.checkBeo(param, (error, data) => {
            setFormLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (_.get(data, "Status", 1) === 0) { // OK
                    onAddBEO(status);
                    return;
                } else {
                    if (_.get(data, "MsgAsk", "") === 0) {
                        Config.popup.show("INFO", _.get(data, "Message", ""));
                        return false;
                    }
                    else if (_.get(data, "MsgAsk", "") === 1) {
                        Config.popup.show('YES_NO', _.get(data, "Message", ""), () => { // OK
                            onAddBEO(status);
                            return;
                        });
                    }
                }
            }
        }));
    };

    const onChange = (fieldName, e) => {
        if (!e || _.isEmpty(fieldName)) return;
        let statusSetState = false;
        let value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        const { OPreAmount, TotalAmount05, TotalAmount01 } = dataMasterRef.current;
        let objectValuePlus = {};
        switch (fieldName) {
            //DropDown
            case "ContractTypeID":
            case "EStatus":
            case "EmployeeID":
            case "MembershipCardID":
            case "VATObjectTypeID":
            case "VATObjectID":
            case "PaymentTermID":
            case "PaymentMethodID":
            case "SalesPersonID":
            case "CurrencyID":
            case "ObjectID":
            case "PromotionsID":
                const positionID = fieldName.indexOf("ID");
                const standardDDName = positionID > 0 ? fieldName.slice(0, positionID) : fieldName;
                const DDName = standardDDName + "Name";
                const valueDDName = _.get(e.data, [DDName], _.get(e.data, "ObjectName", ""));
                objectValuePlus = { [DDName]: valueDDName };
                const { ObjectName = "", ObjectAddress = "", Position = "", ContactPerson = "", ContactTelNo = "",
                    ContactFaxNo = "", VATNo = "", ContractRep = "", Email = "", Auto = null } = _.get(e, "data", {});
                if (fieldName === "ContractTypeID") {
                    if (Auto === 0) { // Nhập
                        if (contractNoStatus) setcontractNoStatus(false);
                        objectValuePlus = { ...objectValuePlus, Auto };
                    } else { // Không Nhập
                        setcontractNoStatus(true);
                        objectValuePlus = { ...objectValuePlus, Auto, ContractNo: "" };
                    }
                } else if (fieldName === "ObjectID") { // Cbo Khách hàng
                    objectValuePlus = {
                        ...objectValuePlus,
                        ObjectName,
                        ObjectAddress,
                        Position,
                        ContactPerson,
                        ContactTelNo,
                        ContactFaxNo,
                        VATNo,
                        ContractRep,
                        Email,
                        VATObjectID: value,
                        MembershipCardID: value,
                        VATObjectName: ObjectName,
                    };
                } else if (fieldName === "SalesPersonID") { // Cbo NVKD
                    const SalesPersonName = _.get(e, "data.ObjectNameU", "");
                    objectValuePlus = {
                        ...objectValuePlus,
                        SalesPersonName
                    };
                } else if (fieldName === "VATObjectID") { // Cbo Mã đối tượng
                    objectValuePlus = {
                        ...objectValuePlus,
                        VATObjectName: ObjectName,
                        ObjectAddress,
                    };
                }
                statusSetState = true;
                break;
            //Text Input
            case "DiscountRate":
                const DiscountAmountCal = calPercent(TotalAmount01, value);
                objectValuePlus = {
                    ...objectValuePlus,
                    DiscountAmount: DiscountAmountCal,
                    OTotalAmount: OPreAmount - (TotalAmount05 + DiscountAmountCal)
                }
                statusSetState = true;
                break;
            default:
                break;
        }
        if (fieldName === "EventID" && dataInfo.current) dataInfo.current.EventID = value;
        objectValuePlus = { ...objectValuePlus, [fieldName]: value };
        if (dataMaster[fieldName] !== value && !_.isEmpty(objectValuePlus)) { // Không set state tránh giật
            Object.keys(objectValuePlus).forEach(key => {
                dataMaster[key] = objectValuePlus[key];
            });
        }
        if (statusSetState) { // Set State gây giật nhưng sẽ show value ngay lập tức
            setDataMaster({
                ...dataMasterRef.current,
                ...objectValuePlus
            });
        }
        if (error.hasOwnProperty(fieldName) && !Config.isEmpty(value, true)) {
            if (fieldName === "ContractTypeID" && _.get(e, "data.Auto", null) === 1) {
                setError(_.omit(error, [fieldName, "ContractNo"]));
            } else {
                setError(_.omit(error, fieldName));
            }
        }
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [...e.removedAttached];
            const _arrRemove = deletedFile.current.map(d => d.AttachmentID);
            setDataOldAttachments(
                dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            );
        }
    };

    const setStateErrorText = (objValue) => {
        setError({ ...error, ...objValue });
        return Object.keys(objValue).length !== 0;
    };

    const checkValidateMaster = (arrName) => {
        if (_.isEmpty(arrName)) return;
        return arrName.map(item => {
            return {
                name: item,
                rules: ["isRequired"],
                value: dataMaster[item]
            }
        });
    };

    const renderChooseColumn = (e) => {
        return <Checkbox
            readOnly={!(Mode === "edit")}
            style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}
            checked={_.get(e, "data.Choose", 0) === 1}
            onChange={(data) => {
                let value = data.value ? 1 : 0;
                const { UnitPrice = null } = e?.row?.data;
                if (value === 0 && !(UnitPrice > 0)) { // chưa có đơn giá và unCheck
                    Config.popup.show("INFO", Config.lang("Ban_phai_nhap_don_gia_truoc_khi_bo_chon_mon"), () => {
                        const allColumn = e.component.getVisibleColumns();
                        const unitPriceColIndex = allColumn.findIndex(item => item.dataField === "UnitPrice");
                        e.component.cellValue(e.rowIndex, "Choose", value);
                        if (UnitPrice === 0) e.component.cellValue(e.rowIndex, "UnitPrice", null);
                        if (unitPriceColIndex > -1) e.component.editCell(e.rowIndex, unitPriceColIndex);
                        onUpdateTotal(totalAmountNum); // Tính toán lại Tổng khi thay đổi
                    });
                } else {
                    e.component.cellValue(e.rowIndex, "Choose", value);
                    e.component.saveEditData();
                    onUpdateTotal(totalAmountNum); // Tính toán lại Tổng khi thay đổi
                }
            }}
        />;
    };

    const onSaveHistory = async (dataDetailNew = [], EventID = "", dataAttachmentCDN = []) => {
        let action = 0; // add hoac copy
        if (Mode === "edit") action = 1;
        const captions = {
            ObjectID: "Ma_khach_hang",
            ObjectName: "Ten_khach_hang",
            EmployeeName: "Nguoi_lap",
            EventID: "Ma_su_kien",
            Version: "Phien_ban",
            BEO: "Ma_phieu_BEO",
            BookingID: "Ma_booking",
            DiscountRate: "Giam_gia_thuc_don",
            TotalAmount01: "Tong_tien_thuc_don_chinh",
            TotalAmount02: "Tong_tien_thuc_don_phu",
            TotalAmount03: "Tong_tien_dich_vu_dat_them",
            OPreAmount: "Thanh_tien_truoc_GG_KM",
            TotalAmount05: "Tong_tien_khuyen_mai",
            DiscountAmount: "Tong_tien_giam_gia",
            OTotalAmount: "Thanh_tien_sau_GG_KM",
        };
        const captionDetail01 = { // GỒM TAB 1 VÀ TAB 2
            //Master
            MenuID: "Ma_menu",
            MenuName: "Ten_menu",
            PriceMenu: "Gia_menu",
            //Detail
            InventoryID: "Mon_an",
            InventoryName: "Ten_mon_an",
            UnitID: "DVT",
            Quantity: "So_luong",
            UnitPrice: "Don_gia",
            OAmount: "Thanh_tien",
            Description: "Ghi_chu"
        };
        const captionDetail02 = { // GỒM TAB 3 VÀ TAB 5
            InventoryID: "Ma_dich_vu",
            InventoryName: "Ten_san_pham_dich_vu",
            Quantity: "So_luong",
            UnitPrice: "Don_gia",
            OAmount: "Thanh_tien",
            Description: "Ghi_chu"
        };
        const captionDetail03 = { // GỒM TAB 4
            Description: "Noi_dung_trang_tri",
            Quantity: "So_luong",
            UnitPrice: "Don_gia",
            OAmount: "Thanh_tien",
        };
        const captionAttachment = {
            URL: "URL",
            FileName: "Ten_dinh_kem",
        };
        const newData = { ...dataMasterRef.current };
        const oldData = { ...oldDataMaster.current };
        const options = {
            data: newData,
            dataCompare: oldData,
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID: EventID ? EventID : _.get(dataInfo.current, "EventID", ""),
            TransactionName: Config.lang("Cap_nhat_su_kien"),
        };
        const history = new History(options);
        const dataDetailnew01 = dataDetailNew.filter(item => item.TypeID === "MainMenu" || item.TypeID === "ExtraMenu");
        const dataDetailOld01 = oldDataDetail.current.filter(item => item.TypeID === "MainMenu" || item.TypeID === "ExtraMenu");
        const dataDetailnew02 = dataDetailNew.filter(item => item.TypeID === "ExtraService" || item.TypeID === "PromotionService");
        const dataDetailOld02 = oldDataDetail.current.filter(item => item.TypeID === "ExtraService" || item.TypeID === "PromotionService");
        const dataDetailnew03 = dataDetailNew.filter(item => item.TypeID === "Decorate");
        const dataDetailOld03 = oldDataDetail.current.filter(item => item.TypeID === "Decorate");
        const attachment = [...dataAttachmentCDN, ...dataOldAttachments]; // Đính kèm mới
        const dataAttachmentOld = oldDataAttachments.current; // Đính kèm cũ
        history.createDetailHistory("Danh_sach_chi_tiet_thuc_don", dataDetailnew01, dataDetailOld01, captionDetail01, "uniqueKey", null, options);
        history.createDetailHistory("Danh_sach_chi_tiet_thuc_don", dataDetailnew02, dataDetailOld02, captionDetail02, "uniqueKey", null, options);
        history.createDetailHistory("Danh_sach_chi_tiet_thuc_don", dataDetailnew03, dataDetailOld03, captionDetail03, "uniqueKey", null, options);
        history.createDetailHistory("Dinh_kem", attachment, dataAttachmentOld, captionAttachment, "URL", null, options);
        // console.log("===========HIS=========>", history.get());
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const _getAttachments = (file) => {
        const dataFile = _.get(file, "data.paths", []);
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: _.get(att, "url", ""),
                FileName: _.get(att, "fileName", ""),
                FileSize: _.get(att, "fileSize", ""),
                FileExt: att.fileName ? att.fileName.split(".").pop() : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    const getFieldName = (keyName) => {
        let fieldName = "";
        switch (keyName) {
            case "EventID":
                fieldName = "Ma_su_kien";
                break;
            case "BookingNo":
                fieldName = "Ma_booking";
                break;
            default:
                break;
        }
        return fieldName;
    };

    const redirectTabChild = (indexGrid = null) => {
        if (tabIndex === 1) setTabIndex(2);
        if (_.isNumber(indexGrid)) setTabDetailIndex(indexGrid);
    };

    const redirectTabFather = (indexGrid = null) => {
        if (_.isNumber(indexGrid) && indexGrid !== tabIndex) setTabIndex(indexGrid);
    };

    const checkNegativeCondition = (allDetail = []) => {
        if (dataMaster?.TotalAmount03 < 0) { // Kiểm tra số âm Tổng Tiền Dịch Vụ Đặt Thêm
            Config.popup.show("YES_NO", Config.lang(`%${Config.lang("Tong_tien_dich_vu_dat_them")}%_dang_nho_hon_%${0}%_,ban_vui_long_kiem_tra_lai!`), () => { // YES
                redirectTabFather(2);
                return false;
            }, () => { // No đi tiếp
                onSave(allDetail);
            });
        } else if (onCheckNegativeTotal()) { // Kiểm tra số âm (Lưới)
            return false;
        } else {
            onSave(allDetail);
        }
    };

    const onSave = async (allDetail) => {
        let detail = [];
        let apiSave = "onSaveEdit";
        if (Mode === "add") {
            apiSave = "onSaveAdd";
            detail = JSON.stringify(allDetail);
        } else if (Mode === "edit") {
            const allDetailNewID = allDetail.map(inven => inven.uniqueKey);
            const addDetailOldID = oldDataDetail.current.map(inven => inven.uniqueKey);
            const removed = oldDataDetail.current.filter(item => allDetailNewID.indexOf(item.uniqueKey) < 0);
            const added = allDetail.filter(item => addDetailOldID.indexOf(item.uniqueKey) < 0);
            const edited = allDetail.filter(item => addDetailOldID.indexOf(item.uniqueKey) > -1);
            detail = JSON.stringify({
                added,
                edited,
                removed
            });
        }
        const dataResUploadFile = !_.isEmpty(attachments.current) ? await _uploadFile(attachments.current, true) : {};
        const attachment = JSON.stringify(_getAttachments(dataResUploadFile));
        const { ContractID } = dataMasterRef.current;
        const { BatchID } = dataInfo.current;
        const param = {
            DivisionID,
            ContractID,
            BatchID,
            ...dataMasterRef.current, // Master
            detail,
            attachment,
        };
        dispatch(WA3F2010Actions[apiSave](param, async (error, data) => {
            setFormLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (data.Status === 0) {
                    const EventID = _.get(data, "EventID", dataMaster.EventDate) || "";
                    const BatchID = _.get(data, "BatchID", dataMaster.BatchID) || "";
                    let dataAttachmentCDN = _.get(dataResUploadFile, "data.paths", []);
                    if (!_.isEmpty(dataAttachmentCDN)) {
                        dataAttachmentCDN = dataAttachmentCDN.map(item => ({
                            ...item,
                            URL: _.get(item, "url", ""),
                            FileName: _.get(item, "fileName", "")
                        }));
                    }
                    await onSaveHistory(allDetail, EventID, dataAttachmentCDN); // Lưu Lịch SỬ
                    Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    saveSuccess.current = true;
                    if (Mode === "add") {
                        dataInfo.current.EventID = EventID;
                        dataInfo.current.BatchID = BatchID;
                        oldDataMaster.current = dataMaster; // Lấy lịch sử cũ Khi thêm mới xong
                        setDataMaster({ ...dataMasterRef.current, EventID, BatchID });
                    }
                    setBtnSaveStatus(true);
                    setMode("view"); // Chuyển về View
                    if (Mode === "edit" || Mode === "add") loadForm();// LƯu Edit load lại Form
                } else {
                    Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                    return false;
                }
            }
        }));
    };

    const conditionRequireTab01Tab02 = (obj = {}) => {
        const { MenuCode = "", InventoryID = "", UnitID = "", Quantity = null, UnitPrice = null, Choose } = obj;
        let conditionMenuCode = _.isEmpty(MenuCode);
        if (Mode === "edit") conditionMenuCode = _.isEmpty(MenuCode) || Choose === 0;
        const quantityCondition = conditionMenuCode ? !(Quantity > 0) || !(UnitPrice > 0) : false;
        return _.isEmpty(InventoryID) || _.isEmpty(UnitID) || quantityCondition;
    };

    const onCheckNegativeTotal = () => { // Kiểm tra số âm
        const arrFieldName = ["TotalAmount01", "TotalAmount02", "TotalAmount03", "TotalAmount05", "OPreAmount", "DiscountAmount", "OTotalAmount"];
        const status = arrFieldName.findIndex(fieldName => dataMaster.hasOwnProperty(fieldName) && dataMasterRef.current[fieldName] < 0) > -1;
        if (status) {
            setTimeout(() => Config.popup.show("INFO", Config.lang(`Thanh_tien_dang_nho_hon_%${0}%_ban_vui_long_kiem_tra_lai!`)), 300);
            redirectTabChild(1);
        }
        return status;
    };

    const onCheckSave = async () => {
        let indexGrid = 1;
        let gridName = "Thuc_don_chinh";
        let requiredField = ["EventID", "BookingNo"];
        const resultValidate = checkValidateMaster(requiredField);
        const validateForm = MForm.formValidation(resultValidate);
        if (dataGrid01.current) await dataGrid01.current.instance.saveEditData();
        if (dataGrid02.current) await dataGrid02.current.instance.saveEditData();
        if (dataGrid03.current) await dataGrid03.current.instance.saveEditData();
        if (dataGrid04.current) await dataGrid04.current.instance.saveEditData();
        if (dataGrid05.current) await dataGrid05.current.instance.saveEditData();
        const { OQTYTable, VegesTableQTY } = dataMasterRef.current;
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            if (tabIndex === 2) setTabIndex(1);
            const fieldName = getFieldName(_.keys(validateForm)[0]);
            Config.popup.show("INFO", `${Config.lang("Ban_chua_nhap_gia_tri")}: ${Config.lang(fieldName)}`);
            return false;
        } else if (OQTYTable < 1 && VegesTableQTY < 1) {
            Config.popup.show("INFO", `${Config.lang("So_ban_chay_hoac_so_ban_chinh_phai_lon_hon")} 0`, () => {
                if (tabIndex !== 1) redirectTabFather(1); // Về trang 1 nhé
            });
            return false;
        } else if (!(OQTYTable < 1) && _.isEmpty(_.get(dataTabDetail, "tab01.detail", []))) { // Kiểm tra số bàn chính và lưới 1
            Config.popup.show("INFO", `${Config.lang("Ban_phai_nhap_du_lieu_luoi")} ${Config.lang(gridName).toLowerCase()}`, () => {
                redirectTabChild(indexGrid);
            });
            return false;
        } else if (!(VegesTableQTY < 1) && _.isEmpty(_.get(dataTabDetail, "tab02.detail", []))) { // Kiểm tra số bàn chay và lưới 2
            indexGrid = 2;
            gridName = "Thuc_don_phu";
            Config.popup.show("INFO", `${Config.lang("Ban_phai_nhap_du_lieu_luoi")} ${Config.lang(gridName).toLowerCase()}`, () => {
                redirectTabChild(indexGrid);
            });
            return false;
        } else if (!_.isEmpty(dataCboCTKM) && _.isEmpty(dataMaster?.PromotionsID)) { // Cbo CTKM có data mà chưa có chọn
            Config.popup.show("YES_NO", Config.lang(`Ban_chua_chon_%${Config.lang("Chuong_trinh_khuyen_mai_dich_vu").toLowerCase()}%_,ban_co_muon_tiep_tuc_khong?`), () => { // YES
                indexGrid = 5;
                redirectTabChild(indexGrid);
            }, () => { // Nhấn No đi tiếp
                onCheckRequiredGrid();
            });
        } else {
            onCheckRequiredGrid();
        }
    };

    const onCheckRequiredGrid = () => { // Kiểm tra các trường hợp Required trên lưới
        let indexGrid = 1;
        let gridName = "Thuc_don_chinh";
        let detailTab01 = _.get(dataTabDetail, "tab01.detail", []);
        let detailTab02 = _.get(dataTabDetail, "tab02.detail", []);
        const detailTab03 = _.get(dataTabDetail, "tab03.detail", []);
        const detailTab04 = _.get(dataTabDetail, "tab04.detail", []);
        const detailTab05 = _.get(dataTabDetail, "tab05.detail", []);
        if (!_.isEmpty(detailTab01)) detailTab01 = detailTab01.map(item => ({ ...item, ..._.omit(dataTabDetail.tab01, "detail") }));
        if (!_.isEmpty(detailTab02)) detailTab02 = detailTab02.map(item => ({ ...item, ..._.omit(dataTabDetail.tab02, "detail") }));
        const checkDetailRequire1 = !_.isEmpty(detailTab01) ? detailTab01.some(item => conditionRequireTab01Tab02(item)) : false;
        const checkDetailRequire2 = !_.isEmpty(detailTab02) ? detailTab02.some(item => conditionRequireTab01Tab02(item)) : false;
        const checkDetailRequire3 = !_.isEmpty(detailTab03) ? detailTab03.some(item => _.isEmpty(item.InventoryID) || _.isEmpty(item.UnitID) || !(item.Quantity > 0) || Config.isEmpty(item.UnitPrice, true)) : false;
        const checkDetailRequire4 = !_.isEmpty(detailTab04) ? detailTab04.some(item => _.isEmpty(item.Description)) : false;
        const checkDetailRequire5 = !_.isEmpty(detailTab05) ? detailTab05.some(item => _.isEmpty(item.InventoryID) || !(item.Quantity > 0) || Config.isEmpty(item.UnitPrice, true)) : false;
        if (checkDetailRequire1) {
            indexGrid = 1;
            gridName = "Thuc_don_chinh";
        } else if (checkDetailRequire2) {
            indexGrid = 2;
            gridName = "Thuc_don_phu";
        } else if (checkDetailRequire3) {
            indexGrid = 3;
            gridName = "Dich_vu_dat_them";
        } else if (checkDetailRequire4) {
            indexGrid = 4;
            gridName = "Trang_tri";
        } else if (checkDetailRequire5) {
            indexGrid = 5;
            gridName = "Dich_vu_khuyen_mai";
        }
        if (checkDetailRequire1 || checkDetailRequire2 || checkDetailRequire3 || checkDetailRequire4 || checkDetailRequire5) {
            Config.popup.show("INFO", `${Config.lang("Vui_long_nhap_day_du_thong_tin_tren_luoi")} ${Config.lang(gridName).toLowerCase()}`, () => {
                redirectTabChild(indexGrid);
            });
            return false;
        } else {
            const allDetail = [...detailTab01, ...detailTab02, ...detailTab03, ...detailTab04, ...detailTab05];
            if (_.isEmpty(detailTab03) || _.isEmpty(detailTab04)) {
                if (_.isEmpty(detailTab03)) {
                    indexGrid = 3;
                    gridName = "Dich_vu_dat_them";
                } else if (_.isEmpty(detailTab04)) {
                    indexGrid = 4;
                    gridName = "Trang_tri";
                }
                Config.popup.show("YES_NO", Config.lang("Ban_chua_nhap_thong_tin_dich_vu_dat_them_trang_tri_ban_co_muon_tiep_tuc_khong?"), () => { // YES
                    redirectTabChild(indexGrid);
                }, () => { // NO
                    checkNegativeCondition(allDetail);
                });
            } else {
                checkNegativeCondition(allDetail);
            }
        }
    };

    const onEdit = () => {
        const { ContractID, ContractNo, BatchID, EventID } = dataMasterRef.current;
        const param = {
            DivisionID,
            ContractID,
            ContractNo,
            EventID,
            BatchID
        };
        dispatch(WA3F2010Actions.checkEdit(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (data?.Status === 0) {
                    setMode("edit");
                    setBtnSaveStatus(false);
                } else {
                    Config.popup.show("INFO", _.get(data, "Message", ""));
                    return false;
                }
            }
        }));
    };

    // const onNext = useCallback(() => {
    //     let EmployeeID = "";
    //     setMode("add");
    //     setBtnSaveStatus(false);
    //     if (attRef.current) attRef.current.clear(); // clear all đính kèm
    //     if (!_.isEmpty(dataCboCreateUser?.rows)) { // Default theo UserID khi mode ADD
    //         EmployeeID = _.get(dataCboCreateUser.rows.find(item => item.UserID === _.get(Config.profile, "UserID", "")), "EmployeeID", "");
    //     }
    //     setDataMaster({ //Set default Master
    //         ...initialState,
    //         EmployeeID
    //     });
    //     setDataTabDetail(initialDetailState); //Set default Detail
    //     getBookingNo();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const onPrint = useCallback(() => {
        if (_.isArray(dataCboReport)) {
            if (dataCboReport.length === 0) {
                Config.popup.show("INFO", Config.lang("Ban_phai_thiet_lap_mau_bao_cao_truoc_khi_xuat_du_lieu"));
            } else if (dataCboReport.length === 1) { // 1 Mẫu
                if (exportExcelModalRef.current.hasOwnProperty("onExport")) exportExcelModalRef.current.onExport();
            } else { // Nhiều mẫu
                if (exportExcelModalRef.current && exportExcelModalRef.current.hasOwnProperty("onClickShow")) {
                    exportExcelModalRef.current.onClickShow();
                }
            }
        }
        if (btnMoreRef.current) btnMoreRef.current.close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboReport, exportExcelModalRef.current]);

    const getPlaceholder = (fieldName = "") => {
        //Show value trong placeholder khi mode === "view" vì không cần load Api combo
        let result = "";
        switch (fieldName) {
            case "ContractTypeID":
                result = disabled || Mode === "edit" ? dataMaster[fieldName] : Config.lang("Chon");
                break;
            default:
                result = disabled ? dataMaster[fieldName] : Config.lang("Chon");
                break;
        }
        return result;
    };

    const handleChangeTab = (index) => {
        if (_.isNumber(index)) setTabIndex(index);
    };

    const handleChangeTabDetail = async (index) => {
        switch (tabDetailIndex) {
            case 1:
                if (dataGrid01.current) await dataGrid01.current.instance.saveEditData();
                break;
            case 2:
                if (dataGrid02.current) await dataGrid02.current.instance.saveEditData();
                break;
            case 3:
                if (dataGrid03.current) await dataGrid03.current.instance.saveEditData();
                break;
            case 4:
                if (dataGrid04.current) await dataGrid04.current.instance.saveEditData();
                break;
            case 5:
                if (dataGrid05.current) await dataGrid05.current.instance.saveEditData();
                break;
            default:
                break;
        }
        if (_.isNumber(index)) setTabDetailIndex(index);
    };

    const onBack = () => {
        let status = false;
        const paramEvent = _.get(location, "state.paramEvent", {});
        const contractGrid = _.get(location, "state.contractGrid", {});
        const screenMaster = _.get(location, "state.screenMaster", "");
        if (!_.isEmpty(paramEvent) && screenMaster === "WA3F2000") status = true;
        Config.popup.show("YES_NO", Config.lang("Ban_co_muon_roi_khoi_trang_nay_khong?"), () => {
            if (status) {
                browserHistory.push({
                    pathname: Config.getRootPath() + "WA3F2000",
                    state: { paramEvent, contractGrid, permission: _.get(location, "state.Permission", dataInfo.current.iPermission) || null }
                });
            } else {
                browserHistory.goBack();
            }
        });
    };

    const onCollectDeposit = (status) => {
        if (_.isBoolean(status)) setOpenModalWA3F2022(status);
        if (btnMoreRef.current) btnMoreRef.current.close();
    };

    const updateOrderNo = () => {
        let result = null;
        switch (tabName) {
            case "tab01":
                result = countOrderNo01.current++;
                break;
            case "tab02":
                result = countOrderNo02.current++;
                break;
            default:
                break;
        }
        return result;
    };

    const onOpenModal = (modalName, status = false, data = {}) => {
        if (!_.isBoolean(status) || _.isEmpty(modalName)) return;
        const { TotalAmount03, TotalAmount05, DiscountRate } = dataMasterRef.current;
        let dataTab01Detail = _.get(dataTabDetail, "tab01.detail", []);
        let dataTab02Detail = _.get(dataTabDetail, "tab02.detail", []);
        switch (modalName) {
            case "Booking":
                setOpenBookingModal(status);
                break;
            case "Menu":
                setOpenMenuModal(status);
                break;
            default:
                break;
        }
        if (!_.isEmpty(data)) {
            if (modalName === "Booking") {
                let TotalAmount01Cal = dataMaster.TotalAmount01;
                let TotalAmount02Cal = dataMaster.TotalAmount02;
                let DiscountAmountCal = dataMaster.DiscountAmount;
                const { OQTYTable = 0, VegesTableQTY = 0 } = data;
                const PriceMenuTab01 = _.get(dataTabDetail, "tab01.PriceMenu", 0) || 0;
                const PriceMenuTab02 = _.get(dataTabDetail, "tab02.PriceMenu", 0) || 0;
                if (!_.isEmpty(dataTab01Detail)) {
                    if (OQTYTable > 0) {
                        dataTab01Detail.forEach(item => {
                            item.Quantity = OQTYTable;
                            item.OAmount = OQTYTable * item.UnitPrice;
                        });
                    } else {
                        dataTab01Detail = [];
                        dataTabDetail.tab01 = {
                            ...initialMasterInDetail,
                            detail: dataTab01Detail
                        };
                    }
                }
                if (!_.isEmpty(dataTab02Detail)) {
                    if (VegesTableQTY > 0) {
                        dataTab02Detail.forEach(item => {
                            item.Quantity = VegesTableQTY;
                            item.OAmount = VegesTableQTY * item.UnitPrice;
                        });
                    } else {
                        dataTab02Detail = [];
                        dataTabDetail.tab02 = {
                            ...initialMasterInDetail,
                            detail: dataTab02Detail
                        };
                    }
                }
                const oAmountData01 = dataTab01Detail.filter(item => item.OAmount);
                const totalOAmountData01 = oAmountData01.reduce((currentValue, sum) => currentValue + sum.OAmount, 0);
                const oAmountData02 = dataTab02Detail.filter(item => item.OAmount);
                const totalOAmountData02 = oAmountData02.reduce((currentValue, sum) => currentValue + sum.OAmount, 0);
                if (PriceMenuTab01 === 0) {
                    TotalAmount01Cal = totalOAmountData01;
                } else { // khác 0
                    TotalAmount01Cal = OQTYTable * PriceMenuTab01 + totalOAmountData01;
                }
                if (PriceMenuTab02 === 0) {
                    TotalAmount02Cal = totalOAmountData02;
                } else { // khác 0
                    TotalAmount02Cal = OQTYTable * PriceMenuTab02;
                }
                if (DiscountRate > 0) DiscountAmountCal = calPercent(TotalAmount01Cal, DiscountRate);
                const OPreAmountCal = TotalAmount01Cal + TotalAmount02Cal + TotalAmount03;
                if (dataInfo.current) dataInfo.current.BookingID = _.get(data, "BookingID", dataInfo.current.BookingID);
                setDataMaster({
                    ...dataMasterRef.current,
                    ...data,
                    TotalAmount01: TotalAmount01Cal,
                    DiscountAmount: DiscountAmountCal,
                    TotalAmount02: TotalAmount02Cal,
                    OPreAmount: OPreAmountCal,
                    OTotalAmount: OPreAmountCal - (TotalAmount05 + DiscountAmountCal),
                    UnitPriceTable01: TotalAmount01Cal / OQTYTable,
                    UnitPriceTable02: TotalAmount02Cal / VegesTableQTY,
                    Description: data?.BookingNo || ""
                });
                if (error.hasOwnProperty("BookingNo")) setError(_.omit(error, "BookingNo"));
            } else if (modalName === "Menu") {
                let tableValue = null;
                let detail = dataTabDetail[tabName].detail;
                let QuantityCol = null;
                let objectMaster = {};
                let filterDataDetail = _.get(data, "detail", []);
                if (!_.isEmpty(detail)) {
                    detail = detail.filter(item => !item.MenuCode); // remove những row có MenuCode
                    filterDataDetail = [...detail, ...filterDataDetail]
                }
                if (tabName === "tab01" || tabName === "tab02") {
                    objectMaster = {
                        ...objectMaster,
                        ...data
                    };
                }
                if (!_.isEmpty(filterDataDetail)) {
                    if (tabName === "tab01") {
                        countOrderNo01.current = 1; // Đếm lại STT
                        QuantityCol = tableValue ?? _.get(dataMaster, "OQTYTable", null);
                    }
                    if (tabName === "tab02") {
                        countOrderNo02.current = 1; // Đếm lại STT
                        QuantityCol = tableValue ?? _.get(dataMaster, "VegesTableQTY", null);
                    }
                    filterDataDetail.forEach(item => {
                        item.TypeID = typeID;
                        item.OrderNo = updateOrderNo();
                        item.Quantity = QuantityCol;
                        item.Choose = item.Choose ? 1 : 0;
                        item.OAmount = QuantityCol * item.UnitPrice;
                    });
                }
                const tabDetailData = { // master
                    ...dataTabDetail[tabName],
                    ..._.omit(objectMaster, "detail"),
                    detail: [ // detail
                        ...filterDataDetail
                    ]
                };
                onUpdateTotal(totalAmountNum, null, tabDetailData);
                setDataTabDetail({
                    ...dataTabDetail,
                    [tabName]: tabDetailData
                });
            }
        }
    };

    const refreshGrid = () => {
        switch (tabDetailIndex) {
            case 1:
                if (dataGrid01.current) dataGrid01.current.instance.refresh();
                break;
            case 2:
                if (dataGrid02.current) dataGrid02.current.instance.refresh();
                break;
            case 3:
                if (dataGrid03.current) dataGrid03.current.instance.refresh();
                break;
            case 4:
                if (dataGrid03.current) dataGrid04.current.instance.refresh();
                break;
            case 5:
                if (dataGrid05.current) dataGrid05.current.instance.refresh();
                break;
            default:
                break;
        }
    };

    const sortByOrder = () => {
        switch (tabDetailIndex) {
            case 1:
                _.get(dataTabDetail, "tab01.detail", []).forEach((item, idx) => item.OrderNo = idx + 1);
                countOrderNo01.current = _.get(dataTabDetail, "tab01.detail", []).length + 1;
                break;
            case 2:
                _.get(dataTabDetail, "tab02.detail", []).forEach((item, idx) => item.OrderNo = idx + 1);
                countOrderNo02.current = _.get(dataTabDetail, "tab02.detail", []).length + 1;
                break;
            case 3:
                _.get(dataTabDetail, "tab03.detail", []).forEach((item, idx) => item.OrderNo = idx + 1);
                countOrderNo03.current = _.get(dataTabDetail, "tab03.detail", []).length + 1;
                break;
            case 4:
                _.get(dataTabDetail, "tab04.detail", []).forEach((item, idx) => item.OrderNo = idx + 1);
                countOrderNo04.current = _.get(dataTabDetail, "tab04.detail", []).length + 1;
                break;
            case 5:
                _.get(dataTabDetail, "tab05.detail", []).forEach((item, idx) => item.OrderNo = idx + 1);
                countOrderNo05.current = _.get(dataTabDetail, "tab05.detail", []).length + 1;
                break;
            default:
                break;
        }
    };

    const saveEditDataGrid = async () => {
        switch (tabDetailIndex) {
            case 1:
                if (dataGrid01.current) await dataGrid01.current.instance.saveEditData();
                break;
            case 2:
                if (dataGrid02.current) await dataGrid02.current.instance.saveEditData();
                break;
            case 3:
                if (dataGrid03.current) await dataGrid03.current.instance.saveEditData();
                break;
            case 4:
                if (dataGrid04.current) await dataGrid04.current.instance.saveEditData();
                break;
            case 5:
                if (dataGrid05.current) await dataGrid05.current.instance.saveEditData();
                break;
            default:
                break;
        }
    };

    const onDelete = (e) => {
        if (!e) return;
        saveEditDataGrid();
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            let isDeleteAll = false;
            let objPlus = {};
            e.component.deleteRow(e.rowIndex);
            refreshGrid();// Refesh lại grid để lấy đúng data
            sortByOrder();// Sắp xếp lại STT
            if (tabName === "tab01" || tabName === "tab02") {
                objPlus = {
                    ...objPlus,
                    ...initialMasterInDetail
                };
                const dataFilter01 = _.get(dataTabDetail, "tab01.detail", []);
                const dataFilter02 = _.get(dataTabDetail, "tab02.detail", []);
                if ((tabName === "tab01" && _.isEmpty(dataFilter01)) || (tabName === "tab02" && _.isEmpty(dataFilter02))) {
                    if (tabName === "tab01") {
                        objPlus = {
                            ...objPlus,
                            DiscountRate: null,
                            DiscountAmount: null
                        };
                    }
                    isDeleteAll = true;
                    onUpdateTotal(objPlus);
                    setDataTabDetail({
                        ...dataTabDetail,
                        [tabName]: { // master
                            ...dataTabDetail[tabName],
                            ...initialMasterInDetail
                        }
                    });
                }
            }
            if (!isDeleteAll) onUpdateTotal(totalAmountNum);
        });
    };

    const renderActionGrid = (e) => {
        return (
            <GridActionBar >
                <ButtonIcon
                    circular
                    disabled={e?.row?.isNewRow || disabled}
                    name={"Delete"}
                    size={"medium"}
                    viewType={"text"}
                    onClick={() => onDelete(e)}
                    style={{ cursor: 'pointer' }} />
            </GridActionBar>
        );
    };

    const statusQuantityUnitPrice = (data = {}) => { // Điều kiện require cột Số lượng và đơn gía
        const { MenuCode = "", Choose = 0 } = data;
        return !_.isEmpty(MenuCode) && Choose === 1; //  Có Check
    };

    const renderNumberFormat = (e) => {
        if (!e) return false;
        const { column = {}, row = {}, column: { dataField = "" }, row: { data = {} } } = e;
        const decimalValue = data[dataField];
        const cellElement = e.component.getCellElement(row.rowIndex, column.dataField);
        const value = _.get(_.get(dataTabDetail[tabName], "detail", [])[e.rowIndex], dataField, "");
        if (cellElement && !(decimalValue > 0)) {
            if (!(row?.isNewRow === true)) {
                let isEmptyValue = _.isEmpty(value);
                if ((tabName === "tab03" || tabName === "tab05") && dataField === "UnitPrice") isEmptyValue = Config.isEmpty(value, true);
                if (isEmptyValue) {
                    if (dataField === "UnitPrice" || dataField === "Quantity") {
                        let status = statusQuantityUnitPrice(row.data);
                        if (!status) cellElement.style.backgroundColor = colors.danger2;
                    }
                }
            }
        }
        if (_.isUndefined(decimalValue)) return;
        return <NumberInput
            nonStyle
            readOnly={true}
            {...(dataField !== "Quanity" ? { thousandSeparator: "," } : {})}
            value={decimalValue}
            className={classes.moneyInputColumn}
        />
    };

    const calPercent = (number, percent) => {
        return number * (percent / 100);
    };

    const onUpdateTotal = (totalAmountNum = "", tableValue = null, dataGridTab = {}) => { // Không rõ thì đừng có đụng vô nguy hiểm lắm (Liên hệ Anh Tài nếu còn làm)
        if (["TotalAmount01", "TotalAmount02", "TotalAmount03", "TotalAmount05"].includes(totalAmountNum) || _.isObject(totalAmountNum)) {
            const { TotalAmount01, TotalAmount02, TotalAmount03, TotalAmount05,
                OQTYTable, VegesTableQTY, OPreAmount, OTotalAmount, DiscountRate, DiscountAmount } = dataMasterRef.current;
            let DiscountRateState = DiscountRate;
            let DiscountAmountState = DiscountAmount;
            let currentTab = "tab01";
            if (totalAmountNum === "TotalAmount02") currentTab = "tab02";
            let dataGridObjPlus = { ...dataTabDetail[currentTab] };
            let { PriceMenu = 0, DiscountRate: DiscountRateObj = 0, DiscountAmount: DiscountAmountObj = 0 } = _.isObject(totalAmountNum) ? totalAmountNum : _.get(dataTabDetail, currentTab, {});
            let PriceMenuState = PriceMenu;
            if (_.isObject(totalAmountNum)) {
                DiscountRateState = DiscountRateObj;
                DiscountAmountState = DiscountAmountObj;
            }
            if (_.isObject(totalAmountNum)) totalAmountNum = tabName === "tab01" ? "TotalAmount01" : "TotalAmount02";
            let DiscountAmountCal = DiscountAmountState;
            let TotalAmountCal = null;
            let table = tableValue ?? OQTYTable;
            let OPreAmountCal = OPreAmount;
            let OTotalAmountCal = OTotalAmount;
            let objectState = {};
            let dataGrid = !_.isEmpty(dataGridTab) ? dataGridTab.detail : getCurrentDataSource(totalAmountNum);
            if (totalAmountNum === "TotalAmount02") table = tableValue ?? VegesTableQTY;
            if (!_.isEmpty(dataGridTab)) PriceMenuState = dataGridTab?.PriceMenu;
            if (dataGrid) {
                if (tableValue === 0 && (totalAmountNum === "TotalAmount01" || totalAmountNum === "TotalAmount02")) {
                    dataGrid = [];
                    dataGridObjPlus = { ...dataGridObjPlus, ...initialMasterInDetail };
                } else {
                    if (!_.isNull(tableValue)) dataGrid = dataGrid.map(item => {
                        return {
                            ...item,
                            Quantity: tableValue,
                            OAmount: tableValue * item.UnitPrice
                        };
                    });
                }
                TotalAmountCal = dataGrid.reduce((currentValue, sum) => currentValue + sum.OAmount, 0); // SUm cột thành tiền
            }
            if (_.isEmpty(dataGrid)) {
                PriceMenuState = _.get(initialMasterInDetail, "PriceMenu", null);
                TotalAmountCal = _.get(initialState, totalAmountNum, null);
            }
            if (totalAmountNum === "TotalAmount01" || totalAmountNum === "TotalAmount02" ? PriceMenuState > 0 : false) {
                const menuCodeEmptySum = dataGrid.filter(item => _.isEmpty(item.MenuCode)).reduce((currentValue, sum) => currentValue + sum.OAmount, 0); // Những data MenuCode = trống
                const chooseUncheckSum = dataGrid.filter(item => item.Choose === 0).reduce((currentValue, sum) => currentValue + sum.OAmount, 0); // Nhưng data unCheck(Choose)
                TotalAmountCal = (table * PriceMenuState) + menuCodeEmptySum - chooseUncheckSum;
            }
            switch (totalAmountNum) {
                case "TotalAmount01":
                    if (_.isEmpty(dataGrid)) {
                        DiscountRateState = initialState?.DiscountRate ?? null;
                        DiscountAmountCal = initialState?.DiscountAmount ?? null;
                    } else {
                        DiscountAmountCal = calPercent(TotalAmountCal, DiscountRateState);
                    }
                    OPreAmountCal = TotalAmountCal + TotalAmount02 + TotalAmount03;
                    OTotalAmountCal = OPreAmountCal - TotalAmount05 - DiscountAmountCal;
                    objectState = {
                        ...objectState,
                        DiscountRate: DiscountRateState,
                        TotalAmount01: TotalAmountCal,
                        OPreAmount: OPreAmountCal,
                        DiscountAmount: DiscountAmountCal,
                        OTotalAmount: OTotalAmountCal,
                        UnitPriceTable01: TotalAmountCal / table
                    }
                    break;
                case "TotalAmount02":
                    OPreAmountCal = TotalAmount01 + TotalAmountCal + TotalAmount03;
                    OTotalAmountCal = OPreAmountCal - TotalAmount05 - DiscountAmountCal;
                    objectState = {
                        ...objectState,
                        TotalAmount02: TotalAmountCal,
                        OPreAmount: OPreAmountCal,
                        OTotalAmount: OTotalAmountCal,
                        UnitPriceTable02: TotalAmountCal / table
                    }
                    break;
                case "TotalAmount03":
                    OPreAmountCal = TotalAmount01 + TotalAmount02 + TotalAmountCal;
                    OTotalAmountCal = OPreAmountCal - TotalAmount05 - DiscountAmountCal;
                    objectState = {
                        ...objectState,
                        TotalAmount03: TotalAmountCal,
                        OPreAmount: OPreAmountCal,
                        OTotalAmount: OTotalAmountCal
                    };
                    break;
                case "TotalAmount05":
                    OTotalAmountCal = OPreAmountCal - TotalAmountCal - DiscountAmountCal;
                    objectState = {
                        ...objectState,
                        TotalAmount05: TotalAmountCal,
                        OTotalAmount: OTotalAmountCal
                    };
                    break;
                default:
                    break;
            }
            if (!_.isNull(tableValue)) {  // Có số bàn chính và số bàn chay
                let tabIdx = "tab01";
                let tableName = "OQTYTable";
                if (totalAmountNum === "TotalAmount02") {
                    tabIdx = "tab02";
                    tableName = "VegesTableQTY";
                }
                objectState = {
                    ...objectState,
                    [tableName]: tableValue
                };
                if (dataGrid) {
                    setDataTabDetail({
                        ...dataTabDetail,
                        [tabIdx]: {
                            ...dataGridObjPlus,// master
                            detail: dataGrid // detail
                        }
                    });
                }
            }
            setDataMaster({
                ...dataMasterRef.current,
                ...objectState
            });
        }
    };

    const onAddNewRow = async (index) => {
        await saveEditDataGrid();
        switch (index) {
            case 1:
                if (dataGrid01.current) setTimeout(() => dataGrid01.current.instance.addRow(), 0);
                break;
            case 2:
                if (dataGrid02.current) setTimeout(() => dataGrid02.current.instance.addRow(), 0);
                break;
            case 3:
                if (dataGrid03.current) setTimeout(() => dataGrid03.current.instance.addRow(), 0);
                break;
            case 4:
                if (dataGrid04.current) setTimeout(() => dataGrid04.current.instance.addRow(), 0);
                break;
            case 5:
                if (dataGrid05.current) setTimeout(() => dataGrid05.current.instance.addRow(), 0);
                break;
            default:
                break;
        }
    };

    const getCellData = (cellData) => {
        if (_.isEmpty(cellData)) return;
        let value = "";
        const dataField = _.get(cellData, "column.dataField", "");
        switch (dataField) {
            case "InventoryID":
                value = _.get(cellData, "data.InventoryID", "") || "";
                break;
            case "UnitID":
                value = _.get(cellData, "data.UnitID", "") || "";
                break;
            case "Quantity":
                value = _.get(cellData, "data.Quantity", "") ?? null;
                break;
            case "Description":
                value = _.get(cellData, "data.Description", "") ?? null;
                break;
            default:
                break;
        }
        return { value, dataField };
    };

    const getCurrentDataSource = (totalAmountNum = "") => {
        let dataSource = [];
        switch (totalAmountNum ? _.toNumber(totalAmountNum.slice(-1)) : tabDetailIndex) {
            case 1:
                if (dataGrid01.current) dataSource = dataGrid01.current.instance.option("dataSource");
                break;
            case 2:
                if (dataGrid02.current) dataSource = dataGrid02.current.instance.option("dataSource");
                break;
            case 3:
                if (dataGrid03.current) dataSource = dataGrid03.current.instance.option("dataSource");
                break;
            case 4:
                if (dataGrid04.current) dataSource = dataGrid04.current.instance.option("dataSource");
                break;
            case 5:
                if (dataGrid05.current) dataSource = dataGrid05.current.instance.option("dataSource");
                break;
            default:
                break;
        }
        return dataSource;
    };

    const ddPopupAction = (cellData) => {
        let aligns = "";
        const { column, row } = cellData;
        const { value, dataField } = getCellData(cellData);
        const cellElement = cellData.component.getCellElement(row.rowIndex, column.dataField);
        if (dataField === "Quantity") aligns = "jus-end";
        if (cellElement && _.isEmpty(value) && (dataField === "InventoryID" || dataField === "UnitID" || dataField === "Quantity" || dataField === "Description")) {
            if (!(row?.isNewRow === true)) {
                if (Config.isEmpty(_.get(_.get(dataTabDetail[tabName], "detail", [])[cellData.rowIndex], dataField, ""), true)) {
                    cellElement.style.backgroundColor = colors.danger2;
                }
            }
        }
        return (
            <div className={`display_row align-center ${aligns}`}>
                {(dataField === "InventoryID" || dataField === "UnitID") &&
                    <div className={"pull-left mgr5"}>
                        <ButtonIcon
                            circular
                            name={"Menu"}
                            size={"medium"}
                            viewType={"text"}
                            disabled={disabled}
                            onClick={() => {
                                saveEditDataGrid();
                                const dataSource = getCurrentDataSource();
                                const indexRow = dataSource.findIndex(obj => _.get(obj, "OrderNo", null) === _.get(cellData, "data.OrderNo", null));
                                selectedRowIndx.current = indexRow;
                                const positionID = dataField.indexOf("ID");
                                const standardDDName = positionID > 0 ? dataField.slice(0, positionID) : dataField;
                                const dataFieldName = standardDDName + "Name";
                                const dataRowKey = {
                                    dataField,
                                    dataFieldName,
                                    valueID: value
                                };
                                if (cbMenuModalRef.current?.hasOwnProperty("show")) cbMenuModalRef.current.show(true, dataRowKey); // Đóng Action nếu có mở
                            }}
                            style={{ cursor: 'pointer' }} />
                    </div>
                }
                <div className={`${classes.ellipsis} pull-left textCellHidden`}
                    title={value}>
                    {value}
                </div>
            </div>
        );
    };

    const rowInitOpenDD = () => { // Thêm dòng mở mở Popup chọn Menu luôn
        const data = {
            dataField: "InventoryID",
            dataFieldName: "InventoryName",
            isAddNew: true
        };
        selectedRowIndx.current = _.get(dataTabDetail[tabName], "detail", []).length;
        if (cbMenuModalRef.current?.hasOwnProperty("show")) cbMenuModalRef.current.show(true, data); // Có DD Column mở lên luôn
    };

    const forcusColumn = (dataGridRef, rowIdx) => { //focus vào ô trống ghi chọn xong pop column
        if (dataGridRef) {
            const dataSource = getCurrentDataSource();
            const row = dataSource[rowIdx];
            const allColumn = dataGridRef.instance.getVisibleColumns();
            const columnNameEdit = allColumn?.find(item => item?.allowEditing && Config.isEmpty(_.get(row, item?.dataField, ""), true)); // tìm cloumn edit gần nhất
            if (columnNameEdit) {
                const columnEditIndex = allColumn.findIndex(item => item.dataField === columnNameEdit.dataField);
                if (columnEditIndex > -1) dataGridRef.instance.editCell(rowIdx, columnEditIndex);
            }
        }
    };

    const onOpenComboModal = async (status, data = {}) => {
        if (!_.isBoolean(status) || Config.isEmpty(selectedRowIndx.current, true)) return;
        let dataGridRef = null;
        const rowIdx = selectedRowIndx.current;
        if (!_.isEmpty(data)) {
            const dataSource = getCurrentDataSource();
            const currentRow = dataSource?.[rowIdx];
            if (data.isAddNew) await saveEditDataGrid(); // Thêm mới đợi Insert data vào rồi mới thực hiện phép tính dưới
            const { InventoryID = "", InventoryName = "", UnitID = "", UnitPrice = null } = data || {};
            switch (tabName) {
                case "tab01":
                    setTimeout(() => {
                        if (data.hasOwnProperty("InventoryID")) {
                            const quantity = (!currentRow ? dataMaster?.OQTYTable : currentRow?.Quantity) ?? null;
                            dataGrid01.current.instance.cellValue(rowIdx, "InventoryID", InventoryID);
                            dataGrid01.current.instance.cellValue(rowIdx, "InventoryName", InventoryName);
                            dataGrid01.current.instance.cellValue(rowIdx, "UnitID", UnitID);
                            dataGrid01.current.instance.cellValue(rowIdx, "UnitPrice", UnitPrice);
                            dataGrid01.current.instance.cellValue(rowIdx, "OAmount", quantity * UnitPrice);
                        }
                    }, 300);
                    dataGridRef = dataGrid01.current;
                    dataGrid01.current.instance.saveEditData();
                    break;
                case "tab02":
                    setTimeout(() => {
                        if (data.hasOwnProperty("InventoryID")) {
                            const quantity = (!currentRow ? dataMaster?.VegesTableQTY : currentRow?.Quantity) ?? null;
                            dataGrid02.current.instance.cellValue(rowIdx, "InventoryID", InventoryID);
                            dataGrid02.current.instance.cellValue(rowIdx, "InventoryName", InventoryName);
                            dataGrid02.current.instance.cellValue(rowIdx, "UnitID", UnitID);
                            dataGrid02.current.instance.cellValue(rowIdx, "UnitPrice", UnitPrice);
                            dataGrid02.current.instance.cellValue(rowIdx, "OAmount", quantity * UnitPrice);
                        }
                    }, 300);
                    dataGridRef = dataGrid02.current;
                    dataGrid02.current.instance.saveEditData();
                    break;
                case "tab03":
                    setTimeout(() => {
                        const { Quantity = null, Discount = null } = currentRow || {};
                        dataGrid03.current.instance.cellValue(rowIdx, "InventoryID", InventoryID);
                        dataGrid03.current.instance.cellValue(rowIdx, "InventoryName", InventoryName);
                        dataGrid03.current.instance.cellValue(rowIdx, "UnitID", UnitID);
                        dataGrid03.current.instance.cellValue(rowIdx, "UnitPrice", UnitPrice);
                        if (!_.isEmpty(currentRow)) dataGrid03.current.instance.cellValue(rowIdx, "OAmount", (Quantity * UnitPrice) - Discount);
                    }, 300);
                    dataGridRef = dataGrid03.current;
                    dataGrid03.current.instance.saveEditData();
                    break;
                case "tab05":
                    setTimeout(() => {
                        dataGrid05.current.instance.cellValue(rowIdx, "InventoryID", data?.InventoryID || "");
                        dataGrid05.current.instance.cellValue(rowIdx, "InventoryName", data?.InventoryName || "");
                    }, 300);
                    dataGridRef = dataGrid05.current;
                    dataGrid05.current.instance.saveEditData();
                    break;
                default:
                    break;
            }
        }
        const dataSource = getCurrentDataSource();
        if (status === false && _.isEmpty(data?.InventoryID)) {
            if (!_.isEmpty(dataSource) && _.isEmpty(dataSource[rowIdx]?.InventoryID)) {
                setTimeout(() => dataGridRef.instance.deleteRow(getCurrentDataSource().length - 1), 300);
            }
        } else {
            setTimeout(() => forcusColumn(dataGridRef, rowIdx), 600);
        }
        if (cbMenuModalRef.current?.hasOwnProperty("show")) cbMenuModalRef.current.show(status);
    };

    const currentWidth = () => {
        let grid = "";
        const currentW = window.innerWidth;
        if (currentW >= 992) {
            grid = "lg";
        } else if (currentW >= 768) {
            grid = "md";
        } else if (currentW >= 576) {
            grid = "sm";
        } else {
            grid = "xs";
        }
        return grid;
    };

    const btnMoreAction = () => {
        setOpenBtnMore(true);
    };

    const onAddBEO = (status) => {
        if (_.isBoolean(status)) {
            if (status === true) {
                dataInfoWA3F2031.current = {
                    screen: "WA3F2010",
                    Mode: "add",
                    Permission: _.get(location, "state.Permission", 0),
                    ContractID: _.get(dataMaster, "ContractID", ""),
                    ContractNo: _.get(dataMaster, "ContractNo", ""),
                    BatchID: _.get(dataInfo.current, "BatchID", ""),
                    EventID: _.get(dataMaster, "EventID", ""),
                    EventName: _.get(dataMaster, "EventName", ""),
                    EventDate: _.get(dataMaster, "EventDate", null),
                    Version: _.get(dataMaster, "Version", null),
                };
            } else {
                dataInfoWA3F2031.current = {};
            }
            setOpenModalWA3F2031(status);
        }
    };

    const StayCalmBeforeTHeStorm = () => {
        return (
            <Popover
                ref={btnMoreRef}
                open={openBtnMore}
                anchor={btnMoreAnchor}
                fullScreen={false}
                anchorOrigin={{
                    horizontal: "left",
                    vertical: "bottom"
                }}
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
                transformOrigin={{
                    horizontal: "left",
                    vertical: "top"
                }}
                onClose={() => setOpenBtnMore(false)}
            >
                <div className={"wrap"}>
                    <Button
                        disabled={Mode !== "view" ? btnActionStatus || !btnSaveStatus : false}
                        className={classes.btnActionItem}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("Thu_tien_coc")}
                        onClick={() => onCheckReceipt(true)}
                    />
                    {/* <Button
                        disabled={btnActionStatus || Mode === "add" || !btnSaveStatus}
                        className={classes.btnActionItem}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("Nhap_tiep")}
                        onClick={onNext}
                    /> */}
                    <Button
                        disabled={Mode !== "view"}
                        className={classes.btnActionItem}
                        size={"medium"}
                        color={"primary"}
                        viewType={"text"}
                        label={Config.lang("In1")}
                        onClick={onPrint}
                    />
                </div>
            </Popover>
        )
    };

    const onUpdateTableCal = (TotalAmountNum, value = 0) => {
        if (_.isEmpty(TotalAmountNum)) return;
        clearTimeout(TableTimer.current); // load api lưới 5 khi thay đổi tránh gọi nhiều lần
        TableTimer.current = setTimeout(() => {
            onUpdateTotal(TotalAmountNum, value);
        }, 700);
    };

    const updateTableValueRef = (tableName = "", value = 0) => {
        if (tableName === "OQTYTable") {
            if (OQTYTableRef.current) OQTYTableRef.current.value = value;
        } else if (tableName === "VegesTableQTY") {
            if (VegesTableQTYRef.current) VegesTableQTYRef.current.value = value;
        } else if (tableName === "GiftTableQTY") {
            if (GiftTableQTYRef.current) GiftTableQTYRef.current.value = value;
        } else if (tableName === "EQTYTable") {
            if (EQTYTableRef.current) EQTYTableRef.current.value = value;
        }
    };

    const handleTableChange = (e, tableName = "") => {
        if (!e) return;
        const value = (e?.value ?? e?.target?.value) || 0;
        if (tableName === "VegesTableQTY" || tableName === "OQTYTable") {
            let tabName = Config.lang("Thuc_don_chinh");
            let TotalAmountNum = "TotalAmount01";
            let dataGrid = dataTabDetail?.tab01?.detail || [];
            if (tableName === "VegesTableQTY") {
                TotalAmountNum = "TotalAmount02";
                tabName = Config.lang("Thuc_don_phu");
                dataGrid = dataTabDetail?.tab02?.detail || [];
            }
            if (value === 0 && !_.isEmpty(dataGrid)) {
                Config.popup.show("YES_NO", Config.lang(`Du_lieu_tren_luoi_%${tabName.toLowerCase()}%_se_bi_xoa,_ban_co_muon_tiep_tuc_khong?`), () => {
                    onUpdateTableCal(TotalAmountNum, value);
                    updateTableValueRef(tableName, value);
                }, () => { // Nhấn No giữ lại giá trị cũ
                    updateTableValueRef(tableName, dataMasterRef.current[tableName]);
                });
            } else {
                if (value === 0) updateTableValueRef(tableName, value);
                onUpdateTableCal(TotalAmountNum, value);
            }
        } else {
            setDataMaster({ ...dataMasterRef.current, [tableName]: value });
            if (value === 0) {
                updateTableValueRef(tableName, value);
            }
        }
    };

    const NumberInputCol = (props) => <NumberInput
        nonStyle
        disabledNegative
        className={classes.moneyInputColumn}
        {...props}
    />;

    const renderGrid01 = useMemo(() => {
        return (
            <GridContainer
                reference={ref => dataGrid01.current = ref}
                height={336}
                typePaging={"normal"}
                pagerFullScreen={false}
                style={{ border: 'none', marginTop: spacing(4) }}
                filterRow={{
                    visible: !isModeAdd,
                    showOperationChooser: false,
                }}
                editing={{
                    mode: "cell",
                    refreshMode: "reshape",
                    allowUpdating: !disabled,
                    texts: {
                        confirmDeleteMessage: "",
                    },
                    startEditAction: "click",
                }}
                onRowUpdating={(e) => {
                    const { newData, oldData } = e;
                    const columnName = (Object.keys(newData))[0];
                    const actionColumn = ["Quantity", "UnitPrice"];
                    if (actionColumn.includes(columnName)) {
                        const Quantity = columnName === "Quantity" ? newData[columnName] : oldData.Quantity;
                        const UnitPrice = columnName === "UnitPrice" ? newData[columnName] : oldData.UnitPrice;
                        oldData.OAmount = Quantity * UnitPrice; // Cột Thành Tiền
                        onUpdateTotal("TotalAmount01");
                    }
                }}
                onInitNewRow={(eMethod) => {
                    eMethod.data.ContractID = "";
                    eMethod.data.InventoryID = "";
                    eMethod.data.InventoryName = "";
                    eMethod.data.MenuCode = "";
                    eMethod.data.Notes = "";
                    eMethod.data.UnitID = null;
                    eMethod.data.Quantity = _.get(dataMaster, "OQTYTable", null);
                    eMethod.data.UnitPrice = null;
                    eMethod.data.OAmount = null;
                    eMethod.data.ServiceNumber = null;
                    eMethod.data.Choose = 1;
                    rowInitOpenDD();
                }}
                onRowInserted={(e) => {
                    e.data["OrderNo"] = countOrderNo01.current++;
                    e.data["TypeID"] = "MainMenu";
                    refreshGrid();
                    setTimeout(() => onUpdateTotal("TotalAmount01"), 900); // Tính Tổng
                }}
                dataSource={_.get(dataTabDetail, "tab01.detail", [])}
            >
                <Column
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile && !disabled && Mode !== "edit"}
                    cellRender={renderActionGrid}
                />
                <Column
                    width={60}
                    allowEditing={false}
                    allowFiltering={false}
                    alignment={"center"}
                    dataField={"OrderNo"}
                    caption={Config.lang("STT")}
                />
                <Column
                    width={120}
                    allowEditing={false}
                    alignment={"center"}
                    dataField={"Choose"}
                    caption={Config.lang("Chot_mon")}
                    cellRender={renderChooseColumn}
                />
                <Column
                    width={200}
                    allowEditing={false}
                    caption={Config.lang("Mon_an")}
                    dataField={"InventoryID"}
                    cellRender={ddPopupAction}
                    headerCellRender={() =>
                    (<> {Config.lang("Mon_an")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                />
                <Column
                    width={360}
                    allowEditing={false}
                    caption={Config.lang("Ten_mon_an")}
                    dataField={"InventoryName"}
                />
                <Column
                    width={170}
                    allowEditing={false}
                    dataField={"UnitID"}
                    cellRender={ddPopupAction}
                    headerCellRender={() =>
                    (<> {Config.lang("DVT")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                />
                <Column
                    width={90}
                    alignment={"right"}
                    dataType={"number"}
                    dataField={"Quantity"}
                    cellRender={renderNumberFormat}
                    headerCellRender={() =>
                    (<> {Config.lang("So_luong")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                    editCellRender={(e) => {
                        const status = statusQuantityUnitPrice(e.data);
                        return (
                            <NumberInputCol
                                readOnly={status}
                                value={e.value}
                                onChange={(c) => e.setValue(c.value)}
                            />
                        )
                    }}
                />
                <Column
                    width={120}
                    alignment={"right"}
                    dataField={"UnitPrice"}
                    cellRender={renderNumberFormat}
                    headerCellRender={() =>
                    (<> {Config.lang("Don_gia")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                    editCellRender={(e) => {
                        const status = statusQuantityUnitPrice(e.data);
                        return (
                            <NumberInputCol
                                decimalDigit={8}
                                thousandSeparator={","}
                                readOnly={status}
                                value={e.value}
                                onChange={(c) => e.setValue(c.value)}
                            />
                        )
                    }}
                />
                <Column
                    width={180}
                    allowEditing={false}
                    alignment={"right"}
                    dataField={"OAmount"}
                    caption={Config.lang("Thanh_tien")}
                    cellRender={renderNumberFormat}
                />
                <Column
                    width={180}
                    alignment={"right"}
                    dataField={"ServiceNumber"}
                    caption={Config.lang("STT_len_mon")}
                    cellRender={renderNumberFormat}
                    editCellRender={(e) => {
                        return (
                            <NumberInputCol
                                value={e.value}
                                onChange={(c) => e.setValue(c.value)}
                            />
                        )
                    }}
                />
                <Column
                    width={220}
                    dataField={"Notes"}
                    caption={Config.lang("Ghi_chu")}
                />
                <Column
                    width={40}
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile && !disabled && Mode !== "edit"}
                    cellRender={renderActionGrid}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTabDetail?.tab01?.detail, tabDetailIndex, dataMaster?.OQTYTable, Mode]);

    const renderGrid02 = useMemo(() => {
        return (
            <GridContainer
                reference={ref => dataGrid02.current = ref}
                height={336}
                typePaging={"normal"}
                pagerFullScreen={false}
                style={{ border: 'none', marginTop: spacing(4) }}
                loading={formLoading}
                filterRow={{
                    visible: !isModeAdd,
                    showOperationChooser: false,
                }}
                editing={{
                    mode: "cell",
                    refreshMode: "reshape",
                    allowUpdating: !disabled,
                    texts: {
                        confirmDeleteMessage: "",
                    },
                    startEditAction: "click",
                }}
                onRowUpdating={(e) => {
                    const { newData, oldData } = e;
                    const columnName = (Object.keys(newData))[0];
                    const actionColumn = ["Quantity", "UnitPrice"];
                    if (actionColumn.includes(columnName)) {
                        const Quantity = columnName === "Quantity" ? newData[columnName] : oldData.Quantity;
                        const UnitPrice = columnName === "UnitPrice" ? newData[columnName] : oldData.UnitPrice;
                        oldData.OAmount = Quantity * UnitPrice;
                        onUpdateTotal("TotalAmount02");
                    }
                }}
                onInitNewRow={(eMethod) => {
                    eMethod.data.InventoryID = "";
                    eMethod.data.InventoryName = "";
                    eMethod.data.Notes = "";
                    eMethod.data.MenuCode = "";
                    eMethod.data.UnitID = null;
                    eMethod.data.Quantity = _.get(dataMaster, "VegesTableQTY", null);
                    eMethod.data.UnitPrice = null;
                    eMethod.data.OAmount = null;
                    eMethod.data.ServiceNumber = null;
                    eMethod.data.Choose = 1; // default check
                    rowInitOpenDD();
                }}
                onRowInserted={(e) => {
                    e.data["OrderNo"] = countOrderNo02.current++;
                    e.data["TypeID"] = "ExtraMenu";
                    refreshGrid();
                    setTimeout(() => onUpdateTotal("TotalAmount02"), 900); // Tính Tổng
                }}
                dataSource={_.get(dataTabDetail, "tab02.detail", [])}
            >
                <Column
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile && !disabled && Mode !== "edit"}
                    cellRender={renderActionGrid}
                />
                <Column
                    width={60}
                    allowEditing={false}
                    allowFiltering={false}
                    alignment={"center"}
                    dataField={"OrderNo"}
                    caption={Config.lang("STT")}
                />
                <Column
                    width={120}
                    allowEditing={false}
                    alignment={"center"}
                    dataField={"Choose"}
                    caption={Config.lang("Chot_mon")}
                    cellRender={renderChooseColumn}
                />
                <Column
                    width={200}
                    allowEditing={false}
                    caption={Config.lang("Mon_an")}
                    dataField={"InventoryID"}
                    cellRender={ddPopupAction}
                    headerCellRender={() =>
                    (<> {Config.lang("Mon_an")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                />
                <Column
                    width={360}
                    allowEditing={false}
                    caption={Config.lang("Ten_mon_an")}
                    dataField={"InventoryName"}
                />
                <Column
                    width={170}
                    allowEditing={false}
                    dataField={"UnitID"}
                    cellRender={ddPopupAction}
                    headerCellRender={() =>
                    (<> {Config.lang("DVT")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                />
                <Column
                    width={90}
                    alignment={"right"}
                    dataType={"number"}
                    dataField={"Quantity"}
                    cellRender={renderNumberFormat}
                    headerCellRender={() =>
                    (<> {Config.lang("So_luong")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                    editCellRender={(e) => {
                        const status = statusQuantityUnitPrice(e.data);
                        return (
                            <NumberInputCol
                                readOnly={status}
                                value={e.value}
                                onChange={(c) => e.setValue(c.value)}
                            />
                        )
                    }}
                />
                <Column
                    width={120}
                    alignment={"right"}
                    dataField={"UnitPrice"}
                    cellRender={renderNumberFormat}
                    headerCellRender={() =>
                    (<> {Config.lang("Don_gia")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                    editCellRender={(e) => {
                        const status = statusQuantityUnitPrice(e.data);
                        return (
                            <NumberInputCol
                                readOnly={status}
                                value={e.value}
                                decimalDigit={8}
                                thousandSeparator={","}
                                onChange={(c) => e.setValue(c.value)}
                            />
                        )
                    }}
                />
                <Column
                    width={180}
                    allowEditing={false}
                    alignment={"right"}
                    dataField={"OAmount"}
                    caption={Config.lang("Thanh_tien")}
                    cellRender={renderNumberFormat}
                />
                <Column
                    width={180}
                    alignment={"right"}
                    dataField={"ServiceNumber"}
                    caption={Config.lang("STT_len_mon")}
                    cellRender={renderNumberFormat}
                    editCellRender={(e) => {
                        return (
                            <NumberInputCol
                                value={e.value}
                                onChange={(c) => e.setValue(c.value)}
                            />
                        )
                    }}
                />
                <Column
                    width={220}
                    dataField={"Notes"}
                    caption={Config.lang("Ghi_chu")}
                />
                <Column
                    width={40}
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile && !disabled && Mode !== "edit"}
                    cellRender={renderActionGrid}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTabDetail?.tab02?.detail, tabDetailIndex, dataMaster?.VegesTableQTY, Mode]);

    const renderGrid03 = useMemo(() => {
        return (
            <GridContainer
                reference={ref => dataGrid03.current = ref}
                height={336}
                typePaging={"normal"}
                pagerFullScreen={false}
                style={{ border: 'none', marginTop: spacing(4) }}
                loading={formLoading}
                filterRow={{
                    visible: !isModeAdd,
                    showOperationChooser: false,
                }}
                editing={{
                    mode: "cell",
                    refreshMode: "reshape",
                    allowUpdating: !disabled,
                    texts: {
                        confirmDeleteMessage: "",
                    },
                    startEditAction: "click",
                }}
                onRowUpdating={(e) => {
                    const { newData, oldData } = e;
                    const columnName = (Object.keys(newData))[0];
                    const actionColumn = ["Quantity", "UnitPrice", "Discount"];
                    if (actionColumn.includes(columnName)) {
                        const Quantity = columnName === "Quantity" ? newData[columnName] : oldData.Quantity;
                        const UnitPrice = columnName === "UnitPrice" ? newData[columnName] : oldData.UnitPrice;
                        const Discount = columnName === "Discount" ? newData[columnName] : oldData.Discount;
                        oldData.OAmount = (Quantity * UnitPrice) - Discount;
                        onUpdateTotal("TotalAmount03");
                    }
                }}
                onInitNewRow={(eMethod) => {
                    eMethod.data.InventoryID = "";
                    eMethod.data.InventoryName = "";
                    eMethod.data.Description = "";
                    eMethod.data.Quantity = null;
                    eMethod.data.UnitPrice = null;
                    eMethod.data.OAmount = null;
                    eMethod.data.Discount = null;
                    rowInitOpenDD();
                }}
                onRowInserted={(e) => {
                    e.data["OrderNo"] = countOrderNo03.current++;
                    e.data["TypeID"] = "ExtraService";
                    refreshGrid();
                    setTimeout(() => onUpdateTotal("TotalAmount03"), 900); // Tính Tổng
                }}
                dataSource={_.get(dataTabDetail, "tab03.detail", [])}
            >
                <Column
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile && !disabled}
                    cellRender={renderActionGrid}
                />
                <Column
                    width={60}
                    allowEditing={false}
                    allowFiltering={false}
                    alignment={"center"}
                    dataField={"OrderNo"}
                    caption={Config.lang("STT")}
                />
                <Column
                    width={200}
                    allowEditing={false}
                    caption={Config.lang("Ma_dich_vu")}
                    dataField={"InventoryID"}
                    cellRender={ddPopupAction}
                    headerCellRender={() =>
                    (<> {Config.lang("Ma_dich_vu")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                >
                </Column>
                <Column
                    width={260}
                    allowEditing={false}
                    caption={Config.lang("Ten_san_pham_dich_vu")}
                    dataField={"InventoryName"}
                />
                <Column
                    width={170}
                    allowEditing={false}
                    dataField={"UnitID"}
                    cellRender={ddPopupAction}
                    headerCellRender={() =>
                    (<> {Config.lang("DVT")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                />
                <Column
                    width={90}
                    alignment={"right"}
                    dataType={"number"}
                    dataField={"Quantity"}
                    cellRender={ddPopupAction}
                    headerCellRender={() =>
                    (<> {Config.lang("So_luong")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                />
                <Column
                    width={120}
                    alignment={"right"}
                    dataField={"UnitPrice"}
                    cellRender={renderNumberFormat}
                    headerCellRender={() =>
                    (<> {Config.lang("Don_gia")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                    editCellRender={(e) => (
                        <NumberInputCol
                            decimalDigit={8}
                            thousandSeparator={","}
                            value={e.value}
                            onChange={(c) => e.setValue(c.value)}
                        />
                    )}
                />
                <Column
                    width={120}
                    alignment={"right"}
                    dataField={"Discount"}
                    cellRender={renderNumberFormat}
                    caption={Config.lang("Giam_gia")}
                    editCellRender={(e) => (
                        <NumberInputCol
                            decimalDigit={8}
                            thousandSeparator={","}
                            value={e.value}
                            onChange={(c) => e.setValue(c.value)}
                        />
                    )}
                />
                <Column
                    width={180}
                    allowEditing={false}
                    alignment={"right"}
                    dataField={"OAmount"}
                    caption={Config.lang("Thanh_tien")}
                    cellRender={renderNumberFormat}
                />
                <Column
                    width={280}
                    caption={Config.lang("Ghi_chu")}
                    dataField={"Description"}
                />
                <Column
                    width={40}
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile && !disabled}
                    cellRender={renderActionGrid}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTabDetail?.tab03?.detail, tabDetailIndex, Mode]);

    const renderGrid04 = useMemo(() => {
        return (
            <GridContainer
                reference={ref => dataGrid04.current = ref}
                height={336}
                typePaging={"normal"}
                pagerFullScreen={false}
                style={{ border: 'none', marginTop: spacing(4) }}
                loading={formLoading}
                filterRow={{
                    visible: !isModeAdd,
                    showOperationChooser: false,
                }}
                editing={{
                    mode: "cell",
                    refreshMode: "reshape",
                    allowUpdating: !disabled,
                    texts: {
                        confirmDeleteMessage: "",
                    },
                    startEditAction: "click",
                }}
                onInitNewRow={(eMethod) => {
                    eMethod.data.Description = "";
                }}
                onRowInserted={(e) => {
                    e.data["OrderNo"] = countOrderNo04.current++;
                    e.data["TypeID"] = "Decorate";
                    refreshGrid();
                }}
                dataSource={_.get(dataTabDetail, "tab04.detail", [])}
            >
                <Column
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile && !disabled}
                    cellRender={renderActionGrid}
                />
                <Column
                    width={60}
                    allowEditing={false}
                    allowFiltering={false}
                    alignment={"center"}
                    dataField={"OrderNo"}
                    caption={Config.lang("STT")}
                />
                <Column
                    width={740}
                    dataField={"Description"}
                    headerCellRender={() =>
                    (<> {Config.lang("Noi_dung_trang_tri")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                    cellRender={ddPopupAction}
                />
                <Column
                    width={40}
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile && !disabled}
                    cellRender={renderActionGrid}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTabDetail?.tab04?.detail, tabDetailIndex, Mode]);

    const renderGrid05 = useMemo(() => {
        return (
            <GridContainer
                reference={ref => dataGrid05.current = ref}
                height={336}
                typePaging={"normal"}
                pagerFullScreen={false}
                style={{ border: 'none', marginTop: spacing(4) }}
                loading={formLoading}
                filterRow={{
                    visible: !isModeAdd,
                    showOperationChooser: false,
                }}
                editing={{
                    mode: "cell",
                    refreshMode: "reshape",
                    allowUpdating: !disabled,
                    texts: {
                        confirmDeleteMessage: "",
                    },
                    startEditAction: "click",
                }}
                onInitNewRow={(eMethod) => {
                    eMethod.data.InventoryID = "";
                    eMethod.data.InventoryName = "";
                    eMethod.data.Description = "";
                    eMethod.data.Quantity = null;
                    eMethod.data.UnitPrice = null;
                    eMethod.data.OAmount = null;
                    rowInitOpenDD();
                }}
                onRowInserted={(e) => {
                    e.data["OrderNo"] = countOrderNo05.current++;
                    e.data["TypeID"] = "PromotionService";
                    refreshGrid();
                    setTimeout(() => onUpdateTotal("TotalAmount05"), 900); // Tính Tổng
                }}
                onRowUpdating={(e) => {
                    const { newData, oldData } = e;
                    const columnName = (Object.keys(newData))[0];
                    const actionColumn = ["Quantity", "UnitPrice"];
                    if (actionColumn.includes(columnName)) {
                        const Quantity = columnName === "Quantity" ? newData[columnName] : oldData.Quantity;
                        const UnitPrice = columnName === "UnitPrice" ? newData[columnName] : oldData.UnitPrice;
                        oldData.OAmount = Quantity * UnitPrice;
                        onUpdateTotal("TotalAmount05");
                    }
                }}
                dataSource={_.get(dataTabDetail, "tab05.detail", [])}
            >
                <Column
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    caption={Config.lang("Hanh_dong")}
                    visible={Config.isMobile && !disabled}
                    cellRender={renderActionGrid}
                />
                <Column
                    width={60}
                    allowEditing={false}
                    allowFiltering={false}
                    alignment={"center"}
                    dataField={"OrderNo"}
                    caption={Config.lang("STT")}
                />
                <Column
                    width={200}
                    allowEditing={false}
                    caption={Config.lang("Ma_dich_vu")}
                    dataField={"InventoryID"}
                    cellRender={ddPopupAction}
                    headerCellRender={() =>
                    (<> {Config.lang("Ma_dich_vu")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                />
                <Column
                    width={260}
                    allowEditing={false}
                    caption={Config.lang("Ten_san_pham_dich_vu")}
                    dataField={"InventoryName"}
                />
                <Column
                    width={90}
                    alignment={"right"}
                    dataType={"number"}
                    dataField={"Quantity"}
                    cellRender={ddPopupAction}
                    headerCellRender={() =>
                    (<> {Config.lang("So_luong")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                    editCellRender={(e) => {
                        const status = e?.data?.IsEdit === 0; // Load từ Cbo CTKM
                        return (
                            <NumberInputCol
                                value={e.value}
                                readOnly={status}
                                onChange={(c) => e.setValue(c.value)}
                            />
                        )
                    }}
                />
                <Column
                    width={120}
                    alignment={"right"}
                    dataField={"UnitPrice"}
                    cellRender={renderNumberFormat}
                    headerCellRender={() =>
                    (<> {Config.lang("Don_gia")}
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </>)}
                    editCellRender={(e) => {
                        const status = e?.data?.IsEdit === 0; // Load từ Cbo CTKM
                        return (
                            <NumberInputCol
                                value={e.value}
                                readOnly={status}
                                decimalDigit={8}
                                thousandSeparator={","}
                                onChange={(c) => e.setValue(c.value)}
                            />
                        )
                    }}
                />
                <Column
                    width={180}
                    allowEditing={false}
                    alignment={"right"}
                    dataField={"OAmount"}
                    caption={Config.lang("Thanh_tien")}
                    cellRender={renderNumberFormat}
                />
                <Column
                    width={280}
                    caption={Config.lang("Ghi_chu")}
                    dataField={"Description"}
                />
                <Column
                    width={40}
                    fixed={true}
                    allowEditing={false}
                    alignment={"right"}
                    fixedPosition={"right"}
                    visible={!Config.isMobile && !disabled}
                    cellRender={renderActionGrid}
                />
            </GridContainer>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTabDetail?.tab05?.detail, tabDetailIndex, Mode]);

    if (!dataInfo.current.iPermission) return null;
    return (
        <React.Fragment>
            <LoadPanel
                position={{ my: 'center', of: '#root' }}
                visible={formLoading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
            {openModalWA3F2022 &&
                <WA3F2022
                    openModal={openModalWA3F2022}
                    dataInfo={{ ...dataInfo.current, Mode: "add" }}
                    onOpenModalAction={(status, reloadPage = false) => {
                        onCheckReceipt(status);
                        if (reloadPage === true) loadForm();
                    }}
                />
            }
            {openBookingModal &&
                <BookingModal
                    FormID={"WA3F2010"}
                    currentSelect={dataMaster.BookingNo}
                    openModal={openBookingModal}
                    dataInfo={dataInfo.current}
                    onOpenModalAction={(status, data) => onOpenModal("Booking", status, data)}
                />
            }
            {openMenuModal &&
                <MenuModal
                    FormID={"WA3F2010"}
                    gridName={gridName}
                    openModal={openMenuModal}
                    dataInfo={dataInfo.current}
                    currentMenuData={{
                        ...dataTabDetail[tabName],
                        detail: dataTabDetail[tabName].detail
                    }}
                    onOpenModalAction={(status, data) => onOpenModal("Menu", status, data)}
                />
            }
            <CBMenuModal
                ref={ref => cbMenuModalRef.current = ref}
                dataInfo={dataInfo.current}
                statusInven={statusInven}
                onOpenComboModal={(status, data) => {
                    onOpenComboModal(status, data);
                }}
            />
            {openModalWA3F2031 &&
                <WA3F2031
                    openModal={openModalWA3F2031}
                    dataInfo={dataInfoWA3F2031.current}
                    onOpenModalAction={(status, reLoad) => {
                        onCheckBeo(status);
                        if (reLoad === true) loadForm();
                    }}
                />
            }
            <ExportExcelModal
                ref={exportExcelModalRef}
                IsDefault={0}
                ModuleID={"A3"}
                FormID={"WA3F2010"}
                label={Config.lang("Xuat_du_lieu")}
                disabled={formLoading}
                dataInfo={dataInfo.current}
                ReportTypeID={"WA3F2030"}
                StoreParams={StoreParams}
                setFormMasterLoading={(status) => setFormMasterLoading(status)}
            />
            <ActionToolbar title={Config.lang("Cap_nhat_su_kien")} onBack={onBack}>
                <div className={"wrap"} style={{ width: currentWidth() === 'lg' ? '100%' : window.innerWidth }}>
                    {Mode === "view" ?
                        <Button
                            disabled={btnActionStatus || formLoading}
                            className={classes.btnAction}
                            size={"medium"}
                            color={"primary"}
                            startIcon={"Edit"}
                            viewType={"outlined"}
                            label={Config.lang("Sua")}
                            onClick={onEdit}
                        />
                        : <Button
                            disabled={btnActionStatus || btnSaveStatus || formLoading}
                            className={classes.btnAction}
                            size={"medium"}
                            color={"info"}
                            startIcon={"Save"}
                            viewType={"filled"}
                            label={Config.lang("Luu")}
                            onClick={onCheckSave}
                        />
                    }
                    <Button
                        ref={btnMoreAnchor}
                        className={classes.btnMore}
                        size={"medium"}
                        color={"primary"}
                        startIcon={"More"}
                        viewType={"outlined"}
                        onClick={btnMoreAction}
                    />
                    {StayCalmBeforeTHeStorm()}
                    <Button
                        disabled={btnActionStatus || Mode === "view" ? !(Config.isEmpty(dataMaster?.StatusBEO, true) || Number.parseInt(dataMaster?.StatusBEO) === 9) : true} //  "9" = "Hủy"
                        style={{ marginLeft: currentWidth() === 'lg' ? 'auto' : 'initial' }}
                        size={"medium"}
                        color={"success"}
                        startIcon={"FastForward"}
                        viewType={"filled"}
                        label={Config.lang("Duyet_phieu_BEO")}
                        onClick={() => onCheckBeo(true)}
                    />
                </div>
            </ActionToolbar>
            <TabContainer tabIndex={tabIndex} level={"level1"}>
                <TabHeader>
                    <TabItem
                        index={1}
                        label={Config.lang("Thong_tin_chung")}
                        onClick={() => handleChangeTab(1)}
                    />
                    <TabItem
                        index={2}
                        label={Config.lang("Chi_tiet")}
                        onClick={() => handleChangeTab(2)}
                    />
                </TabHeader>
                <TabPanel index={1}>
                    <AccordionGroup>
                        <Accordion expand={true}>
                            <AccordionSummary>
                                {Config.lang("Thong_tin_khach_hang")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("So_hop_Dong")}
                                            value={dataMaster?.ContractNo}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Ma_khach_hang")}
                                            value={dataMaster?.ObjectID}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Ten_khach_hang")}
                                            value={dataMaster?.ObjectName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            readOnly={disabled || Mode === "edit" || Mode === "view"}
                                            dataSource={_.get(dataCboCreateUser, "rows", [])}
                                            total={_.get(dataCboCreateUser, "total", 0)}
                                            skip={filterCboCreateUser.current.skip}
                                            limit={filterCboCreateUser.current.limit}
                                            value={dataMaster?.EmployeeID}
                                            loading={cboCreateUserLoading}
                                            valueExpr={"EmployeeID"}
                                            keyExpr={"EmployeeName"}
                                            displayExpr={"EmployeeName"}
                                            valueObjectDefault={{
                                                EmployeeID: dataMaster.EmployeeID,
                                                EmployeeName: _.get(dataMaster, "EmployeeName", "")
                                            }}
                                            label={Config.lang("Nguoi_lap")}
                                            placeholder={getPlaceholder("EmployeeID")}
                                            onChange={(e) => onChange("EmployeeID", e)}
                                            onLoadMore={(e) => {
                                                filterCboCreateUser.current.skip = e.skip;
                                                filterCboCreateUser.current.limit = e.limit;
                                                loadCboCreateUser();
                                            }}
                                            onInput={(e) => {
                                                const value = e?.target?.value;
                                                if (timerCboCreateUser.current) clearTimeout(timerCboCreateUser.current);
                                                timerCboCreateUser.current = setTimeout(() => {
                                                    filterCboCreateUser.current.search = value;
                                                    filterCboCreateUser.current.skip = 0;
                                                    loadCboCreateUser(true);
                                                }, 700);
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            required={true}
                                            readOnly={disabled}
                                            error={error && error["EventID"]}
                                            label={Config.lang("Ma_su_kien")}
                                            onChange={(e) => onChange("EventID", e)}
                                            value={dataMaster?.EventID}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            type={"number"}
                                            label={Config.lang("Phien_ban")}
                                            value={dataMaster?.Version}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Ma_phieu_BEO")}
                                            value={dataMaster?.BEO}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Trang_thai_BEO")}
                                            value={dataMaster?.Status}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Dien_giai")}
                                            onChange={(e) => onChange("Description", e)}
                                            value={dataMaster?.Description}
                                        />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                    <AccordionGroup>
                        <Accordion expand={true}>
                            <AccordionSummary>
                                {Config.lang("Thong_tin_to_chuc_su_kien")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            required={true}
                                            endIcon={"ArrowDown"}
                                            error={error && error["BookingNo"]}
                                            placeholder={getPlaceholder("BookingNo")}
                                            style={{ cursor: 'pointer' }}
                                            label={Config.lang("Ma_booking")}
                                            onClick={() => { if (!disabled && Mode !== "edit") onOpenModal("Booking", true) }}
                                            value={dataMaster?.BookingNo}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <DatePicker
                                            readOnly={true}
                                            width={"100%"}
                                            margin={"normal"}
                                            placeholder={"dd/mm/yyyy"}
                                            displayFormat={"DD/MM/YYYY"}
                                            label={Config.lang("Ngay_tiec")}
                                            value={dataMaster?.EventDate}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Row>
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                <TextInput
                                                    readOnly={true}
                                                    endIcon={"Clock"}
                                                    placeholder={"hh:mm"}
                                                    label={Config.lang("Gio")}
                                                    value={dataMaster?.HourFrom}
                                                />
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6}>
                                                <TextInput
                                                    readOnly={true}
                                                    label={" "}
                                                    endIcon={"Clock"}
                                                    placeholder={"hh:mm"}
                                                    value={dataMaster?.HourTo}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Ten_su_kien")}
                                            value={dataMaster?.EventName}
                                            onChange={e => onChange("EventName", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Hinh_thuc_tiec")}
                                            value={dataMaster?.EventTypeName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Loai_ban_tiec")}
                                            value={dataMaster?.SetupName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Sanh")}
                                            value={dataMaster?.BanquetName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            title={dataMaster.Location}
                                            readOnly={disabled}
                                            label={Config.lang("Dia_diem_to_chuc_vi_tri")}
                                            value={dataMaster?.Location}
                                            onChange={e => onChange("Location", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <NumberInput
                                                    min={0}
                                                    defaultValue={0}
                                                    disabledNegative
                                                    readOnly={disabled}
                                                    inputRef={OQTYTableRef}
                                                    label={Config.lang("So_ban_chinh")}
                                                    onChange={(e) => {
                                                        handleTableChange(e, "OQTYTable");
                                                    }}
                                                    value={dataMaster?.OQTYTable}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <NumberInput
                                                    min={0}
                                                    defaultValue={0}
                                                    disabledNegative
                                                    readOnly={disabled}
                                                    inputRef={VegesTableQTYRef}
                                                    label={Config.lang("So_ban_chay")}
                                                    onChange={(e) => {
                                                        handleTableChange(e, "VegesTableQTY");
                                                    }}
                                                    value={dataMaster?.VegesTableQTY}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <NumberInput
                                                    min={0}
                                                    defaultValue={0}
                                                    disabledNegative
                                                    readOnly={disabled}
                                                    inputRef={GiftTableQTYRef}
                                                    label={Config.lang("So_ban_tang")}
                                                    onChange={(e) => handleTableChange(e, "GiftTableQTY")}
                                                    value={dataMaster?.GiftTableQTY}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <NumberInput
                                                    min={0}
                                                    defaultValue={0}
                                                    disabledNegative
                                                    readOnly={disabled}
                                                    inputRef={EQTYTableRef}
                                                    label={Config.lang("So_ban_du_bi")}
                                                    onChange={(e) => handleTableChange(e, "EQTYTable")}
                                                    value={dataMaster?.EQTYTable}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <TextInput
                                            rows={4}
                                            maxRows={4}
                                            multiline={true}
                                            inputProps={{
                                                maxLength: 4000
                                            }}
                                            readOnly={disabled}
                                            viewType={"outlined"}
                                            label={Config.lang("Ghi_chu")}
                                            onChange={(e) => onChange("notes", e)}
                                            value={dataMaster?.notes}
                                        />
                                    </Col>
                                    <Col style={{ marginTop: 24 }} xs={12} sm={12} md={12} lg={12}>
                                        <Attachment
                                            ref={attRef}
                                            accept={attachmentType}
                                            isStripDomain={true}
                                            style={{ minHeight: 120 }}
                                            domain={Config.getCDNPath()}
                                            data={dataOldAttachments}
                                            onChange={onChangeAttachments}
                                            readOnly={disabled}
                                            uploadErrorInfo={{
                                                existingFile: Config.lang("File_da_duoc_dinh_kem"),
                                                maxFile: Config.lang("File_vuot_qua_so_luong_cho_phep"),
                                                maxSize: Config.lang("File_vuot_qua_dung_luong_cho_phep"),
                                                fileType: `${Config.lang("Chi_ho_tro_dinh_kem_cac_file_co_dinh_dang")}: ${attachmentType.join(', ')}`,
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                </TabPanel>
                {/* ==================== TAB CHI TIẾT ================= */}
                <TabPanel index={2}>
                    <FormGroup className={"mgb0"} style={{ backgroundColor: colors?.dark11 }}>
                        <div className={classes.tabDetailPanel}>
                            <TabContainer className={classes.detailPanelCard} tabIndex={tabDetailIndex} level={"level2"}>
                                <TabHeader>
                                    <TabItem
                                        index={1}
                                        label={`1.${Config.lang("Thuc_don_chinh")}`}
                                        onClick={() => handleChangeTabDetail(1)}
                                    />
                                    <TabItem
                                        index={2}
                                        label={`2.${Config.lang("Thuc_don_phu")}`}
                                        onClick={() => handleChangeTabDetail(2)}
                                    />
                                    <TabItem
                                        index={3}
                                        label={`3.${Config.lang("Dich_vu_dat_them")}`}
                                        onClick={() => handleChangeTabDetail(3)}
                                    />
                                    <TabItem
                                        index={4}
                                        label={`4.${Config.lang("Trang_tri")}`}
                                        onClick={() => handleChangeTabDetail(4)}
                                    />
                                    <TabItem
                                        index={5}
                                        label={`5.${Config.lang("Dich_vu_khuyen_mai")}`}
                                        onClick={() => handleChangeTabDetail(5)}
                                    />
                                </TabHeader>
                                {/* ==================== THỰC ĐƠN CHÍNH ================= */}
                                <TabPanel index={1} style={{ paddingTop: spacing(4), paddingBottom: spacing(6) }}>
                                    <Row>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <TextInput
                                                readOnly={true}
                                                endIcon={"ArrowDown"}
                                                style={{ cursor: 'pointer' }}
                                                label={Config.lang("Ma_menu")}
                                                onClick={() => { if (menuTab1Status && dataMaster?.OQTYTable !== 0) onOpenModal("Menu", true) }}
                                                value={_.get(dataTabDetail, "tab01.MenuID", "")}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <TextInput
                                                readOnly={true}
                                                label={Config.lang("Ten_menu")}
                                                value={_.get(dataTabDetail, "tab01.MenuName", "")}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <NumberInput
                                                readOnly={true}
                                                decimalDigit={8}
                                                thousandSeparator={","}
                                                label={Config.lang("Gia_menu")}
                                                value={_.get(dataTabDetail, "tab01.PriceMenu", null)}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <NumberInput
                                                min={0}
                                                max={100}
                                                readOnly={disabled}
                                                value={dataMaster?.DiscountRate}
                                                label={`% ${Config.lang("Giam_gia_thuc_don")}`}
                                                onChange={(e) => onChange("DiscountRate", e)}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <NumberInput
                                                readOnly={true}
                                                thousandSeparator={","}
                                                label={Config.lang("Tong_tien_giam_gia")}
                                                value={dataMaster?.DiscountAmount}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <NumberInput
                                                readOnly={true}
                                                thousandSeparator={","}
                                                label={Config.lang("Gia_ban/ban")}
                                                value={dataMaster?.UnitPriceTable01}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <Row>
                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                    <Button
                                                        disabled={disabled || dataMaster?.OQTYTable === 0}
                                                        size={"medium"}
                                                        color={"primary"}
                                                        viewType={"filled"}
                                                        startIcon={"AddCircle"}
                                                        label={Config.lang("Them")}
                                                        onClick={() => onAddNewRow(1)}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                    <div className={"wrap align-center jus-end"}>
                                                        <Label style={{ marginRight: spacing(2) }}>{`${Config.lang("Tong_tien")} :`}</Label>
                                                        <NumberInput
                                                            nonStyle
                                                            readOnly
                                                            thousandSeparator={","}
                                                            value={dataMaster?.TotalAmount01}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {renderGrid01}
                                </TabPanel>
                                {/* ==================== THỰC ĐƠN CHAY ================= */}
                                <TabPanel index={2} style={{ paddingTop: spacing(4), paddingBottom: spacing(6) }}>
                                    <Row>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <TextInput
                                                readOnly={true}
                                                endIcon={"ArrowDown"}
                                                style={{ cursor: 'pointer' }}
                                                label={Config.lang("Ma_menu")}
                                                onClick={() => { if (menuTab2Status && dataMaster?.VegesTableQTY !== 0) onOpenModal("Menu", true) }}
                                                value={_.get(dataTabDetail, "tab02.MenuID", "")}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <TextInput
                                                readOnly={true}
                                                label={Config.lang("Ten_menu")}
                                                value={_.get(dataTabDetail, "tab02.MenuName", "")}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <NumberInput
                                                readOnly={true}
                                                thousandSeparator={","}
                                                label={Config.lang("Gia_menu")}
                                                value={_.get(dataTabDetail, "tab02.PriceMenu", null)}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <NumberInput
                                                readOnly={true}
                                                thousandSeparator={","}
                                                label={Config.lang("Gia_ban/ban")}
                                                value={dataMaster?.UnitPriceTable02}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <Row>
                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                    <Button
                                                        disabled={disabled || dataMaster?.VegesTableQTY === 0}
                                                        size={"medium"}
                                                        color={"primary"}
                                                        viewType={"filled"}
                                                        startIcon={"AddCircle"}
                                                        label={Config.lang("Them")}
                                                        onClick={() => onAddNewRow(2)}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                    <div className={"wrap align-center jus-end"}>
                                                        <Label style={{ marginRight: spacing(2) }}>{`${Config.lang("Tong_tien")} :`}</Label>
                                                        <NumberInput
                                                            nonStyle
                                                            readOnly
                                                            thousandSeparator={","}
                                                            value={dataMaster?.TotalAmount02}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {renderGrid02}
                                </TabPanel>
                                {/* ==================== DỊCH VỤ ĐẶT THÊM ================= */}
                                <TabPanel index={3} style={{ paddingTop: spacing(4), paddingBottom: spacing(6) }}>
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <Button
                                                disabled={disabled}
                                                size={"medium"}
                                                color={"primary"}
                                                viewType={"filled"}
                                                startIcon={"AddCircle"}
                                                label={Config.lang("Them")}
                                                onClick={() => onAddNewRow(3)}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <div className={"wrap align-center jus-end"}>
                                                <Label style={{ marginRight: spacing(2) }}>{`${Config.lang("Tong_tien")} :`}</Label>
                                                <NumberInput
                                                    nonStyle
                                                    readOnly
                                                    thousandSeparator={","}
                                                    value={dataMaster?.TotalAmount03}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {renderGrid03}
                                </TabPanel>
                                {/* ==================== TRANG TRÍ ================= */}
                                <TabPanel index={4} style={{ paddingTop: spacing(4), paddingBottom: spacing(6) }}>
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <Button
                                                disabled={disabled}
                                                size={"medium"}
                                                color={"primary"}
                                                viewType={"filled"}
                                                startIcon={"AddCircle"}
                                                label={Config.lang("Them")}
                                                onClick={() => onAddNewRow(4)}
                                            />
                                        </Col>
                                    </Row>
                                    {renderGrid04}
                                </TabPanel>
                                {/* ==================== DỊCH VỤ KHUYẾN MÃI ================= */}
                                <TabPanel index={5} style={{ paddingTop: spacing(4), paddingBottom: spacing(6) }}>
                                    <Row>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <Dropdown
                                                // clearAble
                                                dataSource={dataCboCTKM}
                                                value={dataMaster?.PromotionsID}
                                                loading={cboServiceLoading}
                                                valueExpr={"PromotionsID"}
                                                displayExpr={"{PromotionsID} - {PromotionsName}"}
                                                label={Config.lang("Ma_CTKM")}
                                                onChange={(e) => onChange("PromotionsID", e)}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={3} lg={2}>
                                            <TextInput
                                                readOnly
                                                label={Config.lang("Ten_CTKM")}
                                                value={dataMaster?.PromotionsName}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <Row>
                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                    <Button
                                                        disabled={disabled}
                                                        size={"medium"}
                                                        color={"primary"}
                                                        viewType={"filled"}
                                                        startIcon={"AddCircle"}
                                                        label={Config.lang("Them")}
                                                        onClick={() => onAddNewRow(5)}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={6} md={6} lg={6}>
                                                    <div className={"wrap align-center jus-end"}>
                                                        <Label style={{ marginRight: spacing(2) }}>{`${Config.lang("Tong_tien")} :`}</Label>
                                                        <NumberInput
                                                            nonStyle
                                                            readOnly
                                                            thousandSeparator={","}
                                                            value={dataMaster?.TotalAmount05}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {renderGrid05}
                                </TabPanel>
                            </TabContainer>
                        </div>
                        <FormGroup className={"mgb0"} style={{ background: 'white', padding: spacing([4, 6, 0, 6]) }}>
                            <Row>
                                <Col xs={12} sm={6} md={2} lg={2}>
                                    <TextInput
                                        readOnly={true}
                                        endIcon={"ArrowDown"}
                                        label={Config.lang("Loai_tien")}
                                        value={dataMaster?.CurrencyID}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={2} lg={2}>
                                    <TextInput
                                        readOnly={true}
                                        label={Config.lang("Ty_gia")}
                                        value={dataMaster?.ExchangeRate}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <NumberInput
                                        readOnly={true}
                                        thousandSeparator={","}
                                        value={dataMaster?.TotalAmount01}
                                        label={`1. ${Config.lang("Tong_tien_thuc_don_chinh")}`}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <NumberInput
                                        readOnly={true}
                                        thousandSeparator={","}
                                        value={dataMaster?.OPreAmount}
                                        label={`4. ${Config.lang("Thanh_tien_truoc_GG_KM")} (1+2+3)`}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <NumberInput
                                        readOnly={true}
                                        thousandSeparator={","}
                                        label={`${Config.lang("Tien_coc_cho")}`}
                                        value={dataMaster?.DepositAmount01}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <NumberInput
                                        readOnly={true}
                                        thousandSeparator={","}
                                        value={dataMaster?.TotalAmount02}
                                        label={`2. ${Config.lang("Tong_tien_thuc_don_phu")}`}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <NumberInput
                                        readOnly={true}
                                        thousandSeparator={","}
                                        value={dataMaster?.TotalAmount05}
                                        label={`5. ${Config.lang("Tong_tien_dich_vu_khuyen_mai")}`}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <NumberInput
                                        readOnly
                                        thousandSeparator={","}
                                        onChange={(e) => onChange("DepositAmount02", e)}
                                        label={`${Config.lang("Tien_coc_su_kien")}`}
                                        value={dataMaster?.DepositAmount02}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <NumberInput
                                        readOnly={true}
                                        thousandSeparator={","}
                                        value={dataMaster?.TotalAmount03}
                                        label={`3. ${Config.lang("Tong_tien_dich_vu_dat_them")}`}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <NumberInput
                                        readOnly={true}
                                        thousandSeparator={","}
                                        label={`6. ${Config.lang("Tong_tien_giam_gia_thuc_don")}`}
                                        value={dataMaster?.DiscountAmount}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <NumberInput
                                        readOnly={true}
                                        thousandSeparator={","}
                                        label={`${Config.lang("Tong_tien_coc")}`}
                                        value={dataMaster?.TotalDepositAmount}
                                    />
                                </Col>
                                <Col xs={0} sm={0} md={4} lg={4}>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <NumberInput
                                        readOnly={true}
                                        thousandSeparator={","}
                                        value={dataMaster?.OTotalAmount}
                                        label={`7. ${Config.lang("Thanh_tien_sau_GG_KM")} (4-5-6)`}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </FormGroup>
                </TabPanel>
            </TabContainer>
        </React.Fragment >
    );
};

const CBMenuModal = memo(forwardRef((props, ref) => {
    const { onOpenComboModal, statusInven, dataInfo } = props;
    const [openComboModal, setOpenComboModal] = useState(false);
    const dataRowKey = useRef({});
    useImperativeHandle(ref, () => ({
        show: (status, dataRow) => {
            if (!_.isUndefined(dataRow)) dataRowKey.current = dataRow;
            if (_.isBoolean(status)) setOpenComboModal(status);
        }
    }));
    return (
        <>
            {openComboModal &&
                <ChoseCBModal
                    FormID={FormID}
                    mode={"single"}
                    openModal={openComboModal}
                    dataInfo={dataInfo}
                    statusInven={statusInven}
                    dataKeyChose={_.get(dataRowKey.current, "valueID", "")}
                    keyExpr={_.get(dataRowKey.current, "dataField", "")}
                    keyExprName={_.get(dataRowKey.current, "dataFieldName", "")}
                    onOpenModalAction={(status, data) => {
                        onOpenComboModal(status, { ...data, isAddNew: _.get(dataRowKey.current, "isAddNew", false) });
                    }}
                />
            }
        </>
    )
}));

WA3F2010.propTypes = {
    dataInfo: PropTypes.object,
};

export default WA3F2010;
