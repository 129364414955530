/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/19/2021
 * @Example
 */
import React, {useEffect, useState}     from "react";
import PropTypes                        from "prop-types";
import Config                           from "../../../../config";
import {FormGroup}                      from "react-bootstrap";
import {Column}                         from "devextreme-react/data-grid";
import GridContainer                    from "../../../common/grid-container/grid-container";
import {Drawer}                         from "@material-ui/core";
import _                                from "lodash";
import {makeStyles, useTheme}           from "diginet-core-ui/theme";
import Icons                            from "diginet-core-ui/icons";
import {ButtonIcon, Typography, Status} from "diginet-core-ui/components";

const paperWidth     = "33%";
const useStyles      = makeStyles(theme => ({
    divIcon:       {
        width:  30,
        height: 30,
    },
    headerToolbar: {
        height:         50,
        top:            0,
        width:          "100%",
        padding:        15,
        display:        "flex",
        justifyContent: "space-between",
        flexDirection:  "row",
        alignItems:     "center",
    },
    hide:          {
        display: "none",
    },
    viewDetail:    {
        color:  "#7F828E",
        cursor: "pointer",
    },
    drawerHeader:  {
        display:        "flex",
        alignItems:     "center",
        padding:        10,
        // necessary for content to be below app bar
        justifyContent: "flex-end",
    },
    drawer:        {
        width:                          paperWidth,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        flexShrink:                     0,
    },
    drawerPaper:   {
        width:                          paperWidth,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        top:                            50,
        boxShadow:                      "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius:                   "4px",
    },
}));
const coreTheme      = useTheme();
const WA3F0100Detail = (props) => {

    const classes                       = useStyles();
    const {showPopupDetail, dataDetail} = props;
    const [dataSource, setDataSource]   = useState([]);

    const _renderAction = (e) => {
        const {data} = e.row;
        return <div>{_renderStatus(data)}</div>;
    };
    const _renderStatus = (data) => {
        let actions = {
            added:   {
                color:  coreTheme.colors.success,
                action: Config.lang("Them_moi1"),
                icons:  "add",
            },
            edited:  {
                color:  coreTheme.colors.info,
                action: Config.lang("Sua"),
                icons:  "edit",
            },
            removed: {
                color:  coreTheme.colors.warning,
                action: Config.lang("Xoa"),
                icons:  "delete",
            },
        };
        if (!_.isEmpty(data)) {
            return <Status
                text={actions[data.action].action}
                icon={<Icons
                    name={actions[data.action].icons}
                    color={actions[data.action].color}
                />}
                color={actions[data.action].color}
            />;
        }
    };

    const checkIsZero = (number) => {
        return number || number === 0;
    };

    const _renderContent = (e) => {
        const {data} = e.row;
        return (
            <div>
                {Object.keys(data).map((item, idx) => {
                    if (item !== "action" && item.substring(0, 4) !== "data") {
                        if (_.isObject(data[item]) && !_.isUndefined(data[item]["old"])) {
                            let oldVal = checkIsZero(data[item]["old"]) ? data[item]["old"] : "",
                                newVal = checkIsZero(data[item]["new"]) ? data[item]["new"] : "";
                            return (
                                <div className="display_row align-center" key={idx}>
                                    {`${Config.lang(item)}: ${oldVal} -> ${newVal}`}
                                </div>
                            );
                        } else {
                            return (
                                <div className="display_row align-center" key={idx}>
                                    {`${Config.lang(item)}: ${checkIsZero(data[item]) ? data[item] : ""}`}
                                </div>
                            );
                        }
                    }
                    return null;
                })}
            </div>
        );
    };
    const loadDataDetail = () => {
        let newArr = [];
        if (dataDetail && !_.isEmpty(dataDetail.value)) {
            Object.keys(dataDetail.value).map((item) => {
                dataDetail.value[item].map((ii) => {
                    ii["action"] = item;
                    return ii;
                });
                newArr.push(dataDetail.value[item]);
                return item;
            });
        }
        setDataSource(newArr.flat(1));
    };

    useEffect(() => {
        if (showPopupDetail) loadDataDetail();
        /* eslint-disable */
    }, [showPopupDetail]);

    const onClose = () => {
        const {handleClose} = props;
        setDataSource([]);
        if (handleClose) handleClose();
    };
    return (
        <React.Fragment>
            <Drawer
                className={classes.drawer}
                anchor="right"
                open={showPopupDetail}
                onClose={onClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    BackdropProps: {
                        invisible: true,
                    },
                }}
            >
                <div className={classes.headerToolbar}>
                    <Typography variant="h6">
                        {dataDetail?.title ? dataDetail.title : ""}
                    </Typography>
                    <ButtonIcon
                        name={"Close"}
                        viewType={"text"}
                        width={16}
                        height={16}
                        circular
                        onClick={onClose}
                    />
                </div>
                {/* <Divider style={{ marginLeft: -15, marginRight: -15 }} /> */}
                {/* className="display_row row" style={{ flexDirection: "row" }} */}
                <FormGroup>
                    <GridContainer
                        dataSource={dataSource}
                        showBorders={false}
                        height={Config.getHeightGrid()}
                        listPerPage={[15, 30, 45, 60]}
                        typePaging={"normal"}
                        hoverStateEnabled={true}
                        showColumnHeaders={false}
                        rowAlternationEnabled={false}
                        // showRowLines={false}
                    >
                        <Column
                            caption={Config.lang("Mo_ta")}
                            alignment={"left"}
                            minWidth={250}
                            cellRender={_renderContent}
                        />
                        <Column
                            caption={Config.lang("Hanh_dong")}
                            alignment={"left"}
                            width={150}
                            cellRender={_renderAction}
                        />
                    </GridContainer>
                </FormGroup>
            </Drawer>
        </React.Fragment>
    );
};

WA3F0100Detail.propTypes = {
    onClose:         PropTypes.func,
    showPopupDetail: PropTypes.bool,
    dataDetail:      PropTypes.object,
};

export default WA3F0100Detail;
