/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/16/2019
 * @Example
 */
import React from "react";
import {Dialog, DialogContent, IconButton} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {withStyles} from "@material-ui/styles";
import Config from "../../config";

const styles = {
    paper: {
        padding: 0,
        width: 'calc(100% - 10px)',
        margin: '2px 0 0 0',
        alignSelf: 'flex-start',
    }
};
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            load: null,
            openPopupFilter: false
        };
    }

    setFilter = (html) => {
        this.setState({
            filter: html
        });
    };

    toggleFilter = (flag) => {
        this.setState({
            openPopupFilter: flag
        });
    };

    componentWillUnmount() {
        if (!Config.filters) {
            this.setState({
                filter: null
            })
        }
    }

    render() {
        const {filter, openPopupFilter} = this.state;

        return (
            <React.Fragment>
                {filter && <>
                    <Dialog
                        open={openPopupFilter}
                        fullWidth={true}
                        maxWidth={"lg"}
                        onClose={() => this.toggleFilter(false)}
                        PaperProps={{
                            style: styles.paper
                        }}
                    >
                        <DialogContent style={{padding: '0 10px'}} className={"no-margin"}>
                            {filter && typeof filter === "function" ? filter() : filter}
                        </DialogContent>
                    </Dialog>
                    <div className={"set-filter"} style={{width: '100%'}}>
                        {filter && typeof filter === "function" ? filter() : filter}
                    </div>
                    <div className={'toogle-filter'}>
                        <IconButton
                            aria-label="filter"
                            size="small"
                            onClick={() => this.toggleFilter(true)}
                        >
                            <SearchIcon />
                        </IconButton>
                    </div>
                </>}
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Search);