/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/28/2019
 * @Example
 */

import React, {
    Component,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import PropTypes                                                                                 from 'prop-types';
import Config                                                                                    from "../../../config";
import DataGrid, {
    Paging as DxPaging,
    Pager,
    Selection,
    KeyboardNavigation,
    FilterRow,
    FilterPanel,
    FilterBuilderPopup, Sorting, Grouping, FilterBuilder, Scrolling
} from "devextreme-react/data-grid";
// import Paging                                                                 from "./paging";
import { PagingInfo as Paging } from 'diginet-core-ui/components';
import GridToolbar                                                            from "./tool-bar/grid-toolbar";
import GridFilterBar                                                          from "./tool-bar/grid-filter";
import { LoadPanel }                                                          from 'devextreme-react/load-panel';

const GridContainer = React.memo(forwardRef((props, ref) => {

    //props...
    const {keyExpr, dataSource, title, totalItems, filterData, buttonCRM, selectedRowKey, typeShort, wordWrapEnabled,
              editing, columnResizingMode, filterRow, columnAutoWidth, style, allowSearch, widthFull, disabled, typePaging,
              isPer, allowAdding, showSendMail, reference, id, children, valueSearch, paging, selection,
              showBorders, showColumnHeaders, showColumnLines, showRowLines, scrolling, hoverStateEnabled, loading,
              allowColumnResizing, pagerFullScreen, pager, elementAttr, allowCellSelection, filterValue, filterPanel,
              filterBuilder, filterBuilderPopup, disabledCellSelection, sorting, isOrderAPI, rowAlternationEnabled,
              grouping, focusStateEnabled, focusedRowEnabled, allowColumnReordering, defaultFocusedRowIndex,
              onFocusedRowChanged, onFocusedRowChanging, keyboardNavigation,
              gridProps
          } = props;
    //func...
    const {onFilter, onSearch, onAddNew, onSendMail, onChangePage, onChangePerPage, onFilterChanged,
              loadMore, onRowInserted, onRowUpdated, onRowRemoved, onRowUpdating, onEditingStart, onInitNewRow, onRowRemoving,
              onEditorPrepared, onEditorPreparing, onContentReady, onSelectionChanged, onRowPrepared, onFocusedCellChanging,
              onKeyDown, onInitialized, onCellSelectionChanged, onRowValidating, onFocusedCellChanged, onRowInserting,
              onContextMenuPreparing, onRowClick, onOrderChanged, onCellPrepared, onCellHoverChanged, onChangingPage, onChangingPerPage
          } = props;

    const listPerPage = useRef(props.listPerPage || [10, 20, 40, 50]);
    const [currentPage, setCurrentPage] = useState(props.isPage ?? 0);
    const [itemPerPage, setItemPerPage] = useState(props.itemPerPage || listPerPage.current[0]);
    const [height, setHeight] = useState(props.height);

    const isSubmit = useRef(false);
    const isFiltered = useRef(false);
    const filterGrid = useRef({});
    const grid = useRef(null);
    const dataGrid = useRef(null);
    const selectedRange = useRef({});
    const isSelectionStopped = useRef(true);
    const fieldSort = useRef("");
    const typeSort = useRef({});
    const pagingRef = useRef(null);
    const isChangePageIndex = useRef(false);

    const pageSize = useMemo(() => {
        return paging && paging.pageSize ? paging.pageSize : (itemPerPage ? itemPerPage : 10);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paging, itemPerPage]);;
    const _typePaging = useMemo(() => {
        return typeof typePaging === "undefined" ? (typeof totalItems !== "undefined" ? "remote" : "default") : typePaging;
    }, [typePaging, totalItems]);
    const _sorting = useMemo(() => {
        return isOrderAPI ? {mode: "none"} : (sorting ? sorting : {});
    }, [isOrderAPI, sorting]);

    //allow custom onCellHoverChanged...
    const allowCustomCellHoverChanged = useMemo(() => {
        return onCellSelectionChanged && !disabledCellSelection;
    }, [disabledCellSelection, onCellSelectionChanged]);

    const foreachRange = useCallback((selectedRange, cb) =>  {
        if(selectedRange.startRowIndex >= 0) {
            var minRowIndex = Math.min(selectedRange.startRowIndex, selectedRange.endRowIndex);
            var maxRowIndex = Math.max(selectedRange.startRowIndex, selectedRange.endRowIndex);
            var minColumnIndex = Math.min(selectedRange.startColumnIndex, selectedRange.endColumnIndex);
            var maxColumnIndex = Math.max(selectedRange.startColumnIndex, selectedRange.endColumnIndex);

            for(var rowIndex = minRowIndex; rowIndex <= maxRowIndex; rowIndex++) {
                for(var columnIndex = minColumnIndex; columnIndex <= maxColumnIndex; columnIndex++) {
                    cb(rowIndex, columnIndex);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const showSelection = useCallback((component, selectedRange) => {
        const cellSelected = component.element().querySelectorAll(".cell-selected");
        cellSelected.forEach(cell => cell.classList.remove("cell-selected"));

        if (onCellSelectionChanged && !disabledCellSelection) onCellSelectionChanged({component: component, selectedRange: selectedRange});

        foreachRange(selectedRange, (rowIndex, columnIndex) =>  {
            if (component.getCellElement(rowIndex, columnIndex)) {
                component.getCellElement(rowIndex, columnIndex).classList.add("cell-selected");
            }
        });
        /* eslint-disable */
    }, [disabledCellSelection, onCellSelectionChanged]);

    const onFilterGrid = useCallback((e) => {
        let filter = e.component.getCombinedFilter(true);
        let letFilter = false;
        let listFilter = {};
        let eventFilter = {};
        eventFilter.component = e.component;

        if (filter) {
            filter = !Array.isArray(filter[0]) ? [filter] : filter;
            filter.forEach((val) => {
                if (Array.isArray(val)) {
                    listFilter[val[0]] = val.filterValue;
                    if (filterGrid.current[val[0]] || filterGrid.current[val[0]] === 0) {
                        if (filterGrid.current[val[0]] !== val.filterValue) {
                            letFilter = true;
                        }
                    } else {
                        letFilter = true;
                    }
                }
            });
            if (Object.keys(filterGrid.current).length !== Object.keys(listFilter).length) {
                letFilter = true;
            }
            filterGrid.current = listFilter;
            if (letFilter) {
                isFiltered.current = true;
                eventFilter.filter = filterGrid.current;
                onFilterChanged(eventFilter);
            }
        } else {
            filterGrid.current = {};
            if (isFiltered.current) {
                isFiltered.current = false;
                eventFilter.filter = null;
                onFilterChanged(eventFilter);
            }
        }
    }, [filterGrid, isFiltered, onFilterChanged]);

    useImperativeHandle(ref, () => ({
        itemPerPage: (_itemPerPage) => {
            if (_itemPerPage !== 0 && !_itemPerPage) return itemPerPage;
            if (itemPerPage !== _itemPerPage) setItemPerPage(_itemPerPage);
        },
        getRefDataGrid: () => dataGrid.current,
        getRefPaging: () => pagingRef.current
    }));

    useEffect(() => {
        setHeight(props.height);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (pagingRef.current && props.height) setHeight(props.height - (37 - 14))
    }, [props.totalItems, props.height]);

    useEffect(() => {
        if (props.itemPerPage || props.itemPerPage === 0) setItemPerPage(props.itemPerPage);
        if (props.isPage || props.isPage === 0) setCurrentPage(props.isPage);
    }, [props.itemPerPage, props.isPage]);

    useEffect(() => {
        if (props.skipPerPage || props.skipPerPage === 0) setCurrentPage(props.skipPerPage / itemPerPage);
    }, [props.skipPerPage]);

    // useEffect(() => {
    //     const focusedRowIndex = dataGrid.current.instance.option("focusedRowIndex");
    //     if (dataGrid.current && (typeof focusedRowIndex === "number" && focusedRowIndex >= 0))
    //         dataGrid.current.instance.option("focusedRowKey", '');
    // }, [dataSource]);

    useEffect(() => {
        if (dataGrid.current) {
            if (currentPage !== dataGrid.current.instance.pageIndex()) {
                dataGrid.current.instance.pageIndex(currentPage);
            }
        }
    }, [currentPage]);

    const handleScroll = (e) => {
        if (isSubmit.current) return;
        const bottom = e.target.scrollTop && ((e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight + 30);
        if (bottom && props.loadMore) {
            isSubmit.current = true;
            props.loadMore(() => {
                isSubmit.current = false;
            });
        }
    };

    const updateTotal = useCallback((e) => {
        const _total = e.component.totalCount();
        if (pagingRef.current && _typePaging === "normal") pagingRef.current.setTotalItems(_total);
    }, [pagingRef, _typePaging]);

    const setOrderChanged = useCallback((e) => {
        if (!e) return false;
        if (fieldSort.current && typeSort.current?.[fieldSort.current] && e.cellElement) {
            if (e.rowType === "header") {
                const grid = e.component?.element();
                const indicators = grid?.getElementsByClassName("dx-column-indicators");
                if (indicators.length > 0) {
                    for (let ind of indicators) {
                        ind.remove();
                    }
                }
                e.cellElement.children[0].classList.add("dx-text-content-alignment-left", "dx-text-content-alignment-right", "dx-sort-indicator");
                e.cellElement.innerHTML += '<div class="dx-column-indicators" role="presentation"><span class="dx-sort '+ (typeSort.current[fieldSort.current] === "ASC" ? "dx-sort-up" : "dx-sort-down") +'" /></div>';
            }
        }
    }, [typeSort, fieldSort]);

    const onCellClick = (e) => {
        const {onCellClick} = props;
        if (isOrderAPI && onOrderChanged) {
            if (e.rowType === "header") {
                const {column} = e;
                if (fieldSort.current !== column.dataField) fieldSort.current = column.dataField;
                typeSort.current[fieldSort.current] = typeSort.current[fieldSort.current] === "ASC" ? "DESC" : "ASC";
                // this.cellHeader = e.cellElement;

                setOrderChanged(e);

                if (onOrderChanged) onOrderChanged({...e, column: column, typeSort: typeSort.current[fieldSort.current].toUpperCase()});
            }
        }

        if (onCellSelectionChanged && !disabledCellSelection) {
            selectedRange.current.rowType = e.rowType;
            if (e.rowType !== "header") {
                selectedRange.current.startRowIndex = selectedRange.current.endRowIndex = e.rowIndex;
                selectedRange.current.startColumnIndex = selectedRange.current.endColumnIndex = e.columnIndex;
                isSelectionStopped.current = false;
                showSelection(e.component, selectedRange.current);
            }
        }

        if (onCellClick) onCellClick(e);
    };
    const onCellDblClick = (e) => {
        const {onDbCellClick, onCellDblClick} = props;
        if (onCellDblClick) onCellDblClick(e);
        if (onDbCellClick) onDbCellClick(e);
    };
    const _onCellHoverChanged = (e) => {
        if (onCellHoverChanged) onCellHoverChanged(e);

        //set selection range
        const event = e.event;
        if(event.buttons === 1 && onCellSelectionChanged && !disabledCellSelection) {
            if(isSelectionStopped.current) {
                isSelectionStopped.current = false;
                selectedRange.current = {};
            }
            selectedRange.current.rowType = e && e.rowType ? e.rowType : "";
            if(selectedRange.current.startRowIndex === undefined) {
                selectedRange.current.startRowIndex = e.rowIndex;
            }
            if(selectedRange.current.startColumnIndex === undefined) {
                selectedRange.current.startColumnIndex = e.columnIndex;
            }

            selectedRange.current.endRowIndex = e.rowIndex;
            selectedRange.current.endColumnIndex = e.columnIndex;

            showSelection(e.component, selectedRange.current);

        }
        else {
            isSelectionStopped.current = true;
        }
    };
    const _onFocusedCellChanging = (e) => {
        if (onFocusedCellChanging) {
            onFocusedCellChanging(e);
        } else {
            e.isHighlighted = allowCellSelection;
        }
    };
    const onRowDblClick = (e) => {
        const {onDbRowClick, onRowDblClick} = props;
        if (onRowDblClick) onRowDblClick(e);
        if (onDbRowClick) onDbRowClick(e);
    };
    const _onEditorPreparing = (e) => {
        if (e.parentType === "filterRow") {
            let placeholder = "";
            switch (e.editorName) {
                case "dxTextBox":
                case "dxNumberBox":
                    placeholder = e.filterPlaceholder || Config.lang("Nhap");
                    break;
                case "dxDateBox":
                    placeholder = e.filterPlaceholder || e?.format || Config.lang("Chon");
                    break;
                default:
                    placeholder = e.filterPlaceholder || Config.lang("Chon");
                    break;
            }
            e.editorOptions.placeholder = placeholder || Config.lang("Chon");

            //convert value filter to normalize (data and filterValue)
            const dataSource = e.component.option("dataSource") || [];
            const {dataField} = e || {};
            dataSource?.forEach(d => {
                if (d[dataField] && typeof d[dataField] === "string") {
                    d[dataField] = d[dataField].normalize();
                }
            });
            const onValueChanged = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = (evt) => {
                if (evt.value && typeof evt.value === "string") {
                    if (evt?.event?.target) evt.event.target.value = evt.value.normalize();
                    evt.value = evt.value.normalize();
                }
                onValueChanged(evt);
            };
        }
        if (onEditorPreparing) onEditorPreparing(e);
    };

    const _onInitialized = (e) => {
        const columns = e.component.getVisibleColumns();
        const dateColumns = columns?.filter(col => col?.dataType === "date");
        if (dateColumns && dateColumns.length > 0) {
            for (let col of dateColumns) {
                e.component.columnOption(col.dataField, "minWidth", 180);
            }
        }
        if (onInitialized) onInitialized(e);
    };

    const onOptionChanged = (e) => {
        const {onOptionChanged: _onOptionChanged} = props;
        if (!e) return false;
        switch (e.name) {
            case "columns":
                if (e.fullName.endsWith("sortOrder") && !isOrderAPI) {
                    const fullName = e.fullName.split('.');
                    if (fullName.length < 1) return false;
                    const matchs = fullName[0].match(/columns\[(\d+)\]/);
                    if (matchs.length < 2) return false;
                    const colIndx = matchs[1];
                    const columns = e.component.option("columns");
                    const column = columns[colIndx];
                    const typeSort =  e.value ? e.value : 'asc';
                    if (_onOptionChanged) _onOptionChanged({...e, column: column, typeSort: typeSort.toUpperCase()});
                    // setTimeout(() => {
                    //     e.component.columnOption(column.dataField, 'sortOrder', typeSort);
                    // }, 300);
                }
                if (e.fullName.endsWith("filterValue") && props.onFilterGrid) {
                    const {onFilterGrid} = props;
                    if (onFilterGrid) {
                        const fullName = e.fullName.split('.');
                        if (fullName.length < 1) return false;
                        const matchs = fullName[0].match(/columns\[(\d+)\]/);
                        if (matchs.length < 2) return false;
                        const colIndx = matchs[1];
                        const columns = e.component.option("columns");
                        const column = columns[colIndx];
                        const value = e.value ? e.value : '';


                        if (onFilterGrid) onFilterGrid({component: e.component, column, filterValue: value, columnIndex: Number(colIndx)});
                        // setTimeout(() => {
                        //     e.component.columnOption(column.dataField, 'sortOrder', typeSort);
                        // }, 300);
                    }
                }
                break;
            case "filterValue":
                if (e.fullName === "filterValue" && e.value === null && props.onFilterGrid) {
                    const {onFilterGrid} = props;
                    if (onFilterGrid) onFilterGrid({component: e.component, column: null, filterValue: null});
                }
                break;
            case "paging":
                if (e.fullName === "paging.pageIndex" && (e.value === 0 || e.value) && _typePaging !== "remote") {
                    if (dataGrid.current && focusedRowEnabled && typeof defaultFocusedRowIndex === "number") {
                        isChangePageIndex.current = true;
                    }
                    if (pagingRef.current && e.value !== currentPage) {
                        pagingRef.current.onChangePage(e.value);
                    }
                }
                break;
            default:
                break;
        }
        if (_onOptionChanged) _onOptionChanged(e);
    };

    return (
        <div ref={grid} className={"grid-container"}
             style={style ? style : {}}
             onScroll={loadMore ? handleScroll : null}>
            {title && <TitleGrid label={title} />}

            {onSearch &&
            <GridToolbar disabled={disabled}
                         allowAdding={allowAdding}
                         allowSearch={allowSearch}
                         isPer={isPer}
                         buttonCRM={buttonCRM && buttonCRM}
                         valueSearch={valueSearch && valueSearch}
                         onSearch={(text) => {
                             onSearch && onSearch(text);
                         }} onAddNew={onAddNew}
                         showSendMail={showSendMail}
                         onSendMail={onSendMail} />}

            {onFilter && filterData &&
            <GridFilterBar onFilter={onFilter} filterData={filterData} />}

            <LoadPanel
                position={{ my: 'center', of: grid.current || null }}
                visible={grid.current && loading}
                showIndicator={true}
                shading={false}
                showPane={true}
            />

            <DataGrid
                disabled={disabled ? disabled : (loading ? loading : false) }
                id={id ? id : null}
                ref={ref => {
                    if (ref) {
                        dataGrid.current = ref;
                        reference && reference(ref);
                    }
                }}
                dataSource={dataSource}
                keyExpr={keyExpr}
                editing={editing ? {texts: {confirmDeleteMessage: ""}, ...editing} : editing}
                width={widthFull ? 'fit-content' : "100%"}
                wordWrapEnabled={wordWrapEnabled}
                showRowLines={showRowLines}
                showBorders={showBorders}
                showColumnHeaders={showColumnHeaders}
                showColumnLines={showColumnLines}
                elementAttr={elementAttr}
                height={height}
                filterValue={filterValue}
                defaultFilterValue={filterValue}
                allowColumnReordering={allowColumnReordering}
                allowColumnResizing={allowColumnResizing}
                noDataText={loading ? "" : Config.lang('Khong_co_du_lieu')}
                columnAutoWidth={columnAutoWidth}
                columnResizingMode={columnResizingMode}
                repaintChangesOnly={true}
                highlightChanges={true}
                // scrolling={{ //phai viet nhu vay..
                //     scrollByContent: true,
                //     scrollByThumb: true,
                //     ...scrolling
                // }}
                onOptionChanged={onOptionChanged}
                hoverStateEnabled={hoverStateEnabled}
                focusStateEnabled={focusStateEnabled}
                focusedRowEnabled={focusedRowEnabled}
                onContentReady={(e) => {
                    updateTotal(e);
                    if (onFilterChanged) onFilterGrid(e);
                    if (onContentReady) onContentReady(e);
                    if (onCellSelectionChanged && !disabledCellSelection)
                        showSelection(e.component, selectedRange.current);

                    if (isChangePageIndex.current) {
                        isChangePageIndex.current = false;
                        e.component.option("focusedRowIndex", defaultFocusedRowIndex);
                    }
                }}
                onSelectionChanged={onSelectionChanged}
                onRowInserting={onRowInserting}
                onRowInserted={onRowInserted}
                onRowUpdated={onRowUpdated}
                onRowRemoved={onRowRemoved}
                onRowUpdating={onRowUpdating}
                onEditingStart={onEditingStart}
                onInitNewRow={onInitNewRow}
                onRowRemoving={onRowRemoving}
                onRowPrepared={onRowPrepared}
                onEditorPrepared={onEditorPrepared}
                onEditorPreparing={_onEditorPreparing}
                onCellPrepared={onCellPrepared} //danger event cause render
                onRowValidating={onRowValidating}
                onContextMenuPreparing={onContextMenuPreparing}
                onFocusedCellChanging={_onFocusedCellChanging}
                onFocusedCellChanged={onFocusedCellChanged}
                onCellHoverChanged={allowCustomCellHoverChanged ? _onCellHoverChanged : onCellHoverChanged} //danger event cause render
                onInitialized={_onInitialized}
                onKeyDown={onKeyDown}
                rowAlternationEnabled={rowAlternationEnabled}
                onCellClick={onCellClick}
                onCellDblClick={onCellDblClick}
                onRowClick={onRowClick}
                onRowDblClick={onRowDblClick}
                selectedRowKeys={selectedRowKey}
                onFocusedRowChanged={onFocusedRowChanged}
                onFocusedRowChanging={onFocusedRowChanging}
                {...gridProps}
            >
                {filterRow && <FilterRow {...filterRow} />}
                {filterBuilder && <FilterBuilder {...filterBuilder} />}
                {filterPanel && <FilterPanel texts={{clearFilter: Config.lang("Xoa")}} {...filterPanel} />}
                {filterBuilderPopup && <FilterBuilderPopup {...filterBuilderPopup} />}
                {grouping && <Grouping {...grouping} />}
                {_sorting && <Sorting {..._sorting} />}
                <Scrolling scrollByContent scrollByThumb {...scrolling} />
                <Selection mode={selection?.mode ? selection?.mode : "single"} {...selection} />
                {/* Press "Enter" save current cell and move to next row(down) Press "Shift + Enter" move to previous row(up) */}
                <KeyboardNavigation
                    editOnKeyPress={true}
                    enterKeyDirection={'column'}
                    {...keyboardNavigation}
                />
                <DxPaging defaultPageSize={_typePaging === "remote" ? totalItems : pageSize}
                          pageSize={_typePaging === "remote" ? totalItems : pageSize}
                          enabled={_typePaging === "remote" ? false : (paging && paging.enabled ? paging.enabled : true)}
                          defaultPageIndex={paging && paging.pageIndex ? paging.pageIndex : (currentPage ? currentPage : 0)}
                />
                <Pager visible={_typePaging !== "default" ? false : (pager && typeof pager.visible !== "undefined" ? pager.visible : dataSource && dataSource.length > pageSize)}
                       showInfo={pager && pager.showInfo ? pager.showInfo : false}
                       showNavigationButtons={pager && pager.showNavigationButtons ? pager.showNavigationButtons : false}
                       showPageSizeSelector={pager && pager.showPageSizeSelector ? pager.showPageSizeSelector : false}
                />
                {children}
            </DataGrid>

            {_typePaging === "remote" && !!totalItems && <Paging
                ref={pagingRef}
                typeShort={typeShort ? typeShort : false}
                totalItems={totalItems}
                currentPage={currentPage}
                itemsPerPage={itemPerPage}
                listPerPage={listPerPage.current}
                className={"paging " + (pagerFullScreen ? "paging-fixed" : "")}
                onChangePage={(page) => {
                    if (onChangePage) onChangePage(page);
                }}
                onChangePerPage={(per) => {
                    if (onChangePerPage) onChangePerPage(per);
                }}
            />}
            {_typePaging === "normal" && <Paging
                ref={pagingRef}
                typeShort={typeShort ? typeShort : false}
                totalItems={dataSource?.length || 0}
                currentPage={currentPage}
                itemsPerPage={itemPerPage}
                listPerPage={listPerPage.current}
                className={"paging " + (pagerFullScreen ? "paging-fixed" : "")}
                onChangePage={(page) => {
                    setCurrentPage(page);
                    if (onChangePage) onChangePage(page);
                }}
                onChangePerPage={(per) => {
                    if (dataGrid.current) {
                        dataGrid.current.instance.pageSize(per);
                        dataGrid.current.instance.pageIndex(0);
                    }
                    setCurrentPage(0);
                    setItemPerPage(per);
                    if (onChangePerPage) onChangePerPage(per);
                }}
                onChangingPage={onChangingPage}
                onChangingPerPage={onChangingPerPage}
            />}
        </div>
    );
}));

class TitleGrid extends Component {
    render() {
        let { label } = this.props;
        if (!label) return;

        return (
            <div className="grid-title">
                {label}
            </div>
        );
    }
}

TitleGrid.propTypes = {
    label: PropTypes.string
};

GridContainer.defaultProps = {
    pagerFullScreen: true,
    rowAlternationEnabled: true,
    focusStateEnabled: true,
    focusedRowEnabled: false,
    hoverStateEnabled: true,
    showColumnLines: false,
    showRowLines: false,
    columnAutoWidth: true,
    showColumnHeaders: true,
    allowColumnReordering: true,
    columnResizingMode: "nextColumn",
    wordWrapEnabled: false,
    showBorders: true,
    defaultFocusedRowIndex: 0
};

GridContainer.propTypes = {
    hoverStateEnabled: PropTypes.bool,
    focusStateEnabled: PropTypes.bool,
    focusedRowEnabled: PropTypes.bool,
    selectedRowKey: PropTypes.any,
    title: PropTypes.string,
    type: PropTypes.string,
    field: PropTypes.string,
    itemPerPage: PropTypes.number,
    skipPerPage: PropTypes.number,
    totalItems: PropTypes.number,
    listPerPage: PropTypes.any,
    dataSource: PropTypes.any,
    keyExpr: PropTypes.string.isRequired,
    showSendMail: PropTypes.bool,
    selection: PropTypes.object,
    buttonCRM: PropTypes.array,
    isPer: PropTypes.number,
    isPage: PropTypes.number,
    allowAdding: PropTypes.number,
    allowSearch: PropTypes.string,
    typeShort: PropTypes.bool,
    wordWrapEnabled: PropTypes.bool,
    editing: PropTypes.object,
    filterRow: PropTypes.object,
    allowColumnResizing: PropTypes.bool,
    columnResizingMode: PropTypes.string,
    columnAutoWidth: PropTypes.bool,
    reference: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    valueSearch: PropTypes.string,
    paging: PropTypes.any,
    pager: PropTypes.any,
    height: PropTypes.any,
    showBorders: PropTypes.bool,
    showColumnHeaders: PropTypes.bool,
    showColumnLines: PropTypes.bool,
    showRowLines: PropTypes.bool,
    scrolling: PropTypes.any,
    gridProps: PropTypes.any,
    loading: PropTypes.bool,
    rowAlternationEnabled: PropTypes.bool,
    pagerFullScreen: PropTypes.bool,
    elementAttr: PropTypes.any,
    allowCellSelection: PropTypes.bool,
    filterValue: PropTypes.any,
    filterPanel: PropTypes.object,
    filterBuilder: PropTypes.object,
    filterBuilderPopup: PropTypes.object,
    disabledCellSelection: PropTypes.bool,
    sorting: PropTypes.object,
    grouping: PropTypes.object,
    defaultFocusedRowIndex: PropTypes.number,
    keyboardNavigation: PropTypes.object,
    onChangingPage: PropTypes.func,
    onChangingPerPage: PropTypes.func,

    /**
     * @typePaging: String
     * "default": With default type of DataGrid Devextreme
     * "normal": With type of HR customize paging (local).
     * "remote": With type of HR customize paging (remote). Require props:
     *      - @totalItems: Total of all data.
     */
    typePaging: PropTypes.oneOf(['default', 'normal', 'remote']),

    /**
     * $isOrderAPI: Allow order with API with every column.
     */
    isOrderAPI: PropTypes.bool,

    onFilter: PropTypes.func,
    onAddNew: PropTypes.func,
    onSearch: PropTypes.func,
    onChangePage: PropTypes.func,
    onRowClick: PropTypes.func,
    onDbRowClick: PropTypes.func,
    onDbCellClick: PropTypes.func,
    onCellClick: PropTypes.func,
    onCellDblClick: PropTypes.func,
    onChangePerPage: PropTypes.func,
    customizeColumns: PropTypes.func,
    onSendMail: PropTypes.func,
    onRowInserted: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    onRowUpdated: PropTypes.func,
    onRowRemoved: PropTypes.func,
    onRowUpdating: PropTypes.func,
    onEditingStart: PropTypes.func,
    onInitNewRow: PropTypes.func,
    onRowRemoving: PropTypes.func,
    onEditorPrepared: PropTypes.func,
    onEditorPreparing: PropTypes.func,
    onContentReady: PropTypes.func,
    onRowDblClick: PropTypes.func,
    onRowPrepared: PropTypes.func,
    onFocusedCellChanging: PropTypes.func,
    onFocusedCellChanged: PropTypes.func,
    onRowInserting: PropTypes.func,
    onInitialized: PropTypes.func,
    onOptionChanged: PropTypes.func,
    onOrderChanged: PropTypes.func,
    onKeyDown: PropTypes.func,
    onCellHoverChanged: PropTypes.func,
    onCellSelectionChanged: PropTypes.func,
    onCellPrepared: PropTypes.func,
    loadMore: PropTypes.func,
    onRowValidating: PropTypes.func,
    onContextMenuPreparing: PropTypes.func,
    onFocusedRowChanging: PropTypes.func,
    onFocusedRowChanged: PropTypes.func,

    /**
     * get filter when changed..
     */
    onFilterChanged: PropTypes.func,
    onFilterGrid: PropTypes.func
};

export default GridContainer;
