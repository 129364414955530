/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 4/23/2021
 * @Example
 */

import React, {useEffect} from "react";
import Config             from "../../../../config";
import {browserHistory}   from "react-router";
import {LoadPanel}        from "devextreme-react";

const WA3F1025 = () => {

    const getLink = (item) => {
        if (!item) return "";
        const params = {
            route:     "W17F1025",
            type:      "Customer",
            ProductID: Config.env.productID || "WBEM"
        };
        const url    = item.url && item.url.indexOf("/") === item.url.length - 1
            ? item.url.substring(0, item.url.length - 1)
            : item.url;
        let link     = item.link ? item.link : url + "/auth?token=" + item.token;
        link         = link.indexOf("http") > -1 ? link : "";
        for (let key of Object.keys(params)) {
            link += "&" + key + "=" + params[key];
        }
        return link;
    };

    useEffect(() => {
        if (Config.products && Config.products.length > 0) {
            const appCRM = Config.products.find(p => p.appID === "WCRM");
            if (appCRM) {
                const link = getLink(appCRM);
                window.open(link);
                browserHistory.push(Config.getRootPath());
            }
        }
    }, []);

    return (
        <>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{
                    my: 'center',
                    of: "#root"
                }}
                visible={true}
                showIndicator={true}
                shading={false}
                showPane={true}
            />
        </>
    );
};

export default WA3F1025;
