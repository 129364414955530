/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 17/06/2021
 * @Example
 */
import { LoadPanel } from 'devextreme-react/load-panel';
import {
    Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Attachment, Button, Checkbox, DatePicker, Dropdown, Modal, ModalBody, ModalFooter, ModalHeader,
    MoneyInput, NumberInput, TextInput, Col, Row
} from 'diginet-core-ui/components';
import _ from "lodash";
import moment from "moment";
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Config from '../../../../config/index';
import * as generalActions from "../../../../redux/general/general_actions";
import * as WA3F2001Actions from "../../../../redux/modules/WA3/WA3F2001/WA3F2001_actions";
import MForm from "../../../common/forms/form-material/form";
import History from "../../../common/libs/history/history";
import CDN from "../../../common/utils/CDN";
import { useTheme } from 'diginet-core-ui/theme';

const FormID = "WA3F2001";
const { spacing } = useTheme();
const attachmentType = ["txt", "jpg", "png", "doc", "docx", "xls", "xlsx", "jpeg", "pdf", "gif", "ppt", "pptx"];
const initialState = {
    Auto: null,
    IsSigned: 0, // default unCheck
    IsCurrency: 0,  // default unCheck
    EmployeeID: "",
    EmployeeName: "",
    ContractDate: moment().format("YYYY-MM-DD"),
    StartDate: moment().format("YYYY-MM-DD"),
    EndDate: moment().format("YYYY-MM-DD"),
    OAmount: null,
    CAmount: null,
    OPromotionAmount: null,
    CPromotionAmount: null,
    ODiscountAmount: null,
    CDiscountAmount: null,
    OTotal: null,
    CTotal: null,
    SignDate: null,
    EStatus: "1", // default 1
    VATObjectTypeID: "KH",  // default KH
    CurrencyID: "VND", // default VND
    ExchangeRate: null,
    ContractID: "",
    ContractTypeID: "",
    ContractNo: "",
    OriginalContractNo: "",
    OriginalContractID: "",
    EStatusName: "",
    ContractDesc: "",
    ObjectID: "",
    ObjectName: "",
    CardID: "",
    Description: "",
    MembershipCardID: "",
    MembershipCardName: "",
    ObjectAddress: "",
    Position: "",
    ContactPerson: "",
    ContactTelNo: "",
    ContactFaxNo: "",
    VATNo: "",
    ContractRep: "",
    Email: "",
    VATObjectID: "",
    VATObjectName: "",
    PaymentMethodID: "",
    PaymentMethodName: "",
    PaymentTermID: "",
    PaymentTermName: "",
    SalesPersonID: "",
    SalesPersonName: "",
    BookingID: "",
};
const captions = {
    ContractTypeID: "Loai_hop_dong",
    ContractNo: "So_hop_Dong",
    OriginalContractNo: "So_hop_dong_truoc",
    EStatusName: "Trang_thai_hop_dongU",
    EmployeeName: "Nguoi_lap",
    ObjectID: "Ma_khach_hang",
    ObjectName: "Ten_khach_hang",
    PaymentTermName: "Dieu_khoan_TM",
    PaymentMethodName: "Phuong_thuc_TT",
    SalesPersonName: "Ten_NVKD",
};

const WA3F2001 = (props) => {
    const dispatch = useDispatch();
    const { openModal, onOpenModalAction, dataInfo: dataInfoProp = {} } = props;
    const dataCboEStatus = useSelector(state => state?.general?.dataCboEStatus ?? []);
    const dataCboCurrency = useSelector(state => state?.general?.dataCboCurrency ?? []);
    const dataCboContractType = useSelector(state => state?.general?.dataCboContractType ?? []);
    const dataCboPaymentTerm = useSelector(state => state?.general?.dataCboPaymentTerm ?? []);
    const dataCboPaymentMethod = useSelector(state => state?.general?.dataCboPaymentMethod ?? []);
    const DivisionID = _.get(Config.profile, "DivisionID", Config.getDivisionID()) || Config.getLocalStorage("DIVISIONBEM");

    //==========STATE==========
    const [Mode, setMode] = useState(_.get(dataInfoProp, "Mode", "add"));
    const [btnSaveStatus, setBtnSaveStatus] = useState(false);
    const [loading, _setLoading] = useState({
        formLoading: false,
        cboObjectLoading: false,
        cboContractTypeLoading: false,
        cboEStatusLoading: false,
        cboCreateUserLoading: false,
        cboCardLoading: false,
        cboCardOwnerLoading: false,
        cboVATObjectLoading: false,
        cboVATObjectTypeLoading: false,
        cboPaymentTermLoading: false,
        cboSalesPersonLoading: false,
        cboCurrencyLoading: false,
        cboPaymentMethodLoading: false,
    });
    const { formLoading, cboObjectLoading, cboContractTypeLoading, cboEStatusLoading, cboCreateUserLoading, cboCardLoading, cboCardOwnerLoading,
        cboVATObjectLoading, cboVATObjectTypeLoading, cboPaymentTermLoading, cboSalesPersonLoading, cboCurrencyLoading, cboPaymentMethodLoading } = loading;
    const [contractNoStatus, setcontractNoStatus] = useState(Mode === "view" || Mode === "edit");
    const [error, setError] = useState({});
    const [dataCboCreateUser, setDataCboCreateUser] = useState({
        rows: [],
        total: 0
    });
    const [dataCboCustomer, setDataCboCustomer] = useState({
        rows: [],
        total: 0
    });
    const [dataCboCardOwner, setDataCboCardOwner] = useState([]);
    const [dataCboCCard, setDataCboCCard] = useState([]);
    const [dataCboVATObjectType, setDataCboVATObjectType] = useState([]);
    const [dataCboVATObject, setDataCboVATObject] = useState([]);
    const [dataCboSalesPerson, setDataCboSalesPerson] = useState([]);

    const isModeAdd = Mode === "add" || Mode === "copy";

    const [dataMaster, setDataMaster] = useState(initialState);

    //=======REF==========
    const attRef = useRef(null);
    const contractNoRef = useRef(null);
    const saveSuccess = useRef(false);
    const refLoading = useRef({});
    const objValueDefault = useRef({});
    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const oldDataMaster = useRef({});
    const oldDataAttachments = useRef([]);
    const filterCboCreateUsers = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    const filterCboCustomer = useRef({
        skip: 0,
        limit: 20,
        search: ""
    });
    const dataInfo = useRef({
        iPermission: _.get(dataInfoProp, "Permission", 0),
        ContractID: _.get(dataInfoProp, "ContractID", ""),
        ContractNo: _.get(dataInfoProp, "ContractNo", ""),
        ObjectID: _.get(dataInfoProp, "ObjectID", ""),
        DataType: _.get(dataInfoProp, "DataType", ""),
    });
    //=======Variable==========
    const disabled = useMemo(() => {
        return formLoading || Mode === "view";
    }, [formLoading, Mode]);
    const btnActionStatus = !(dataInfo.current.iPermission >= 2);

    const setLoading = (obj) => {
        refLoading.current = { ...refLoading.current, ...obj };
        _setLoading(refLoading.current);
    };

    useEffect(() => {
        if (_.isEmpty(dataCboCurrency)) getDataCboGeneral("getCboCurrency");
        if (_.isEmpty(dataCboContractType)) getDataCboGeneral("getCboContractType");
        if (_.isEmpty(dataCboEStatus)) getDataCboGeneral("getCboEStatus");
        if (_.isEmpty(dataCboPaymentTerm)) getDataCboGeneral("getCboPaymentTerm");
        if (_.isEmpty(dataCboPaymentMethod)) getDataCboGeneral("getCboPaymentMethod");
        loadCboCard();
        loadCboCardOwner();
        loadCboCreateUser();
        loadCboCustomer();
        loadCboVATObject();
        loadCboVATObjectType();
        loadCboSalesPerson();
        const loadFormType = ["LoadReSignContract", "LoadAddendumContract"];
        if (Mode !== "add" || loadFormType.includes(dataInfo.current?.DataType)) loadForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dataCboCurrency && isModeAdd) {
            objValueDefault.current = { ...objValueDefault.current, ExchangeRate: _.get(dataCboCurrency.find(item => item.CurrencyID === "VND"), "ExchangeRate", null) };
            setDefaultValueGeneral();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboCurrency, isModeAdd]);

    useEffect(() => {
        if (!_.isEmpty(dataCboCreateUser?.rows) && isModeAdd) {
            const dataEmployee = dataCboCreateUser.rows.find(item => item?.UserID === _.get(Config.profile, "UserID", "")); // Default theo UserID
            if (!_.isEmpty(dataEmployee)) {
                objValueDefault.current = { ...objValueDefault.current, EmployeeID: dataEmployee?.EmployeeID, EmployeeName: dataEmployee?.EmployeeName };
                setDefaultValueGeneral();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboCreateUser, isModeAdd]);

    useEffect(() => {
        if (dataMaster.Auto === 1) loadConTractNo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMaster.ContractTypeID]);

    const setDefaultValueGeneral = () => { // Dùng để set default value
        if (!_.isEmpty(objValueDefault.current)) {
            setDataMaster({ ...dataMaster, ...objValueDefault.current });
        }
    };

    const dataCboPaymentTermCustom = useMemo(() => {
        if (_.isEmpty(dataCboPaymentTerm)) return;
        return dataCboPaymentTerm.map(item => {
            const PaymentTermID = _.get(item, "PaymentTermID", "");
            const PaymentTermName = _.get(item, "PaymentTermName", "");
            const line = _.isEmpty(PaymentTermID) || _.isEmpty(PaymentTermName) ? "" : "-";
            return ({ ...item, PaymentTermCustomName: `${PaymentTermID} ${line} ${PaymentTermName}` })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboPaymentTerm]);

    const dataCboPaymentMethodCustom = useMemo(() => {
        if (_.isEmpty(dataCboPaymentMethod)) return;
        return dataCboPaymentMethod.map(item => {
            const PaymentMethodID = _.get(item, "PaymentMethodID", "");
            const PaymentMethodName = _.get(item, "PaymentMethodName", "");
            const line = _.isEmpty(PaymentMethodID) || _.isEmpty(PaymentMethodName) ? "" : "-";
            return ({ ...item, PaymentMethodCustomName: `${PaymentMethodID} ${line} ${PaymentMethodName}` })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboPaymentMethod]);

    const dataCboContractTypeCustom = useMemo(() => {
        if (_.isEmpty(dataCboContractType)) return;
        return dataCboContractType.map(item => {
            const ContractTypeID = _.get(item, "ContractTypeID", "");
            const ContractTypeName = _.get(item, "ContractTypeName", "");
            const line = _.isEmpty(ContractTypeName) || _.isEmpty(ContractTypeName) ? "" : "-";
            return ({ ...item, ContractTypeCustomName: `${ContractTypeID} ${line} ${ContractTypeName}` })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboContractType]);

    const loadForm = () => {
        const { ObjectID, ContractID, ContractNo, DataType } = dataInfo.current;
        const param = {
            FormID,
            ObjectID,
            ContractID,
            ContractNo,
            DivisionID,
            DataType
        };
        setLoading({ formLoading: true });
        dispatch(WA3F2001Actions.loadForm(param, (error, data) => {
            setLoading({ formLoading: false });
            if (error) {
                oldDataMaster.current = initialState;
                oldDataAttachments.current = [];
                setDataMaster(initialState);
                setDataOldAttachments([]);
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                if (data.master) {
                    oldDataMaster.current = { ...data.master };
                    setDataMaster(data.master);
                }
                if (data.attachment) {
                    oldDataAttachments.current = [...data.attachment];
                    setDataOldAttachments(data.attachment);
                }
            }
        }));
    };

    const loadConTractNo = () => {
        const param = {
            DivisionID,
            VoucherTypeID: _.get(dataMaster, "ContractTypeID", ""),
        };
        setLoading({ cboContractTypeLoading: true });
        dispatch(WA3F2001Actions.loadConTractNo(param, (error, data) => {
            setLoading({ cboContractTypeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataMaster({ ...dataMaster, ContractNo: _.get(data, "VoucherNo", "") });
            }
        }));
    };

    const setCboLoading = (cboName = "", status) => {
        if (_.isEmpty(cboName) || !_.isBoolean(status)) return;
        switch (cboName) {
            case "getCboContractType":
                setLoading({ cboContractTypeLoading: status });
                break;
            case "getCboEStatus":
                setLoading({ cboEStatusLoading: status });
                break;
            case "getCboPaymentTerm":
                setLoading({ cboPaymentTermLoading: status });
                break;
            case "getCboPaymentMethod":
                setLoading({ cboPaymentMethodLoading: status });
                break;
            case "getCboCurrency":
                setLoading({ cboCurrencyLoading: status });
                break;
            default:
                break;
        }
    };

    const loadCboSalesPerson = () => {
        const { ObjectID = "", BookingID = "", ObjectTypeID = "NV" } = dataMaster;
        const param = {
            FormID,
            ObjectID,
            BookingID,
            ObjectTypeID
        };
        setLoading({ cboSalesPersonLoading: true });
        dispatch(generalActions.getCboSalesPerson(param, (error, data) => {
            setLoading({ cboSalesPersonLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataCboSalesPerson(data);
            }
        }));
    };

    const loadCboVATObject = () => {
        const { ObjectID = "", BookingID = "", ObjectTypeID = "KH" } = dataMaster;
        const param = {
            FormID,
            ObjectID,
            ObjectTypeID,
            BookingID
        };
        setLoading({ cboVATObjectLoading: true });
        dispatch(generalActions.getCboVATObject(param, (error, data) => {
            setLoading({ cboVATObjectLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataCboVATObject(data);
            }
        }));
    };

    const loadCboVATObjectType = () => {
        const { ObjectID = "", BookingID = "", ObjectTypeID = "" } = dataMaster;
        const param = {
            FormID,
            ObjectID,
            ObjectTypeID,
            BookingID
        };
        setLoading({ cboVATObjectTypeLoading: true });
        dispatch(generalActions.getCboVATObjectType(param, (error, data) => {
            setLoading({ cboVATObjectTypeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataCboVATObjectType(data);
            }
        }));
    };

    const loadCboCard = () => {
        const { ObjectID = "", ObjectTypeID = "" } = dataMaster;
        const param = {
            FormID,
            ObjectID,
            ObjectTypeID
        };
        setLoading({ cboCardLoading: true });
        dispatch(generalActions.getCboCard(param, (error, data) => {
            setLoading({ cboCardLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataCboCCard(data);
            }
        }));
    };

    const loadCboCardOwner = () => {
        const { ObjectID = "", BookingID = "", ObjectTypeID = "KH" } = dataMaster;
        const param = {
            FormID,
            ObjectID,
            BookingID,
            ObjectTypeID
        };
        setLoading({ cboCardOwnerLoading: true });
        dispatch(generalActions.getCboCardOwner(param, (error, data) => {
            setLoading({ cboCardOwnerLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataCboCardOwner(data);
            }
        }));
    };

    const getDataCboGeneral = (cboName = "") => {
        setCboLoading(cboName, true);
        dispatch(
            generalActions[cboName]((error) => {
                setCboLoading(cboName, false);
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                }
            })
        );
    };

    const loadCboCreateUser = () => {
        const { ObjectID = "", BookingID = "", ObjectTypeID = "" } = dataMaster;
        const { skip, limit, search } = filterCboCreateUsers.current;
        const param = {
            FormID,
            ObjectID,
            ObjectTypeID,
            BookingID,
            skip,
            limit,
            search
        };
        setLoading({ cboCreateUserLoading: true });
        dispatch(generalActions.getCboCreateUser(param, (error, data) => {
            setLoading({ cboCreateUserLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                const rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                setDataCboCreateUser({
                    rows: skip === 0 ? rows : dataCboCreateUser.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const loadCboCustomer = () => {
        const { ObjectID = "", BookingID = "" } = dataMaster;
        const { skip, limit, search } = filterCboCustomer.current;
        const param = {
            FormID,
            ObjectID,
            BookingID,
            skip,
            limit,
            search
        };
        setLoading({ cboCustomerLoading: true });
        dispatch(generalActions.getCboCustomer(param, (error, data) => {
            setLoading({ cboCustomerLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            else if (data) {
                let rows = _.get(data, "rows", []);
                const total = _.get(data, "total", 0);
                if (!_.isEmpty(rows)) {
                    rows = rows.map(item => {
                        const line = _.isEmpty(item.ObjectID) || _.isEmpty(item.ObjectName) ? "" : "-";
                        return ({ ...item, ObjectCustomName: `${item.ObjectID} ${line} ${item.ObjectName}` })
                    });
                }
                setDataCboCustomer({
                    rows: skip === 0 ? rows : dataCboCustomer.rows.concat(rows),
                    total
                });
            }
        }));
    };

    const onChange = async (fieldName, e) => {
        if (!e || _.isEmpty(fieldName)) return;
        let value = _.isObject(e) ? _.get(e, "data.value", _.get(e, "value", _.get(e, "target.value", ""))) : e;
        let objectValuePlus = {};
        switch (fieldName) {
            //DropDown
            case "ContractTypeID":
            case "EStatus":
            case "EmployeeID":
            case "CardID":
            case "MembershipCardID":
            case "VATObjectTypeID":
            case "VATObjectID":
            case "PaymentTermID":
            case "PaymentMethodID":
            case "SalesPersonID":
            case "CurrencyID":
            case "ObjectID":
                const positionID = fieldName.indexOf("ID");
                const standardDDName = positionID > 0 ? fieldName.slice(0, positionID) : fieldName;
                let DDName = standardDDName + "Name";
                let valueDDName = _.get(e.data, [DDName], _.get(e.data, "ObjectName", ""));
                if (_.isUndefined(valueDDName) && fieldName === "CardID") {
                    DDName = "Description";
                    valueDDName = _.get(e, "data.Description", "");
                }
                objectValuePlus = { [DDName]: valueDDName };
                const { ObjectName = "", ObjectAddress = "", Position = "", ContactPerson = "", ContactTelNo = "",
                    ContactFaxNo = "", VATNo = "", Email = "", ExchangeRate = "", Auto = null } = _.get(e, "data", {});
                if (fieldName === "ContractTypeID") {
                    if (Auto === 0 || _.isEmpty(value)) { // Tự Nhập hoặc clear Data
                        setcontractNoStatus(_.isEmpty(value));
                        objectValuePlus = { ...objectValuePlus, Auto, ContractNo: "" };
                        if (contractNoRef.current) contractNoRef.current.value = "";
                    } else if (Auto === 1) {
                        setcontractNoStatus(true);
                        objectValuePlus = { ...objectValuePlus, Auto };
                    }
                } else if (fieldName === "ObjectID") { // Cbo Khách hàng
                    objectValuePlus = {
                        ...objectValuePlus,
                        ObjectName,
                        ObjectAddress,
                        Position,
                        ContactPerson,
                        ContactTelNo,
                        ContactFaxNo,
                        VATNo,
                        ContractRep: ContactPerson,
                        Email,
                        VATObjectID: value,
                        MembershipCardID: value,
                        VATObjectName: ObjectName,
                    };
                } else if (fieldName === "VATObjectID") { // Cbo Mã đối tượng
                    objectValuePlus = {
                        ...objectValuePlus,
                        ObjectAddress,
                    };
                } else if (fieldName === "CurrencyID") {
                    objectValuePlus = {
                        ...objectValuePlus,
                        ExchangeRate,
                    };
                }
                break;
            //Checkbox
            case "IsSigned":
            case "IsCurrency":
                value = value ? 1 : 0;
                if (value === 0) {
                    if (fieldName === "IsSigned") {
                        objectValuePlus = { ...objectValuePlus, SignDate: null };
                        if (error.hasOwnProperty("SignDate")) setError(_.omit(error, "SignDate"));
                    } else if (fieldName === "IsCurrency") {
                        objectValuePlus = { ...objectValuePlus, CurrencyID: "VND" };
                    }
                }
                break;
            default:
                break;
        }
        objectValuePlus = { ...objectValuePlus, [fieldName]: value };
        if (dataMaster[fieldName] !== value) {
            setDataMaster({
                ...dataMaster,
                ...objectValuePlus,
            });
        }
        if (error.hasOwnProperty(fieldName) && !Config.isEmpty(value, true)) {
            if (fieldName === "ContractTypeID" && _.get(e, "data.Auto", null) === 1) {
                setError(_.omit(error, [fieldName, "ContractNo"]));
            } else {
                setError(_.omit(error, fieldName));
            }
        }
    };

    const onChangeAttachments = (e) => {
        attachments.current = _.get(e, "allNewAttached", []);
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [...e.removedAttached];
            const _arrRemove = deletedFile.current.map(d => d.AttachmentID);
            setDataOldAttachments(
                dataOldAttachments.filter(att => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            );
        }
    };

    const setStateErrorText = (objValue) => {
        setError({ ...error, ...objValue });
        return Object.keys(objValue).length !== 0;
    };

    const checkValidateMaster = (arrName) => {
        if (_.isEmpty(arrName)) return;
        return arrName.map(item => {
            return {
                name: item,
                rules: ["isRequired"],
                value: dataMaster[item]
            }
        });
    };

    const onSaveHistory = async (ContractID = "", dataAttachmentCDN = []) => {
        let action = 0; // add hoac copy
        let TransactionName = Config.lang("Chi_tiet_bang_muc_tieu");
        if (Mode === "edit") action = 1;
        if (dataInfo.current?.DataType === "LoadAddendumContract") TransactionName = Config.lang("Phu_luc_hop_dong");
        const captionAttachment = {
            URL: "URL",
            FileName: "Ten_dinh_kem",
        };
        const newData = { ...dataMaster };
        const oldData = { ...oldDataMaster.current };
        const options = {
            data: newData,
            dataCompare: oldData,
            captions,
            action,
            ModuleID: "DA3",
            TransactionID: FormID,
            TransID: ContractID ? ContractID : _.get(dataInfo.current, "ContractID", ""),
            TransactionName
        };
        const history = new History(options);
        const attachment = [...dataAttachmentCDN, ...dataOldAttachments]; // Đính kèm mới
        const dataAttachmentOld = oldDataAttachments.current; // Đính kèm cũ
        history.createDetailHistory("Dinh_kem", attachment, dataAttachmentOld, captionAttachment, "URL", null, options);
        // console.log("===========HIS=========>", history.get());
        return history.get()?.length > 0 ? await history.save() : true;
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const _getAttachments = (file) => {
        const dataFile = _.get(file, "data.paths", []);
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach(att => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split(".").pop() : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    const getFieldName = (keyName) => {
        let fieldName = "";
        switch (keyName) {
            case "ContractTypeID":
                fieldName = "Loai_hop_dong";
                break;
            case "ContractNo":
                fieldName = "So_hop_Dong";
                break;
            case "EStatus":
                fieldName = "Trang_thai_hop_dongU";
                break;
            case "ContractDate":
                fieldName = "Ngay_hop_dong";
                break;
            case "StartDate":
                fieldName = "Hieu_luc";
                break;
            case "ObjectID":
                fieldName = "Ma_khach_hang";
                break;
            case "SalesPersonID":
                fieldName = "Ma_NVKD";
                break;
            default:
                break;
        }
        return fieldName;
    };

    const onSave = async () => {
        let apiSave = "onSave";
        const requiredField = ["ContractTypeID", "ContractDate", "StartDate", "ObjectID", "ContractNo"];
        if (dataMaster.IsSigned === 1) requiredField.push("SignDate");
        const resultValidate = checkValidateMaster(requiredField);
        const validateForm = MForm.formValidation(resultValidate);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            const fieldName = getFieldName(_.keys(validateForm)[0]);
            Config.popup.show("INFO", `${Config.lang("Ban_chua_nhap_gia_tri")}: ${Config.lang(fieldName)}`);
            return false;
        } else {
            if (Mode === "edit") apiSave = "onSaveEdit";
            const dataResUploadFile = !_.isEmpty(attachments.current) ? await _uploadFile(attachments.current, true) : {};
            const attachment = JSON.stringify(_getAttachments(dataResUploadFile));
            const { Auto, ContractTypeID, ContractID, ContractNo } = dataMaster;
            const param = {
                FormID,
                DivisionID,
                Auto,
                VoucherTypeID: ContractTypeID,
                ContractID,
                ContractNo,
                attachment,
                data: [dataMaster]
            };
            setLoading({ formLoading: true });
            dispatch(WA3F2001Actions[apiSave](param, async (error, data) => {
                setLoading({ formLoading: false });
                if (error) {
                    Config.popup.show("ERROR", error);
                    return false;
                } else if (data) {
                    if (data.Status === 0) {
                        let dataAttachmentCDN = _.get(dataResUploadFile, "data.paths", []);
                        if (!_.isEmpty(dataAttachmentCDN)) {
                            dataAttachmentCDN = dataAttachmentCDN.map(item => ({
                                ...item,
                                URL: _.get(item, "url", ""),
                                FileName: _.get(item, "fileName", "")
                            }));
                        }
                        const contractID = _.get(data, "ContractID", ContractID) || "";
                        const contractNo = _.get(data, "ContractNo", ContractNo) || "";
                        if (Mode === "add") {
                            dataInfo.current.ContractID = contractID;
                            dataInfo.current.ContractNo = contractNo;
                            setDataMaster({ ...dataMaster, ContractID: contractID, ContractNo: contractNo });
                        }
                        if (contractID) await onSaveHistory(contractID, dataAttachmentCDN);
                        saveSuccess.current = true;
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                        setBtnSaveStatus(true);
                        setMode("view"); // Chuyển về View
                    } else {
                        Config.popup.show("INFO", _.get(data, "Message", "") || Config.lang("Loi_chua_xac_dinh"));
                        return false;
                    }
                }
            }));
        }
    };

    const onEdit = () => {
        setMode("edit");
        setBtnSaveStatus(false);
    };

    const onNext = () => {
        setMode("add");
        setBtnSaveStatus(false);
        if (attRef.current) attRef.current.clear(); // clear all đính kèm
        setDataMaster(initialState);
    };

    if (!dataInfo.current.iPermission) return null;
    return (
        <Modal
            id={"Popup-WA3F2001"}
            zIndex={1020}
            width={"1440"}
            open={openModal}
            onClose={() => {
                if (onOpenModalAction) {
                    let reLoad = false;
                    if (saveSuccess.current === true) reLoad = true;
                    onOpenModalAction(false, reLoad);
                }
            }}>
            <ModalHeader>{Config.lang("Lap_hop_dong_dich_vu")}</ModalHeader>
            <ModalBody>
                <React.Fragment>
                    <LoadPanel
                        position={{ my: 'center', of: '#Popup-WA3F2001' }}
                        visible={formLoading}
                        showIndicator={true}
                        shading={true}
                        showPane={true}
                    />
                    <Row>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Dropdown
                                clearAble
                                required={true}
                                readOnly={disabled || Mode === "edit"}
                                valueExpr={"ContractTypeID"}
                                keyExpr={"ContractTypeName"}
                                label={Config.lang("Loai_hop_dong")}
                                error={error && error["ContractTypeID"]}
                                displayExpr={"ContractTypeCustomName"}
                                onChange={e => onChange("ContractTypeID", e)}
                                dataSource={dataCboContractTypeCustom}
                                loading={cboContractTypeLoading}
                                value={dataMaster.ContractTypeID}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <TextInput
                                required={true}
                                inputRef={contractNoRef}
                                endIcon={"SyncHorizontal"}
                                error={error && error["ContractNo"]}
                                label={Config.lang("So_hop_Dong")}
                                onChange={(e) => onChange("ContractNo", e)}
                                value={dataMaster.ContractNo}
                                readOnly={disabled || contractNoStatus || cboContractTypeLoading}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <TextInput
                                readOnly={true}
                                label={Config.lang("So_hop_dong_truoc")}
                                onChange={(e) => onChange("OriginalContractNo", e)}
                                value={dataMaster.OriginalContractNo}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Dropdown
                                clearAble
                                required={true}
                                valueExpr={"EStatus"}
                                keyExpr={"EStatusName"}
                                displayExpr={"EStatusName"}
                                label={Config.lang("Trang_thai_hop_dongU")}
                                error={error && error["EStatus"]}
                                onChange={e => onChange("EStatus", e)}
                                readOnly={disabled}
                                dataSource={dataCboEStatus}
                                loading={cboEStatusLoading}
                                value={dataMaster.EStatus}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} verticalAlign={"end"}>
                            <Dropdown
                                clearAble
                                allowSearch
                                searchDelayTime={700}
                                readOnly={disabled || Mode === "edit"}
                                dataSource={_.get(dataCboCreateUser, "rows", [])}
                                total={_.get(dataCboCreateUser, "total", 0)}
                                skip={filterCboCreateUsers.current.skip}
                                limit={filterCboCreateUsers.current.limit}
                                value={dataMaster.EmployeeID}
                                loading={cboCreateUserLoading}
                                valueExpr={"EmployeeID"}
                                displayExpr={"EmployeeName"}
                                label={Config.lang("Nguoi_lap")}
                                onChange={(e) => onChange("EmployeeID", e)}
                                {...(dataMaster.EmployeeID && dataMaster.EmployeeName ? {
                                    valueObjectDefault: {
                                        EmployeeID: dataMaster.EmployeeID,
                                        EmployeeName: dataMaster.EmployeeName
                                    }
                                } : {})}
                                onLoadMore={(e) => {
                                    filterCboCreateUsers.current.skip = e.skip;
                                    filterCboCreateUsers.current.limit = e.limit;
                                    loadCboCreateUser();
                                }}
                                onInput={(e) => {
                                    const value = e?.target?.value || "";
                                    filterCboCreateUsers.current.search = value;
                                    filterCboCreateUsers.current.skip = 0;
                                    loadCboCreateUser();
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3} verticalAlign={"end"}>
                            <DatePicker
                                clearAble
                                required={true}
                                readOnly={disabled || Mode === "edit"}
                                width={"100%"}
                                placeholder={"dd/mm/yyyy"}
                                displayFormat={"DD/MM/YYYY"}
                                label={Config.lang("Ngay_hop_dong")}
                                value={dataMaster.ContractDate}
                                error={error && error["ContractDate"]}
                                onChange={e => onChange("ContractDate", e)}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={3}>
                            <Checkbox
                                color={"primary"}
                                readOnly={disabled}
                                label={Config.lang("Da_ky_ngay")}
                                checked={dataMaster.IsSigned === 1}
                                onChange={e => onChange("IsSigned", e)}
                            />
                            <DatePicker
                                readOnly={disabled || dataMaster.IsSigned !== 1}
                                displayFormat={"DD/MM/YYYY"}
                                required={dataMaster.IsSigned === 1}
                                error={error && error["SignDate"]}
                                value={dataMaster.SignDate}
                                onChange={e => onChange("SignDate", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={3} verticalAlign={"end"}>
                            <Row className={"display_row"}>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <DatePicker
                                        clearAble
                                        required={true}
                                        readOnly={disabled || Mode === "edit"}
                                        displayFormat={"DD/MM/YYYY"}
                                        label={Config.lang("Hieu_luc")}
                                        error={error && error["StartDate"]}
                                        value={dataMaster.StartDate}
                                        onChange={e => onChange("StartDate", e)}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6} className={"display_row align-right"}>
                                    <DatePicker
                                        clearAble
                                        className={"full_w"}
                                        readOnly={disabled || Mode === "edit"}
                                        displayFormat={"DD/MM/YYYY"}
                                        value={dataMaster.EndDate}
                                        onChange={e => onChange("EndDate", e)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                readOnly={disabled}
                                label={Config.lang("Dien_giai_hop_dong")}
                                onChange={(e) => onChange("ContractDesc", e)}
                                value={dataMaster.ContractDesc}
                            />
                        </Col>
                        <Col style={{ margin: spacing([6, 0]) }} xs={12} sm={12} md={12} lg={12}>
                            <Attachment
                                ref={attRef}
                                accept={attachmentType}
                                isStripDomain={true}
                                style={{ minHeight: 120 }}
                                domain={Config.getCDNPath()}
                                data={dataOldAttachments}
                                onChange={onChangeAttachments}
                                readOnly={disabled}
                                uploadErrorInfo={{
                                    existingFile: Config.lang("File_da_duoc_dinh_kem"),
                                    maxFile: Config.lang("File_vuot_qua_so_luong_cho_phep"),
                                    maxSize: Config.lang("File_vuot_qua_dung_luong_cho_phep"),
                                    fileType: `${Config.lang("Chi_ho_tro_dinh_kem_cac_file_co_dinh_dang")}: ${attachmentType.join(', ')}`,
                                }}
                            />
                        </Col>
                    </Row>
                    <AccordionGroup>
                        <Accordion expand={true}>
                            <AccordionSummary>
                                {Config.lang("Thong_tin_khach_hang")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            required
                                            clearAble
                                            allowSearch
                                            searchDelayTime={700}
                                            error={error && error["ObjectID"]}
                                            dataSource={_.get(dataCboCustomer, "rows", [])}
                                            total={_.get(dataCboCustomer, "total", 0)}
                                            skip={filterCboCustomer.current.skip}
                                            limit={filterCboCustomer.current.limit}
                                            value={dataMaster.ObjectID}
                                            loading={cboObjectLoading}
                                            valueExpr={"ObjectID"}
                                            readOnly={disabled || Mode === "edit"}
                                            label={Config.lang("Ma_khach_hang")}
                                            placeholder={Config.lang("Chon")}
                                            displayExpr={"ObjectCustomName"}
                                            onChange={(e) => onChange("ObjectID", e)}
                                            {...(dataMaster.ObjectID && dataMaster.ObjectName ? {
                                                valueObjectDefault: {
                                                    ObjectID: dataMaster.ObjectID,
                                                    ObjectCustomName: `${dataMaster.ObjectID} - ${dataMaster.ObjectName}`
                                                }
                                            } : {})}
                                            onLoadMore={(e) => {
                                                filterCboCustomer.current.skip = e.skip;
                                                filterCboCustomer.current.limit = e.limit;
                                                loadCboCustomer();
                                            }}
                                            onInput={(e) => {
                                                const value = e?.target?.value || "";
                                                filterCboCustomer.current.search = value;
                                                filterCboCustomer.current.skip = 0;
                                                loadCboCustomer();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Ten_khach_hang")}
                                            value={dataMaster.ObjectName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            readOnly={disabled}
                                            dataSource={dataCboCCard}
                                            value={dataMaster.CardID}
                                            loading={cboCardLoading}
                                            valueExpr={"CardID"}
                                            keyExpr={"Description"}
                                            displayExpr={"Description"}
                                            label={Config.lang("The_thanh_vien")}
                                            onChange={(e) => onChange("CardID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            readOnly={disabled}
                                            dataSource={dataCboCardOwner}
                                            value={dataMaster.MembershipCardID}
                                            loading={cboCardOwnerLoading}
                                            valueExpr={"ObjectID"}
                                            keyExpr={"ObjectName"}
                                            displayExpr={"ObjectName"}
                                            label={Config.lang("Chu_the_thanh_vien")}
                                            onChange={(e) => onChange("MembershipCardID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Dia_chi")}
                                            onChange={(e) => onChange("ObjectAddress", e)}
                                            value={dataMaster.ObjectAddress}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Chuc_vu")}
                                            onChange={(e) => onChange("Position", e)}
                                            value={dataMaster.Position}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Nguoi_lien_he")}
                                            onChange={(e) => onChange("ContactPerson", e)}
                                            value={dataMaster.ContactPerson}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("So_DT")}
                                            onChange={(e) => onChange("ContactTelNo", e)}
                                            value={dataMaster.ContactTelNo}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("So_Fax")}
                                            onChange={(e) => onChange("ContactFaxNo", e)}
                                            value={dataMaster.ContactFaxNo}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Ma_so_thue")}
                                            onChange={(e) => onChange("VATNo", e)}
                                            value={dataMaster.VATNo}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Nguoi_dai_dien")}
                                            onChange={(e) => onChange("ContractRep", e)}
                                            value={dataMaster.ContractRep}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={6}>
                                        <TextInput
                                            readOnly={disabled}
                                            label={Config.lang("Email")}
                                            onChange={(e) => onChange("Email", e)}
                                            value={dataMaster.Email}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            readOnly={disabled}
                                            dataSource={dataCboVATObjectType}
                                            value={dataMaster.VATObjectTypeID}
                                            loading={cboVATObjectTypeLoading}
                                            valueExpr={"ObjectTypeID"}
                                            keyExpr={"ObjectTypeName"}
                                            displayExpr={"ObjectTypeName"}
                                            label={Config.lang("Loai_doi_tuong_GTGT")}
                                            onChange={(e) => onChange("VATObjectTypeID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            readOnly={disabled}
                                            dataSource={dataCboVATObject}
                                            value={dataMaster.VATObjectID}
                                            loading={cboVATObjectLoading}
                                            valueExpr={"ObjectID"}
                                            displayExpr={"{ObjectID} - {ObjectName}"}
                                            label={Config.lang("Ma_doi_tuong_GTGT")}
                                            onChange={(e) => onChange("VATObjectID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={8} lg={6}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Ten_doi_tuong")}
                                            value={dataMaster.VATObjectName}
                                        />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                    <AccordionGroup>
                        <Accordion expand={true}>
                            <AccordionSummary>
                                {Config.lang("Thong_tin_thuong_mai")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            readOnly={disabled}
                                            title={dataMaster.PaymentTermName}
                                            valueExpr={"PaymentTermID"}
                                            keyExpr={"PaymentTermCustomName"}
                                            displayExpr={"PaymentTermCustomName"}
                                            label={Config.lang("Dieu_khoan_TM")}
                                            onChange={e => onChange("PaymentTermID", e)}
                                            dataSource={dataCboPaymentTermCustom}
                                            loading={cboPaymentTermLoading}
                                            value={dataMaster.PaymentTermID}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            readOnly={disabled}
                                            title={dataMaster.PaymentMethodName}
                                            valueExpr={"PaymentMethodID"}
                                            keyExpr={"PaymentMethodCustomName"}
                                            displayExpr={"PaymentMethodCustomName"}
                                            label={Config.lang("Phuong_thuc_TT")}
                                            onChange={e => onChange("PaymentMethodID", e)}
                                            dataSource={dataCboPaymentMethodCustom}
                                            loading={cboPaymentMethodLoading}
                                            value={dataMaster.PaymentMethodID}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <Dropdown
                                            clearAble
                                            readOnly={disabled}
                                            dataSource={dataCboSalesPerson}
                                            value={dataMaster.SalesPersonID}
                                            loading={cboSalesPersonLoading}
                                            valueExpr={"ObjectID"}
                                            displayExpr={"{ObjectID} - {ObjectName}"}
                                            label={Config.lang("Ma_NVKD")}
                                            onChange={(e) => onChange("SalesPersonID", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3}>
                                        <TextInput
                                            readOnly={true}
                                            label={Config.lang("Ten_NVKD")}
                                            value={dataMaster.SalesPersonName}
                                        />
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                    <AccordionGroup>
                        <Accordion expand={true}>
                            <AccordionSummary>
                                {Config.lang("Gia_tri_hoa_don")}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <Row className={`wrap align-right`}>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <Checkbox
                                                    color={"primary"}
                                                    readOnly={disabled}
                                                    label={Config.lang("Ngoai_te")}
                                                    checked={dataMaster.IsCurrency === 1}
                                                    onChange={e => onChange("IsCurrency", e)}
                                                />
                                                <Dropdown
                                                    clearAble
                                                    readOnly={disabled || dataMaster.IsCurrency !== 1}
                                                    valueExpr={"CurrencyID"}
                                                    displayExpr={"{CurrencyID} - {CurrencyName}"}
                                                    onChange={e => onChange("CurrencyID", e)}
                                                    dataSource={dataCboCurrency}
                                                    loading={cboCurrencyLoading}
                                                    value={dataMaster.CurrencyID}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <NumberInput
                                                    readOnly={true}
                                                    label={Config.lang("Ty_gia")}
                                                    value={dataMaster.ExchangeRate}
                                                    defaultValue={_.get(dataCboCurrency.find(item => item.CurrencyID === "VND"), "ExchangeRate", null)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6} style={{ marginTop: spacing(2) }}>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <MoneyInput
                                                    readOnly={true}
                                                    thousandSeparator={","}
                                                    label={Config.lang("Tong_tien_truoc_GG_KM")}
                                                    onChange={(e) => onChange("OAmount", e)}
                                                    value={dataMaster.OAmount}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                {dataMaster.IsCurrency === 1 &&
                                                    <MoneyInput
                                                        readOnly={true}
                                                        thousandSeparator={","}
                                                        label={Config.lang("Tong_tien_truoc_GG_KM_quy_doi")}
                                                        onChange={(e) => onChange("CAmount", e)}
                                                        value={dataMaster.CAmount}
                                                    />
                                                }
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <MoneyInput
                                                    readOnly={true}
                                                    thousandSeparator={","}
                                                    label={Config.lang("Tong_tien_khuyen_mai")}
                                                    onChange={(e) => onChange("OPromotionAmount", e)}
                                                    value={dataMaster.OPromotionAmount}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                {dataMaster.IsCurrency === 1 &&
                                                    <MoneyInput
                                                        readOnly={true}
                                                        thousandSeparator={","}
                                                        label={Config.lang("Tong_tien_khuyen_mai_quy_doi")}
                                                        onChange={(e) => onChange("CPromotionAmount", e)}
                                                        value={dataMaster.CPromotionAmount}
                                                    />
                                                }
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <MoneyInput
                                                    readOnly={true}
                                                    thousandSeparator={","}
                                                    label={Config.lang("Tong_tien_giam_gia")}
                                                    onChange={(e) => onChange("ODiscountAmount", e)}
                                                    value={dataMaster.ODiscountAmount}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                {dataMaster.IsCurrency === 1 &&
                                                    <MoneyInput
                                                        readOnly={true}
                                                        thousandSeparator={","}
                                                        label={Config.lang("Tong_tien_giam_gia_quy_doi")}
                                                        onChange={(e) => onChange("CDiscountAmount", e)}
                                                        value={dataMaster.CDiscountAmount}
                                                    />
                                                }
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <MoneyInput
                                                    readOnly={true}
                                                    thousandSeparator={","}
                                                    label={Config.lang("Tong_tien_sau_GG_KM")}
                                                    onChange={(e) => onChange("OTotal", e)}
                                                    value={dataMaster.OTotal}
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                {dataMaster.IsCurrency === 1 &&
                                                    <MoneyInput
                                                        readOnly={true}
                                                        thousandSeparator={","}
                                                        label={Config.lang("Tong_tien_sau_GG_KM_quy_doi")}
                                                        onChange={(e) => onChange("CTotal", e)}
                                                        value={dataMaster.CTotal}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <div className={"wrap"}>
                    <Button
                        // disabled={formLoading ||btnActionStatus || !btnSaveStatus}
                        disabled={true}
                        style={{ marginRight: spacing(2) }}
                        size={"medium"}
                        color={"primary"}
                        startIcon={"Printer"}
                        viewType={"outlined"}
                        label={Config.lang("In1")}
                    // onClick={onPrint}
                    />
                    <Button
                        disabled={formLoading || btnActionStatus || Mode !== "view"}
                        style={{ marginRight: spacing(2) }}
                        size={"medium"}
                        color={"primary"}
                        startIcon={"Edit"}
                        viewType={"outlined"}
                        label={Config.lang("Sua")}
                        onClick={onEdit}
                    />
                    <Button
                        disabled={formLoading || btnActionStatus || Mode === "add" || !btnSaveStatus}
                        style={{ marginRight: spacing(2) }}
                        size={"medium"}
                        color={"primary"}
                        startIcon={"Plus"}
                        viewType={"outlined"}
                        label={Config.lang("Nhap_tiep")}
                        onClick={onNext}
                    />
                    <Button
                        disabled={formLoading || btnActionStatus || Mode === "view" || btnSaveStatus}
                        size={"medium"}
                        color={"info"}
                        startIcon={"Save"}
                        viewType={"filled"}
                        label={Config.lang("Luu")}
                        onClick={onSave}
                    />
                </div>
            </ModalFooter>
        </Modal >
    );
};

WA3F2001.propTypes = {
    openModal: PropTypes.bool,
    dataInfo: PropTypes.object,
    onOpenModalAction: PropTypes.func,
};

export default WA3F2001;
