/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/14/2020
 * @Example
 */

import Config    from "../../../../config";
import PropTypes from "prop-types";

class History {
    /**
     * @param props
     *      @options: {
     *          data: [],
     *          dataCompare: [],
     *          captions: {},
     *          action: 0,
     *          keyExpr: "",
     *          keyTransID: "",
     *          ortherData: [],
     *          transID, "",
     *          transactionID: "",
     *          transactionName: "",
     *          moduleID: "",
     *          detail: "",
     *          keyForAdd: [],
     *          keyForEdit: [],
     *          keyForRemove: [],
     *          itemRender: () => {}
     *      }
     */
    constructor (props) {
        const {UserID, UserName, UserNameU} = Config.profile || {};
        this.options = {
            //original data (required)
            data: props && props.data ? props.data : null,

            //Old data to compare
            dataCompare: props && props.dataCompare ? props.dataCompare : null,

            //Captions
            captions: props && props.captions ? props.captions : null,

            //Actions (options)
            action: props && props.action ? props.action : null,

            keyExpr: props && props.keyExpr ? props.keyExpr : "",
            keyTransID: props && props.keyTransID ? props.keyTransID : "",

            ortherData: props && props.ortherData ? props.ortherData : null,

            //TransactionID
            transID: props && props.TransID ? props.TransID : "",
            transactionID: props && props.TransactionID ? props.TransactionID : "",
            transactionName: props && props.TransactionName ? props.TransactionName : "",
            moduleID: props && props.ModuleID ? props.ModuleID : "",

            userID: props && props.UserID ? props.UserID : (UserID || ""),
            userName: props && props.UserName ? props.UserName : (UserNameU ? UserNameU : (UserName || "")),

            detail: props && props.detail ? props.detail : null,
            ...props
        };

        this.event = {
            itemRender: this.options.itemRender ? this.options.itemRender : null
        };

        this.connection = Config.controller ? Config.controller : null;

        this._data = [];

        //Init object
        this.init();
    }

    _setError = (msg) => {
        if (msg) console.log("=========== History =============\nError: " + msg);
        return false;
    };

    _isEmpty = (value) => {
        return value || value === 0 ? value : null;
    };

    _getNameAction = (action) => {
        if (action === 0 || action) {
            switch (Number(action)) {
                case 0: return "added";
                case 1: return "edited";
                case 2: return "added";
                case 3: return "removed";
                default: return "";
            }
        }
        return null;
    };

    /**
     * Create "Content": "" value...
     * @param newData
     * @param captions
     * @param action
     * @param options
     * @param dataOldCompare
     * @param test
     * @returns object "Content"
     * @private
     */
    _createContentHistory = (newData, captions, action, options, dataOldCompare, type) => {
        if (!newData || !captions || (action !== 0 && !action)) return null;
        if (action === 1 && !dataOldCompare) return null;

        const {keyForAdd, keyForEdit, keyForRemove, itemRender, excludeFields} = options || {};
        let obj = {};
        type = "Content" + (type ==="detail" ? "_DETAIL" : "");
        let objDetail = null;
        let isDiff = false;
        if (action !== 1) { //Khong phai edit
            Object.keys(captions).forEach(cap => {
                if (excludeFields && excludeFields.includes(cap)) return false; //Escapse fields in exclude field..
                if (action === 0 && keyForAdd && !keyForAdd.includes(cap)) return false; //Depend of keyForAdd when keyForAdd had inputed
                if (action === 3 && keyForRemove && !keyForRemove.includes(cap)) return false; //Depend of keyForRemove when keyForRemove had inputed
                const key = captions[cap] ? captions[cap] : "data_" + cap;
                const param = {data: newData, oldData: null, action: action, key: cap, item: {[key]: captions[cap]}};
                obj[key] = itemRender && !Config.isEmpty(itemRender(param, type), true) ? itemRender(param, type) : this._isEmpty(newData[cap]);
            });
            objDetail = this._createDetail(options);
            obj = Object.assign(obj, objDetail);
        } else {
            Object.keys(captions).forEach(cap => {
                if (excludeFields && excludeFields.includes(cap)) return false;
                if (keyForEdit && !keyForEdit.includes(cap)) return false; //Depend of keyForEdit when keyForEdit had inputed
                const key = captions[cap] ? captions[cap] : "data_" + cap;
                const param = {data: newData, oldData: dataOldCompare, action: action, key: cap, item: {[cap]: captions[cap]}};
                if (dataOldCompare[cap] !== newData[cap]) {
                    isDiff = true;
                    obj[key] = {
                        old: itemRender && !Config.isEmpty(itemRender(param, type + "_OLD"), true) ? itemRender(param, type + "_OLD") : this._isEmpty(dataOldCompare[cap]),
                        new: itemRender && !Config.isEmpty(itemRender(param, type + "_NEW"), true) ? itemRender(param, type + "_NEW") : this._isEmpty(newData[cap])
                    }
                } else {
                    obj[key] = itemRender && !Config.isEmpty(itemRender(param, type), true) ? itemRender(param, type) : this._isEmpty(newData[cap]);
                }
            });
            objDetail = this._createDetail(options);
            obj = Object.assign(obj, objDetail);
        }
        if (action !== 1) {
            return Object.keys(obj).length > 0 ? obj : null;
        } else {
            return (isDiff || objDetail) && Object.keys(obj).length > 0 ? obj : null;
        }
    };

    /**
     * Create key for data detail in Content value..
     * @param options
     * @param master
     * @returns object "Content"
     * @private
     */
    _createDetail = (options) => {
        const {detail} = options || this.options;
        const obj = {};
        if (detail && Object.keys(detail).length > 0) {
            Object.keys(detail).forEach(key => {
                obj[key] = detail[key];
            });
        }
        return Object.keys(obj).length > 0 ? obj : null;
    };

    /**
     * Init data to save history...
     * @param options
     * @param returnData
     * @returns {Promise<boolean|Array>}
     */
    init = async (options, returnData = false) => {
        options = options || this.options;
        let {data: newData, dataCompare, captions, action, transID, transactionID, transactionName,
                moduleID, keyExpr, keyTransID, ortherData, event, userID, userName
            } = options;
        if (!newData) return this._setError("Missing data");
        if (!captions) return this._setError("Missing captions data");
        if (action === 1 && !dataCompare) return this._setError("Missing dataCompare");
        if (action === 1 && Object.is(newData) !== Object.is(dataCompare)) return this._setError("Input data and dataCompare isn't same datatype");
        if (!transID || !transactionID || !transactionName) return this._setError("TransID or TransactionID or TransactionName is missing");

        let data = [];
        let dataType = newData ? Array.isArray(newData) ? "array" : (typeof newData === "object" ? "object" : null) : null;
        const {itemRender} = event || this.event;

        let param = {};
        let el = null;
        switch (dataType) {
            case "object":
                const _TransID = transID ? transID : (newData[keyTransID] ? newData[keyTransID] : newData[keyExpr]);
                if (action < 0 || (action !== 0 && !action)) {
                    [0,1,3].forEach(_action => {
                        param = {data: newData, oldData: dataCompare, action: _action,captions: captions};
                        const content = this._createContentHistory(newData, captions, _action, options, dataCompare);
                        if (content) {
                            el = {
                                ModuleID:        itemRender && itemRender(param, "ModuleID") ? itemRender(param, "ModuleID") : (moduleID || ""),
                                TransactionID:   itemRender && itemRender(param, "TransactionID") ? itemRender(param, "TransactionID") : (transactionID || ""),
                                TransactionName: itemRender && itemRender(param, "TransactionName") ? itemRender(param, "TransactionName") : (transactionName || ""),
                                TransID:         itemRender && itemRender(param, "TransID") ? itemRender(param, "TransID") : (_TransID || _TransID === 0 ? _TransID : ""),
                                Content:         content,
                                UserID:          userID || "",
                                UserName:        userName || "",
                                Action:          itemRender && itemRender(param, "Action") ? itemRender(param, "Action") : (_action || 0),
                            };
                            data.push(el);
                        }
                    });
                } else {
                    param = {data: newData, oldData: dataCompare, action: action,captions: captions};
                    const content = this._createContentHistory(newData, captions, action, options, dataCompare);
                    if (content) {
                        el = {
                            ModuleID:        itemRender && itemRender(param, "ModuleID") ? itemRender(param, "ModuleID") : (moduleID || ""),
                            TransactionID:   itemRender && itemRender(param, "TransactionID") ? itemRender(param, "TransactionID") : (transactionID || ""),
                            TransactionName: itemRender && itemRender(param, "TransactionName") ? itemRender(param, "TransactionName") : (transactionName || ""),
                            TransID:         itemRender && itemRender(param, "TransID") ? itemRender(param, "TransID") : (_TransID || _TransID === 0 ? _TransID : ""),
                            Content:         content,
                            UserID:          userID || "",
                            UserName:        userName || "",
                            Action:          itemRender && itemRender(param, "Action") ? itemRender(param, "Action") : (action || 0),
                        };
                        data.push(el);
                    }
                }

                break;
            case "array":
                //Array..
                newData = newData ? newData : [];
                let oldData = dataCompare ? dataCompare : [];
                if (action < 0 || (action !== 0 && !action)) { // Truong hop ko truyen action se tu dinh nghia action boi 2 array
                    const newDataKeys = newData.map(d => d[keyExpr]);
                    const oldDataKeys = oldData.map(d => d[keyExpr]);
                    const added = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) <= -1).map(d => ({...d, action: 2}));
                    const edited = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) > -1).map(d => ({...d, action: 1}));
                    const removed = oldData.filter(d => newDataKeys.indexOf(d[keyExpr]) <= -1).map(d => ({...d, action: 3}));
                    const arr = added.concat(edited).concat(removed); //Gop mang
                    arr.forEach(_d => {
                        const _action = _d.action;
                        const _dataOld = oldData && oldData.find(old => old[keyExpr] === _d[keyExpr]);
                        if (_action === 1 && !_dataOld) return false;
                        param = {data: _d, oldData: _dataOld, action: _action, captions: captions};
                        const content = this._createContentHistory(_d, captions, _action, options, _dataOld);
                        const _TransID = transID ? transID : (_d[keyTransID] ? _d[keyTransID] : _d[keyExpr]);
                        if (content) {
                            el = {
                                ModuleID:        itemRender && itemRender(param, "ModuleID") ? itemRender(param, "ModuleID") : (moduleID || ""),
                                TransactionID:   itemRender && itemRender(param, "TransactionID") ? itemRender(param, "TransactionID") : (transactionID || ""),
                                TransactionName: itemRender && itemRender(param, "TransactionName") ? itemRender(param, "TransactionName") : (transactionName || ""),
                                TransID:         itemRender && itemRender(param, "TransID") ? itemRender(param, "TransID") : (_TransID || _TransID === 0 ? _TransID : ""),
                                Content:         content,
                                UserID:          userID || "",
                                UserName:        userName || "",
                                Action:          itemRender && itemRender(param, "Action") ? itemRender(param, "Action") : (_action || 2),
                            };
                            data.push(el);
                        }
                    });

                } else { // Truong hop truyen action cu the cho truong hop array
                    newData.forEach(_d => {
                        const _dataOld = oldData && oldData.find(old => old[keyExpr] === _d[keyExpr]);
                        if (action === 1 && !_dataOld) return false;
                        param = {data: _d, oldData: _dataOld, action: action, captions: captions};
                        const content = this._createContentHistory(_d, captions, action, options, _dataOld);
                        const _TransID = transID ? transID : (_d[keyTransID] ? _d[keyTransID] : _d[keyExpr]);
                        if (content) {
                            el = {
                                ModuleID:        itemRender && itemRender(param, "ModuleID") ? itemRender(param, "ModuleID") : (moduleID || ""),
                                TransactionID:   itemRender && itemRender(param, "TransactionID") ? itemRender(param, "TransactionID") : (transactionID || ""),
                                TransactionName: itemRender && itemRender(param, "TransactionName") ? itemRender(param, "TransactionName") : (transactionName || ""),
                                TransID:         itemRender && itemRender(param, "TransID") ? itemRender(param, "TransID") : (_TransID || _TransID === 0 ? _TransID : ""),
                                Content:         content,
                                UserID:          userID || "",
                                UserName:        userName || "",
                                Action:          itemRender && itemRender(param, "Action") ? itemRender(param, "Action") : (action || 0),
                            };
                            data.push(el);
                        }
                    });
                }
                break;
            default:
                break;
        }

        if (ortherData && ortherData.length > 0) {
            data = ortherData.concat(data);
        }

        if (returnData) return data;
        this._data = data;

    };

    create = (options, importData = false) => {
        if (options) {
            options = Object.assign(options, this.options);
            const data = this.init(options, true);
            if (importData) this._data = this._data.concat(data);
            return importData ? this._data : data;
        } else {
            this.init();
        }
    };

    createDetailHistory = (key, data, dataCompare, captions, keyExpr, action, options ) => {
        if (!key) return this._setError("Detail: Missing key");
        if (!data) return this._setError("Detail: Missing data");
        if (!captions) return this._setError("Detail: Missing captions");
        if (action === 1 && !dataCompare) return this._setError("Detail: Missing dataCompare");
        if (action === 1 && Object.is(data) !== Object.is(dataCompare)) return this._setError("Detail: data and dataCompare isn't same datatype");

        let _data = {};
        if (Object.is(data)) { //data is object
            if (action === 0 || !action) {
                const content = this._createContentHistory(data, captions, action, options, dataCompare, "detail");
                const name = this._getNameAction(action);
                if (name && content) _data[name] = [content];
            } else {
                this._setError("Detail: Missing action");
                return null;
            }
        } else if (Array.isArray(data)) {
            if (!keyExpr) return this._setError("Detail: Missing keyExpr");
            //Array..
            const newData = data ? data : [];
            let oldData = dataCompare ? dataCompare : [];
            if (action < 0 || (action !== 0 && !action)) { // Truong hop ko truyen action se tu dinh nghia action boi 2 array
                const newDataKeys = newData.map(d => d[keyExpr]);
                const oldDataKeys = oldData.map(d => d[keyExpr]);
                const added = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) <= -1).map(d => ({...d, action: 2}));
                const edited = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) > -1).map(d => ({...d, action: 1}));
                const removed = oldData.filter(d => newDataKeys.indexOf(d[keyExpr]) <= -1).map(d => ({...d, action: 3}));
                const arr = added.concat(edited).concat(removed); //Gop mang
                arr.forEach(_d => {
                    const _action = _d.action;
                    const name = this._getNameAction(_action);
                    // if (name && !_data[name]) return false;
                    const _dataOld = oldData && oldData.find(old => old[keyExpr] === _d[keyExpr]);
                    if (_action === 1 && !_dataOld) return false;
                    const content = this._createContentHistory(_d, captions, _action, options, _dataOld, "detail");
                    if (name && content) {
                        if (!_data[name]) _data[name] = [];
                        _data[name].push(content);
                    }
                });

            } else { // Truong hop truyen action cu the cho truong hop array
                newData.forEach(_d => {
                    const _dataOld = oldData && oldData.find(old => old[keyExpr] === _d[keyExpr]);
                    if (action === 1 && !_dataOld) return false;
                    const name = this._getNameAction(action);
                    // if (name && !_data[name]) _data[name] = [];
                    const content = this._createContentHistory(_d, captions, action, options, _dataOld, "detail");
                    if (name && content) {
                        if (!_data[name]) _data[name] = [];
                        _data[name].push(content);
                    }
                });
            }
        }

        if (_data && Object.keys(_data).length > 0) {
            const {detail} = this.options;
            if (!detail) {
                this.options.detail = {[key]: _data};
            } else {
                this.options.detail[key] = _data;
            }
        }
        this.init();
    };

    options = (key, value) => {
        this.options[key] = value;
        this.init();
    };

    refresh = () => {
        this.init();
    };

    get = () => {
        this.refresh();
        return this._data;
    };

    import = (data) => {
        if (data && data.length > 0) {
            this._data = this._data.concat(data);
            return true;
        }
        return false;
    };

    setConnection = (newConnection) => {
        this.connection = newConnection;
    };

    save = async (cb, data2) => {
        let status = false;
        const data = data2 || this._data;
        if (!data?.length) return false;
        const params = {
            attributes: JSON.stringify(data),
        };
        if (this.connection) {
            await this.connection.saveHistory(params, (error, data) => {
                cb && cb(error, data);
                status = !error;
            });
        }
        return status;
    };

}

History.propTypes = {
    data: PropTypes.any, //{} or []
    dataCompare: PropTypes.any, //{} or []
    captions: PropTypes.object,
    action: PropTypes.number,
    keyExpr: PropTypes.string,
    keyTransID: PropTypes.string,
    ortherData: PropTypes.array,
    transID: PropTypes.string,
    transactionID: PropTypes.string,
    transactionName: PropTypes.string,
    moduleID: PropTypes.string,
    keyForAdd: PropTypes.array,
    keyForEdit: PropTypes.array,
    keyForRemove: PropTypes.array,

    itemRender: PropTypes.func,
};

export default History;
