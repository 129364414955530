/**
 * @copyright 2022 @ DigiNet
 * @author ANHTAI
 * @create 27/06/2022
 * @Example
 */

import _ from "lodash";
import PropTypes from "prop-types";
import React from 'react';
import { makeStyles } from "diginet-core-ui/theme";
import DateBox from 'devextreme-react/date-box';
import { Label } from 'diginet-core-ui/components';
import { useTheme } from "diginet-core-ui/theme";

const useStyles = makeStyles((theme) => ({
    dangerColor: {
        color: theme?.colors?.danger || ''
    },
    validateField: {
        '&:not(.disableDot)': {
            '& .DGN-UI-InputBase': {
                '&:before': {
                    borderBottom: `1px solid ${theme?.colors?.danger} !important`,
                },
                '&:after': {
                    borderBottom: `1px solid ${theme?.colors?.danger} !important`,
                }
            },
            '&:hover': {
                '& .DGN-UI-InputBase': {
                    '&:before': {
                        borderBottom: `1px solid ${theme?.colors?.line?.hover} !important`,
                    },
                    '&:after': {
                        borderBottom: `1px solid ${theme?.colors?.line?.hover} !important`,
                    }
                }
            }
        }
    }
}));

const TimePickerDropDown = (props) => {
    const classes = useStyles();
    const { spacing } = useTheme();
    const { disabled, value, placeholder, error, style, className, label, readOnly, defaultValue, required, interval, showClearButton } = props;
    const timePickerValidateClass = (!_.isEmpty(error) && _.isEmpty(value)) && classes.validateField;
    const requiredStatus = !_.isEmpty(error) && _.isEmpty(value) ? classes.dangerColor : '';
    const dotted = disabled && "disableDot";

    const onChange = (e) => {
        const { onChange } = props;
        const valueChange = e?.value || "";
        if (onChange) onChange(valueChange);
    };

    return (
        <div style={{ position: 'relative' }}>
            {label && <div className={"display_row"}><Label type={"h6"}>{label}</Label>
                {required && <span style={{ fontSize: spacing(3), lineHeight: `${spacing(4)}px`, marginLeft: 2 }} className={classes.dangerColor}>*</span>}
            </div>}
            <DateBox
                style={{ ...style }}
                className={`${timePickerValidateClass} ${dotted} ${className}`}
                useMaskBehavior
                type={"time"}
                format={"HH:mm"}
                displayFormat={"HH:mm"}
                dateSerializationFormat={"HH:mm"}
                value={value}
                interval={interval}
                disabled={disabled}
                readOnly={readOnly}
                onValueChanged={onChange}
                defaultValue={defaultValue}
                placeholder={placeholder}
                showClearButton={showClearButton}
            />
            {requiredStatus && error && <div style={{ fontSize: spacing(3) }} className={`${classes.dangerColor}`}>{error}</div>}
        </div>
    )
};

TimePickerDropDown.defaultProps = {
    required: false,
    disabled: false,
    readOnly: false,
    showClearButton: false,
    interval: 30, // Khoảng cách phút
    error: "",
    label: ""
};

TimePickerDropDown.propTypes = {
    error: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    showClearButton: PropTypes.bool,
    interval: PropTypes.number,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
};

export default TimePickerDropDown;