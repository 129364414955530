/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/11/2020
 * @Example
 */

import React        from "react";
import {withStyles} from "@material-ui/core";
import PropTypes    from "prop-types";
import {compose}    from "redux";
import {Popover}    from "devextreme-react";

const styles = {};

class PopoverAction extends React.PureComponent {
    constructor (props) {
        super(props);
        this.state = {};
    }

    render () {
        const {
                  style, className, popoverProps, position, maxWidth,
                  reference, target, deferRendering, visible, onContentReady,
                  children
              } = this.props;

        return (
            <Popover
                ref={reference}
                style={style}
                className={className}
                target={target}
                position={position}
                maxWidth={maxWidth}
                deferRendering={deferRendering}
                visible={visible}
                onContentReady={onContentReady}
                {...popoverProps}
            >
                {children}
            </Popover>
        );
    }
}

PopoverAction.propTypes = {
    style:          PropTypes.any,
    className:      PropTypes.string,
    reference:      PropTypes.any,
    target:         PropTypes.any,
    position:       PropTypes.oneOf(["left", "right"]),
    deferRendering: PropTypes.bool,
    visible:        PropTypes.bool,
    maxWidth:       PropTypes.any,

    onContentReady: PropTypes.func
};

export default compose(withStyles(styles))(PopoverAction)